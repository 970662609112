@import '../../../../../../../../../../styles/mixins';

.timePresetModal {
    max-width: 100%;
    width: 520px;
    background-color: #fff;
    border-radius: 4px;
    padding: 24px 40px 20px;
    text-align: left;
    box-sizing: border-box;
    @include max_rwd(599) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.title {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #3c3c3c;
}

.description {
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #3c3c3c;
}

.presetsList {
    margin-top: 14px;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    @include max_rwd(599) {
        flex-direction: column;
    }
}

.cancelButton {
    margin-right: 12px;
    @include max_rwd(599) {
        margin-right: 0;
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
