@import '../../../styles/variables';
@import '../../../styles/mixins';

.selectOrganizationWrapper {
    width: 100%;
    background: #fff;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 99999999999;
    overflow: auto;
    padding: 60px 20px;
    box-sizing: border-box;
}
.selectOrganization {
    max-width: 525px;
    margin: auto;
    font-family: $Ubuntu;
    font-style: normal;
    text-align: center;
}

.selectOrganizationTitle {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -1px;
    color: #000;
    @include max_sm {
        font-size: 32px;
        line-height: normal;
    }
}

.selectOrganizationDescription {
    margin-top: 28px;
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: -1px;
    color: $color_dark_gray--3;
    @include max_sm {
        margin-top: 20px;
        font-size: 24px;
        line-height: normal;
    }
}

.selectOrganizationList {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    @include max_sm {
        margin-top: 40px;
    }
}

.selectOrganizationItem {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 13px;

    width: 100%;
    padding: 15px 23px;
    box-shadow: 0 0 0 1px $color_light_gray--2;
    border-radius: 4px;

    font-family: $Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    cursor: pointer;

    color: $color_dark_gray--3;

    &:hover {
        box-shadow: 0 0 0 2px $color_light_blue--1;
    }
}
.selectOrganizationItemSelected {
    box-shadow: 0 0 0 2px $color_blue--1 !important;
}
.selectOrganizationItemImage {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    overflow: hidden;
    > img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
    }

    > span {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color_white;
        border: 1px solid $color_light_gray--2;
        border-radius: 4px;
        font-family: $Roboto;
        font-weight: 500;
        font-size: 24px;
        color: $color_blue--1;
    }
}
.selectOrganizationButtons {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}
