.group {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0 4px 12px rgb(0 0 0 / 8%);
    border-radius: 8px;
    padding: 12px;
    margin: 10px 0;
}

.groupActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.dropdown {
    text-align: center;
    width: 20px;
    margin-left: 50px;
}

.input {
    font-size: 14px;
}

.dropdownItem {
    display: flex;
    align-items: center;
    margin: 3px 0;
}

.dropdownIcon {
    width: 20px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
