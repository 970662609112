@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.organization-panel__user-list {
    display: flex;
    flex-direction: column;
}

.organization-panel__user {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    border-radius: 4px;
    min-height: 48px;

    font-family: $Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $color_dark_gray--3;
    cursor: pointer;

    &.selected {
        background-color: $color_light_blue--1;
        color: $color_white;
        .organization-panel__user-projects {
            color: $color_white;
        }
        .organization-panel__user-postfix {
            color: $color_white;
        }
    }

    &.un-approved {
        opacity: 0.5;
        pointer-events: none;
    }

    &-icon {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        color: $color_white;
        margin-right: 6px;
        overflow: hidden;
        flex-shrink: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &-title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-postfix {
        color: #979797;
        margin-left: 5px;
    }

    &-projects {
        flex-shrink: 0;
        margin-left: 10px;
        text-align: right;
        color: $color_middle_gray--2;
    }
}
