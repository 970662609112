@import '../../../styles/mixins';

.MuiSwitch-root {
    font-size: 0;
    position: relative;
    display: inline-block;
    width: 30px;
    height: 16px;
    padding: 0;
    cursor: pointer;

    &.Mui-disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }

    & .MuiSwitch-track {
        background: #bfc7cf;
        border-radius: 8px;
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    & .MuiSwitch-thumb {
        display: block;
        width: 14px;
        height: 14px;
        top: 1px;
        left: 1px;
        border-radius: 16px;
        background-color: #fff;
        position: relative;
        transition: all 200ms ease;
    }

    &.focusVisible .MuiSwitch-thumb {
        background-color: #aab4be;
        box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
    }

    &.Mui-checked {
        .MuiSwitch-thumb {
            left: 15px;
            background-color: #fff;
        }

        .MuiSwitch-track {
            background: #65c466;
            @include transition(0.1s, ease-in-out, 0s, (background-color));
        }
    }

    & .MuiSwitch-input {
        cursor: inherit;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 1;
        margin: 0;
    }
}
