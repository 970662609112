.memory-modal-pairs {
    padding: 36px 40px 20px;
    height: 100%;

    .memory-modal-pairs__head-wrapper {
        display: flex;
        justify-content: space-between;

        .memory-modal-pairs__head-title {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            position: relative;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 500;
            font-size: 24px;
            color: #3c3c3c;
        }
        .memory-modal-pairs__head-switcher {
        }
    }

    .memory-modal-pairs__body-wrapper {
        height: 70%;

        .memory-modal-pairs__card-block-wrapper {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .memory-modal-pairs__card-block {
                display: flex;
                height: 100%;
                flex-flow: row wrap;
                margin: 0 18px;
                box-sizing: border-box;

                &.with-description {
                    width: 100%;
                }

                .memory-modal-pairs__card-pair {
                    color: #000;
                    margin: 0.5rem;
                    position: relative;

                    .memory-modal-pairs__card-pair-title {
                        margin-bottom: 0.2rem;
                        margin-left: 0.2rem;
                    }

                    .memory-modal-pairs__card-pair-view {
                        display: flex;
                    }
                }
                .memory-modal-pairs__card-description {
                    width: 75%;
                }
            }
        }
    }
}
