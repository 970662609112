.puzzle-block-horizontal {
    overflow: hidden;
    background-color: white;
    width: 100%;
    &__img-container {
        display: inline-block;
        width: 50%;
        height: 100%;
        padding: 0 12px;
    }
    &__img {
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
        width: 100%;
        height: 100%;
    }
    &__img-grid {
        transform: translate(0%, -100%);
    }
    &__grid {
        td {
            opacity: 1;
            box-shadow: inset 0 0 0 1px white;
        }
    }
    &__table {
        width: 100%;
        td {
            background-color: #f3f7fa;
            box-shadow: inset 0 0 0 1px white;
        }
    }
    &__inline-block {
        display: inline-block;
        width: calc(25% - 12px);
        height: 100%;
    }
}

.puzzle-modal-playground__preview {
    .puzzle-block-horizontal {
        background-color: white;
        width: 100%;
        margin: auto;
        &__img-container {
            display: inline-block;
            width: 100%;
            height: 50%;
            padding: 12px 0;
        }
        &__inline-block {
            display: inline-block;
            width: 100%;
            height: 25%;
        }
    }
}

.puzzle-playground-preview__cover-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .puzzle-playground-preview__cover {
        width: 60%;
        padding: 60px 40px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
        text-align: center;

        .puzzle-playground-preview__cover-header {
            font-weight: 500;
            font-size: 36px;
            line-height: 42px;
            color: #000;
            margin-bottom: 32px;
            white-space: pre-line;
        }

        .puzzle-playground-preview__cover-btn {
            background-color: #4891f3;
            overflow: hidden;
            outline: none;
            cursor: pointer;
            border: 0;
            padding: 0 44px;
            height: 50px;
            white-space: nowrap;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
            border-radius: 4px;
            color: #fff;
            font-size: 20px;
        }
        &.is-modal {
            padding: 20px 25px;
            .puzzle-playground-preview__cover-header {
                font-size: 16px;
                line-height: 18px;
                margin-bottom: 15px;
            }

            .puzzle-playground-preview__cover-btn {
                font-size: 12px;
                padding: 0 42px;
                height: 35px;
            }
        }
    }
}
