.common-upload-image-group {
    padding: 5px 0;

    &.is-disabled {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    }

    .common-upload-image-group__label {
        height: 16px;
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .common-upload-image-group__content {
        display: flex;
        align-items: center;

        .common-upload-image-group__img-preview {
            width: 32px;
            height: 32px;
            border-radius: 2px;
            margin-right: 8px;
            flex-shrink: 0;
            object-position: center;
            object-fit: cover;
            background-color: #d7d7d7;
        }

        .common-upload-image-group__contr-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 112px;
            height: 32px;
            margin-bottom: 0;
            font-size: 16px;
            font-family: 'Roboto', sans-serif;
            font-weight: normal;
            font-style: normal;
            color: #fff;
            cursor: pointer;
            background-color: #2990fb;
            border-radius: 4px;
        }

        .common-upload-image-group__delete-btn {
            margin-left: 15px;
            cursor: pointer;
            height: 32px;
            width: 20px;
            background-image: url('i/delete.svg');
            background-position: center;
            background-repeat: no-repeat;
            transition-duration: 0.2s;
            transition-timing-function: ease-in-out;
            transition-delay: 0s;
            transition-property: opacity;
            &:hover {
                opacity: 0.7;
            }
        }
    }
}
