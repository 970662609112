.addButton {
    display: flex;
}

.button {
    font-size: 14px;
    color: #2990fb;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
    > img {
        padding-right: 8px;
    }
}
