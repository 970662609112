.aside {
    width: 550px;
    height: 100vh;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2990fb;
    background-position: 50% 100%;
    background-image: url('./i/mockups.png');
    background-repeat: no-repeat;
    flex-shrink: 0;
    @media (max-width: 1599px) {
        width: 460px;
    }
    @media (max-width: 1199px) {
        align-items: flex-start;
        width: 400px;
    }
    @media (max-width: 991px) {
        display: none;
    }
}

.box {
    margin-bottom: 150px;
    @media (max-width: 1599px) {
        margin-bottom: 250px;
    }
    @media (max-width: 1199px) {
        margin-bottom: 0;
        margin-top: 45px;
    }
}

.link {
}

.icon {
    width: 240px;
    height: auto;
}

.text {
    color: #fff;
    margin-top: 20px;
    width: 240px;
    font-family: 'Ubuntu', sans-serif;
    font-size: 20px;
    line-height: 24px;
}
