.notSeeEmail {
    margin-top: 32px;
    padding-top: 33px;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        width: calc(100% - 75px);
        background-color: #d8d8d8;
        @media (max-width: 991px) {
            width: 100%;
        }
    }
    @media (max-width: 991px) {
        text-align: center;
    }
}

.title {
    font-weight: 500;
    font-size: 18px;
    color: #000;
}

.description {
    margin-top: 8px;
    font-size: 16px;
}

.image {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 1320px) {
        justify-content: flex-start;
    }
    @media (max-width: 991px) {
        justify-content: center;
    }
    > img {
        margin: -20px;
        @media (max-width: 1320px) {
            max-width: 100%;
        }
    }
}

.buttons {
    display: flex;
    margin-top: 40px;
    @media (max-width: 991px) {
        justify-content: center;
    }
    @media (max-width: 567px) {
        flex-direction: column;
    }
    > button {
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
        @media (max-width: 567px) {
            margin-right: 0;
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
