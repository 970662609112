.item {
    display: flex;
    align-items: center;
    transition: opacity 0.2s ease-in-out;
    justify-content: center;
    &:hover {
        opacity: 0.8;
    }
}

.icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.labelIcon {
    margin-left: 8px;
}

.newBadge {
    margin-left: 8px;
    display: flex;
    align-items: center;
}
.newBadgeContent {
    text-transform: lowercase;
    height: 20px;
    background-color: #785cc7;
    border-radius: 20px;
    font-size: 12px;
    color: #fff;
    padding: 0 8px;
    display: flex;
    align-items: center;
}
