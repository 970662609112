.rank-battle {
    &__like-button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &__icon-wrapper {
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;
            display: flex;
        }

        &__icon {
            position: absolute;
        }
    }
}
