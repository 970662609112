.columns {
    margin-top: 20px;
    height: 200px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.column {
    margin-top: 20px;
    background-color: #2990fb;
    width: 2px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin-right: 2px;
}
