.input {
    height: 36px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.label {
    font-size: 16px !important;
    color: #8a8a8a !important;
    margin-bottom: 8px !important;
    height: auto !important;
}
