.editBlockButton {
    z-index: 10000;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    top: 16px;
    left: 22px;
    padding: 0 16px 0 42px;
    background-image: url('../../../../../i/edit.svg');
    background-position: 15px 50%;
    background-repeat: no-repeat;
    background-color: #2990fb;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 4px;
    height: 38px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #fff;
    animation: pulse 1.5s infinite linear;
    &:hover {
        animation-play-state: paused;
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(41, 144, 251, 0.2);
    }
    50% {
        box-shadow: 0 0 0 3px rgba(41, 144, 251, 0.16);
    }
    75% {
        box-shadow: 0 0 0 7px rgba(41, 144, 251, 0.1);
    }
    100% {
        box-shadow: 0 0 0 14px rgba(41, 144, 251, 0);
    }
}
