.inlineControls {
    position: absolute;
    z-index: 50;
    top: 8px;
    right: 4px;
    display: flex;
    opacity: 0;
    visibility: hidden;
}

.isVisibleInlineControls {
    opacity: 1;
    visibility: visible;
}

.control {
    background-color: #fff;
    width: 24px;
    height: 24px;
    margin-right: 6px;
    border: 1px solid #aaa;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: #eee;
    }
    &:last-child {
        margin-right: 0;
    }
}
