.coverPreview {
    position: absolute;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.coverBox {
    width: 380px;
    max-width: 92%;
    max-height: 92%;
    box-sizing: border-box;
    padding: 48px 40px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
    text-align: center;
}

.isModalCoverBox {
    padding: 20px 25px;
    .header {
        font-size: 16px;
        line-height: 18px;
    }
    .button {
        font-size: 12px;
        padding: 0 20px;
        height: 35px;
        margin-top: 15px;
    }
}

.header {
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    color: #000;
    white-space: pre-line;
}

.button {
    outline: none;
    cursor: pointer;
    border: 0;
    padding: 0 44px;
    height: 50px;
    white-space: nowrap;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 32px;
}

.noData {
    text-align: center;
    font-family: 'Ubuntu', sans-serif;
    font-size: 12px;
    font-style: italic;
    color: #787878;
}
