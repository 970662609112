@import '../../../../../../../../styles/variables';
@import '../../../../../../../../styles/mixins';

.layout {
    width: 106px;
    user-select: none;
    z-index: 5;
    margin: 35px 0 -35px -26px;
    border: 6px solid #efefef;
    border-top: 0;
    border-bottom: 0;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    box-sizing: border-box;
    background-color: #fff;
    @include max_sm {
        max-width: 40%;
    }
    @include max_rwd(1450) {
        display: none;
    }
}

.mockup {
    height: 22px;
    position: relative;
    background-color: #efefef;
    > span {
        position: absolute;
        top: 12px;
        left: 50%;
        transform: translateX(-50%);
        width: 36px;
        height: 3px;
        display: block;
        margin: 0 auto;
        background-color: #d8d8d8;
        border-radius: 1.5px;
    }
}

.image {
    position: relative;
    width: 100%;
    padding-bottom: 177%;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
}
