.column {
    width: 50%;
    list-style: none;
    padding-right: 20px;
    box-sizing: border-box;
    &:last-child {
        padding-right: 0;
    }
}

.title {
    font-size: 20px;
    margin-bottom: 20px;
    color: #3c3c3c;
}
