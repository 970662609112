.progress {
    background: $color_white;
    box-shadow: $box-shadow--1;
    border-radius: 4px;
    padding: 20px 30px 30px;
    margin-bottom: 25px;
    @include max_md {
        padding: 20px;
    }
    > .head {
        display: flex;
        justify-content: space-between;
        > .left {
            display: flex;
            font-size: 14px;
            color: $color_light_gray--4;
            > span {
                color: $color_middle_gray--1;
            }
            @include max_rwd(768) {
                flex-direction: column;
            }
            > p {
                display: inline;
                @include max_rwd(768) {
                    padding-top: 5px;
                    font-size: 12px;
                }
                &:before {
                    content: '\00a0| ';
                    @include max_rwd(768) {
                        display: none;
                    }
                }
            }
        }
        > .right {
            font-size: 14px;
            color: $color_light_gray--5;
            @include max_rwd(1140) {
                display: none;
            }
        }
    }
    > .line {
        margin-top: 8px;
        width: 100%;
        height: 8px;
        display: block;
        border-radius: 2px;
        background-color: $color_light_gray--7;
        position: relative;
        overflow: hidden;
        > span {
            display: inline-block;
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            &:not(.prevent-min-width) {
                min-width: 1%;
            }
            &[data-status='normal'] {
                background-color: $color_blue--1;
            }
            &[data-status='danger'] {
                background-color: $color_orange--2;
            }
        }
    }
    > .mobile {
        display: none;
        font-size: 14px;
        margin-top: 8px;
        color: $color_light_gray--5;
        @include max_rwd(1140) {
            display: block;
        }
    }
}
