@import '../../../styles/variables';

.upload-bar {
    display: flex;
    position: absolute;
    padding: 0 4px;
    bottom: 12px;
    left: 12px;
    width: 320px;
    min-height: 72px;
    border-radius: 8px;
    border: 1px solid $color_light_blue--1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    background-color: $color_white;
    z-index: 150;
}

.upload-bar__loading-box {
    width: 64px;
    display: flex;
    justify-content: center;
    padding-top: 16px;
}

.upload-bar__loading {
    width: 40px;
    height: 40px;
    position: relative;
    // background-color: black;
    &-prepare {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40px;
        height: 40px;
        margin: -20px 0 0 -20px;
        -webkit-animation: spin123456 4s linear infinite;
        -moz-animation: spin123456 4s linear infinite;
        animation: spin123456 4s linear infinite;

        @-moz-keyframes spin123456 {
            100% {
                -moz-transform: rotate(360deg);
            }
        }
        @-webkit-keyframes spin123456 {
            100% {
                -webkit-transform: rotate(360deg);
            }
        }
        @keyframes spin123456 {
            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
    }

    &-error {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40px;
        height: 40px;
        margin: -20px 0 0 -20px;
    }
}

.upload-bar__list {
    font-family: $Roboto;
    font-style: normal;
    font-weight: normal;
    padding-top: 16px;
    margin-bottom: 16px;
    margin-left: 5px;
    line-height: 16px;
    flex-grow: 2;

    &-title {
        font-size: 14px;
        color: $color_dark_gray--3;
        margin-bottom: 8px;
        text-align: left;

        &.error {
            color: $color_red--1;
        }
    }
}

.upload-bar__item {
    font-size: 13px;
    color: $color_middle_gray--2;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1px;

    span {
        max-width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow-wrap: break-word;
        word-wrap: break-word;

        &.ok {
            color: $color_green--1;
        }

        &.error {
            color: $color_red--1;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    .stats-bar__measure {
        white-space: nowrap;
    }
}

.upload-bar__close-box {
    width: 15px;
    padding: 10px 6px;
}
.upload-bar__close-icon {
    cursor: pointer;
}
