@import '../../styles/variables';
@import '../../styles/mixins';

@import 'utils/variables';

.asset-manager__main {
    overflow: hidden;
    display: flex;
    width: 960px;
    height: 600px;
    background-color: #f9fafd;
    font-family: $Ubuntu;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.asset-manager__side-panel {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: $width_side-panel;
    box-shadow: inset -1px 0 0 0 $color_light_gray--6;
    background-color: #fff;
    flex-shrink: 0;
    position: relative;
}

.asset-manager__upload-settings {
    background-color: #f9fafd;
    border-radius: 4px;
    padding: 20px 16px 16px;
    box-sizing: border-box;
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    text-align: left;
    &__list {
        &-item {
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__note {
        margin-top: 12px;
        font-size: 11px;
        line-height: 14px;
        color: #787878;
    }
}

.asset-manager__menu {
    margin-top: 20px;
    margin-bottom: 30px;
}

.asset-manager__upload-media {
    width: 168px;
    justify-content: center;
}

.asset-manager__tab-space {
    overflow: hidden;
    flex-grow: 1;
    position: relative;
}

.asset-manager__record-voice {
    width: 168px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    background-color: #fff;
    border: 1px solid #979797;
    border-radius: 4px;
    margin-bottom: 16px;
    font-size: 16px;
    color: #3c3c3c;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.7;
    }
    > span {
        position: relative;
        height: 14px;
        width: 14px;
        display: flex;
        margin-right: 6px;
        border: 1px solid #eb5757;
        border-radius: 50%;
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #eb5757;
        }
    }
}
