@font-face {
    font-family: 'Ubuntu';
    src: url('../../fonts/Ubuntu/Ubuntu-Medium.eot');
    src: url('../../fonts/Ubuntu/Ubuntu-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Ubuntu/Ubuntu-Medium.woff2') format('woff2'),
        url('../../fonts/Ubuntu/Ubuntu-Medium.woff') format('woff'),
        url('../../fonts/Ubuntu/Ubuntu-Medium.ttf') format('truetype'),
        url('../../fonts/Ubuntu/Ubuntu-Medium.svg#Ubuntu-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../../fonts/Ubuntu/Ubuntu-BoldItalic.eot');
    src: url('../../fonts/Ubuntu/Ubuntu-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Ubuntu/Ubuntu-BoldItalic.woff2') format('woff2'),
        url('../../fonts/Ubuntu/Ubuntu-BoldItalic.woff') format('woff'),
        url('../../fonts/Ubuntu/Ubuntu-BoldItalic.ttf') format('truetype'),
        url('../../fonts/Ubuntu/Ubuntu-BoldItalic.svg#Ubuntu-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../../fonts/Ubuntu/Ubuntu-MediumItalic.eot');
    src: url('../../fonts/Ubuntu/Ubuntu-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Ubuntu/Ubuntu-MediumItalic.woff2') format('woff2'),
        url('../../fonts/Ubuntu/Ubuntu-MediumItalic.woff') format('woff'),
        url('../../fonts/Ubuntu/Ubuntu-MediumItalic.ttf') format('truetype'),
        url('../../fonts/Ubuntu/Ubuntu-MediumItalic.svg#Ubuntu-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../../fonts/Ubuntu/Ubuntu-LightItalic.eot');
    src: url('../../fonts/Ubuntu/Ubuntu-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Ubuntu/Ubuntu-LightItalic.woff2') format('woff2'),
        url('../../fonts/Ubuntu/Ubuntu-LightItalic.woff') format('woff'),
        url('../../fonts/Ubuntu/Ubuntu-LightItalic.ttf') format('truetype'),
        url('../../fonts/Ubuntu/Ubuntu-LightItalic.svg#Ubuntu-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../../fonts/Ubuntu/Ubuntu-Light.eot');
    src: url('../../fonts/Ubuntu/Ubuntu-Light.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Ubuntu/Ubuntu-Light.woff2') format('woff2'),
        url('../../fonts/Ubuntu/Ubuntu-Light.woff') format('woff'),
        url('../../fonts/Ubuntu/Ubuntu-Light.ttf') format('truetype'),
        url('../../fonts/Ubuntu/Ubuntu-Light.svg#Ubuntu-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../../fonts/Ubuntu/Ubuntu-Regular.eot');
    src: url('../../fonts/Ubuntu/Ubuntu-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Ubuntu/Ubuntu-Regular.woff2') format('woff2'),
        url('../../fonts/Ubuntu/Ubuntu-Regular.woff') format('woff'),
        url('../../fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype'),
        url('../../fonts/Ubuntu/Ubuntu-Regular.svg#Ubuntu-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../../fonts/Ubuntu/Ubuntu-Bold.eot');
    src: url('../../fonts/Ubuntu/Ubuntu-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Ubuntu/Ubuntu-Bold.woff2') format('woff2'),
        url('../../fonts/Ubuntu/Ubuntu-Bold.woff') format('woff'),
        url('../../fonts/Ubuntu/Ubuntu-Bold.ttf') format('truetype'),
        url('../../fonts/Ubuntu/Ubuntu-Bold.svg#Ubuntu-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../../fonts/Ubuntu/Ubuntu-Italic.eot');
    src: url('../../fonts/Ubuntu/Ubuntu-Italic.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Ubuntu/Ubuntu-Italic.woff2') format('woff2'),
        url('../../fonts/Ubuntu/Ubuntu-Italic.woff') format('woff'),
        url('../../fonts/Ubuntu/Ubuntu-Italic.ttf') format('truetype'),
        url('../../fonts/Ubuntu/Ubuntu-Italic.svg#Ubuntu-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
