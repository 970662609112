@import '../../../../../../../../styles/mixins';

.modeCard {
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    height: 100px;
    border: 1px solid transparent;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
    width: 50%;
    margin: 0 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: opacity 0.2s ease-in-out;
    padding-left: 18px;
    box-sizing: border-box;
    position: relative;
    &:hover {
        opacity: 0.8;
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
    }
    @include max_rwd(1023) {
        width: 100%;
        margin: 0 0 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include max_rwd(599) {
        &:before {
            background-color: rgba(255, 255, 255, 0.7);
        }
    }
}

.isSelectedModeCard {
    border-color: #2990fb;
    pointer-events: none;
    cursor: default;
    .mark {
        border-color: #2990fb;
        &:before {
            background-color: #2990fb;
        }
    }
}

.isDisabledModeCard {
    pointer-events: none;
    opacity: 0.4;
}

.mark {
    position: relative;
    z-index: 1;
    width: 24px;
    height: 24px;
    background: #fff;
    border: 1px solid #979797;
    border-radius: 50%;
    flex-shrink: 0;
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: transparent;
        transition: background-color 0.2s ease-in-out;
    }
}

.texts {
    margin-left: 14px;
    max-width: 160px;
    text-align: left;
    padding-right: 20px;
    box-sizing: content-box;
    position: relative;
    z-index: 1;
}

.title {
    font-size: 16px;
    line-height: 20px;
    color: #3c3c3c;
}

.description {
    margin-top: 4px;
    font-size: 12px;
    line-height: 14px;
    color: #787878;
}
