@import '../../../styles/variables';

.radioPair {
    position: relative;
}

.label {
    height: 16px;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-size: 14px;
}

.list {
    display: flex;
}

.item {
    border: 1px solid #d8d8d8;
    width: 140px;
    height: 32px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:first-child {
        border-radius: 4px 0 0 4px;
    }
    &:last-child {
        border-radius: 0 4px 4px 0;
    }
}

.itemLabel {
    font-family: $Roboto;
    font-size: 14px;
    line-height: 14px;
    color: #3c3c3c;
    padding-left: 10px;
}

.isActiveItem {
    border-color: #2990fb;
    pointer-events: none;
}

.itemImage {
    position: relative;
}
