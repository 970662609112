@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.upgradeSuccessful {
    box-sizing: border-box;
    width: 640px;
    height: auto;
    max-width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 60px;
    background-image: url('./i/background.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;
    position: relative;
    overflow: hidden;
    @include max_rwd(700) {
        padding: 40px 20px;
    }
}

.title {
    font-family: $Ubuntu;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -1px;
    color: #000;
    width: 100%;
    max-width: 380px;
    margin: 80px auto 0;
    padding-bottom: 28px;
    background-color: #fff;
    @include max_rwd(700) {
        margin: 40px auto 0;
    }
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    width: 22px;
    height: 22px;
    background-color: #fff;
    padding: 20px;
    background-image: url('./i/cross.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.buttonBox {
    display: flex;
    justify-content: center;
}

.linkBox {
    margin-top: 66px;
    display: flex;
    justify-content: center;
    @include max_rwd(700) {
        margin-top: 40px;
    }
    > a {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 14px;
        color: #979797;
        transition: opacity 0.2s ease-in-out;
        &:hover {
            opacity: 0.8;
        }
        > img {
            padding-left: 10px;
        }
    }
}
