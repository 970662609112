.card-editor {
    padding-top: 20px;

    &__area {
        display: flex;
    }
    &__cover-group {
        margin-right: 20px;
        width: 128px;
    }
    &__detail-group {
        width: 100%;
    }

    &__group-item {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
