.over-banner {
    background-color: #ffffff;
    border: 2px solid #fd8d8d;
    border-radius: 4px;
    &__message {
        line-height: 16px;
        color: #3c3c3c;
    }
    &__icon {
        line-height: 0;
    }
}
