.preview {
    width: 100%;
    pointer-events: none;
    position: relative;
}

.wheelSpin {
    width: 100%;
    box-sizing: border-box;
    padding: 30px 10px 10px;
}

.isModalWheelSpin {
    padding: 15px 0 0;
}

.wheelSpinContainer {
    height: auto;
    position: relative;
    margin: 0 auto;
}
