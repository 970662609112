.name {
    font-size: 14px;
    color: #3c3c3c;
}

.flexedControlBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
