.sectionContent {
    color: #fff;
    transform: scale(-1, -1) translateX(50%);
    position: absolute;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    left: 50%;
    top: auto;
    right: 0;
    overflow: hidden;
}
