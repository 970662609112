.common-toggle {
    display: flex;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;

    &__slider-wrapper {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 16px;
        margin: auto 5px;
    }

    &__slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #979797;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;

        &.checked {
            background-color: #2196f3;
        }
    }

    &__slider:before {
        position: absolute;
        content: '';
        height: 14px;
        width: 14px;
        left: 1px;
        bottom: 1px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }

    &__slider.checked:before {
        -webkit-transform: translateX(14px);
        -ms-transform: translateX(14px);
        transform: translateX(14px);
    }

    &__label {
        margin: auto 5px;
    }
}
