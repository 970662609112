.playground {
    position: relative;
    display: flex;
    height: 100%;
}

.header {
    font-family: 'Ubuntu', sans-serif;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
}

.block {
    margin-bottom: 16px;
    &:last-child {
        margin-bottom: 0;
    }
}
.blockShowNumbersOnTiles {
    margin-bottom: 20px;
}

.flexBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.uploadBox {
    padding: 0;
}
.checkboxBox {
    padding: 0;
    margin-bottom: 0;
}
.checkboxBoxCover {
    margin-bottom: 11px;
}
.inputBox {
    width: 258px;
}

.blockGameType {
    width: 258px;
    margin-right: 16px;
}

.blockLayout {
    width: 258px;
}
