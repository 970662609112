.interestsSelector {
    margin-top: 40px;
    display: flex;
    @media (max-width: 1019px) {
        flex-direction: column;
    }
}

.list {
    width: 100%;
}

.description {
    justify-content: flex-start;
    margin-top: 120px;
    @media (max-width: 1019px) {
        margin-top: 0;
    }
}
