@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.modalHeader {
    height: 116px;
    justify-content: center;
    padding: 0 20px;
}
.modalHeaderTitle {
    font-size: 32px;
    width: 100%;
    line-height: normal;
    padding: 0;
    text-align: center;
    white-space: normal;
    @include max_rwd(1024) {
        font-size: 26px;
    }
    @include max_rwd(767) {
        font-size: 22px;
    }
}
.modalHeaderClose {
    top: 24px;
    right: 15px;
}

.modalHeaderPublished {
    &:before {
        content: '';
        position: absolute;
        height: 100%;
        background-image: url('./i/seeds.png');
        opacity: 0.3;
        left: 50px;
        right: 50px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.shareProjectModal {
    padding: 0;
    height: 100%;
    max-width: 100%;
    width: 960px;
    text-align: left;
}
