.add {
    border: 0;
    padding: 10px 20px 5px;
    margin-bottom: 0;
}

.isAddNotHasBlocks {
    margin: 50px 0 0;
    border: 0;
}

.quickAddList {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: -4px;
}

.button {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    margin: 0 4px 4px;
    padding: 8px;
    background-color: #f2f3f7;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }
    &:hover {
        background-color: #eee;
    }
}

.isLoadingButton {
    pointer-events: none;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #f2f3f7;
        background-image: url("/images/icons/dotsLoader.svg");
        background-size: 40px;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.buttonIcon {
    margin-right: 8px;
    max-width: 24px;
    max-height: 24px;
}

.buttonBlue {
    color: #fff;
    background-color: #4891f3 !important;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.7;
    }
}

.text {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    text-align: center;
    color: #b0b1bd;
    margin-top: 68px;
}
