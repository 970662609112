.imageSelector {
    position: relative;
    margin-right: 24px;
}

.title {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 16px;
    color: #3c3c3c;
}

.image {
    width: 128px;
    height: 128px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid rgb(216, 216, 216);
    cursor: pointer;
    &:hover + .button {
        opacity: 0.8;
    }
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 10;
    bottom: 4px;
    right: 4px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}
