.checkbox-box {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    background-color: $color_light_gray--8;
    cursor: pointer;
    flex-shrink: 0;
    @include transition(
        $transition_duration--1,
        $transition_timing_function--1,
        $transition_delay--1,
        (background-color)
    );
    &.is-checked {
        background-color: $color_blue--1;
        &:hover {
            background-color: $color_light_blue--2;
        }
        > svg {
            width: 10px;
            height: auto;
            padding-bottom: 1px;
            path.color_stroke {
                stroke: $color_white !important;
            }
        }
    }
}
