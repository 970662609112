.item {
    list-style: none;
    color: #3c3c3c;
    font-size: 16px;
    padding-bottom: 12px;
    line-height: 24px;
    display: flex;
    align-items: flex-start;
    &:last-child {
        padding-bottom: 0;
    }
}

.position {
    > span {
        opacity: 0;
        visibility: hidden;
    }
}
