.spinButton {
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    z-index: 300;
    background-color: #fff;
    pointer-events: none;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
}
