.row {
    padding: 20px 60px;
    margin-bottom: 1px;
    background-color: #fff;
    display: flex;
    align-items: center;
    &:last-child {
        margin-bottom: 0;
    }
    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
    }
    @media (max-width: 767px) {
        padding: 20px;
    }
}

.date {
    font-size: 14px;
    line-height: 16px;
    color: #787878;
}

.productName {
    display: flex;
}
.productImage {
    width: 80px;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;
}

.texts {
    margin-left: 16px;
}
.title {
    font-size: 16px;
    line-height: 20px;
    color: #3c3c3c;
}
.productPageLink {
    margin-top: 4px;
    > a {
        font-size: 14px;
        line-height: 16px;
        text-decoration: underline;
        color: #2990fb;
        &:hover {
            text-decoration: none;
        }
    }
}

.type,
.total {
    font-size: 14px;
    line-height: 16px;
    color: #3c3c3c;
}

.invoice {
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    text-decoration: underline;
    color: #2990fb;
    &:hover {
        text-decoration: none;
    }
}
