@import '../../../styles/mixins';

.chooseImageBox {
    position: absolute;
    z-index: 10;
    width: 28px;
    height: 28px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    @include max_rwd(700) {
        top: auto;
        bottom: 8px;
    }
    &:hover {
        opacity: 0.8;
    }
}

.isTopRightPosition {
    top: 8px;
    right: 8px;
}

.isBottomRightPosition {
    bottom: 8px;
    right: 8px;
}

.isDisabled {
    pointer-events: none;
    opacity: 0.5;
}
