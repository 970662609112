.tooltip {
    background-color: #fff;
    padding: 8px 16px;
    border: 1px solid #eee;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
}

.item {
    display: flex;
    margin-bottom: 2px;
    color: #787878;
    font-size: 14px;
    line-height: 18px;
    > span {
        color: #3c3c3c;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
