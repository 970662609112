.notice {
    margin-top: 20px;
    text-align: right;
    font-size: 12px;
    line-height: 16px;
    color: #787878;
    @media (max-width: 575px) {
        text-align: center;
    }
    > a {
        text-decoration: none;
        color: #2990fb;
    }
}
