@import '../../../../../../../../../../styles/mixins';

.cardBox {
    width: 82px;
    height: 82px;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    overflow: hidden;
    background-color: #f2f3f7;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.big {
    font-size: 28px;
    line-height: 32px;
    @include max_rwd(799) {
        font-size: 16px;
        line-height: 20px;
    }
}
.medium {
    font-size: 20px;
    line-height: 24px;
    @include max_rwd(799) {
        font-size: 12px;
        line-height: 20px;
    }
}
.small {
    font-size: 15px;
    line-height: 20px;
    @include max_rwd(799) {
        font-size: 8px;
        line-height: 10px;
    }
}

.cardText {
    text-align: center;
}

.cardAudio {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0 4px 20px rgba(41, 144, 251, 0.25);
    color: #3c3c3c;

    @include max_rwd(799) {
        right: 5px;
        top: 5px;
        width: 35px;
        height: 35px;
    }
}
