@import '../../../../../../../styles/mixins';

.questionView {
    padding: 0 20px;
    margin: 20px 0;
    text-align: center;
    @include max_rwd(799) {
        padding: 12px;
        background-color: #fff;
    }
}
.question {
    position: relative;
}
.noImageQuestion {
    padding: 60px 40px;
    background-color: #fff;
    border: 1px solid #ab9393;
    border-radius: 8px;
    @include max_rwd(799) {
        border: 0;
        padding: 0;
        border-radius: 0;
    }
    .questionText {
        border-radius: 0;
        padding: 0;
        box-shadow: none;
    }
}

.questionImage {
    width: 100%;
    height: 508px;
    object-fit: cover;
    border-radius: 8px;
    overflow: hidden;
    @include max_rwd(799) {
        height: 274px;
        border-radius: 0;
        margin: 0 -12px;
        width: calc(100% + 24px);
    }
}
.questionTextWrap {
    display: flex;
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    justify-content: center;
    @include max_rwd(799) {
        position: static;
        margin: 24px 0 0;
        left: 0;
        right: 0;
    }
}
.questionText {
    text-align: center;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    border-radius: 8px;
    padding: 14px 24px 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
    max-width: 100%;
    box-sizing: border-box;
    white-space: pre-line;
    @include max_rwd(799) {
        box-shadow: none;
        background-color: transparent;
        padding: 0;
    }
}
.questionTextBig {
    font-size: 36px;
    line-height: 42px;
    @include max_rwd(799) {
        font-size: 24px;
        line-height: 28px;
    }
}
.questionTextMedium {
    font-size: 28px;
    line-height: 32px;
    @include max_rwd(799) {
        font-size: 18px;
        line-height: 28px;
    }
}
.questionTextSmall {
    font-size: 24px;
    line-height: 32px;
    @include max_rwd(799) {
        font-size: 18px;
        line-height: 28px;
    }
}
.answers {
    margin-top: 20px;
}
.isImageAnswers {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -24px;
    justify-content: center;
    @include max_rwd(799) {
        margin-bottom: -20px;
    }
}
.answer {
    cursor: pointer;
    padding: 20px 80px 20px 40px;
    background: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin-bottom: 10px;
    position: relative;
    outline: none;
    @include max_rwd(799) {
        padding: 20px 42px 20px 16px;
    }
}
.isImageAnswer {
    box-shadow: none !important;
    margin: 0 16px 24px 0;
    padding: 0;
    width: 178px;
    @include max_rwd(799) {
        width: calc(50% - 5px) !important;
        margin: 0 10px 20px 0 !important;
        &:nth-child(even) {
            margin-right: 0 !important;
        }
    }
    &:nth-child(4n) {
        margin-right: 0;
    }
    &:after {
        right: auto;
        left: 12px;
        top: 12px;
        transform: none;
    }
}
.isBigImageAnswer {
    width: 242px;
    margin-right: 17px;
    &:nth-child(4n) {
        margin-right: 17px;
    }
    &:nth-child(3n) {
        margin-right: 0;
    }
}
.answerText {
    text-align: left;
    font-size: 20px;
    line-height: 28px;
    color: #000;
    white-space: pre-line;
    @include max_rwd(799) {
        font-size: 18px;
        line-height: 24px;
    }
}
.answerImageWrapper {
    position: relative;
}
.answerImage {
    width: 100%;
    height: 178px;
    object-fit: cover;
    filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.12));
    border-radius: 4px;
    overflow: hidden;
    @include max_rwd(799) {
        width: 100%;
    }
    @include max_rwd(567) {
        height: 170px !important;
    }
}
.isBigAnswerImage {
    height: 242px;
}
.answerImageLabel {
    font-family: 'Roboto', sans-serif;
    line-height: 24px;
    font-size: 20px;
    color: #000;
    margin-top: 12px;
    text-align: left;
    @include max_rwd(799) {
        line-height: 20px;
        font-size: 16px;
    }
}
.questionImageDisclaimer {
    margin-top: 28px;
    font-size: 14px;
    line-height: 16px;
    color: #b7b7b7;
    text-align: center;
}
