@import '../../../../styles/variables';

.asset-menu {
    display: flex;
    flex-direction: column;
    width: 168px;
}

.asset-menu__tab {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-height: 58px;
    position: relative;
    box-shadow: inset 0 -1px 0 0 $color_light_gray--6;
    cursor: pointer;
    text-align: left;
    padding: 5px 0;
    box-sizing: border-box;
    &:last-child {
        box-shadow: none;
    }
    &:hover,
    &--active {
        .asset-menu__tab--title {
            color: $color_blue--1;
        }
    }

    &--active {
        pointer-events: none;
    }

    &--title {
        font-size: 16px;
        line-height: 20px;
        color: #3c3c3c;
    }
    &--note {
        margin-top: 2px;
        font-size: 12px;
        line-height: 14px;
        color: #787878;
    }
}
