.lead-form__final {
    display: flex;
    height: 100%;

    &__workplace-wrapper {
        width: 100%;
        box-sizing: border-box;
        background-color: #f9fafd;
        overflow: hidden;
        height: 100%;
    }
    &__workplace {
        padding: 36px 40px 20px;
        &-title {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 500;
            font-size: 24px;
            color: #3c3c3c;
        }
        &-content {
        }
    }

    &__preview-wrapper {
        flex-shrink: 0;
        width: 340px;
        box-sizing: border-box;
        background-color: #f9fafd;
        border-left: 1px solid #d8d8d8;
        overflow: hidden;
    }
    &__preview {
        padding: 10px 40px 20px;
        &-title {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            color: #787878;
            margin-bottom: 10px;
        }
    }
}
