.card {
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}

.cardContent {
    padding: 40px 60px 40px 80px;
    display: flex;
    align-items: center;
    text-decoration: none;
    @media (max-width: 991px) {
        padding: 40px;
    }
    @media (max-width: 767px) {
        flex-direction: column;
    }
}

.imageContainer {
    flex-shrink: 0;
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
        max-width: 100%;
        max-height: 100%;
    }
}

.textsContainer {
    width: 100%;
    padding-left: 60px;
    box-sizing: border-box;
    @media (max-width: 991px) {
        padding-left: 40px;
    }
    @media (max-width: 767px) {
        padding-left: 0;
    }
}

.title {
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000;
    @media (max-width: 767px) {
        display: none;
    }
}
.mobileTitle {
    display: none;
    margin-bottom: 10px;
    @media (max-width: 767px) {
        display: block;
    }
}

.description {
    margin-top: 12px;
    font-size: 14px;
    line-height: 24px;
    color: #3c3c3c;
    white-space: pre-wrap;
}

.link {
    margin-top: 8px;
    font-size: 14px;
    line-height: 24px;
    color: #2990fb;
    text-decoration: none;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}

.button {
    margin-left: 100px;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f7fa;
    background-image: url('./i/arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
    @media (max-width: 991px) {
        margin-left: 60px;
    }
    @media (max-width: 767px) {
        margin-top: 20px;
        margin-left: 0;
    }
}
