.analytics {
    display: flex;
    justify-content: center;
    background-color: #f9fafd;
    @include no-select;

    .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .flag {
            width: 28px;
            height: 20px;
            border-radius: 1px;
            margin-right: 16px;

            @include max_md {
                display: none;
            }
        }

        .row {
            align-self: stretch;
            display: flex;
            height: 49px;
            border-bottom: 1px solid #d9d9d9;
        }

        .col {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &:last-child {
                justify-content: flex-end;
            }
        }

        li {
            font-family: $Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: $color_middle_gray--8;
        }
    }

    .more-details {
        margin: 25px 0;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        text-decoration-line: underline;
        color: #2990fb;
    }

    &-page {
        width: 860px;
        padding: 36px 50px 60px;
        background-color: $color_white;
        position: relative;

        h1 {
            font-weight: 300;
            font-size: 32px;
            line-height: 37px;
            margin-bottom: 16px;
            width: 100%;
        }

        h2 {
            font-family: $Roboto;
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 23px;
            text-align: center;
            color: $color_black;
            margin-bottom: 30px;
        }

        .description {
            font-size: 15px;
            line-height: 18px;
            color: $color_middle_gray--8;
            margin-top: 20px;
            margin-bottom: 30px;
        }

        @include max_rwd(1205) {
            h1 {
                font-size: 29px !important;
            }

            .description {
                font-size: 13px !important;
            }
        }

        @include max_rwd(780) {
            width: 264px !important;
            padding: 12px !important;
            overflow: hidden;

            h1 {
                font-size: 20px !important;
                line-height: 23px !important;
                margin-bottom: 0px !important;
            }

            .header-container {
                flex-direction: column;

                .description {
                    margin-bottom: 20px !important;
                }
            }
        }
    }

    &-container {
        width: 960px;
        margin-top: 50px;
        box-sizing: border-box;

        .axis-tick {
            font-size: 11px !important;
            font-family: Roboto !important;
            color: #979797 !important;
            font-style: normal !important;
            font-weight: normal !important;
        }

        @include max_rwd(935) {
            padding: 0 16px;
            margin-top: 16px !important;
        }

        .mobile-menu {
            display: none;
            width: 100%;
            margin-bottom: 16px;

            .dropdown {
                width: 100%;
            }

            @include max_rwd(935) {
                display: block;
            }
        }
    }

    .upgrade-subscriptions {
        width: 100%;
        position: absolute;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        &__content {
            width: 264px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > span {
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #000000;
            }

            > button {
                margin-top: 20px;
            }
        }
    }

    &.blocked-statistics {
        .upgrade {
            display: flex;
        }

        .percent {
            // filter: blur(10px);
        }

        .persent-scale-container {
            // filter: blur(10px);
        }

        .actions-container {
            // filter: blur(10px);
        }

        .pie-data-container {
            // filter: blur(10px);
            pointer-events: none;
        }

        .map-card {
            .map-block {
                pointer-events: none;
            }
        }

        .engagement-chart {
            // filter: blur(10px);
            pointer-events: none;
        }
    }

    .engagement-page {
        padding: 36px 50px 60px;
        background-color: $color_white;
        position: relative;
    }

    .pie-block {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .legend {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            font-size: 16px;
            line-height: 18px;
            color: $color_middle_gray--8;
            width: 320px;

            @include max_rwd(780) {
                width: 290px !important;
                margin-bottom: 20px !important;
            }

            &-item {
                display: flex;
                align-items: center;
                margin-right: 8px;
                margin-bottom: 8px;

                .color {
                    width: 24px;
                    height: 24px;
                    border-radius: 20px;
                    margin-right: 8px;
                }
            }
        }
    }

    .pie-container {
        width: 320px;
        height: 320px;
        margin-bottom: 40px;

        @include max_rwd(780) {
            margin-bottom: 0 !important;
            width: 290px !important;
            height: 290px !important;
        }

        text {
            font-family: $Roboto !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 14px !important;
            line-height: 16px !important;
            color: $color_black !important;
        }
    }

    .map-block {
        .legend {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            margin-top: 20px;

            .item {
                display: flex;
                align-items: center;
                margin-right: 10px;
                margin-bottom: 10px;

                .color {
                    width: 15px;
                    height: 15px;
                    margin-right: 10px;
                }
            }
        }
    }

    .map-container {
        width: 800px;
        height: 430px;
        position: relative;
        overflow: hidden;

        svg {
            height: 430px;
        }

        @include max_rwd(1205) {
            width: 650px !important;
        }

        @include max_rwd(780) {
            width: 288px !important;
            height: 330px !important;
            overflow: visible;
            svg {
                height: 330px !important;
            }
        }
    }

    .chart-container {
        height: 500px;
        width: 850px;

        @include max_rwd(1205) {
            width: 700px !important;
        }

        @include max_rwd(780) {
            width: 312px !important;
            height: 300px !important;

            svg {
                > g {
                    > g:nth-child(3) {
                        text {
                            transform: rotate(-90deg) translateY(-5px) translateX(-28px);
                        }
                    }
                }
            }
        }
    }

    .pie-data-container {
        display: flex;
        justify-content: space-between;
        margin-top: 80px;

        @include max_rwd(780) {
            flex-direction: column !important;
        }

        > div {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .title {
            font-size: 20px;
            line-height: 23px;
            text-align: center;
            margin-bottom: 20px;
        }
    }

    .tooltip {
        background: #fff !important;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.143903);
        border-radius: 2px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
        padding: 9px 20px;

        @include max_rwd(1205) {
            padding: 5px 10px !important;
        }

        @include max_rwd(780) {
            padding: 0 7px !important;
            font-size: 10px !important;
        }
    }
}

.conversions-page {
    width: 860px;

    &__pie-bar {
        display: flex;
        justify-content: space-between;
    }

    &__bars {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h2,
        .description {
            margin-left: 93px;
        }

        text {
            font-family: $Roboto !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 14px !important;
            line-height: 16px !important;
            text-align: right !important;
            color: $color_middle_gray--8 !important;
        }
    }

    &__chart {
        text {
            font-family: Roboto !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 12px !important;
            line-height: 14px !important;
            color: $color_middle_gray--2 !important;
        }
    }
}

.user-data-page {
    width: 860px;

    .col {
        display: flex;
        justify-content: flex-start !important;
        align-items: center;
    }
}
