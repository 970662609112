.chooseImageBox {
    position: absolute;
    z-index: 10;
    bottom: 8px;
    right: 8px;
    width: 28px;
    height: 28px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}

.input {
    display: none;
}

.isDisabled {
    pointer-events: none;
    opacity: 0.5;
}
