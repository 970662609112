.tile {
    box-sizing: border-box;
    list-style: none;
    position: relative;
}

.inputtableTile {
    background-color: #fff;
    cursor: pointer;
    &:before {
        content: '';
        position: absolute;
        z-index: 1;
        pointer-events: none;
        top: var(--borderOffset);
        left: var(--borderOffset);
        right: var(--borderOffset);
        bottom: var(--borderOffset);
        border-width: var(--borderWidth);
        border-style: solid;
        border-color: #979797;
        border-radius: var(--borderRadius);
    }
}

.tileInner {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.tileContent {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    overflow: hidden;
}

.tileInput {
    position: absolute;
    top: var(--positionOffset);
    left: var(--positionOffset);
    bottom: var(--positionOffset);
    right: var(--positionOffset);
    z-index: 10;
    appearance: none;
    outline: none;
    resize: none;
    text-align: center;
    font-weight: 500;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    caret-color: var(--focusedFontColor);
    color: var(--fontColor);
    transition: color 0.2s ease-in-out;
}
