.lead-form-final-review {
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding-bottom: 6px;
    > img.image {
        height: 164px;
        width: 100%;
        object-position: center;
        object-fit: cover;
    }
    .image-disclaimer {
        margin-top: 12px;
        font-family: 'Roboto', sans-serif;
        font-size: 8px;
        line-height: 9px;
        color: #b7b7b7;
        white-space: pre-line;
        overflow-wrap: break-word;
        padding: 0 8px;
    }
    > div.header {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: 14px;
        text-align: center;
        color: #3c3c3c;
        padding: 16px 20px 10px;
    }
    > div.description {
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #787878;
        padding: 0 36px 38px;
        white-space: pre-line;
    }
}
