@mixin maxWidth($value) {
    @media (max-width: $value+'px') {
        @content;
    }
}

.chooseRole {
    width: 100%;
    padding: 0 100px;
    display: flex;
    position: relative;
    @include maxWidth(1019) {
        flex-direction: column;
        padding: 60px 20px;
        min-height: auto;
    }
    @include maxWidth(599) {
        padding: 40px 20px;
    }
}
