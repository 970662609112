.matching-modal-pairs {
    padding: 36px 40px 0;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .matching-modal-pairs__head-wrapper {
        display: flex;
        justify-content: space-between;

        .matching-modal-pairs__head-title {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            position: relative;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 500;
            font-size: 24px;
            color: #3c3c3c;
        }
        .matching-modal-pairs__head__right {
            display: flex;
            align-items: center;
            .matching-modal-pairs__head-add {
                margin-left: 20px;
                cursor: pointer;
                transition: 0.2s ease-in-out;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }

    .matching-modal-pairs__body-wrapper {
        height: 100%;
        overflow: hidden;

        .matching-modal-pairs__card-block-wrapper {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .matching-modal-pairs__card-block {
                display: flex;
                height: 100%;
                flex-flow: row wrap;
                margin: 0 18px;
                box-sizing: border-box;

                &.with-description {
                    width: 100%;
                }

                .matching-modal-pairs__card-pair {
                    color: #000;
                    margin: 0.5rem;
                    position: relative;

                    .matching-modal-pairs__card-pair-title {
                        margin-bottom: 0.2rem;
                        margin-left: 0.2rem;
                        position: relative;
                    }
                    .matching-modal-pairs__card-pair-remove {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                        cursor: pointer;
                        transition: opacity 0.2s ease-in-out;
                        &:hover {
                            opacity: 0.7;
                        }
                    }

                    .matching-modal-pairs__card-pair-view {
                        display: flex;
                    }
                }
                .matching-modal-pairs__card-description {
                    width: 75%;
                }
            }
        }
    }
}
