.marks {
    display: flex;
    flex-wrap: wrap;
}

.mark {
    height: 44px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: 28px;
    max-width: 100%;
    box-sizing: border-box;
    &:last-child {
        margin-right: 0;
    }
}

.isFilledMark {
    background-color: #f5f5f5;
    border-radius: 60px;
    padding: 0 24px;
}

.isReversedMark {
    flex-direction: row-reverse;
    .text {
        padding-left: 0;
        padding-right: 14px;
    }
}

.icon {
    flex-shrink: 0;
}

.isFilledIcon {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    font-size: 14px;
    color: #3c3c3c;
    padding-left: 14px;
}
