.tabContent {
    width: 100%;
}

.main {
    padding: 28px 72px 40px 40px;
}

.header {
    font-family: 'Ubuntu', sans-serif;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
}

.content {
    position: relative;
}
