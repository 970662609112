@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.manage-custom-domain-modal {
    width: 640px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    padding: 28px 40px 40px;
    position: relative;
    box-sizing: border-box;
    box-shadow: $box-shadow--4;
    text-align: left;
    @include max_sm {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
    }

    &__title {
        font-family: $Ubuntu;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 24px;
    }

    &__instruction {
        padding-bottom: 25px;
        font-family: $Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }

    &__invite-block {
        display: flex;
        margin-bottom: 35px;
        @include max_sm {
            flex-direction: column;
        }

        &-input {
            height: 44px !important;
            padding: 0 !important;
        }

        &-button {
            margin-left: 12px;
            flex-shrink: 0;
            min-width: 120px;
            justify-content: center;
            @include max_sm {
                margin: 12px 0 0;
            }
        }
    }

    &__domain-list {
        display: flex;
        flex-direction: column;
    }

    &__domain {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;

        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: $color_dark_gray--3;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        &-info {
            width: 100%;
            overflow: hidden;
            @include max_sm {
                flex-direction: column;
                align-items: flex-start;
            }

            &-hint {
                font-family: $Roboto;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                opacity: 0.5;
            }

            &-url {
                margin: 10px 0;
                display: flex;
                align-items: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                > p {
                    margin-left: 10px;
                    display: inline-block;
                    cursor: pointer;
                }
            }

            &-dns {
                display: flex;
                align-items: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                > p {
                    color: #2990fb;
                    display: inline-block;
                    cursor: pointer;
                    margin-right: 10px;
                }

                > svg {
                    cursor: pointer;
                }
            }
        }

        &-action-remove {
            color: $color_red--1;
            cursor: pointer;
        }
    }
}
