.project-analytics-sidebar {
    width: 300px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    color: #3c3c3c;
    position: sticky;
    overflow-x: hidden;
    overflow-y: auto;
    flex-shrink: 0;
    padding: 20px 40px;
    box-sizing: border-box;
    z-index: 30;
    clip-path: inset(-8px -8px 0 -8px);

    @media (max-width: 1599px) {
        width: 240px;
    }
    > nav {
        .blocks-items-title {
            margin-bottom: 5px;
            cursor: default;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #979797;
        }
        > ul {
            &.common-items {
                padding-bottom: 25px;
                > li {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #d9d9d9;
                    > a {
                        display: block;
                        padding: 20px 0;
                        text-decoration: none;
                        font-size: 16px;
                        color: #3c3c3c;
                        cursor: pointer;
                        &.is-active {
                            pointer-events: none;
                            color: #2990fb;
                        }
                    }
                }
            }
            &.blocks-items {
                margin-left: 35px;
                > li {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #d9d9d9;
                    > a {
                        display: block;
                        padding: 20px 0;
                        text-decoration: none;
                        font-size: 16px;
                        color: #3c3c3c;
                        cursor: pointer;
                        &.is-active {
                            pointer-events: none;
                            color: #2990fb;
                        }
                    }
                }
            }
        }
    }
}
