.bank {
    padding: 20px 40px;
    font-size: 18px;
    color: #555;
    box-shadow: 0 2px 9px rgb(0 0 0 / 7%);
    margin-bottom: 20px;
    transition: box-shadow 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    &:last-child {
        margin-bottom: 0;
    }
    &:hover {
        box-shadow: 0 2px 15px rgb(0 0 0 / 15%);
    }
    @media screen and (max-width: 680px) {
        font-size: 16px;
        padding: 15px 20px;
    }
}

.publicBank {
    cursor: pointer;
}
