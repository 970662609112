.noData {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.imageBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    > img {
        max-width: 100%;
        max-height: 100%;
    }
}

.text {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: #8a8a8a;
}
