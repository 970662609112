.removeInterval {
    font-size: 16px;
    line-height: 18px;
    color: #555;
    margin-bottom: 16px;
    a {
        margin-left: 5px;
        cursor: pointer;
        transition: opacity 0.2s ease-in-out;
        &:hover {
            opacity: 0.8;
        }
        > img {
            vertical-align: middle;
            width: 14px;
        }
    }
}

.removeIntervalAction {
    cursor: pointer;
    color: #2990fb;
    position: relative;
    transition: opacity 0.2s ease-in-out;
    &:before {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #2990fb;
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
    }
    &:hover {
        opacity: 0.8;
        &:before {
            opacity: 0;
        }
    }
}
