@import '../../../../../../../../styles/mixins';

.table {
    width: 100%;
}

.title {
    font-size: 20px;
    line-height: 23px;
    color: #555;
    margin-bottom: 20px;
}

.list {
    opacity: 1;
}

.item {
    display: flex;
    margin-bottom: 4px;
    height: 52px;
    &:last-child {
        margin-bottom: 0;
    }
    @include max_rwd(575) {
        height: auto;
        padding: 16px 0;
        background-color: #fff;
        margin-bottom: 0;
    }
}

.itemColumn {
    display: flex;
    align-items: center;
    width: calc(50% - 2px);
    padding: 0 28px;
    box-sizing: border-box;
    background-color: #fff;
    &:nth-child(odd) {
        margin-right: 2px;
    }
    &:nth-child(even) {
        margin-left: 2px;
    }
    @include max_rwd(575) {
        width: auto;
        margin: 0 !important;
        background-color: transparent;
    }
}

.itemLabel {
    font-size: 14px;
    line-height: 20px;
    color: #3c3c3c;
    @include max_rwd(575) {
        width: 100%;
        padding: 0 0 0 20px;
    }
}

.itemValue {
    justify-content: center;
    font-size: 15px;
    color: #3c3c3c;
    flex-shrink: 0;
    @include max_rwd(575) {
        padding-right: 20px;
    }
}
