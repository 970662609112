.organizationLimits {
    background-color: #fff;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    padding: 20px 20px 36px 20px;
    position: relative;
    margin-bottom: 20px;
}

.title {
    font-size: 16px;
    line-height: 18px;
    color: #3c3c3c;
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.titleIcon {
    display: flex;
    margin-left: 10px;
}

.button {
    margin-top: 20px;
}
