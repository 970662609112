.section {
    margin-bottom: 20px;
    padding: 0 44px 20px 40px;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 40px;
        right: 44px;
        height: 1px;
        background-color: rgb(217, 217, 217);
    }
    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
        &:after {
            display: none;
        }
    }
}

.sectionHead {
    padding-bottom: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.sectionTitle {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #3c3c3c;
}

.content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.flexBlock {
    display: flex;
    align-items: center;
}

.mainContent {
    position: relative;
    width: 100%;
}

.sectionNameControl {
    padding-top: 0;
    width: 100%;
    margin-right: 20px;
}

.sectionWinProbabilityControl {
    padding-bottom: 5px;
}

.contentBlock {
    display: flex;
    justify-content: flex-start;
}
