.editor-card-text {
    box-sizing: border-box;
    overflow: hidden;
    width: 520px;
    height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #f9fafd;
    border-radius: 4px;
    padding: 20px 40px;
    text-align: left;

    &__header {
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #3c3c3c;
    }
    &__body {
        display: flex;
        justify-content: space-between;
        &-content {
            margin-right: 36px;
            flex: 1;
            &-block {
                min-width: 100%;
                margin-bottom: 8px;
            }
        }
    }
    &__buttons {
        display: flex;
        justify-content: flex-end;
        .button {
            width: 122px;
            margin-right: 12px;
            justify-content: center;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
