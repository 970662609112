@import '../../../../styles/mixins';

.accountDelete {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 100%;
    @include max_rwd(767) {
        flex-direction: column;
    }
}

.side {
    display: flex;
    align-items: center;
    padding-right: 20px;
    width: 100%;
    @include max_rwd(767) {
        padding: 0;
        margin-bottom: 20px;
    }
}
.icon {
    margin-right: 12px;
    flex-shrink: 0;
    width: 32px;
}

.text {
    width: 100%;
    font-size: 16px;
    color: #8a8a8a;
}

.buttonBlock {
    display: flex;
    justify-content: flex-end;
    @include max_rwd(767) {
        width: 100%;
    }
}
