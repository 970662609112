@import '../../../../../../../../styles/variables';
@import '../../../../../../../../styles/mixins';

.navigationListBar {
    padding: 25px 32px 20px;
}

.itemIcon {
    height: 32px;
    width: 32px;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 15px;
    display: flex;
    cursor: pointer;
    &:hover + .itemText {
        opacity: 0.8;
    }
}
.isActiveItemIcon {
    border: 1px solid #2990fb;
}

.itemIconImage {
    width: 100%;
    object-position: center;
    object-fit: cover;
}

.list {
    position: relative;
}

.item {
    height: 30px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #d9d9d9;
    display: flex;

    &:last-child {
        margin-bottom: 0;
    }
}

.itemText {
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    font-family: $Roboto;
    font-size: 16px;
    color: #787878;
    @include transition(0.2s, ease-in-out, 0s, (opacity, color));
    &:hover {
        opacity: 0.8;
    }
}

.isActiveItemText {
    color: #2990fb;
}

.isFullList {
    .item:last-child {
        padding-bottom: 0;
        border: 0;
    }
}
