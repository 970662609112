.elementsGroup {
    display: flex;
    width: 100%;
}

.elementItem {
    box-sizing: border-box;
    flex-shrink: 0;
    padding: 0 10px;
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        width: 100%;
        padding-right: 0;
        flex-shrink: unset;
    }
}
