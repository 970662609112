.common-select {
    min-width: 200px;
    position: relative;

    &.full-width {
        width: 100%;
        min-width: auto;
    }

    &.common-select__disabled {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    }

    .common-select__required {
        &:after {
            content: ' *';
            color: #ff5656;
        }
    }

    .common-select__label {
        height: 16px;
        margin-bottom: 5px;
    }

    .common-select__error {
        position: absolute;
        top: 100%;
        margin-top: 5px;
        right: 0;
        font-size: 12px;
        color: #ff5656;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
