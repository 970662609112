@import '../../../../styles/mixins';

.preview {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.deviceChanger {
    z-index: 250;
    position: fixed;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    padding: 2px 44px 12px;
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    @include max_rwd(1023) {
        top: 80px;
    }
}

.deviceChangerItem {
    margin-right: 30px;
    cursor: pointer;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    &:last-child {
        margin-right: 0;
    }
    &.is-active {
        cursor: default;
        pointer-events: none;
    }
}

.deviceChangerItemDesktop {
    width: 45px;
    background-image: url('./i/desktop.svg');
}
.isActiveDeviceChangerItemDesktop {
    background-image: url('./i/desktop--blue.svg');
}

.deviceChangerItemMobile {
    width: 45px;
    background-image: url('./i/mobile.svg');
}
.isActiveDeviceChangerItemMobile {
    background-image: url('./i/mobile--blue.svg');
}

.iframeWrapper {
    position: relative;
    max-width: 100%;
}

.iframeInner {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.frameWrapperDesktop {
    background-color: transparent;
    height: auto;
    width: 100%;
    margin: 72px 0 20px;
    padding: 0 20px;
    box-sizing: border-box;
    > .iframeInner {
        top: 0;
        left: 0;
        height: 100%;
        position: relative;
    }
}

.frameWrapperMobile {
    display: flex;
    flex-direction: column;
    margin-top: 54px;
    border: 44px solid #fff;
    border-left-width: 8px;
    border-right-width: 8px;
    border-radius: 20px;
    width: 322px;
    height: 570px;
    margin-bottom: 20px;
    background-color: #fff;
    &:before {
        content: '';
        position: absolute;
        top: -29px;
        left: 50%;
        transform: translateX(-50%);
        width: 14px;
        height: 14px;
        background-color: #d8d8d8;
        border-radius: 50%;
    }
    &:after {
        content: '';
        position: absolute;
        bottom: -24px;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 8px;
        background-color: #d8d8d8;
        border-radius: 12px;
    }
    > .iframeInner {
        box-sizing: border-box;
        border: 1px solid #d8d8d8;
        flex: auto;
    }
}
