@import '../../styles/variables';
@import '../../styles/mixins';

.dropdown-menu {
    position: relative;
    cursor: pointer;
    z-index: 1;
}

.dropdown-menu-modal {
    width: 280px;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    padding: 40px 28px;
    position: relative;
    box-sizing: border-box;
    box-shadow: $box-shadow--4;

    .rc-menu-item {
        margin-bottom: 5px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.rc-dropdown {
    z-index: 100000;
}

.rc-dropdown-menu {
    border: 0;
    cursor: default;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.160839);
    background-color: #ffffff;
    padding: 12px;
    min-width: 100px;
    margin: 0;
}

.rc-dropdown-menu > .rc-dropdown-menu-item {
    padding: 0;
    cursor: pointer;
}

.rc-dropdown-menu > .rc-dropdown-menu-item:hover,
.rc-dropdown-menu > .rc-dropdown-menu-item:active,
.rc-dropdown-menu > .rc-dropdown-menu-item-selected {
    background: none;
}
