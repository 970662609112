@import '../../../../../../../../../../styles/mixins';

.sections {
    display: flex;
    height: 100%;
}

.sectionsNavigation {
    height: 100%;
    flex-shrink: 0;
    width: 232px;
    background-color: #fff;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    @include max_rwd(1024) {
        display: none;
    }
}

.sectionsNavigationTitle {
    padding: 28px 32px 25px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #3c3c3c;
    flex-shrink: 0;
}

.sectionsNavigationContent {
    flex: 1;
    overflow: hidden;
}

.navigationListBarClassName {
    padding-top: 0;
}

.sectionsContent {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
}
