.organization-panel__dropdown-menu {
    position: absolute;
    text-align: center;
    width: 20px;
    top: 0;
    right: 10px;
    display: none;

    &-item {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        margin: 2px 0;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #8a8a8a;
    }
    &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
}

.upload-logo-input {
    display: none;
}
