.main-loader {
    &[data-view='1'],
    &[data-view='1-1'] {
        display: flex;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        //background-color: rgba(255,255,255,.5);
        background: $color_white;
        z-index: 999;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        > img {
            width: 200px;
            height: auto;
        }
        > p {
            display: none;
            margin-top: 10px;
            font-size: 16px;
            font-family: $Ubuntu;
            color: #000;
        }

        .lds-ellipsis {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 13px;
            margin-top: 10px;
            div {
                position: absolute;
                top: 0;
                width: 13px;
                height: 13px;
                border-radius: 50%;
                background: $color_blue--1;
                animation-timing-function: cubic-bezier(0, 1, 1, 0);
                &:nth-child(1) {
                    left: 8px;
                    animation: lds-ellipsis1 0.6s infinite;
                }
                &:nth-child(2) {
                    left: 8px;
                    animation: lds-ellipsis2 0.6s infinite;
                }
                &:nth-child(3) {
                    left: 32px;
                    animation: lds-ellipsis2 0.6s infinite;
                }
                &:nth-child(4) {
                    left: 56px;
                    animation: lds-ellipsis3 0.6s infinite;
                }
            }
        }

        @keyframes lds-ellipsis1 {
            0% {
                transform: scale(0);
            }
            100% {
                transform: scale(1);
            }
        }
        @keyframes lds-ellipsis3 {
            0% {
                transform: scale(1);
            }
            100% {
                transform: scale(0);
            }
        }
        @keyframes lds-ellipsis2 {
            0% {
                transform: translate(0, 0);
            }
            100% {
                transform: translate(24px, 0);
            }
        }
    }
    &[data-view='1-1'] {
        background-color: rgba(255, 255, 255, 0.75);
    }
}
