@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.embed-code-tab {
    background-color: #f9fafd;
    width: 100%;
    box-sizing: border-box;
    padding: 40px 120px 80px 120px;
    min-height: 550px;

    @include max_xs {
        padding: 40px;
    }

    &__title {
        font-size: 20px;
        line-height: 23px;
        color: $color_middle_gray--1;
        text-align: center;
        width: 40%;
        margin: 0 auto 24px;

        @include max_xs {
            width: auto;
        }
    }

    &__contact {
        margin-top: 50px;
        text-align: center;
        font-size: 16px;
        color: $color_dark_gray--3;
        a {
            text-decoration: none;
            color: $color_blue--1;
        }
    }
}
