@import '../../../styles/variables';
@import '../../../styles/mixins';

.baseDialog {
    max-width: 100%;
    width: 640px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    padding: 50px 40px 60px;
    box-sizing: border-box;
    text-align: left;
    position: relative;
}

.close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 22px;
    height: 22px;
    background-image: url('i/cross.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.7;
    }
}

.content {
    display: flex;
    justify-content: center;
    margin-top: 42px;
}

.title {
    font-family: $Ubuntu;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -1px;
    color: #000;
    max-width: 370px;
    margin: 0 auto;
}

.note {
    margin-top: 12px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #3c3c3c;
}

.image {
    margin-top: 38px;
    display: flex;
    justify-content: center;
}

.bottomText {
    margin-top: 20px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #3c3c3c;
    padding: 0 100px;
    @include max_rwd(600) {
        padding: 0;
    }
    a {
        text-decoration: none;
        color: #2990fb;
        transition: opacity 0.2s ease-in-out;
        &:hover {
            opacity: 0.8;
        }
    }
}
