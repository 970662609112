@import '../../../styles/variables';
@import '../../../styles/mixins';

.asset-manager-search {
    height: 36px;
    flex-shrink: 0;
    border-radius: 18px;
    box-shadow: inset 0 0 0 1px $color_light_gray--2;
    display: flex;
    align-items: center;
    margin-top: 4px;

    &__icon {
        width: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    input[type='text'] {
        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        height: 19px;
        width: calc(100% - 35px);
        line-height: 19px;
        font-size: 16px;
        outline: none;
        border: none;
        background-color: inherit;
        color: $color_middle_gray--1;

        @include placeholder {
            color: $color_light_gray--2;
            text-overflow: ellipsis;
        }

        &:focus {
            @include placeholder {
                color: transparent;
            }
        }
    }
}
