.gamePreview {
    flex-shrink: 0;
    border-left: 1px solid #d8d8d8;
}

.main {
    position: relative;
    padding: 12px 40px 30px;
    flex-shrink: 0;
    box-sizing: border-box;
}

.title {
    color: #787878;
    font-size: 14px;
    margin-bottom: 10px;
}

.content {
    position: relative;
}
