.question-view {
    display: flex;
    overflow: hidden;
    height: 100%;
    width: 100%;
    background: #f9fafd;

    &-wrapper {
        position: relative;
        display: flex;
        top: 0;
        left: 0;
        height: 100%;
        width: 125%;
        transition: left 0.3s;

        &.is-slide {
            left: -232px;
        }
    }

    &__navigation {
        height: 100%;
        flex-shrink: 0;
        width: 232px;
        background-color: #fff;
        box-sizing: border-box;
        flex: none;
    }

    &__body {
        width: 728px;
        height: 100%;
        box-sizing: border-box;
        flex: none;
        border-right: 1px solid #d8d8d8;
        border-left: 1px solid #d8d8d8;

        &-list-wrapper {
            padding: 36px 40px 20px;
        }

        &-list {
            list-style: none;
            margin-bottom: 40px;
        }
    }

    &__link-bar {
        position: relative;
        height: 100%;
        flex-shrink: 0;
        width: 232px;
        background-color: #fff;
        box-sizing: border-box;
        flex: none;

        &-closer {
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #ffffff;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
            left: -10px;
            top: 30px;
            cursor: pointer;
            z-index: 3;
            &:after {
                content: '';
                position: absolute;
                width: 6px;
                height: 6px;
                border-top: 1px solid #2990fb;
                border-right: 1px solid #2990fb;
                transform: rotate(45deg);
                top: 6px;
                left: 5px;
            }

            &:hover {
                background: #2990fb;
                &:after {
                    border-color: #ffffff;
                }
            }
        }
    }
}
