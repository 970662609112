@import '../../../../../../../../../../styles/mixins';

.playground {
    display: flex;
    height: 100%;
}

.workplaceWrapper {
    width: 100%;
    box-sizing: border-box;
    background-color: #f9fafd;
    overflow: hidden;
    height: 100%;
}

.workplace {
    padding: 36px 40px 20px;
    @include max_rwd(767) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.workplaceTitle {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #3c3c3c;
}

.workplaceContent {
    position: relative;
}

.workplaceLayoutRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
    @include max_rwd(1080) {
        align-items: flex-start;
        flex-direction: column;
    }
    & > * {
        margin-right: 15px;
        @include max_rwd(1080) {
            margin-right: 0;
            margin-bottom: 12px;
        }
        &:last-child {
            margin-right: 0;
            @include max_rwd(1080) {
                margin-bottom: 0;
            }
        }
    }
}

.tabContent {
    padding-right: 20px;
}
.preview {
    padding: 12px 20px 30px;
}
