.banksList {
    width: 100%;
    @media screen and (max-width: 1025px) {
        margin-top: 20px;
    }
}

.isLoadingBanksList {
    opacity: 0.4;
    pointer-events: none;
}

.note {
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    color: #8a8a8a;
    span {
        color: #2990fb;
        cursor: pointer;
        transition: opacity 0.2s ease-in-out;
        &:hover {
            opacity: 0.8;
        }
    }
}

.loader {
    padding: 0;
    width: 100%;
    height: auto;
    display: block;
    text-align: center;
}
