.webhooks-modal__wrapper {
    width: 960px;
    height: 428px;
    background-color: #f9fafd;
}

.webhooks-modal {
    .modal-wrapper__content {
        overflow: hidden;
    }
}
