@import '../../../../../../../../styles/mixins';

.chooseBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 20px 24px;
    box-sizing: border-box;
    width: 50%;
    margin: 0 3px;
    background: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    cursor: pointer;
    transition-property: opacity, border-color, outline-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    outline: 1px solid transparent;
    outline-offset: -2px;
    user-select: none;
    &:hover {
        opacity: 0.8;
    }
    @include max_rwd(767) {
        margin: 0;
        width: 100%;
        &:nth-child(2) {
            margin-top: 6px;
        }
    }
}

.isSelectedChooseBlock {
    pointer-events: none;
    border-color: #2990fb;
    outline-color: #2990fb;
}

.title {
    margin-top: 8px;
    font-size: 16px;
    line-height: 20px;
    color: #3c3c3c;
}

.description {
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
    color: #787878;
}
