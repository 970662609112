.result-view {
    display: flex;
    overflow: hidden;
    height: 100%;
    width: 100%;
    background: #f9fafd;

    &__navigation {
        height: 100%;
        flex-shrink: 0;
        width: 232px;
        background-color: #fff;
        box-sizing: border-box;
    }

    &__body {
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        &-list-wrapper {
            padding: 36px 40px 20px;
        }

        &-list {
            list-style: none;
            margin-bottom: 40px;
        }
    }
}
