footer {
    background-color: #f9fafd;
    color: #000;
    border-top: 1px solid #efefef;
    .main {
        padding: 40px 0 150px;
        @include max_md {
            padding-bottom: 50px;
        }
        .wrap-box {
            width: 100%;
            max-width: 1220px;
            margin: 0 auto;
            box-sizing: border-box;
            padding: 0 40px;
            @include max_md {
                max-width: 560px;
                padding: 0 30px;
            }
            > .links {
                display: flex;
                @include no-select;
                @include max_md {
                    margin-top: 45px;
                    flex-direction: column;
                }
                > li {
                    width: 25%;
                    padding-right: 20px;
                    box-sizing: border-box;
                    &:last-child {
                        padding-right: 0;
                    }
                    @include max_md {
                        padding-right: 0;
                        width: 100%;
                        margin-bottom: 40px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    > .title {
                        font-weight: 300;
                        font-size: 20px;
                        color: #000;
                        margin-bottom: 40px;
                        @include max_md {
                            position: relative;
                            padding-bottom: 10px;
                            margin-bottom: 30px;
                            &:after {
                                content: '';
                                position: absolute;
                                bottom: 0;
                                left: -7px;
                                right: 0;
                                width: calc(100% + 14px);
                                height: 1px;
                                opacity: 0.3;
                                background-color: #000;
                            }
                        }
                    }
                    > ul {
                        > li {
                            margin-bottom: 16px;
                            > a,
                            > span {
                                text-decoration: none;
                                font-weight: 300;
                                font-size: 14px;
                                color: #000;
                                cursor: pointer;

                                &.is-active,
                                &.is-disabled {
                                    pointer-events: none;
                                }
                                &.is-silent {
                                    cursor: default;
                                    &:not(.is-active) {
                                        color: #000 !important;
                                    }
                                }
                                &.is-disabled,
                                &.is-silent:not(.is-active) {
                                    opacity: 0.5;
                                }

                                @include transition(
                                    $transition_duration--1,
                                    $transition_timing_function--1,
                                    $transition_delay--1,
                                    (color)
                                );
                                &:hover {
                                    color: $color_light_blue--3;
                                }
                                &.is-active {
                                    color: $color_blue--1;
                                }
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .down {
        font-weight: 300;
        font-size: 16px;
        color: #787878;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include max_md {
            font-size: 14px;
        }
        @include max_sm {
            justify-content: center;
        }
        > svg {
            z-index: 2;
            position: relative;
            width: 148px;
            height: auto;
            @include max_sm {
                display: none;
            }
            path.color_fill {
                fill: $color_blue--1 !important;
            }
            path.color_stroke {
                stroke: $color_blue--1 !important;
            }
        }
        > span {
            opacity: 0.4;
        }
    }
}
