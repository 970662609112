.folder-item {
    padding: 0 15px;
    display: flex;
    align-items: center;
    height: 52px;
    min-width: 150px;
    border-radius: 8px;
    background-color: #ffffff;
    cursor: pointer;
    box-shadow: 0 2px 9px rgb(0 0 0 / 7%);
    transition: box-shadow 0.2s ease-in-out;
    box-sizing: border-box;

    &:hover {
        box-shadow: 0 2px 15px rgb(0 0 0 / 15%);
        //.folders-dropdown__menu {
        //    opacity: 1;
        //}
    }

    &__name-wrapper {
        padding-left: 15px;
    }

    &__name {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #3c3c3c;
        padding-left: 0;
        margin: 0;
        box-sizing: border-box;
    }

    &__actions {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: 15px;
        position: relative;
    }

    &__count {
        opacity: 0;
        visibility: hidden;
        font-size: 14px;
        color: rgba(60, 60, 60, 0.5);
    }
}
