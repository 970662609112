.block {
    margin-bottom: 6px;
    border-radius: 4px;
    background-color: #f8fbff;
    border: 1px solid #f8fbff;
    box-sizing: border-box;
    transition: border-color 0.2s ease-in-out;
    &:hover {
        border-color: #2990fb;
        .headRight > svg {
            path {
                fill: #2990fb;
            }
        }
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.text {
    margin-left: 8px;
    color: #3c3c3c;
    font-size: 14px;
    line-height: 20px;
}

.iconBox {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    max-width: 100%;
    max-height: 100%;
}

.head {
    padding: 6px 12px 6px 6px;
    min-height: 38px;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
}

.isCollapsedHead {
    &:hover {
        opacity: 0.8;
    }
}

.headLeft {
    display: flex;
    align-items: center;
}

.headRight {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: 20px;
    svg {
        path {
            transition: fill 0.2s ease-in-out;
        }
    }
}

.dropdown {
    display: none;
    text-align: left;
    padding: 0 12px 20px 42px;
}

.isActiveDropdown {
    display: block;
}

.dropdownNote {
    color: #979797;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 4px;
}

.tagsList {
    display: flex;
    flex-wrap: wrap;
    margin: -2px;
}

.tagItem {
    display: flex;
    align-items: center;
    padding: 5px 11px;
    border-radius: 4px;
    background-color: #fff;
    margin: 2px;
    cursor: pointer;
    transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
    border: 1px solid transparent;
    &:hover {
        border-color: #2990fb;
    }
}

.tagIcon {
    width: 14px;
    height: 10px;
    margin-right: 4px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
        max-width: 100%;
        max-height: 100%;
    }
}

.tagText {
    color: #000;
    font-size: 12px;
    line-height: 12px;
    transition: color 0.2s ease-in-out;
}

.isSelectedTagItem {
    background-color: #2990fb;
    border-color: #2990fb;
    .tagText {
        color: #fff;
    }
}
