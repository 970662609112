.folder-list {
    margin-top: 15px;
    margin-bottom: 15px;

    &__control-panel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
    }

    &__title {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        color: #787878;
        margin-right: 5px;
    }

    &__create-button {
        justify-content: center;
        border: 1px dashed #5694f7;
        background-color: transparent;
        transition: all 0.2s ease-in-out;

        .folder-item__name {
            transition: color 0.2s ease-in-out;
            color: #2990fb;
            padding-left: 15px;
        }

        > svg {
            > line,
            > path {
                transition: stroke 0.2s ease-in-out;
            }
        }

        &:hover {
            box-shadow: none;
            background-color: #2990fb;

            > svg {
                > line,
                > path {
                    stroke: #fff;
                }
            }

            .folder-item__name {
                color: white;
            }
        }
    }

    &__delete-confirm {
        width: 640px;
    }
}
