.rank-battle {
    &__description {
        text-align: center;
        height: 32px;
        margin: 0 40px 6px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        &.is-mobile {
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 10;
            right: 200px;
            width: unset;
            text-align: left;
            margin: 0 0 16px 14px;
            height: 48px;
            align-items: flex-end;
            justify-content: flex-start;
        }

        &__text {
            position: relative;
        }
    }
}
