@import '../../../../../styles/mixins';

.folders-dropdown__menu {
    text-align: center;
    display: flex;
    width: 28px;
    height: 28px;
    padding: 2px;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    background-color: #fff;
    //opacity: 0;
    //@include max_rwd(1024) {
    //    opacity: 1;
    //}
    > svg > path {
        transition: fill 0.2s ease-in-out;
    }

    &:hover {
        border-radius: 4px;

        > svg > path {
            fill: #979797;
        }
    }

    &-item {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        margin: 2px 0;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #8a8a8a;
    }

    &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
}
