@mixin transition($transition_duration, $transition_timing_function, $transition_delay, $transition_property) {
    transition-duration: $transition_duration;
    transition-timing-function: $transition_timing_function;
    transition-delay: $transition-delay;
    transition-property: $transition-property;
}

.common-pagination {
    display: none;
    &.is-visible {
        margin-top: 40px;
        display: block;
    }
    &.is-loading {
        opacity: 0.7;
        pointer-events: none;
    }
    ul.pagination {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        > li {
            outline: none;
            border-radius: 2px;
            margin-right: 0;
            background-color: #f9fafd;
            @include transition(0.2s, ease-in-out, 0s, (background-color, box-shadow));
            &:hover:not(.is-active) {
                background-color: darken(#f9fafd, 2);
            }
            &:last-child {
                margin-right: 0;
            }
            > a {
                font-family: 'Roboto', sans-serif;
                display: flex;
                width: 30px;
                height: 30px;
                align-items: center;
                justify-content: center;
                color: #6c6c6c;
                font-size: 14px;
                cursor: pointer;
                outline: none;
                text-decoration: none;
            }
            &.is-active {
                background-color: #2990fb;
                > a {
                    color: #fff;
                    cursor: default;
                }
            }
            &.disabled {
                > a {
                    cursor: default;
                    color: #d8d8d8;
                }
            }
            &.previous,
            &.next {
                background-color: #f9fafd;
                background-repeat: no-repeat;
                background-position: center;
                > a {
                    font-size: 0;
                }
            }
            &.previous {
                background-image: url('i/arrow-left.svg');
                margin-right: 16px;
                &.disabled {
                    visibility: hidden;
                }
            }
            &.next {
                margin-left: 16px;
                background-image: url('i/arrow-right.svg');
                &.disabled {
                    visibility: hidden;
                }
            }

            &.break {
                background-color: #fff;
            }
        }
    }
}
