.subscription-expired-modal {
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
    background-color: #fff;
    border-radius: 4px;

    .modal-button {
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        text-align: center;
        padding: 0 20px;
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;
        position: relative;
        outline: none;
        text-decoration: none;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
        border: 1px solid #2990fb;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        &--primary {
            color: #fff;
            background-color: #2990fb;
        }
        &--secondary {
            color: #2990fb;
            background-color: #fff;
        }
    }
}
