.puzzle-modal-playground {
    display: flex;
    height: 100%;

    .puzzle-modal-playground__workplace-wrapper {
        width: 100%;
        box-sizing: border-box;
        background-color: #f9fafd;
        overflow: hidden;
        height: 100%;

        .puzzle-modal-playground__workplace {
            padding: 36px 40px 20px;

            .puzzle-modal-playground__workplace-title {
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                font-family: 'Ubuntu', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: #3c3c3c;
            }

            .puzzle-modal-playground__workplace-content {
                .puzzle-modal-playground__layout-row {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding-bottom: 10px;
                    & > * {
                        margin-right: 15px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    .puzzle-modal-playground__preview-wrapper {
        flex-shrink: 0;
        width: 340px;
        box-sizing: border-box;
        background-color: #f9fafd;
        border-left: 1px solid #d8d8d8;
        overflow: hidden;

        .puzzle-modal-playground__preview {
            padding: 10px 10px 20px;
            background-color: #ffffff;

            .puzzle-modal-playground__preview-title {
                font-family: 'Roboto', sans-serif;
                font-size: 14px;
                color: #787878;
                margin-bottom: 30px;
            }
        }
    }
}
