.cardsGroup {
    display: flex;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    overflow: hidden;
}

.cardsGroupMobile {
    background-color: transparent;
    box-shadow: none;
    margin: 0 0 12px !important;
    padding: 0 8px !important;
    &:last-child {
        margin-bottom: 0 !important;
    }
}

.card {
    margin-right: 10px;
    &:last-child {
        margin-right: 0;
    }
}
