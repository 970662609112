@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.moveFolderModal {
    width: 480px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    padding: 28px 40px 40px;
    position: relative;
    box-sizing: border-box;
    box-shadow: $box-shadow--4;
    text-align: left;
    @include max_sm {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
    }

    .title {
        font-family: $Ubuntu;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 24px;
    }

    .select {
        height: 44px !important;
        padding: 0 !important;
    }

    .buttonGroup {
        padding-top: 45px;
        display: flex;
        justify-content: flex-end;

        :nth-child(2) {
            margin-left: 12px;
        }
    }
}

.selectItem {
    margin-left: 10px;
    font-size: 14px;
}
