@import '../../../../styles/mixins';

.statistic-header {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    justify-content: space-between;

    &__block {
        display: flex;
    }

    .dropdown {
        margin-right: 16px;
    }

    .export {
        font-size: 16px;
        line-height: 19px;
        text-align: right;
        color: #2990fb;
        margin-right: 16px;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        align-items: center;

        > img {
            margin-left: 12px;
        }
    }

    @include max_rwd(960) {
        position: relative;
        height: 80px;

        .dropdown,
        .export {
            margin-top: -45px;
        }

        .date-range-picker-container {
            position: absolute;
            bottom: 0;

            @include max_rwd(780) {
                > span {
                    margin: 0 3px !important;
                }

                > button {
                    margin-left: 6px !important;
                }
                .date-picker-container {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                    width: 95px !important;
                    text-align: center !important;
                }
            }
        }
    }
}
