.input-box {
    position: relative;
    &.is-error {
        > input[type='text'],
        > input[type='password'] {
            border-color: $color_red--1;
        }
    }
    > .title {
        font-size: 16px;
        color: $color_dark_gray--1;
        padding-bottom: 8px;
        text-align: left;
    }
    > input[type='text'],
    > input[type='password'] {
        appearance: none;
        border: 1px solid $color_middle_gray--2;
        box-sizing: border-box;
        border-radius: 4px;
        background-color: $color_white;
        height: 36px;
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
        outline: none;
        font-size: 16px;
        color: $color_dark_gray--3;
        text-overflow: ellipsis;
        &:hover {
            border-color: $color_dark_gray--1;
        }
        &:focus {
            border-color: $color_light_blue--1;
            caret-color: $color_light_blue--1;
            border-width: 2px;
            padding-left: 11px;
            padding-right: 11px;
        }
        &::placeholder {
            color: $color_middle_gray--2;
        }
        &:disabled {
            opacity: 0.4 !important;
            cursor: not-allowed;
        }
    }
    > input[type='password'] {
        padding-right: 44px;
    }
    > .input-icon {
        position: absolute;
        display: flex;
        align-items: center;
        right: 8px;
        bottom: 4px;
        width: 28px;
        height: 28px;
        justify-content: center;
        cursor: pointer;
        &[data-icon='password'] {
            > svg {
                circle.color_fill--1,
                path.color_stroke--1 {
                    @include transition(
                        $transition_duration--1,
                        $transition_timing_function--1,
                        $transition_delay--1,
                        (fill, stroke)
                    );
                }
                circle.color_fill--1 {
                    fill: $color_middle_gray--2 !important;
                }
                path.color_stroke--1 {
                    stroke: $color_middle_gray--2 !important;
                }
            }
            &:hover > svg {
                circle.color_fill--1 {
                    fill: $color_blue--1 !important;
                }
                path.color_stroke--1 {
                    stroke: $color_blue--1 !important;
                }
            }
        }
    }
    > .msg {
        position: absolute;
        font-size: 12px;
        top: 100%;
        left: 0;
        right: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 2px;
        text-align: right;
        &--error {
            color: $color_red--1;
        }
    }
}
