.menu-list {
    display: none;
    padding: 20px;
    position: absolute;
    width: auto;
    z-index: 10;
    top: calc(100% + 8px);
    background-color: $color_white;
    box-shadow: $box-shadow--2;
    border-radius: 8px;
    &[data-align='right'] {
        left: auto;
        right: -10px;
    }
    &[data-align='left'] {
        left: -10px;
        right: auto;
    }
    &.is-visible {
        display: flex;
    }
    > .block {
        &--1 {
            flex-shrink: 0;
            width: 190px;
        }
        &--2 {
            flex-shrink: 0;
            width: 300px;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: -20px;
                bottom: -20px;
                width: 20px;
                left: -20px;
                background-color: $color_white;
                border-radius: 8px;
            }
            &-1 {
                display: none;
                &.is-visible {
                    display: flex;
                }
                > ul {
                    margin-right: 20px;
                    width: calc(100% - 20px);
                    padding: 15px 24px 0 4px;
                    position: relative;
                    flex-shrink: 0;
                    box-sizing: border-box;
                    top: 0;
                    overflow: hidden;
                    background-color: $color_white;
                    columns: 2;
                    column-gap: 48px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: -20px;
                        bottom: -20px;
                        width: 1px;
                        background-color: $color_light_gray--6;
                    }
                    > li {
                        display: inline-block;
                        width: 100%;
                        cursor: pointer;
                        margin-bottom: 20px;

                        &.is-disabled {
                            pointer-events: none;
                        }
                        &.is-silent {
                            cursor: default;
                            > span {
                                color: $color_middle_gray--1 !important;
                            }
                        }
                        &.is-disabled,
                        &.is-silent {
                            opacity: 0.5;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                        &:hover span {
                            color: $color_light_blue--3;
                        }

                        > div {
                            display: flex;
                            align-items: center;
                            > img {
                                width: 28px;
                                margin-right: 12px;
                            }
                            > span {
                                font-size: 16px;
                                color: $color_middle_gray--1;
                            }
                        }
                    }
                }
            }
        }
        > .divider {
            width: 100%;
            display: block;
            background-color: $color_light_gray--6;
            height: 1px;
            margin: 20px 0;
        }
        > div.header {
            &-1 {
                display: flex;
                align-items: center;
                .ava {
                    flex-shrink: 0;
                    width: 64px;
                    height: 64px;
                    border-radius: 50%;
                    margin-right: 12px;
                    font-size: 19px;
                    font-weight: 500;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
                .info {
                    margin: 4px 0 0 16px;
                    width: 100%;
                    overflow: hidden;
                    .full_name {
                        font-size: 16px;
                        color: $color_middle_gray--1;
                        margin-bottom: 4px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .email {
                        font-size: 14px;
                        color: $color_light_gray--5;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        padding-bottom: 4px;
                    }
                }
            }
        }
        > div.footer {
            &-1 {
                button {
                    cursor: pointer;
                    font-size: 16px;
                    color: $color_light_gray--5;
                }
            }
        }
        > ul.items {
            &-1 {
                > li {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    color: $color_light_gray--5;
                    cursor: pointer;
                    margin-bottom: 10px;
                    &.is-loading {
                        cursor: not-allowed;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:hover,
                    &.is-loading {
                        color: $color_blue--1;
                        > span {
                            > svg {
                                path.color_fill {
                                    fill: $color_blue--1 !important;
                                }
                                path.color_stroke {
                                    stroke: $color_blue--1 !important;
                                }
                            }
                        }
                    }
                    > span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 16px;
                        height: 16px;
                        margin-right: 12px;
                        &[data-icon='copyLink'],
                        &[data-icon='duplicate'],
                        &[data-icon='delete'] {
                            > svg {
                                path.color_fill,
                                path.color_stroke {
                                    @include transition(
                                        $transition_duration--1,
                                        $transition_timing_function--1,
                                        $transition_delay--1,
                                        (fill, stroke)
                                    );
                                }
                                path.color_fill {
                                    fill: $color_light_gray--5;
                                }
                                path.color_stroke {
                                    stroke: $color_light_gray--5;
                                }
                            }
                        }
                    }
                }
            }
            &-2 {
                > li {
                    margin-bottom: 20px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    > a {
                        text-decoration: none;
                        font-size: 16px;
                        color: $color_middle_gray--1;
                        &.is-active {
                            color: $color_blue--1;
                            cursor: default;
                        }
                    }
                }
            }
        }
        > .lng-wrap {
            margin-top: 20px;
            display: flex;
            > .lng {
                display: flex;
                align-items: center;
                cursor: pointer;
                &:hover > span {
                    color: $color_blue--1;
                }
                > img {
                    width: 28px;
                }
                > span {
                    margin-left: 8px;
                    text-decoration: none;
                    font-size: 16px;
                    color: $color_middle_gray--1;
                    &.is-hovered {
                        color: $color_blue--1;
                    }
                }
            }
        }
    }
}
