.personality-modal-wrapper {
    width: 960px;
    height: 428px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    .personality-modal-wrapper__body {
        height: 100%;
        width: 100%;
        display: flex;
    }
}
