div.color-picker {
    &__edit-box {
        display: flex;
        position: relative;
        &-preview {
            width: 32px;
            height: 32px;
            border: 1px solid #d8d8d8;
            box-sizing: border-box;
            flex-shrink: 0;
            margin-right: 8px;
            border-radius: 2px;
            overflow: hidden;
        }
        &-input-container {
            width: 100%;
            height: 32px;
            padding: 0;
            display: flex;
            > input {
                width: 100%;
                height: 32px;
                font-family: 'Roboto', sans-serif;
                font-size: 14px;
                align-items: center;
                color: #3c3c3c;
                padding: 0 8px;
                border: 1px solid #d8d8d8;
                border-radius: 2px;
                box-sizing: border-box;
                outline: none;
            }
        }
    }
    &__select-box {
        position: relative;
        z-index: 3;
        top: 100%;
        left: 0;
        width: 100%;
        margin-top: 8px;
    }
}
