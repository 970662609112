@import '../../../../../../../../styles/mixins';

.removeIntervalModal {
    max-width: 100%;
    width: 640px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    padding: 60px 80px 40px;
    box-sizing: border-box;
    text-align: left;
    position: relative;
    @include max_rwd(767) {
        padding: 60px 20px 40px;
    }
}

.title {
    margin-bottom: 28px;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #555;
}

.list {
    margin-bottom: 28px;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    @include max_rwd(767) {
        flex-direction: column;
    }
    > button {
        margin-right: 12px;
        @include max_rwd(767) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
        }
        &:last-child {
            margin-right: 0;
            @include max_rwd(767) {
                margin-bottom: 0;
            }
        }
    }
}
