.demo-with-modal {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    padding: 16px 14px;
    box-sizing: border-box;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
    border: 1px solid #bf68d5;
    border-radius: 8px;

    &__content {
        .title {
            display: flex;
            align-items: center;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            line-height: 16px;

            color: #3c3c3c;
        }
        .description {
            margin-top: 8px;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            line-height: 16px;
            color: #787878;
        }
        .tooltip-controls-icon {
            width: 12px;
            height: 12px;
            background-color: #bf68d5;
            border: none;

            &:after {
                font-size: 8px;
                color: #fff;
            }
        }
    }

    &__toggle {
        flex: none;
        position: relative;
        width: 30px;
        height: 16px;
        background: #979797;
        border-radius: 29px;
        cursor: pointer;
        &:before {
            content: '';
            position: absolute;
            top: 1px;
            left: 1px;
            display: block;
            width: 14px;
            height: 14px;
            background: #fff;
            border-radius: 50%;
        }
    }

    &__tooltip-content {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 320px;
        background: #ffffff;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        padding: 28px 20px;

        h3 {
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            line-height: 20px;
            color: #3c3c3c;
            margin-bottom: 8px;
        }

        p {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            line-height: 18px;
            color: #787878;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        img {
            margin: 0 auto 20px;
        }
    }
}
