.preview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.back {
    height: 22px;
    position: absolute;
    left: 24px;
    top: 24px;
    text-align: left;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    display: flex;
    align-items: center;
    color: #787878;
    font-size: 14px;
    > img {
        height: 14px;
        margin-right: 10px;
    }
    &:hover {
        opacity: 0.8;
    }
}
.nameWrapper {
    width: 100%;
    margin-bottom: 10px;
}
.nameInput {
    height: 44px !important;
}

.controlBox {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 16px;
}

.control {
    cursor: pointer;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2990fb;
    box-shadow: 0 4px 20px rgba(41, 144, 251, 0.25);
    border-radius: 50%;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}

.controlNote {
    margin-top: 8px;
    font-size: 14px;
    color: #787878;
}

.uploadButton {
    //width: 100%;
}

.buttonWrapper {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
