.answer {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    &:last-child {
        margin-bottom: 0;
    }
}

.checkboxInput {
    padding: 0;
    margin: 0 4px 0 0;
}
