@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.project-block__statistic {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    min-width: 300px;
    flex-shrink: 0;
    padding: 0 20px 0 40px;
    box-sizing: border-box;
    @include max_xl {
        min-width: auto;
    }

    &-item {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        padding-right: 19px;
        border-right: 1px solid $color_light_gray--2;
        margin-right: 20px;
        height: 41px;
        &:last-child {
            padding-right: 0;
            margin-right: 0;
            border: 0;
        }
        @include max_rwd(1360) {
            &:nth-child(2) {
                border: 0;
                margin: 0;
                padding: 0;
            }
            &:nth-child(3) {
                display: none;
            }
        }
        @include max_rwd(1200) {
            height: auto;
            padding: 8px 0 !important;
            display: flex !important;
            align-items: center !important;
            border-bottom: 1px solid $color_light_gray--2 !important;
            &:last-child {
                border-bottom: 0 !important;
            }
        }
    }
    &-value {
        font-size: 24px;
        color: $color_middle_gray--1;
        margin-bottom: 3px;
        @include max_xl {
            font-size: 20px;
        }
    }
    &-label {
        font-size: 14px;
        color: $color_light_gray--5;
        @include max_xl {
            font-size: 13px;
        }
        &__btn {
            margin-top: 4px;
            color: #2990fb;
            cursor: pointer;
            transition: opacity 0.2s ease-in-out;
            &:hover {
                opacity: 0.8;
            }
        }
    }

    @include max_rwd(1200) {
        flex-direction: column;
        min-width: auto;
        padding: 0;
        margin: 20px 0;
        &-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            border-bottom: 1px solid $color_light_gray--2;
            padding: 11px 0 7px 0;
            border-right: none;
            margin-right: 0;

            &:last-child {
                border: 0;
            }
        }
        &-label {
            font-size: 14px;
            color: $color_light_gray--5;
        }
        &-value {
            font-size: 24px;
            color: $color_middle_gray--1;
            flex-shrink: 0;
        }
    }
}
