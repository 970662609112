.body {
}

.auth {
    padding-right: 110px;
    @media (max-width: 1199px) {
        padding-right: 60px;
    }
    @media (max-width: 991px) {
        padding: 0;
    }
}

.form {
}

.image {
    margin-bottom: 35px;
}

.title {
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 36px;
    line-height: 41px;
    letter-spacing: -1px;
    color: #000;
    @media (max-width: 575px) {
        font-size: 28px;
        line-height: 32px;
    }
}

.description {
    margin-top: 10px;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    @media (max-width: 575px) {
        margin-top: 15px;
        font-size: 14px;
        line-height: 16px;
    }
    > span {
        font-weight: bold;
    }
}
