.chooseQuestionBank {
    width: 960px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    //padding: 0 0 40px;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.body {
    height: 540px;
    box-sizing: border-box;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 16px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #fff 100%);
    }
}

.bodyContent {
    padding: 16px 60px;
}

.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    font-family: 'Ubuntu';
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #3c3c3c;
}

.tabs {
    display: flex;
}

.tab {
    height: 26px;
    border: 1px solid #d8d8d8;
    border-radius: 83px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    margin-right: 8px;
    cursor: pointer;
    color: #787878;
    transition: all 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
    &:last-child {
        margin-right: 0;
    }
}

.activeTab {
    pointer-events: none;
    color: #2990fb;
    border-color: #2990fb;
}

.search {
    height: 36px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 18px;
    padding: 0 12px;
}

.searchIcon {
    margin-right: 8px;
}

.searchInput {
    border: 0;
    outline: none;
    font-family: 'Roboto';
    font-size: 16px;
    width: 100%;
}

.banksList {
    margin-top: 24px;
}

.isLoadingBanksList {
    opacity: 0.3;
    pointer-events: none;
}

.bank {
    position: relative;
    background: #fff;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    margin-bottom: 12px;
    padding: 12px 20px;
    font-family: 'Roboto';
    font-size: 20px;
    line-height: 24px;
    color: #3c3c3c;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border: 4px solid transparent;
    &:last-child {
        margin-bottom: 0;
    }
    &:hover {
        box-shadow: 0 2px 15px rgb(0 0 0 / 15%);
    }
}

.selectedBank {
    border-color: #2990fb;
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #2990fb;
        background-repeat: no-repeat;
        background-image: url('./i/checkmark.svg');
        background-position: center;
    }
}

.selectBlock {
    position: absolute;
    z-index: 10;
    bottom: -84px;
    left: 0;
    height: 72px;
    padding: 0 40px;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    transition: bottom 0.3s ease-in-out;
    &:before {
        content: '';
        height: 12px;
        width: 100%;
        position: absolute;
        bottom: 100%;
        left: 0;
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    }
}

.activeSelectBlock {
    bottom: 0;
}
