@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.billing-history {
    &-table {
        overflow: hidden;
        border-radius: 4px;

        .table-header {
            display: flex;
            background-color: rgba(41, 144, 251, 0.7);
            color: #fff;
            padding: 26px 40px;
            @media (max-width: 1199px) {
                padding: 20px 28px;
            }
            @media (max-width: 991px) {
                display: none;
            }
            .col {
                color: #fff;
                font-size: 14px;
                @media (max-width: 1199px) {
                    font-size: 13px;
                }
            }
        }

        .col {
            box-sizing: border-box;
            padding-right: 30px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            flex: none;
            width: 16.666%;
            &:last-child {
                padding-right: 0;
            }
        }

        .table-list {
            &__item {
                padding: 20px 40px;
                border-bottom: 1px solid #d8d8d8;
                @media (max-width: 1199px) {
                    padding: 16px 28px;
                }
                @media (max-width: 991px) {
                    padding-left: 0;
                    padding-right: 0;
                }
                > ul {
                    display: flex;
                    @media (max-width: 991px) {
                        flex-wrap: wrap;
                        margin-bottom: -15px;
                    }
                    .col {
                        color: #8a8a8a;
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        &:last-child {
                            justify-content: flex-end;
                        }
                        @media (max-width: 1199px) {
                            font-size: 13px;
                        }
                        @media (max-width: 991px) {
                            flex-direction: column;
                            align-items: flex-start;
                            width: 50% !important;
                            padding: 0;
                            margin-bottom: 15px;
                            &:nth-child(odd) {
                                padding-right: 10px;
                            }
                            &:nth-child(even) {
                                padding-left: 10px;
                            }
                        }
                        @media (max-width: 479px) {
                            width: 100% !important;
                            padding: 0 !important;
                        }
                        > .label {
                            display: none;
                            padding-bottom: 5px;
                            font-weight: bold;
                            @media (max-width: 991px) {
                                display: block;
                            }
                        }
                        a {
                            color: #2990fb;
                            cursor: pointer;
                        }
                        div.desc {
                            margin-top: 4px;
                        }
                        div.payment-method {
                            display: flex;
                            align-items: center;
                            > img {
                                margin-right: 8px;
                                padding: 4px 5px;
                                border-radius: 3px;
                                border: 1px solid #d8d8d8;
                            }
                        }
                        span {
                            &.dark {
                                color: #555;
                            }
                            &.small {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}
