.addImage {
    margin-top: 20px;
}
.addImageButton {
    font-size: 16px;
    line-height: 19px;
    color: #2990fb;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}

.isLoadingImageButton {
    opacity: 0.8;
    pointer-events: none;
}

.input {
    display: none;
}
