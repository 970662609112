@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';
@import '../../../../../../styles/animations';

.project-block__buttons-view {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include max_rwd(1200) {
        justify-content: center;
    }
    @include max_sm {
        flex-wrap: wrap;
    }

    &-icon {
        margin-right: 5px;
        display: flex;
    }

    > li {
        padding-right: 12px;
        box-sizing: border-box;
        &:last-child {
            padding-right: 0 !important;
        }

        @include max_rwd(1200) {
            width: 33.33%;
        }
        @include max_sm {
            &:nth-child(n) {
                width: 50% !important;
                padding: 3px;
            }
        }

        .button {
            padding: 0 12px;
            justify-content: center;
            @include max_rwd(1750) {
                &.is-hidden-text {
                    font-size: 0;
                    padding: 0;
                    width: 36px;

                    > span {
                        margin: 0;
                    }
                }
            }
            @include max_rwd(1200) {
                font-size: 14px !important;
                padding: 0 20px !important;
                width: 100% !important;
                > span {
                    margin: 5px !important;
                }
            }
            @include max_rwd(413) {
                &.is-hidden-text {
                    font-size: 0 !important;
                    padding: 0 !important;
                    > span {
                        margin: 0 !important;
                    }
                }
            }

            &.is-loading {
                cursor: auto;
                color: transparent;
                pointer-events: none;
                border-color: transparent !important;
                @extend .animatedLoading;
            }
        }
    }

    &_upgrade-button {
        border-color: #785cc7 !important;
        color: #785cc7 !important;
    }

    .settings-button {
        padding: 0 9px !important;
    }
}
