.cover {
    position: relative;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    z-index: 1;
    &:after {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: var(--overlayColor);
    }
}

.content {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;
}

.contentLogotype {
    margin-bottom: 40px;
}

.contentMain {
}
.contentMainText {
    border: 1px dashed #eee;
}
.contentMainButton {
    margin-top: 40px;
}

.contentFakeFooter {
    margin-top: 20px;
}
