@import '../../../styles/mixins';

.tags {
    padding-top: 5px;
}

.title {
    height: 16px;
    font-size: 14px;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tagList {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px -8px 0;
    overflow: hidden;
}

.tag {
    height: 28px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #2990fb;
    border-radius: 40px;
    padding: 0 12px;
    margin: 0 4px 8px 0;
    font-size: 14px;
    color: #2990fb;
    cursor: pointer;
    @include transition(0.2s, ease-in-out, 0s, (color, background-color));
    &:hover {
        opacity: 0.8;
    }
}

.isSelectedTag {
    background-color: #2990fb;
    color: #fff;
}

.isDisabledTag {
    pointer-events: none;
}
