@mixin maxWidth($value) {
    @media (max-width: $value+'px') {
        @content;
    }
}

.user-role-modal__roles {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 100px 0 48px;
    @include maxWidth(1019) {
        max-width: 376px;
        margin: 0 auto;
        padding: 50px 0 0;
    }
    > ul {
        margin-left: auto;
        width: 376px;
        @include maxWidth(1019) {
            width: 100%;
        }
        > li {
            background-color: #f8fbff;
            border: 1px solid #f8fbff;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 7px 12px;
            display: flex;
            width: 100%;
            margin-bottom: 6px;
            transition: border-color 0.2s ease-in-out;
            cursor: pointer;
            align-items: center;
            font-size: 16px;
            color: #3c3c3c;
            &:hover {
                border-color: #2990fb;
            }
            &:last-child {
                margin-bottom: 0;
            }
            &.is-selected {
                pointer-events: none;
                border-color: #2990fb;
                > span.point {
                    border-color: #2990fb;
                    &:before {
                        background-color: #2990fb;
                    }
                }
            }
            > span.point {
                position: relative;
                width: 24px;
                height: 24px;
                margin-right: 12px;
                border-radius: 50%;
                box-sizing: border-box;
                border: 1px solid #d8d8d8;
                background-color: #fff;
                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 14px;
                    height: 14px;
                    background-color: transparent;
                    border-radius: 50%;
                    transition: background-color 0.2s ease-in-out;
                }
            }
        }
    }
    > div.buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include maxWidth(1019) {
            margin-top: 30px;
        }
        > span {
            margin-right: 28px;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            color: #2990fb;
            cursor: pointer;
            transition: opacity 0.2s ease-in-out;
            &:hover {
                opacity: 0.7;
            }
        }
    }
}
