.imagesGallery {
    margin-bottom: 24px;
}

.label {
    font-size: 16px;
    color: #8a8a8a;
    margin-bottom: 8px;
    height: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list {
}

.item {
    margin-bottom: 20px;
    display: flex;
    &:last-child {
        margin-bottom: 0;
    }
}

.actions {
    margin-left: 12px;
    display: flex;
}

.action {
    cursor: pointer;
    margin-right: 4px;
    transition: opacity 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        opacity: 0.8;
    }
    &:last-child {
        margin-right: 0;
    }
}

.uploadImage {
    padding-bottom: 0;
}
