.sharing-settings-modal {
    max-width: 100%;
    width: 960px;
    background-color: #fff;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    overflow: hidden;
    box-sizing: border-box;
    text-align: left;
    display: flex;
    flex-direction: column;
    padding: 36px 156px 54px 124px;

    &__group {
        display: flex;
        padding: 5px 0;
    }

    &__favicon-preview {
        width: 20px;
        height: 20px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__favicon-change-button {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 14px;
        font-size: 14px;
        color: #2990fb;
        transition: opacity 0.2s ease-in-out;

        &:hover {
            opacity: 0.7;
        }
    }

    &__preview {
        width: 296px;
        flex-shrink: 0;

        .window {
            width: 100%;
            border-radius: 4px;
            background-color: #fff;
            border: 1px solid #d8d8d8;

            .image {
                height: 155px;
                background-color: #d8d8d8;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                &.is-loading {
                    background-size: 200px;
                    background-color: #fff;
                    background-image: url('./i/preloader.gif') !important;
                }
            }
            .text {
                height: 45px;
                display: flex;
                justify-content: center;
                padding: 0 12px;
                flex-direction: column;
                .title {
                    font-size: 14px;
                    color: #3c3c3c;
                    font-weight: bold;
                    padding-bottom: 4px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    &-empty {
                        width: 64px;
                        height: 7px;
                        background: #c4c4c4;
                        border-radius: 16px;
                        margin: 3px 0 5px;
                    }
                }
                .description {
                    font-size: 12px;
                    color: #606060;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    &-empty {
                        width: 100%;
                        height: 7px;
                        background: #c4c4c4;
                        border-radius: 16px;
                    }
                }
            }
        }
        .upload {
            display: flex;
            align-items: center;
            margin-top: 16px;
            > p {
                cursor: pointer;
                margin-left: 14px;
                display: inline-block;
                font-size: 14px;
                color: #2990fb;
                transition: opacity 0.2s ease-in-out;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
        .note {
            margin-top: 10px;
            color: #787878;
            font-size: 12px;
            line-height: 14px;
        }
    }
    &__info {
        width: 100%;
        margin-left: 32px;
        .inputs-block {
            > li {
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
                .title {
                    font-family: 'Roboto', sans-serif;
                    width: 100%;
                    position: relative;
                    font-size: 14px;
                    color: #787878;
                    margin-bottom: 6px;
                    font-weight: normal;
                    box-sizing: border-box;
                    > span {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        font-size: 12px;
                        color: #787878;
                    }
                }
                textarea {
                    padding: 12px;
                    &:focus {
                        padding: 11px;
                    }
                }
            }
        }
    }
}
