@import '../../../styles/variables';
@import '../../../styles/mixins';

.upload-media {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.upload-media__dialog {
    width: 640px;

    .confirmation-dialog__head {
        font-family: $Roboto;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
    }
    .confirmation-dialog__note {
        line-height: 24px;
    }

    .confirmation-dialog__buttons {
        flex-direction: row-reverse;
        margin-top: 20px;
    }
    .confirmation-dialog__image {
        img {
            width: 60px;
            height: 60px;
        }
    }
}

label {
    &.label {
        font-family: $Roboto;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        padding: 0 20px;
        box-sizing: border-box;
        border: 1px solid;
        border-radius: 4px;
        cursor: pointer;
        position: relative;
        &:disabled {
            pointer-events: none !important;
        }
        &[data-size='md'] {
            height: 36px;
        }
        &[data-size='lg'] {
            height: 44px;
        }
        // Primary
        &[data-variant='primary'] {
            color: $color_white;
            background-color: $color_blue--1;
            border-color: $color_blue--1;

            @include transition(
                $transition_duration--1,
                $transition_timing_function--1,
                $transition_delay--1,
                (box-shadow, border-color, background-color)
            );

            &:hover {
                box-shadow: $box-shadow--2;
                background-color: $color_light_blue--1;
                border-color: $color_light_blue--1;
            }
            &:disabled {
                background-color: $color_light_gray--2;
                border-color: $color_light_gray--2;
            }
        }
        // Primary-light
        &[data-variant='primary-light'] {
            color: $color_blue--1;
            border-color: $color_blue--1;

            @include transition(
                $transition_duration--1,
                $transition_timing_function--1,
                $transition_delay--1,
                (box-shadow, background-color, border-color)
            );

            &:hover {
                box-shadow: $box-shadow--2;
            }
            &:disabled {
                background-color: $color_light_gray--2;
                border-color: $color_light_gray--2;
            }
        }
        // Primary-danger
        &[data-variant='primary-danger'] {
            color: $color_white;
            background-color: $color_light_red--2;
            border-color: $color_light_red--2;

            @include transition(
                $transition_duration--1,
                $transition_timing_function--1,
                $transition_delay--1,
                (border-color, background-color)
            );

            &:disabled {
                background-color: $color_light_gray--2;
                border-color: $color_light_gray--2;
            }
        }
        // Primary (inverse)
        &[data-variant='primary--inverse'] {
            color: $color_blue--1;
            background-color: $color_white;
            border-color: $color_white;

            @include transition(
                $transition_duration--1,
                $transition_timing_function--1,
                $transition_delay--1,
                (box-shadow)
            );

            &:hover {
                box-shadow: $box-shadow--3;
            }
        }
        // Secondary
        &[data-variant='secondary'] {
            color: $color_light_gray--5;
            background-color: $color_white;
            border-color: $color_light_gray--2;
            @include transition(
                $transition_duration--1,
                $transition_timing_function--1,
                $transition_delay--1,
                (border-color, color)
            );
            &:hover {
                border-color: $color_light_gray--5;
            }
            &:disabled {
                color: $color_middle_gray--2;
            }
        }
        &[data-variant='secondary-white'] {
            color: $color_white;
            background-color: transparent;
            border-color: $color_white;
        }
    }
}
