.hint-popper {
    z-index: 1010;

    &[data-popper-placement='bottom-start'] {
        .hint {
            &:before {
                left: 0;
                border-top: 26px solid transparent;
                border-left: 40px solid #fff;
            }
        }
    }
    &[data-popper-placement='bottom-end'] {
        .hint {
            &:before {
                right: 0;
                border-top: 26px solid transparent;
                border-right: 40px solid #fff;
            }
        }
    }
    &[data-popper-placement='bottom'] {
        .hint {
            &:before {
                left: 185px;
                border-bottom: 40px solid #fff;
                border-left: 40px solid transparent;
                border-right: 40px solid transparent;
            }
        }
    }
}
.hint-wrapper {
    margin-top: 30px;
}
.hint {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 420px;
    min-height: 180px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 24px 40px 24px 36px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
    z-index: 10;
    &__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__text {
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        line-height: 28px;
        color: #000000;
        margin-bottom: 24px;
        white-space: pre-wrap;
    }
    &__button {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        border: 1px solid #2990fb;
        border-radius: 4px;
        padding: 8px 12px;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: #2990fb;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
            background-color: #2990fb;
            color: #fff;
        }
    }
    &__close {
        position: absolute;
        z-index: 2;
        top: 15px;
        right: 15px;
        width: 16px;
        height: 16px;
        cursor: pointer;
        transition: opacity 0.2s ease-in-out;
        &:hover {
            opacity: 0.7;
        }
        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 7px;
            height: 16px;
            width: 2px;
            background-color: #333;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
    &:before {
        content: '';
        position: absolute;
        top: -20px;
        width: 0;
        height: 0;
    }
}
