@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.modalStop {
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;
}

.modalOverlay {
    position: absolute;
    z-index: 110;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
}

.modalContent {
    position: absolute;
    z-index: 120;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 24px 40px 14px 36px;
    box-sizing: border-box;
    text-align: left;
}

.note {
    display: flex;
    margin-bottom: 5px;
    @include max_rwd(767) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }
}

.imageBox {
    margin-right: 20px;
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include max_rwd(767) {
        margin-right: 0;
    }
}

.image {
    max-width: 100%;
    max-height: 100%;
}

.textBox {
    width: 100%;
    padding-top: 10px;
    @include max_rwd(767) {
        padding-top: 30px;
        text-align: center;
    }
}

.textTitle {
    font-family: $Ubuntu;
    font-weight: 500;
    font-size: 24px;
    color: #3c3c3c;
    margin-bottom: 12px;
}

.textContent {
    font-size: 18px;
    color: #3c3c3c;
}

.buttons {
    display: flex;
    justify-content: flex-end;
}

.button {
    margin-right: 12px;
    min-width: 122px;
    justify-content: center;
    &:last-child {
        margin-right: 0;
    }
}
