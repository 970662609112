.serviceCommission {
    padding-top: 16px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #d9d9d9;
}

.label {
    font-size: 14px;
    line-height: 18px;
    color: #787878;
}

.value {
    font-size: 16px;
    line-height: 18px;
    color: #3c3c3c;
}
