@import '../../../../../../../../styles/variables';
@import '../../../../../../../../styles/mixins';

.printBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include max_rwd(1024) {
        align-items: flex-start;
        flex-direction: column;
    }
}

.button {
    margin: -32px 0 0 12px;
    background-color: #6b00be;
    color: #fff;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
    @include max_rwd(1024) {
        margin: 16px 0 0 0;
        width: 100%;
    }
}

.text {
    font-size: 14px;
    line-height: 20px;
    color: #787878;
    max-width: 360px;
    @include max_rwd(1024) {
        max-width: 100%;
    }
}
.coloredText {
    color: #6b00be;
    font-style: italic;
}

.buttonIcon {
    margin-right: 5px;
}
