.question {
    margin-bottom: 28px;
    display: flex;
    &:last-child {
        margin-bottom: 0;
    }
}

.number {
    font-size: 14px;
    color: #3c3c3c;
    width: 24px;
    text-align: left;
    height: 31px;
    display: flex;
    align-items: center;
}

.mainWrapper {
    width: 100%;
}

.addAnswerButton {
    padding: 12px 0 0 26px;
}
