@import '../../../../../../styles/mixins';

.createProjectMenuItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    @include max_rwd(991) {
        flex-direction: column;
    }
}

.createProjectMenuItemImage {
    position: relative;
    margin-right: 20px;
    font-size: 0;
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background-image 0.2s ease-in-out, background-color 0.2s ease-in-out;
    background-color: #f9fafd;
    border-radius: 4px;
    @include max_rwd(991) {
        margin-right: 0;
        margin-bottom: 10px;
    }
}
.createProjectMenuItemImageScratch {
    background-image: url('./i/fromScratch.png');
}
.createProjectMenuItemImageTemplate {
    background-image: url('./i/fromTemplate.png');
}
.createProjectMenuItemImageRealTimeGame {
    background-image: url('./i/realTimeEvent.png');
}

.createProjectMenuItemLabel {
    position: absolute;
    top: 6px;
    right: -12px;
    font-size: 12px;
    color: #fff;
    padding: 0 8px;
    height: 20px;
    background-color: #785cc7;
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.createProjectMenuItemTexts {
    position: relative;
}

.createProjectMenuItemTitle {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 8px;
    white-space: normal;
}

.createProjectMenuItemDescription {
    font-size: 12px;
    line-height: 16px;
    color: #787878;
    white-space: normal;
}

.menuClassName {
    width: 376px;
    padding: 20px;
    box-sizing: border-box;
    @include max_rwd(991) {
        width: 100%;
        padding: 0;
    }
}

.menuItemClassName {
    border-radius: 8px !important;
    padding: 8px !important;
    margin-bottom: 4px !important;
    background-color: transparent !important;
    transition: background-color 0.2s ease-in-out;
    &:hover {
        background-color: #f9fafd !important;
        .createProjectMenuItemImage {
            background-color: #69b1fc;
        }
    }
    &:last-child {
        margin-bottom: 0 !important;
    }
}
