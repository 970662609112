.common-text-input {
    position: relative;
    padding: 5px 0;
    font-family: 'Roboto', sans-serif;
    &.full-wide {
        width: 100%;
    }

    &.common-text-input__disabled {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    }
    .common-text-input__required {
        .common-text-input__label-text:after {
            content: ' *';
            color: #ff5656;
        }
    }
    .common-text-input__optional {
        &:after {
            margin-left: 5px;
            content: attr(data-optional);
            color: #b7b7b7;
        }
    }

    .common-text-input__label {
        height: 16px;
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        text-align: left;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        &[data-description] {
            &:before {
                content: attr(data-description);
                position: absolute;
                right: 0;
                font-style: italic;
                color: #b7b7b7;
            }
        }
        &-text {
            font-size: inherit;
        }
    }

    .common-text-input__maxLength {
        font-size: 12px;
        color: #8a8a8a;
    }

    .common-text-input__input-container {
        position: relative;
        height: 100%;
    }

    .common-text-input__input-text {
        appearance: none;
        border: 1px solid #979797;
        border-radius: 4px;
        background-color: #fff;
        outline: none;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        resize: none;
        font-size: 16px;
        line-height: 19px;
        color: #3c3c3c;
        padding: 5px 12px 6px;
        &::placeholder {
            color: #979797;
        }

        &.is-error {
            border-color: #ff5656;
        }

        &.is-clickable {
            cursor: pointer;
        }

        &:focus:not(:read-only),
        &.is-focused:not(:read-only) {
            border-color: #69b1fc;
            box-shadow: inset 0 0 0 1px #69b1fc;
        }
    }

    .common-text-input__error {
        position: absolute;
        top: 100%;
        right: 0;
        font-size: 12px;
        color: #ff5656;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
