@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.blockSummary {
    font-family: $Roboto;
    font-style: normal;
    color: $color_black;
}
.blockSummaryTitle {
    font-weight: 300;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 24px;
}
.blockSummaryContent {
    display: flex;
}
.blockSummaryItem {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    background: $color_white;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    padding: 16px;

    &:first-child {
        margin-right: 12px;
    }
}
.blockSummaryItemTitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
}
.blockSummaryItemCount {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
}
.blockSummaryItemPostfix {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: $color_blue--1;
    &:before {
        content: ' | ';
        color: $color_light_gray--2;
    }
    &:after {
        content: '%';
    }
}
