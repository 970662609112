.social {
    margin: 40px 0 25px;
}

.divider {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    position: relative;
    height: 16px;
    &:before {
        content: attr(data-text);
        font-size: 16px;
        color: #979797;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        background: #fff;
        z-index: 1;
        padding: 0 13px;
    }
    &:after {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        top: 50%;
        left: 0;
        background-color: #979797;
    }
}
