@import '../../../../styles/mixins';

.blocks {
    display: flex;
    margin-bottom: 31px;
    @include max_rwd(767) {
        flex-direction: column;
        margin-bottom: 5px;
    }
}

.avatarBlock {
    margin-right: 40px;
    flex-shrink: 0;
    @include max_rwd(767) {
        margin: 0 0 20px;
        display: flex;
        justify-content: center;
    }
}

.inputsBlock {
    width: 100%;
}
