.project-analytics {
    min-height: 75vh;
    &__workplace {
        display: flex;
        background-color: #f9fafd;
        min-height: 75vh;
    }
    &-content {
        padding: 40px;
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        min-height: 400px;
        &__inner {
            width: 100%;
            margin: auto;
            max-width: 1080px;
            box-sizing: border-box;
            > h1 {
                text-align: center;
                font-size: 36px;
                margin-bottom: 20px;
            }
        }
    }
}
