@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';
@import '../../../../../styles/animations';

.templates-cards-block {
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.0668875);
    border-radius: 4px;
    padding: 36px 56px 75px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include max_rwd(1340) {
        padding: 20px;
        align-items: stretch;
    }

    &__title {
        margin-bottom: 20px;
        font-family: $Ubuntu;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #000;
    }

    &.is-loading {
        .templates-cards-block__title {
            width: 20%;
            height: 30px;
            color: transparent;
            @extend .animatedLoading;
        }
        .templates-cards-block__templates-template-image {
            width: 208px;
            height: 156px;
            border-radius: 8px;
            z-index: 2;
            @extend .animatedLoading;
        }
        .templates-cards-block__templates-template-title {
            height: 30px;
            position: relative;
            color: transparent;
            @extend .animatedLoading;
        }
    }

    &__templates {
        display: inline-grid;
        grid-template-columns: repeat(4, 208px);
        grid-gap: 32px;

        @include max_rwd(1340) {
            justify-content: start;
            display: grid;
            grid-template-columns: repeat(auto-fit, 208px);
        }
    }

    &__template {
        &-image-wrapper {
            position: relative;
            margin-bottom: 16px;

            &:hover {
                .templates-cards-block__template-buttons {
                    display: flex;
                }
            }
        }

        &-image {
            border-radius: 8px;
            box-shadow: 0 4px 20px rgba(41, 144, 251, 0.2);
            background: #ffffff;
            object-fit: cover;
        }

        &-title {
            font-family: $Roboto;
            font-size: 16px;
            line-height: 19px;
            color: #000;
        }

        &-buttons {
            position: absolute;
            box-sizing: border-box;
            justify-content: center;
            align-content: flex-end;
            flex-direction: column;
            width: 100%;
            bottom: 0;
            display: none;
            background-color: #ffffff;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            padding: 10px 15px;

            button,
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px 12px;
                border-radius: 4px;

                font-family: $Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                text-decoration: none;
                white-space: nowrap;
                cursor: pointer;
                margin-bottom: 12px;

                svg {
                    margin-right: 5px;
                    width: 16px;
                    height: 16px;
                }

                &[data-variant='secondary'] {
                    color: #979797;
                    border: 1px solid #979797;
                }

                &[data-variant='primary'] {
                    background: #2990fb;
                    color: #ffffff;
                }
            }
        }
    }

    &__see-all-templates {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 208px;
        height: 156px;
        border-radius: 8px;
        padding: 0 32px;
        box-shadow: 0 4px 20px rgba(41, 144, 251, 0.2);
        background: #ffffff;

        font-family: $Ubuntu;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        color: #2990fb;
        text-decoration: none;
        &-button {
            position: relative;
            width: 32px;
            height: 32px;
            margin-left: 29px;
            border-radius: 50%;
            box-shadow: 0 4px 20px rgba(41, 144, 251, 0.24);
            flex: none;

            &:after {
                content: '';
                position: absolute;
                width: 8px;
                height: 8px;
                border-top: 2px solid #2990fb;
                border-right: 2px solid #2990fb;
                transform: rotate(45deg);
                top: 11px;
                left: 9px;
            }
        }

        &:hover {
            box-shadow: 0 4px 20px rgba(41, 144, 251, 0.3);
        }
    }
}
