.memory-modal-wrapper {
    width: 960px;
    height: 428px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    background-color: #f9fafd;
    .memory-modal-wrapper__body {
        height: 100%;
        width: 100%;
    }
}
