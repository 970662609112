.codeEditorBox {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #d8d8d8;
    background-color: #f9fafd;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: 16px 16px 10px;
    box-sizing: border-box;
}

.content {
    width: 100%;
    color: #979797;
    font-size: 12px;
    line-height: normal;
}
.isCodeContent {
    max-height: 44px;
    background: linear-gradient(180deg, #979797 -28.18%, rgba(151, 151, 151, 0.25) 119.09%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.button {
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #d8d8d8;
    background-color: #fff;
    padding: 4px 12px;
    color: #787878;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}
