@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.upgradeProjectTariff {
    box-sizing: border-box;
    width: 960px;
    max-width: 100%;
    height: 554px;
    background: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 60px;

    @include max_rwd(1024) {
        width: auto;
        height: auto;
        padding: 60px 20px 40px;
    }
}

.title {
    font-family: $Ubuntu;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: -1px;
    margin-bottom: 20px;
    color: #000000;
}

.description {
    font-family: $Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #3c3c3c;
}

.productList {
    display: flex;
    margin-top: 36px;
    gap: 20px;
    @include max_rwd(1024) {
        flex-direction: column;
    }
}
.cardItem {
    @include max_rwd(1024) {
        width: 100% !important;
    }
}

.cardBox {
    box-sizing: border-box;
    height: 300px;
    background: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 32px 28px 28px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}
.cardInfo {
    display: flex;
    flex-direction: column;
}
.planName {
    font-family: $Ubuntu;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: #2990fb;
    margin-bottom: 8px;
}
.price {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 12px;
}

.priceCurrency {
    font-family: 'Ubuntu', 'Roboto', sans-serif;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    color: #000;
    margin-right: 8px;
}

.priceValue {
    font-family: $Ubuntu;
    font-weight: bold;
    font-size: 48px;
    line-height: 48px;
    color: #000;
    margin-right: 8px;
}
.isCompactPriceValue {
    font-size: 36px;
    line-height: 40px;
}

.cycle {
    display: block;
}

.cycleValue {
    font-family: $Ubuntu;
    font-size: 16px;
    line-height: 28px;
    color: #000;
}
.cardDescription {
    text-align: left;
    font-family: $Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}

.upgradeButton {
    margin: 0 auto;
    width: max-content;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.loader {
    margin-top: 200px;
    @include max_rwd(1024) {
        margin-top: 40px;
    }
}
