@import '../../../../styles/variables';
@import '../../../../styles/mixins';

@mixin transition($transition_duration, $transition_timing_function, $transition_delay, $transition_property) {
    transition-duration: $transition_duration;
    transition-timing-function: $transition_timing_function;
    transition-delay: $transition-delay;
    transition-property: $transition-property;
}

.editor-new {
    .review {
        text-align: center;
        padding: 0 20px 40px;
        > h1 {
            font-family: $Roboto;
            font-size: 20px;
            color: #3c3c3c;
            font-weight: normal;
            margin: 40px 0 40px;
        }
        > p {
            font-family: $Roboto;
            font-size: 16px;
            color: #fff;
            font-weight: normal;
        }
        .wrap-window {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin: -12px;
            .box {
                position: relative;
                width: 100%;
                max-width: 480px;
                box-sizing: border-box;
                background-color: #fff;
                border-radius: 4px;
                padding: 40px 60px;
                margin: 12px;
                overflow: hidden;
                @include max_rwd(520) {
                    padding: 30px 20px;
                }
                &--sharing {
                    > h3 {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: $Roboto;
                        font-size: 24px;
                        color: #3c3c3c;
                        font-weight: normal;
                        margin-bottom: 20px;
                        min-height: 48px;
                        > img {
                            flex-shrink: 0;
                            margin-right: 10px;
                        }
                    }

                    > .inner-box {
                        border-radius: 4px;
                        background-color: #fff;
                        overflow: hidden;
                        text-align: left;
                        border: 1px solid #d8d8d8;
                        .preview-img {
                            height: 155px;
                            width: 100%;
                            background-position: center;
                            background-size: cover;
                            background-repeat: no-repeat;
                            &.is-loading {
                                background-size: 200px;
                            }
                        }
                        .text {
                            padding: 8px 12px;
                            .title {
                                font-family: $Roboto;
                                font-size: 14px;
                                font-weight: bold;
                                color: #3c3c3c;
                                margin-bottom: 4px;
                            }
                            .description {
                                font-family: $Roboto;
                                font-size: 12px;
                                font-weight: normal;
                                color: #606060;
                            }
                        }
                    }
                    > .btns {
                        &--bottom {
                            position: absolute;
                            bottom: 35px;
                            left: 0;
                            width: 100%;
                        }
                        > button {
                            min-width: 144px;
                            justify-content: center;
                            margin: 35px auto 0;
                            color: #3c3c3c;
                            border-color: #3c3c3c;
                        }
                    }
                }
                &--custom-url {
                    .head {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        position: relative;
                        margin-bottom: 25px;
                        .switcher {
                            margin-right: 24px;
                            position: relative;
                            cursor: pointer;
                            width: 38px;
                            height: 19px;
                            background-color: #979797;
                            border-radius: 9.5px;
                            @include transition(0.1s, ease-in-out, 0s, (background-color));
                            &:before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: -1px;
                                width: 21px;
                                height: 21px;
                                background-color: #fff;
                                border-color: #d2d2d2;
                                border-width: 1px;
                                border-style: solid;
                                border-radius: 50%;
                                box-sizing: border-box;
                                @include transition(0.1s, ease-in-out, 0s, (border-color, left));
                            }
                            &.is-checked {
                                background-color: #2990fb;
                                &:before {
                                    border-color: #2990fb;
                                    left: calc(100% - 21px);
                                }
                            }
                        }
                        > p {
                            font-family: $Roboto;
                            font-weight: normal;
                            font-size: 24px;
                            color: #3c3c3c;
                            margin-bottom: 20px;
                            > span {
                                cursor: pointer;
                                color: #2990fb;
                            }
                        }
                        .edit-btn {
                            display: block;
                            position: absolute;
                            top: 50%;
                            right: -19px;
                            transform: translateY(-50%);
                        }
                    }
                    .link {
                        font-family: $Roboto;
                        font-weight: normal;
                        font-size: 16px;
                        letter-spacing: -0.24px;
                        color: #3c3c3c;
                        background: #ffffff;
                        border: 1px solid #d8d8d8;
                        padding: 40px 28px;
                        border-radius: 4px;
                        position: relative;
                        text-align: left;
                        &--domain {
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        .project_id {
                            display: block;
                            margin-top: 3px;
                            > span {
                                font-family: $Roboto;
                                font-weight: 300;
                                font-size: 24px;
                                line-height: 28px;
                                color: #69b1fc;
                                &.ph {
                                    color: #979797;
                                }
                            }
                        }

                        > span.edit-btn {
                            position: absolute;
                            top: 38px;
                            right: 23px;
                            cursor: pointer;
                        }
                    }
                    > p.description {
                        margin-top: 24px;
                        font-family: $Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #3c3c3c;
                    }
                }
            }
        }
    }
}
