.voiceRecorder {
    max-width: 100%;
    width: 420px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    padding: 48px 40px 40px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
