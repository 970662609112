@import '../../../../../../../../styles/mixins';

.urlBlock {
    position: relative;
    margin-bottom: 20px;
}

.box {
    margin-top: 12px;
    display: flex;
    @include max_rwd(767) {
        flex-direction: column;
    }
}

.url {
    width: 100%;
    background-color: #fff;
    border: 1px solid #979797;
    border-radius: 4px;
    margin-right: 12px;
    font-size: 16px;
    line-height: 42px;
    color: #3c3c3c;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 12px;
    box-sizing: border-box;
    @include max_rwd(767) {
        margin-right: 0;
        margin-bottom: 12px;
    }
}

.copyButton {
    margin-right: 12px;
    flex-shrink: 0;
    > img {
        margin-right: 8px;
    }
    @include max_rwd(767) {
        margin-right: 0;
        margin-bottom: 6px;
    }
}

.viewButton {
    flex-shrink: 0;
    > img {
        margin-right: 8px;
    }
}
