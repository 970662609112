.circleSpinner {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image {
    max-width: 100%;
    max-height: 100%;
    -webkit-animation: spinAnimation 4s linear infinite;
    -moz-animation: spinAnimation 4s linear infinite;
    animation: spinAnimation 4s linear infinite;

    @-moz-keyframes spinAnimation {
        100% {
            -moz-transform: rotate(360deg);
        }
    }
    @-webkit-keyframes spinAnimation {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }
    @keyframes spinAnimation {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}
