.content {
    width: 100%;
    max-width: 710px;
    margin: 0 auto;
    padding: 45px 40px;
    box-sizing: border-box;
    @media (max-width: 991px) {
        max-width: 100%;
        padding: 0;
    }
}

.box {
    margin: 90px auto 0;
    box-sizing: border-box;
    @media (max-width: 991px) {
        text-align: center;
        margin-top: 0;
        max-width: 560px;
        width: 100%;
        padding: 30px 20px;
    }
}
