@import '../../../../../styles/variables';

.asset-preview {
    position: absolute;
    background-color: $color-white;
    z-index: 100;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 8px 8px 0;
    display: flex;
    flex-direction: column;

    &__description {
        box-sizing: border-box;
        overflow: hidden;
        padding: 4px 0 8px;
        height: 42px;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: $color_dark_gray--1;
    }
}
