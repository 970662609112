.map-block {
    width: 100%;
    position: relative;
    .map-container {
        width: 100%;
        height: 640px;
        &.is-locked {
            filter: blur(5px);
        }
    }
    > .lock-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        text-align: center;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        font-size: 16px;
        width: 100%;
        max-width: 400px;
        box-sizing: border-box;
        line-height: 18px;
    }
}
