body {
    font-family: $Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    color: $color_middle_gray--1;
    overflow-x: hidden;
    overflow-y: auto;

    //&::-webkit-scrollbar {
    //  width: 8px;
    //  background: rgba(83, 122, 157, 0.05);
    //  border-radius: 4px;
    //}
    //&::-webkit-scrollbar-thumb {
    //  background: rgba(83, 122, 157, 0.2);
    //  border-radius: 4px;
    //}
    //
    //scrollbar-color: rgba(83, 122, 157, 0.05) rgba(83, 122, 157, 0.2);
    //scrollbar-width: thin;
}

[data-simplebar] {
    .simplebar-track {
        &.simplebar-vertical {
            right: 6px;
            width: 8px;
            > .simplebar-scrollbar {
                &:before {
                    top: 8px;
                    bottom: 8px;
                    left: 0;
                    right: 0;
                    background-color: #d8d8d8;
                    border-radius: 9px;
                    opacity: 1;
                }
            }
        }
    }
}

#root {
}
.wrapper {
}

b {
    font-weight: bold;
}
i {
    font-style: italic;
}

a {
    color: inherit;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}

button {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    outline: none;
    box-shadow: none;
    font-family: $Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    color: inherit;
}

h1 {
}
h2 {
}
h3 {
}
h4 {
}
h5 {
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
    background-color: #000 !important;
}

.react-datepicker__year-read-view--down-arrow {
    top: 2px !important;
}

.jvectormap-tip {
    z-index: 11000;
    position: absolute;
}

.react-datepicker-popper {
    @include no-select;
}

.not-support-browser {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: $color_light_blue--6;
    overflow-y: auto;
    h2 {
        font-family: $Ubuntu;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.666px;
        color: $color-black;
        opacity: 0.5;
        margin-bottom: 40px;
        text-align: center;
    }
    h1 {
        font-family: $Ubuntu;
        font-weight: bold;
        font-size: 33px;
        line-height: 48px;
        letter-spacing: -0.666px;
        color: $color-black;
        margin-bottom: 40px;
        text-align: center;
        @include max_sm {
            font-size: 26px;
            line-height: 32px;
        }
    }
    p {
        font-family: $Ubuntu;
        font-size: 16px;
        font-weight: normal;
        line-height: 36px;
        color: $color-black;
        margin-bottom: 80px;
        text-align: center;
        @include max_sm {
            margin-bottom: 60px;
        }
        a {
            text-decoration: none;
            color: $color_blue--1;
            cursor: pointer;
        }
    }
}
