.input {
    input {
        padding-right: 44px !important;
    }
}

.eyeBox {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
    bottom: 4px;
    display: flex;
    height: 28px;
    justify-content: center;
    right: 8px;
    width: 28px;
    svg {
        path {
            transition: stroke 0.2s ease-in-out;
        }
        circle {
            transition: fill 0.2s ease-in-out;
        }
    }
    &:hover {
        svg {
            path {
                stroke: #2990fb;
            }
            circle {
                fill: #2990fb;
            }
        }
    }
}
