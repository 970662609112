.modal-pairs__card {
    box-sizing: border-box;
    position: relative;
    width: 74px;
    height: 74px;
    margin: 3px;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #d8d8d8;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &-text {
        width: 100%;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        word-wrap: break-word;
        overflow: hidden;
        text-align: center;

        &.big {
            font-size: 16px;
            line-height: 20px;
        }
        &.medium {
            font-size: 12px;
            line-height: 20px;
        }
        &.small {
            font-size: 8px;
            line-height: 10px;
        }
    }

    &-audio {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 5px;
        right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #ffffff;
        box-shadow: 0 4px 20px rgba(41, 144, 251, 0.25);
        color: #3c3c3c;
        cursor: pointer;
        svg {
            width: 16px;
            height: 16px;
        }
    }

    .modal-pairs__svg {
        height: 20px;
        width: 20px;
        position: absolute;
        cursor: pointer;
        &:hover {
            > svg {
                path {
                    fill: #3c3c3c;
                }
                rect {
                    fill: #ffffff;
                    fill-opacity: 1;
                }
            }
        }
    }

    .modal-pairs__camera-svg {
        bottom: 4px;
        right: 26px;
    }
    .modal-pairs__text-svg {
        bottom: 4px;
        left: 4px;
    }
    .modal-pairs__audio-svg {
        bottom: 4px;
        right: 4px;
    }
}
