.finalPreview {
    width: 340px;
    flex-shrink: 0;
    border-left: 1px solid #d8d8d8;
}

.main {
    position: relative;
    padding: 12px 40px 30px;
    flex-shrink: 0;
    box-sizing: border-box;
}

.title {
    color: #787878;
    font-size: 14px;
    margin-bottom: 10px;
}

.noData {
    text-align: center;
    font-family: 'Ubuntu', sans-serif;
    font-size: 14px;
    color: #787878;
    padding: 30px 0;
}

.content {
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    padding-bottom: 20px;
}
.contentImage {
    width: 100%;
    padding-bottom: 73.8%;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 4px;
}
.contentHeader {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    padding: 16px 8px 0;
}
.contentDescription {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    padding: 16px 8px 0;
}
.contentImageDisclaimer {
    text-align: left;
    margin-top: 12px;
    font-size: 8px;
    line-height: 9px;
    color: #b7b7b7;
    padding: 12px 8px 0;
}
