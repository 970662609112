@import '../../../../../../../../../../styles/mixins';

.mainInfo {
    display: flex;
    justify-content: space-between;
    @include max_rwd(512) {
        flex-direction: column-reverse;
        align-items: center;
    }
}

.leftGroup {
    cursor: pointer;
    display: flex;
    @include max_rwd(512) {
        width: 100%;
    }
}

.isDisabledLeftGroup {
    pointer-events: none;
    opacity: 0.5;
}

.circle {
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #787878;
    position: relative;
    border-radius: 50%;
    margin-right: 12px;
    flex-shrink: 0;
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: transparent;
        transform: translate(-50%, -50%);
    }
}

.isActiveCircle {
    border-color: #2990fb;
    &:before {
        background-color: #2990fb;
    }
}

.texts {
    opacity: 1;
}

.title {
    font-size: 16px;
    line-height: 16px;
    color: #3c3c3c;
    margin-bottom: 8px;
    > span {
        font-weight: bold;
    }
}

.description {
    font-size: 14px;
    line-height: 18px;
    color: #787878;
}

.disabledLabel {
    margin-left: 50px;
    height: 24px;
    display: flex;
    align-items: center;
    background-color: #6b00be;
    border-radius: 28px;
    padding: 0 12px;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    white-space: nowrap;
    flex-shrink: 0;
    @include max_rwd(512) {
        margin-left: 0;
        margin-bottom: 20px;
    }
}
