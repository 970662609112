@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.controlPanel {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    color: #787878;
    margin-right: 5px;
}

.projectListArea {
    margin-top: 15px;
}
