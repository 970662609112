.image-picker {
    padding-bottom: 12px;
    width: 128px;

    &__label {
        margin-bottom: 8px;
    }

    &__area {
        border-radius: 4px;
        border: 1px solid #d8d8d8;
        width: 128px;
        height: 128px;
        position: relative;
        display: flex;

        &.is-error {
            border-color: #ff5656;
        }
    }
    &__preview {
        width: 100%;
        object-position: center;
        object-fit: cover;
    }

    &__button {
        cursor: pointer;
        width: 28px;
        height: 28px;
        border-radius: 3px;
        border: 1px solid #d8d8d8;
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 4px;
        background: rgba(0, 0, 0, 0.2) center no-repeat url('photo.svg');
    }

    &__error {
        font-size: 12px;
        padding-top: 4px;
        color: #ff5656;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: end;
    }
}
