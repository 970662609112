.actionSwitcher {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.desktopView {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 991px) {
        display: none;
    }
}

.button {
    min-width: 144px;
    padding: 0 10px !important;
    justify-content: center;
}

.text {
    font-size: 16px;
    margin-right: 20px;
    color: #000;
}

.mobileView {
    width: 100%;
    display: none;
    height: 92px;
    padding: 0 20px;
    background-color: #2990fb;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 991px) {
        display: flex;
    }
}

.icon {
    height: auto;
    width: 120px;
}
