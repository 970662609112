.following {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.list {
    margin-top: 30px;
    width: 100%;
}

.seeMoreContainer {
    margin-top: 28px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.seeMoreButton {
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #2990fb;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        text-decoration: none;
        opacity: 0.8;
    }
}
