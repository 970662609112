.addButtonContainer {
    display: flex;
    justify-content: flex-start;
}

.addButton {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #2990fb;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
    > img {
        margin-right: 8px;
    }
    &:hover {
        opacity: 0.8;
    }
}
