@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.previewBlock {
    display: flex;
    flex-shrink: 0;
    position: relative;
    max-height: 100%;
    overflow: hidden;
    margin-right: 40px;
    @include max_xl {
        margin-right: 30px;
    }
    @include max_rwd(1200) {
        margin: 20px 0;
        justify-content: center;
    }
}
