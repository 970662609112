@import '../../styles/variables';

.MailchimpIntegrations {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 12px;

    .group {
        display: flex;
        align-items: center;
        > p {
            padding-left: 10px;
        }
    }

    button {
        cursor: pointer;
        padding-right: 20px;
        padding-left: 20px;
        height: 44px;
        border: 1px solid #2990fb;
        box-sizing: border-box;
        border-radius: 4px;

        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;

        color: #2990fb;

        &:hover {
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        }
    }

    .name {
        font-family: $Ubuntu;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
    }
}
