.head {
    position: relative;
    padding: 0 60px;
    background-color: #2990fb;
    opacity: 0.7;
    @media (max-width: 1024px) {
        display: none;
    }
}

.list {
    display: flex;
    height: 68px;
    align-items: center;
}

.item {
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    padding-right: 20px;
    box-sizing: border-box;
    &:last-child {
        padding-right: 0;
    }
}
