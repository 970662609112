.page--auth {
    display: flex;
    @include max_md {
        flex-direction: column;
    }
    > aside {
        width: 550px;
        height: 100vh;
        position: sticky;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color_blue--1;
        background-position: 50% 100%;
        background-image: url('../../images/mockups.png');
        background-repeat: no-repeat;
        flex-shrink: 0;
        @include max_xl {
            width: 460px;
        }
        @include max_lg {
            align-items: flex-start;
            width: 400px;
        }
        @include max_md {
            display: none;
        }
        > .text {
            margin-bottom: 150px;
            @include max_lg {
                margin-bottom: 0;
                margin-top: 45px;
            }
            svg {
                width: 240px;
                height: auto;
                path.color_fill {
                    fill: $color_white !important;
                }
                path.color_stroke {
                    stroke: $color_white !important;
                }
            }
            > p {
                color: $color_white;
                margin-top: 20px;
                width: 240px;
                font-family: $Ubuntu;
                font-size: 20px;
                line-height: 24px;
            }
        }
    }
    > section {
        width: 100%;
        max-width: 710px;
        margin: 0 auto;
        padding: 45px 40px;
        box-sizing: border-box;
        @include max_lg {
            max-width: 580px;
            padding: 30px 30px;
        }
        @include max_xs {
            padding: 40px 20px;
        }
        > .switcher {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @include max_md {
                display: none;
            }
            > .text {
                font-size: 16px;
                margin-right: 20px;
                color: $color_black;
            }
            > button {
                width: 144px;
                padding: 0;
                justify-content: center;
            }
        }
        > .box {
            margin-top: 90px;
            @include max_md {
                text-align: center;
                margin-top: 0;
            }
            > .image {
                margin-bottom: 35px;
                > svg {
                    width: 240px;
                    height: auto;
                    path.color_fill--1 {
                        fill: $color_blue--1 !important;
                    }
                    path.color_stroke--1 {
                        stroke: $color_blue--1 !important;
                    }
                }
            }
            > h2 {
                font-family: $Ubuntu;
                font-weight: bold;
                font-size: 36px;
                line-height: 41px;
                letter-spacing: -1px;
                color: $color_black;
                @include max_xs {
                    font-size: 28px;
                    line-height: 32px;
                }
            }
            > h3 {
                margin-top: 10px;
                font-size: 16px;
                line-height: 22px;
                color: $color_black;
                @include max_xs {
                    margin-top: 15px;
                    font-size: 14px;
                    line-height: 16px;
                }
                > span {
                    font-weight: bold;
                }
            }
        }
        > div.reg--success {
            margin-top: 32px;
            padding-top: 33px;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 1px;
                width: calc(100% - 75px);
                background-color: #d8d8d8;
                @include max_md {
                    width: 100%;
                }
            }
            @include max_md {
                text-align: center;
            }
            > h4 {
                font-weight: 500;
                font-size: 18px;
                color: #000000;
            }
            > p {
                margin-top: 8px;
                font-size: 16px;
            }
            > div.image {
                margin-top: 24px;
                display: flex;
                justify-content: flex-end;
                @include max_rwd(1320) {
                    justify-content: flex-start;
                }
                @include max_md {
                    justify-content: center;
                }
                > img {
                    margin: -20px;
                    @include max_rwd(1320) {
                        max-width: 100%;
                    }
                }
            }
            > div.buttons {
                display: flex;
                margin-top: 40px;
                @include max_md {
                    justify-content: center;
                }
                @include max_rwd(567) {
                    flex-direction: column;
                }
                > div,
                > a {
                    font-size: 16px;
                    line-height: 20px;
                    color: #2990fb;
                    padding: 14px 24px;
                    border: 1px solid #2990fb;
                    text-decoration: none;
                    border-radius: 4px;
                    cursor: pointer;
                    @include transition(
                        $transition_duration--1,
                        $transition_timing_function--1,
                        $transition_delay--1,
                        (color, background-color)
                    );
                    &:hover {
                        color: #fff;
                        background-color: #2990fb;
                    }
                }
                > div {
                    margin-right: 20px;
                    @include max_rwd(567) {
                        margin: 0 0 20px 0;
                    }
                    &.is-loading {
                        cursor: wait;
                        color: #fff;
                        background-color: #2990fb;
                    }
                    &.is-success {
                        cursor: not-allowed;
                        color: $color_light_gray--2;
                        border-color: $color_light_gray--2;
                        background-color: #fff;
                    }
                }
            }
        }
        > form {
            margin-top: 40px;
            padding-right: 110px;
            @include max_lg {
                padding-right: 60px;
            }
            @include max_md {
                padding-right: 0;
            }
            @include max_xs {
                margin-top: 35px;
            }
            .react-code-input {
                display: block !important;
                &.is-error > input {
                    border-color: $color_red--1 !important;
                }
                > input {
                    width: 70px !important;
                    height: 80px !important;
                    margin: 0 16px 0 0 !important;
                    background: $color_white !important;
                    border: 1px solid $color_middle_gray--2 !important;
                    box-sizing: border-box !important;
                    border-radius: 4px !important;
                    font-family: $Ubuntu !important;
                    font-size: 48px !important;
                    text-align: center !important;
                    box-shadow: none !important;
                    outline: none !important;
                    padding: 0 !important;
                    &:focus {
                        border-color: $color_light_blue--1 !important;
                        caret-color: $color_light_blue--1 !important;
                        border-width: 2px !important;
                    }
                    &:nth-child(3) {
                        margin-right: 36px !important;
                    }
                    &:last-child {
                        margin-right: 0 !important;
                    }
                    @include max_lg {
                        width: 62px !important;
                        height: 72px !important;
                        margin-right: 15px !important;
                        &:nth-child(3) {
                            margin-right: 28px !important;
                        }
                    }
                    @include max_md {
                        font-size: 42px !important;
                        width: 14% !important;
                        margin-right: 3% !important;
                        &:nth-child(3) {
                            margin-right: 4% !important;
                        }
                    }
                    @include max_rwd(480) {
                        height: 62px !important;
                        font-size: 34px !important;
                    }
                }
            }
            > .inputs {
                > .input-box {
                    margin-bottom: 24px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            > .block {
                display: flex;
                flex-flow: row wrap;
                > .input-box {
                    width: 50%;
                    box-sizing: border-box;
                    margin-bottom: 24px;
                    &:nth-child(odd) {
                        padding-right: 10px;
                        > .msg {
                            right: 10px;
                        }
                    }
                    &:nth-child(even) {
                        padding-left: 10px;
                    }
                    @include max_xs {
                        width: 100%;
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        > .msg {
                            right: 0 !important;
                        }
                    }
                }
            }
            > .ads {
                margin-top: 25px;
                display: flex;
                > p {
                    cursor: pointer;
                    margin-left: 10px;
                    font-size: 14px;
                    line-height: 16px;
                    color: $color_dark_gray--1;
                }
            }
            > .buttons {
                margin-top: 35px;
                display: flex;
                justify-content: space-between;
                @include max_xs {
                    justify-content: center;
                    &.mobile-column {
                        flex-direction: column;
                    }
                    &.mobile-column-reverse {
                        flex-direction: column-reverse;
                    }
                }
                > div {
                    display: flex;
                    @include max_xs {
                        justify-content: center;
                    }
                    &:first-child {
                        padding-right: 20px;
                    }
                    > button {
                        margin-right: 12px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                > .plain {
                    @include max_xs {
                        padding-right: 0 !important;
                        margin-top: 15px;
                        justify-content: center;
                    }
                    > span {
                        cursor: pointer;
                        font-size: 14px;
                        line-height: 16px;
                        color: $color_blue--1;
                    }
                }
            }
            > .notice {
                margin-top: 20px;
                text-align: right;
                font-size: 12px;
                line-height: 16px;
                color: $color_dark_gray--1;
                @include max_xs {
                    text-align: center;
                }
                > a {
                    text-decoration: none;
                    color: $color_blue--1;
                }
            }
        }
    }
    > .switcher-mobile {
        display: none;
        height: 92px;
        padding: 0 20px;
        background-color: $color_blue--1;
        align-items: center;
        justify-content: space-between;
        @include max_md {
            display: flex;
        }
        svg {
            width: 120px;
            height: auto;
            path.color_fill {
                fill: $color_white !important;
            }
            path.color_stroke {
                stroke: $color_white !important;
            }
        }
    }

    .auth-social-block {
        margin-bottom: 25px;
        > div.dev-block {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            > a {
                width: 100%;
                background: #ffffff;
                border: 1px solid #2990fb;
                box-sizing: border-box;
                border-radius: 4px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                @include transition(
                    $transition_duration--1,
                    $transition_timing_function--1,
                    $transition_delay--1,
                    (opacity)
                );
                &:hover {
                    opacity: 0.7;
                }
                > img {
                    width: 18px;
                }
            }
        }
        > div.list {
            display: flex;
            justify-content: space-between;
            > a {
                width: calc(50% - 10px);
                background: #ffffff;
                border: 1px solid #2990fb;
                box-sizing: border-box;
                border-radius: 4px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                @include transition(
                    $transition_duration--1,
                    $transition_timing_function--1,
                    $transition_delay--1,
                    (opacity)
                );
                &:hover {
                    opacity: 0.7;
                }
                > img {
                }
                > p {
                    margin-left: 8px;
                    font-size: 16px;
                    color: #767676;
                    @include max_xs {
                        display: none;
                    }
                }
            }
        }
        > div.divider {
            margin-top: 25px;
            display: flex;
            justify-content: center;
            position: relative;
            height: 16px;
            &:before {
                content: attr(data-text);
                font-size: 16px;
                color: #979797;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
                background: #fff;
                z-index: 1;
                padding: 0 13px;
            }
            &:after {
                content: '';
                position: absolute;
                height: 1px;
                width: 100%;
                top: 50%;
                left: 0;
                background-color: #979797;
            }
            //> span {
            //    font-size: 16px;
            //    color: #979797;
            //    &:before {
            //        content: '';
            //        position: absolute;
            //        right: 100%;
            //        width: 9999px;
            //        height: 1px;
            //    }
            //}
        }
    }
}
