@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/mixins';

.mobileTongue {
    display: none;
    @include max_rwd(768) {
        display: block;
    }
}

.isLeftMobileTongue {
    .tongue {
        left: 100%;
        right: auto;
        border-radius: 0 10px 10px 0;
    }
    .layout {
        left: 100%;
        right: auto;
    }
}

.isRightMobileTongue {
    .tongue {
        left: auto;
        right: 100%;
        border-radius: 10px 0 0 10px;
    }
    .layout {
        left: auto;
        right: 100%;
    }
}

.tongue {
    position: absolute;
    z-index: 100;
    top: 20px;
    width: 20px;
    writing-mode: vertical-rl;
    white-space: nowrap;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: $color_blue--1;
    font-size: 12px;
    cursor: pointer;
}

.layout {
    display: block;
    position: fixed;
    z-index: 90;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 10000px;
    background-color: rgba(0, 0, 0, 0.2);
}
