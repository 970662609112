.textarea-box {
    position: relative;
    font-size: 0;
    > textarea {
        appearance: none;
        border: 1px solid $color_middle_gray--2;
        border-radius: 4px;
        background-color: $color_white;
        outline: none;
        font-size: 16px;
        padding: 15px 20px 25px;
        box-sizing: border-box;
        width: 100%;
        resize: none;
        line-height: 19px;
        color: $color_dark_gray--3;
        font-family: $Roboto;
        &:read-only {
            color: $color_dark_gray--1;
        }
        &:focus {
            border-color: $color_light_blue--1;
            caret-color: $color_light_blue--1;
            border-width: 2px;
            padding: 14px 19px 24px;
        }
        &::placeholder {
            color: $color_middle_gray--2;
        }
    }
}
