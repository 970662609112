.title {
    font-size: 16px;
    line-height: 19px;
    color: #787878;
    text-align: left;
}

.isCenteredTitle {
    text-align: center;
}
