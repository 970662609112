@import '../../../../styles/variables';

.my-library {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    padding: 0 40px;
}

.my-library__header {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
        font-family: $Ubuntu;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: $color_dark_gray--3;
    }
}
