.sectionContentImage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    transform: scale(-1, -1);
}

.image {
    width: 100%;
    padding-bottom: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
