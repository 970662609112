.menu {
    display: flex;
    align-items: center;
    position: relative;
}

.dots {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dropdown {
    padding: 0;

    &:focus {
        background-color: transparent;
    }
}
