@import '../../../../../../../../styles/variables';
@import '../../../../../../../../styles/mixins';

.socialMedia {
    position: absolute;
    bottom: 40px;
    left: 40px;
    right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include max_rwd(1024) {
        flex-direction: column;
    }
    @include max_rwd(767) {
        position: static;
        margin-top: 30px;
    }
}

.label {
    margin-right: 10px;
    font-size: 16px;
    line-height: 19px;
    color: #787878;
    @include max_rwd(1024) {
        margin-bottom: 5px;
    }
    @include max_rwd(767) {
        text-align: center;
    }
}

.socialMediaList {
    display: flex;
    align-items: center;
}

.socialMediaItem {
    cursor: pointer;
    margin-right: 10px;
    &:last-child {
        margin-right: 0;
    }
}
