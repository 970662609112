@import '../../../../../../../../styles/variables';
@import '../../../../../../../../styles/mixins';

.layout {
    width: 270px;
    user-select: none;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    background-color: #fff;
    @include max_sm {
        max-width: 100%;
    }
}

.mockup {
    height: 18px;
    position: relative;
    background-color: #efefef;
    > ul {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        left: 8px;
        display: flex;
        align-items: center;
        > li {
            width: 6px;
            height: 6px;
            background-color: #fff;
            border-radius: 50%;
            display: inline-block;
            margin-right: 4px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.image {
    position: relative;
    width: 100%;
    padding-bottom: 75%;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
}
