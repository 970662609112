@import '../../../../../../../../styles/mixins';

.inputBox {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.textInput {
    padding: 0;
    width: 100%;
}

.inputWithLetter {
    padding-left: 26px !important;
}

.isError:not(:focus) {
    border-color: #ff5656 !important;
}

.letter {
    position: absolute;
    z-index: 3;
    font-size: 16px;
    line-height: 19px;
    padding-top: 5px;
    top: 0;
    left: 8px;
    text-transform: uppercase;
}

.remove {
    margin-left: 20px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
    @include max_rwd(767) {
        margin-left: 16px;
    }
}

.isHideRemove {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.error {
    position: absolute;
    top: 50%;
    left: 100%;
    margin-left: 20px;
    transform: translateY(-50%);
    font-size: 12px;
    line-height: 14px;
    color: #ff5656;
    width: 130px;
    @include max_rwd(767) {
        margin: 0;
        right: 32px;
        left: auto;
        white-space: nowrap;
        bottom: -10px;
        top: auto;
        line-height: normal;
        width: 100%;
        text-align: right;
        transform: none;
        font-size: 8px;
    }
}
