.hiddenGroup {
    position: relative;
    width: 100%;
}

.toggle {
    position: absolute;
    margin-bottom: 14px;
    bottom: 100%;
    right: 0;
    padding-left: 20px;
    cursor: pointer;
    font-size: 14px;
    color: #2990fb;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 14px;
        width: 14px;
        transform: rotate(180deg);
        background-image: url('./i/iconArrow.svg');
        background-repeat: no-repeat;
        background-position: center;
    }
}

.isVisibleToggle {
    &:before {
        transform: none;
    }
}

.content {
    width: 100%;
}
