@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.project-list-item {
    padding: 28px 25px 0;
    background-color: $color_white;
    display: flex;
    height: 248px;
    justify-content: space-between;
    box-shadow: $box-shadow--1;
    border-radius: 4px;
    margin-bottom: 15px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;

    @include transition($transition_duration--1, $transition_timing_function--1, $transition_delay--1, (box-shadow));
    &:hover {
        box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
    }
    &:last-child {
        margin-bottom: 0;
    }

    @include max_rwd(1200) {
        min-height: auto;
        height: auto;
        padding: 30px;
        max-height: none;
    }
    @include max_md {
        padding: 20px;
    }

    &__author-info {
        display: flex;
        align-items: center;
        &-icon {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            color: $color_white;
            margin-right: 6px;
            overflow: hidden;
            flex: none;
            object-position: center;
            object-fit: cover;
        }

        &-title {
            font-family: $Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: #787878;
        }
    }

    &__top-right-box {
        display: flex;
        align-items: center;
        gap: 8px;
        position: absolute;
        top: 0;
        right: -10px;
        height: 24px;
        @include max_rwd(1200) {
            position: static;
        }
    }

    &__visibility-icon {
        font-size: 0;
        cursor: default;
    }

    &__upgraded-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;
        gap: 6px;
        padding: 4px 12px;
        background: #785cc7;
        border-radius: 26px;
        cursor: default;

        font-family: $Roboto;
        font-size: 14px;
        line-height: 20px;
        color: #fff;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &__status-indicator {
        display: flex;
        align-items: flex-start;
        padding: 4px 16px;
        border-radius: 26px;
        cursor: default;
        font-family: $Roboto;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;

        &.is-multiplayer {
            background-color: #fe537c;
            color: #fff;
        }
        &.is-published {
            background-color: #3cc76b;
            color: #fff;
        }
        &.is-draft {
            background-color: #eceff8;
            color: #3c3c3c;
        }
        &.is-template {
            background-color: #ffcc00;
            color: #3c3c3c;
        }

        &-tooltip {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 12px 8px;

            font-size: 14px;
            line-height: 20px;
            color: #787878;

            a {
                color: #2990fb;
                text-decoration: none;
            }

            span {
                color: #3c3c3c;
            }
        }
    }

    &__dropdown-menu {
        &-item {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            margin: 2px 0;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #8a8a8a;
            text-align: left;
        }
        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }
    }

    &__mobile-view {
        display: none;
        @include max_rwd(1200) {
            display: block;
            width: 100%;
        }
        &-header {
            display: flex;
            width: 100%;
            justify-content: space-between;

            @include max_rwd(1200) {
                flex-direction: column;
            }

            &--top {
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        &-title {
            width: 100%;
            margin-right: 20px;
        }
    }

    &__desktop-view {
        width: 100%;
        display: flex;
        position: relative;

        @include max_rwd(1200) {
            display: none;
        }

        &-block {
            display: flex;
            position: relative;
            margin-bottom: 25px;
            width: 100%;
            overflow: hidden;
        }

        &-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-sizing: border-box;
            position: relative;
            margin: 25px 20px 0 0;
            width: 100%;

            &-header {
                width: 100%;
                overflow: hidden;
            }

            &-title {
                font-size: 24px;
                padding-bottom: 5px;
                color: $color_middle_gray--1;
                max-height: 100%;
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                @include max_xl {
                    font-size: 20px;
                }
            }
        }

        &-actions {
            padding-top: 60px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            bottom: 0;
        }
    }

    &__deleted-view {
        position: absolute;
        z-index: 30;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.75);
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: $Roboto;
        font-size: 14px;
        line-height: 20px;
        color: #787878;

        &-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #f9fafd;
            border: 1px solid #fd8d8d;
            border-radius: 8px;
            padding: 20px 28px;
        }
    }
}
