.questionBankPanel {
    background-color: #fff;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    padding: 20px 60px 16px 20px;
    position: relative;
}

.title {
    font-size: 16px;
    line-height: 18px;
    color: #3c3c3c;
    margin-bottom: 8px;
}

.description {
    font-size: 14px;
    line-height: 18px;
    color: #979797;
}

.icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.7;
    }
}
