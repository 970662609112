@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.followingModal {
    width: 800px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    padding: 40px 60px 144px;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    text-align: left;
    @include max_rwd(767) {
        padding: 30px 20px 40px;
    }
}

.title {
    font-family: $Ubuntu;
    font-size: 24px;
    line-height: 24px;
    color: #000;
    margin-bottom: 60px;
    @include max_rwd(767) {
        margin-bottom: 40px;
    }
}

.list {
    opacity: 1;
}
