@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.organization-invite-modal {
    width: 640px;
    height: 400px;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    padding: 48px 40px 28px;
    position: relative;
    box-sizing: border-box;
    box-shadow: $box-shadow--4;

    &__title {
        font-family: $Ubuntu;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 28px;
        text-align: center;
        letter-spacing: -1px;
        color: #000000;
        margin-bottom: 24px;
    }

    &__description {
        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #3c3c3c;
        margin-bottom: 20px;
    }

    &__org-logo {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border: 1px solid $color_light_gray--2;
        background-color: $color_white;
        border-radius: 4px;
        color: $color_blue--1;
        font-family: $Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 20px;
        text-align: center;
        margin: 0 auto 15px;
        overflow: hidden;
        box-shadow: 0 0 0 1px $color_light_gray--2;

        > img {
            width: 100%;
            object-position: center;
            object-fit: cover;
        }
    }

    &__org-name {
        font-family: $Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #3c3c3c;
        margin-bottom: 35px;
    }

    &__buttons {
        margin-top: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        > button {
            margin-right: 12px;
            min-width: 120px;
            display: flex;
            justify-content: center;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
