@import '../../../../styles/mixins';

.manageMultiplayerGame {
    padding: 0;
    max-width: 100%;
    width: 960px;
    background-color: #fff;
    border-radius: 4px;
}

.modalContent {
    padding: 40px 130px 90px;
    min-height: 540px;
    box-sizing: border-box;
    display: flex;
    position: relative;
    text-align: left;
    @include max_rwd(1023) {
        padding: 40px 60px 60px;
    }
    @include max_rwd(559) {
        padding: 40px 20px;
    }
}

.loader {
    width: 100%;
}
