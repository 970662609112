.tile-editor {
    padding-top: 20px;

    &__area {
        display: flex;
    }
    &__cover-group {
        margin-right: 20px;
        width: 128px;
    }
    &__detail-group {
        width: 100%;
    }

    &__group-item {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
        .common-checkbox {
            float: right;
            z-index: 1;
            position: relative;
        }
    }

    &__group-item-flex {
        display: flex;
        justify-content: space-between;
        .common-upload-image-group {
            align-self: end;
        }
        a {
            color: #2990fb;
            text-decoration: none;
            padding: 25px 0;
        }
        div {
            margin-right: 10px;
            width: 100%;
        }
    }
}
