.whatIs {
    margin-top: 40px;
    display: flex;
    @media (max-width: 1019px) {
        flex-direction: column;
    }
}

.blocks {
    position: relative;
}
