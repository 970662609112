@import '../../../styles/animations';

.inlineInputWrapper {
    width: 100%;
    box-sizing: border-box;
}

.inlineInput {
    font-size: 24px;
    color: #555;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 0;
    outline: none;
    margin: 0;
    padding: 0;
    &:read-only {
        pointer-events: none;
    }
    @media (max-width: 1599px) {
        font-size: 20px;
    }
}

.isLoading {
    width: auto;
    color: transparent;
    @extend .animatedLoading;
}
