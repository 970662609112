@import '../../styles/mixins';

.date-picker-container {
    height: 36px;
    width: 160px;
    box-sizing: border-box;
    background: #fff;
    padding-left: 40px;
    padding-right: 40px;
    border: 1px solid #acacac;
    border-radius: 2px;
    line-height: 36px;
    cursor: pointer;

    @include no-select;
}

.date-range-picker-container {
    display: flex;
    align-items: center;

    > span {
        margin: 0 10px;
    }

    > button {
        margin-left: 16px;
    }
}
