@import '../../styles/mixins';

.accountSettings {
    background-color: #f9fafd;
    padding: 45px 0 110px;
}

.container {
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
    box-sizing: border-box;
    padding: 0 40px;
    @include max_rwd(767) {
        padding: 0 15px;
    }
}

.title {
    font-size: 20px;
    margin-bottom: 25px;
}

.section {
}
