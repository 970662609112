@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.header {
    padding: 28px 60px 23px;
    border-bottom: 1px solid #d8d8d8;
    @include max_rwd(767) {
        padding: 50px 20px 23px 20px;
    }
}

.box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include max_rwd(767) {
        flex-direction: column;
    }
}

.texts {
    @include max_rwd(767) {
        width: 100%;
    }
}

.actions {
    display: flex;
    align-items: center;
    @include max_rwd(767) {
        flex-direction: column;
        width: 100%;
        margin-top: 16px;
    }
}

.title {
    font-family: $Ubuntu;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #3c3c3c;
    margin-bottom: 8px;
}

.number {
    font-size: 14px;
    line-height: 16px;
    color: #979797;
}

.notice {
    font-size: 14px;
    color: #3cba6e;
    margin-right: 24px;
    @include max_rwd(767) {
        margin-right: 0;
        margin-bottom: 10px;
        text-align: center;
    }
}
.noticeError {
    color: #ff5656;
}

.name {
    margin-top: 20px;
}

.textInput {
    padding: 0 0 5px;
}

.button {
    @include max_rwd(767) {
        width: 100%;
    }
}
