.upload-button {
    &__label {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        color: #3c3c3c;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        line-height: 16px;
    }
    &__value-name {
        margin-bottom: 8px;
        line-height: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #3c3c3c;
    }
    > div.content {
        &.upload {
            display: flex;
            position: relative;
            > button {
                &.upload-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                    cursor: pointer;
                    font-family: 'Roboto', sans-serif;
                    font-size: 14px;
                    color: #fff;
                    background-color: #4891f3;
                    width: 100%;
                    height: 32px;
                    padding: 0 8px;
                }
                &.remove {
                    flex-shrink: 0;
                    margin-left: 15px;
                    cursor: pointer;
                    height: 32px;
                    width: 20px;
                    background-image: url('../../../../i/delete2.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    transition: opacity 0.2s ease-in-out;
                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    &__img-preview {
        width: 32px;
        height: 32px;
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        flex-shrink: 0;
        margin-right: 8px;
        border-radius: 2px;
        object-position: center;
        object-fit: cover;
        overflow: hidden;
    }
}

.upload-button__file-icon {
    width: 32px;
    height: 32px;
    background: #018fef;
    position: relative;
    border-radius: 3px;
    margin-right: 8px;
    flex: none;

    &:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-bottom-left-radius: 2px;
        border-width: 5px;
        border-style: solid;
        border-color: #fff #fff rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.35);
    }

    svg {
        display: block;
        position: absolute;
        top: 6px;
        left: 6px;
        color: #fff;
    }
}
