@import '../../../../../../../styles/mixins';

.freeOffer {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.content {
    background: #fff;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 14px 32px;
}

.image {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    margin-right: 16px;
    @include max_rwd(767) {
        display: none;
    }
}

.text {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #555;
    @include max_rwd(767) {
        flex-direction: column;
    }
}

.link {
    margin-left: 8px;
    display: flex;
    align-items: center;
    color: #2990fb;
    text-decoration: none;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
    @include max_rwd(767) {
        margin-left: 0;
        margin-top: 5px;
    }
}

.icon {
    flex-shrink: 0;
    margin-left: 10px;
}
