@import '../../../styles/variables';
@import '../../../styles/mixins';

.pdfGeneration {
    max-width: 100%;
    width: 520px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    padding: 46px 20px 40px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.closeIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}

.imageBox {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.title {
    font-family: $Ubuntu;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #3c3c3c;
    margin-bottom: 20px;
}

.text {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #979797;
    margin-bottom: 20px;
}

.downloadLnk {
    cursor: pointer;
    color: #298ff8;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}
