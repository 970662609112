.contacts {
    margin-top: 120px;
    display: flex;
    @media (max-width: 1019px) {
        flex-direction: column;
        margin-top: 40px;
    }
}

.imageBox {
    position: relative;
    cursor: pointer;
    &:hover {
        .button > img {
            transform: scale(1.05);
        }
    }
    > img {
        max-width: 100%;
    }
}

.button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 56px;
    height: 56px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    box-sizing: border-box;
    > img {
        transition: transform 0.4s ease-in-out;
    }
}
