.privacy-settings-modal {
    max-width: 100%;
    width: 960px;
    background-color: #fff;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    overflow: hidden;
    //box-sizing: border-box;
    text-align: left;
    // padding: 40px 100px 20px;
    height: 468px;
    .scrollbar-inner {
        padding: 40px 100px 20px;
        //height: 468px;
        box-sizing: border-box;
    }
    &__title {
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #555555;
        white-space: pre-line;
    }
    &__workplace {
        width: 640px;
        padding: 32px 60px 0;
    }
    &__adding-box {
        &__title {
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            color: #787878;
            margin-bottom: 12px;
        }
        &__block {
            display: flex;
            justify-content: space-between;
            > input {
                width: 100%;
                font-family: 'Roboto', sans-serif;
                font-size: 16px;
                color: #555555;
                outline: none;
                border: 1px solid #979797;
                box-shadow: none;
                margin: 0;
                caret-color: #2990fb;
                height: 44px;
                border-radius: 4px;
                padding: 0 20px;
                box-sizing: border-box;
                &:focus {
                    border-color: #2990fb;
                }
            }
            > button {
                margin-left: 12px;
                flex-shrink: 0;
                min-width: 120px;
                justify-content: center;
            }
        }
    }

    &__table {
        margin-top: 32px;
        &__head {
            padding-bottom: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #d8d8d8;
            > p {
                font-family: 'Ubuntu', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                color: #3c3c3c;
                > span {
                    color: #979797;
                }
            }
            > div {
                cursor: pointer;
                font-family: 'Roboto', sans-serif;
                font-size: 16px;
                color: #4891f3;
                transition: opacity 0.2s ease-in-out;
                &:hover {
                    opacity: 0.8;
                }
            }
        }
        &__item {
            padding: 12px 22px 12px 0;
            border-bottom: 1px solid #d8d8d8;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            color: #555555;
            position: relative;
            > span {
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 10px;
                width: 10px;
                background-image: url('./i/cross.svg');
                background-position: center;
                background-repeat: no-repeat;
                transition: opacity 0.2s ease-in-out;
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    &__empty-block {
        margin-top: 48px;
        display: flex;
        flex-direction: column;
        align-items: center;
        > img {
            width: 48px;
        }
        > p {
            margin-top: 20px;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #787878;
            white-space: pre-line;
        }
    }
}
