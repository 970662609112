.component {
    margin-bottom: 40px;
    padding: 40px 50px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    &:last-child {
        margin-bottom: 0;
    }
    &:empty {
        padding: 0;
    }
}
