@import '../../../../../../styles/mixins';

.howTrialWorksModal {
    display: flex;
    align-items: center;
    min-height: 600px;
    max-width: 960px;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: auto;
    text-align: center;
    @include max_rwd(1023) {
        min-height: auto;
    }
}

.image {
    max-width: 494px;
    flex-shrink: 0;
    @include max_rwd(1023) {
        display: none;
    }
}

.content {
    width: 100%;
    padding: 0 40px 0 26px;
    box-sizing: border-box;
    text-align: left;
    @include max_rwd(1023) {
        padding: 40px 20px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.title {
    font-family: 'Ubuntu', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #000;
}

.subTitle {
    margin-top: 24px;
    font-family: 'Ubuntu', 'sans-serif';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: -1px;
    color: #000;
}

.text {
    margin-top: 24px;
    font-size: 14px;
    line-height: 24px;
    color: #555;
    margin-bottom: 28px;
}

.checkbox {
    margin: 24px 0 0 0;
}
