@import '../../../../../../../../styles/variables';
@import '../../../../../../../../styles/mixins';

.cards-block-preview {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: stretch;
    align-items: stretch;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5%;

    &__card {
        padding: 2%;
        box-sizing: border-box;
        width: 160px;
        min-width: 50px;
        max-width: 200px;
        @include max_rwd(599) {
            max-width: 100px;
        }
    }

    &__card-cover-image {
        width: 100%;
        border-radius: 8px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 0;
        padding-bottom: 100%;
    }

    &__card-disclaimer {
        width: 100%;
        padding-top: 6px;
        font-family: $Roboto;
        font-size: 20px;
        line-height: 24px;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        color: #ffffff;
    }
}
