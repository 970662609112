@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.media-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
        max-width: 100px;
        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        margin-top: 6px;
        margin-bottom: 10px;
        color: $color_dark_gray--1;
        text-align: center;
        overflow-wrap: break-word;
        word-wrap: break-word;
    }

    &.disabled {
        pointer-events: none;
    }
}

.media-item__box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;
    height: 120px;
    cursor: pointer;
    &:before {
        content: '';
        z-index: 3;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0 0 0 1px $color_light_gray--2;
    }

    &:hover:before {
        box-shadow: inset 0 0 0 1px $color_middle_gray--2, $box-shadow--2;
    }

    .media-item__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &_active {
        &:before {
            box-shadow: inset 0 0 0 4px $color_blue--1 !important;
        }

        .media-item__action_select {
            visibility: visible !important;
        }
    }
}

.media-item__image-box {
    width: 120px;
    height: 120px;
    margin-top: -36px;
    display: flex;
    justify-content: center;
    align-items: center;
    // z-index: -1;
}

.media-item__author {
    visibility: hidden;
    position: absolute;
    bottom: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: calc(100% - 2px);
    padding: 4px 0;
    background: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    line-height: 12px;
    cursor: pointer;
    color: #787878;

    a {
        position: relative;
        z-index: 5;
        &:hover {
            color: #3c3c3c;
            text-decoration: underline;
        }
    }
}

.media-item__box:hover .media-item__author {
    visibility: visible;
}

.media-item__action {
    cursor: pointer;
}

.media-item__actions {
    z-index: 5;
    visibility: hidden;
    width: calc(100% - 16px);
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.media-item__box:hover .media-item__action {
    visibility: visible;
}

.media-item__actions_right {
    display: flex;
    justify-content: space-between;

    > div {
        margin-right: 4px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.media-item__action_audio_preview {
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid white;
    background-color: $color_middle_gray--2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    &:hover {
        background-color: $color_dark_gray--1;
    }
}

.media-item__action_preview,
.media-item__action_details {
    > svg:hover circle:first-child {
        fill: $color_dark_gray--1;
    }
}

.media-item__action_details {
    position: relative;
}

.media-item__details-box {
    position: absolute;
    cursor: default;
    padding-top: 4px;
}

.media-item__details {
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.160839);
    background-color: $color-white;
    padding: 16px 24px 18px 21px;

    > div {
        width: 70px;
        display: flex;
        justify-content: space-between;
        height: 18px;
        cursor: pointer;
    }

    span {
        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: $color_light_gray--5;
    }

    > div:hover {
        span {
            color: $color_blue--1;
        }
        svg path {
            fill: $color_blue--1;
        }
    }
}

.media-item:nth-child(5n) .media-item__details-box {
    right: -25px;
}

.media-item__file-icon {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    display: inline-block;
    width: 48px;
    height: 64px;
    border-radius: 3px;
    background: #018fef;
    position: relative;
    text-align: left;

    &:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: #f9fafd #f9fafd rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.35);
        border-bottom-left-radius: 2px;
        border-width: 8px;
    }

    &:after {
        display: block;
        content: attr(data-type);
        position: absolute;
        bottom: 0;
        left: 0;
        color: #fff;
        text-transform: lowercase;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        font-size: 16px;
        padding: 4px 6px;
    }
}
