.lead-form-form-review {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 24px 20px 28px;
    margin: 0 auto;
    box-sizing: border-box;
    & .logo-image {
        height: 100px;
        width: 100%;
        object-fit: contain;
        object-position: center;
        margin-bottom: 24px;
    }
    > div.header {
        font-family: 'Roboto', sans-serif;
        text-align: center;
        color: #3c3c3c;
        padding-bottom: 16px;
        font-weight: normal;
        font-size: 16px;
        line-height: 14px;
    }
    > div.description {
        font-family: 'Roboto', sans-serif;
        text-align: center;
        color: #787878;
        padding-bottom: 20px;
        font-size: 12px;
        line-height: 16px;
        white-space: pre-line;
    }
    > ul.fields {
        > li {
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
            > p {
                font-family: 'Roboto', sans-serif;
                font-size: 12px;
                color: #3c3c3c;
                padding-bottom: 6px;
            }
            > div {
                background-color: #ffffff;
                border: 1px solid #d8d8d8;
                box-sizing: border-box;
                border-radius: 2px;
                height: 28px;
            }
            &.TEXT_AREA {
                > div {
                    height: 56px;
                }
            }
            &.CHECKBOX,
            &.CONSENT {
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                > div {
                    flex-shrink: 0;
                    width: 12px;
                    height: 12px;
                    border: 0;
                    border-radius: 2px;
                    background-color: #d7d7d7;
                    margin-right: 5px;
                }
            }
        }
    }
    > div.additional-notes {
        margin-top: 16px;
        font-size: 10px;
        line-height: 14px;
        color: #979797;
    }
    > div.button-wrapper {
        margin-top: 20px;
        text-align: center;
        > button {
            background-color: #000;
            border-radius: 4px;
            min-width: 112px;
            height: 32px;
            padding: 0 10px;
            box-sizing: border-box;
        }
    }
    > div.privacy-wrapper {
        display: flex;
        justify-content: center;
        > div.privacy {
            padding-top: 20px;
            font-family: 'Roboto', sans-serif;
            font-size: 10px;
            color: #979797;
            > span {
                display: inline-block;
                width: 10px;
                height: 10px;
                background-color: #d8d8d8;
                border-radius: 1px;
                margin-bottom: -1px;
            }
            > a {
                color: #2990fb;
                text-decoration: none;
            }
        }
    }
}
