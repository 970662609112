.trial-ended-modal__loader {
    min-height: 370px;
    width: 640px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
        width: 300px;
    }
}
