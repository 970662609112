@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.organization-panel {
    display: flex;
    flex-direction: column;

    .organization-rename-input {
        font-size: 16px;
        color: #3c3c3c;
    }

    &__content {
        background: $color_white;
        box-shadow: $box-shadow--1;
        border-radius: 4px;
        padding: 20px 0;
        margin-bottom: 20px;
    }

    &__users-block {
        overflow: hidden;
        transition: max-height 0.5s, padding 0.5s;
        max-height: 0;
    }

    &__space-block {
        padding: 0 8px;
        position: relative;
        &:first-child {
            border-bottom: 1px solid $color_light_gray--2;
            .organization-panel__space-header {
                margin-top: 0;
            }
        }
        &:last-child {
            border: none;
        }

        &.selected {
            .organization-panel__space-header-icon {
                &:before {
                    content: '';
                    position: absolute;
                    width: 54px;
                    height: 54px;
                    left: -2px;
                    top: -2px;
                    border-radius: 4px;
                    box-shadow: 0 0 0 2px $color_blue--1;
                }
            }
            .organization-panel__dropdown-menu {
                display: block;
            }

            .organization-panel__users-block {
                max-height: 700px;
                overflow-x: hidden;
                overflow-y: auto;
            }
        }

        &:hover {
            .organization-panel__space-header-icon {
                &:before {
                    content: '';
                    position: absolute;
                    width: 54px;
                    height: 54px;
                    left: -2px;
                    top: -2px;
                    border-radius: 4px;
                    box-shadow: 0 0 0 2px $color_light_blue--1;
                }
            }
            .organization-panel__dropdown-menu {
                display: block;
            }
        }
    }

    &__invite-button {
        display: flex;
        align-items: center;
        padding: 10px 12px;
        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #2990fb;
        cursor: pointer;

        &:before {
            content: '';
            display: inline-block;
            width: 13px;
            height: 13px;

            background: linear-gradient(#2990fb, #2990fb), linear-gradient(#2990fb, #2990fb);
            background-size: 100% 1px, 1px 100%;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 8px;
        }

        &.organization-panel__coming-soon {
            pointer-events: none;
            color: #d8d8d8;
            &:before {
                background: linear-gradient(#d8d8d8, #d8d8d8), linear-gradient(#d8d8d8, #d8d8d8);
                background-size: 100% 1px, 1px 100%;
                background-position: center;
                background-repeat: no-repeat;
            }
            &:after {
                content: attr(data-coming-soon-text);
                padding: 2px 10px;
                background-color: #d8d8d8;
                border-radius: 17px;
                display: flex;
                align-items: center;
                color: #ffffff;
                margin-left: 12px;
            }
        }
    }
}
