.footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 40px;
    height: 72px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    text-align: left;
    position: relative;
}

.footerItem {
    margin-right: 12px;
    &:last-child {
        margin-right: 0;
    }
}
