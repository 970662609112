.tooltip {
    position: relative;
    display: inline-block;
    cursor: help;
    font-size: 0;
    line-height: normal;
    &.clickable {
        cursor: pointer;
    }
    &__box {
        display: inline-block;
        background: #ffffff;
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
        border-radius: 4px;
        padding: 8px 12px;
        text-align: center;
        color: #787878;
        max-width: 250px;
    }
}

.rc-tooltip {
    opacity: 1;
    z-index: 999999;
    background: none;
}
.rc-tooltip-inner {
    background: none;
    border: 0;
}
.rc-tooltip-arrow {
    display: none;
}

.tooltip-controls-icon {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    border-radius: 50%;
    margin-left: 4px;
    transition: background-color 0.2s ease-in-out;
    flex-shrink: 0;
    color: #d8d8d8;
    &:after {
        content: '?';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        font-size: 10px;
    }
    &:hover {
        border-color: #979797;
        color: #979797;
    }
}
