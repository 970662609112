.no-padding {
    padding: 0 !important;
}
.no-border {
    border: 0 !important;
}
.no-margin {
    margin: 0 !important;
}

.fz-14 {
    font-size: 14px !important;
}
.fz-24 {
    font-size: 24px !important;
}

.mt-10 {
    margin-top: 10px !important;
}
.mt-20 {
    margin-top: 20px !important;
}
.mr-5 {
    margin-right: 5px !important;
}
.mr-10 {
    margin-right: 10px !important;
}
.mr-24 {
    margin-right: 24px !important;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.ml-a {
    margin-left: auto !important;
}
.ml-5 {
    margin-left: 5px !important;
}
.ml-10 {
    margin-left: 10px !important;
}

.p-20 {
    padding: 20px !important;
}
.pt-5 {
    padding-top: 5px !important;
}
.pt-10 {
    padding-top: 10px !important;
}
.pr-10 {
    padding-right: 10px !important;
}
.pr-24 {
    padding-right: 24px !important;
}
.pr-28 {
    padding-right: 28px !important;
}
.pb-5 {
    padding-bottom: 5px !important;
}
.pb-6 {
    padding-bottom: 6px !important;
}
.pb-10 {
    padding-bottom: 10px !important;
}
.pl-10 {
    padding-left: 10px !important;
}

.h-a {
    height: auto !important;
}
.h-14 {
    height: 14px !important;
}
.h-22 {
    height: 22px !important;
}
.h-33 {
    height: 33px !important;
}
.h-36 {
    height: 36px !important;
}
.h-40 {
    height: 40px !important;
}
.h-60 {
    height: 60px !important;
}
.h100 {
    height: 100% !important;
}

.us-n {
    user-select: none;
}

.c-p {
    cursor: pointer !important;
}

.pos-a {
    position: absolute !important;
}
.pos-f {
    position: fixed !important;
}
.pos-s {
    position: static !important;
}
.pos-r {
    position: relative !important;
}

.w-100 {
    width: 100px !important;
}
.w5 {
    width: 5% !important;
}
.w10 {
    width: 10% !important;
}
.w12 {
    width: 12% !important;
}
.w15 {
    width: 15% !important;
}
.w16 {
    width: 16% !important;
}
.w20 {
    width: 20% !important;
}
.w25 {
    width: 25% !important;
}
.w26 {
    width: 26% !important;
}
.w30 {
    width: 30% !important;
}
.w35 {
    width: 35% !important;
}
.w40 {
    width: 40% !important;
}
.w45 {
    width: 45% !important;
}
.w50 {
    width: 50% !important;
}
.w100 {
    width: 100% !important;
}

.mw-72 {
    max-width: 72px !important;
}
.mw-300 {
    max-width: 300px !important;
}
.mw-420 {
    max-width: 420px !important;
}
.mh-54 {
    max-height: 54px !important;
}

.d-f {
    display: flex !important;
}

.jc-c {
    justify-content: center !important;
}
.jc-fs {
    justify-content: flex-start !important;
}
.jc-fe {
    justify-content: flex-end !important;
}
.jc-sb {
    justify-content: space-between !important;
}
.ai-c {
    align-items: center !important;
}
