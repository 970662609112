@import '../../../../styles/mixins';

.payoutText {
    display: flex;
    flex-direction: column;
    @include max_rwd(767) {
        margin-top: 15px;
    }
}

.main {
    font-size: 16px;
    line-height: 20px;
    color: #3c3c3c;
}

.sum {
    padding-left: 12px;
    font-weight: 500;
}

.date {
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #8a8a8a;
}
