@import '../../../../../../styles/mixins';

.creation {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main {
    position: relative;
}

.modeSelector {
    display: flex;
    margin: 0 -10px;
    @include max_rwd(1023) {
        flex-direction: column;
        margin: 0;
    }
}

.rangeSliderBox {
    position: relative;
}

.buttons {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    @include max_rwd(767) {
        flex-direction: column;
    }
}

.upgradeButton {
    margin-right: 12px;
    @include max_rwd(767) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    img {
        margin-right: 10px;
    }
}
