@import '../../../../../../../../../../../../styles/variables';
@import '../../../../../../../../../../../../styles/mixins';

.confirmation {
    box-sizing: border-box;
    width: 640px;
    height: auto;
    max-width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 30px 100px 55px;
    @include max_rwd(700) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.image {
    display: flex;
    justify-content: center;
}

.title {
    margin-top: 12px;
    font-family: $Ubuntu;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -1px;
    color: #000;
}

.description {
    margin-top: 12px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #555;
}

.buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.button {
    min-width: 140px;
    margin-right: 16px;
    &:last-child {
        margin-right: 0;
    }
}
