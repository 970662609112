.chooseInterests {
    padding: 24px 80px 24px 100px;
    width: 100%;
    @media (max-width: 1019px) {
        padding: 30px 20px;
    }
}

.content {
    opacity: 1;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
