@import '../../../../../styles/variables';

.empty-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $Roboto;
    font-style: normal;
    font-weight: normal;

    h1 {
        margin-top: 100px;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: $color_middle_gray--1;
    }

    label {
        margin-top: 32px;
        width: 173px;
        justify-content: center;
    }

    p {
        margin-top: 48px;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: $color_middle_gray--2;
    }
}
