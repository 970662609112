@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.editor-new {
    .top-bar {
        position: sticky;
        top: 0;
        left: 0;
        height: 60px;
        background-color: #fff;
        width: 100%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        z-index: 150;
        padding: 0 20px;
        justify-content: space-between;
        box-shadow: $box-shadow-blue-1;
        @include max_rwd(1023) {
            height: 80px;
            align-items: flex-start;
            padding-top: 10px;
        }
        > .left {
            display: flex;
            .name-box {
                margin-left: 20px;
                display: flex;
                align-items: center;
                position: relative;
                @include max_rwd(480) {
                    display: none;
                }
                .status-dot {
                    margin-right: 4px;
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #2990fb;
                    &[data-enabled='true'] {
                        background-color: #2990fb;
                    }
                }
                input {
                    appearance: none;
                    width: 240px;
                    box-sizing: border-box;
                    outline: none;
                    border: 0;
                    background-color: transparent;
                    padding: 0 4px;
                    margin: 0;
                    color: #000;
                    font-family: $Roboto;
                    font-size: 16px;
                    height: 28px;
                    border-radius: 4px;
                    text-overflow: ellipsis;
                    &:hover:not(:focus):not(:disabled) {
                        background-color: #efeeee;
                    }
                    @media (max-width: 1579px) {
                        width: 180px;
                    }
                }
                .notify {
                    position: absolute;
                    left: 12px;
                    font-family: $Roboto;
                    font-size: 10px;
                    color: #979797;
                    bottom: 0;
                    transform: translateY(100%);
                    margin-bottom: 4px;
                }
            }
        }
        > .center {
            display: flex;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include max_rwd(1023) {
                transform: translate(-50%, 0);
                top: auto;
                bottom: 10px;
                width: 100%;
                justify-content: center;
            }
            @include max_rwd(480) {
                padding: 5px 20px;
                width: 100%;
                overflow-x: auto;
                margin: 0 -20px;
                box-sizing: border-box;
                transform: none;
                left: 20px;
                right: 20px;
                justify-content: unset;
                bottom: 5px;
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
            li {
                position: relative;
                display: flex;
                align-items: center;
                padding-right: 54px;
                @include max_rwd(600) {
                    padding-right: 36px;
                }
                &:after {
                    content: '';
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 16px;
                    height: 16px;
                    background-image: url('i/arrow.svg');
                    @include max_rwd(600) {
                        right: 10px;
                    }
                }
                &:last-child {
                    padding-right: 0;
                    &:after {
                        display: none;
                    }
                }
                > a {
                    cursor: pointer;
                    font-family: $Roboto;
                    font-size: 16px;
                    color: #3c3c3c;
                    text-decoration: none;
                    white-space: nowrap;
                    @include max_rwd(600) {
                        font-size: 14px;
                    }
                    &:hover {
                        color: #56a9ff;
                    }
                    &.is-active {
                        color: #2990fb;
                        pointer-events: none;
                        cursor: default;
                    }
                }
            }
        }
        > .right {
            display: flex;
        }

        .btn {
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            padding: 0 20px 0 40px;
            box-sizing: border-box;
            border: 1px solid;
            border-radius: 4px;
            cursor: pointer;
            position: relative;
            outline: none;
            white-space: nowrap;
            text-decoration: none;
            background-repeat: no-repeat;
            height: 36px;
            color: #2990fb;
            background-position: 14px 50%;
            &--preview {
                background-color: #fff;
                border: 1px solid #d8d8d8;
                background-image: url('i/eye.svg');
                margin-right: 12px;
                &.is-active,
                &:hover {
                    color: #2990fb;
                    border-color: #2990fb;
                    background-color: rgba(41, 144, 251, 0.05);
                    background-image: url('i/eye--blue.svg');
                }
                &.is-active {
                    pointer-events: none;
                }
                @media (max-width: 1319px) {
                    padding: 0 21px;
                    font-size: 0;
                    background-position: center;
                }
            }
            &--publish {
                background-color: #2990fb;
                border-color: #2990fb;
                color: #fff;
                background-image: url('i/mark.svg');
                &:disabled {
                    background-size: 16px;
                    background-image: url('i/spinner.svg');
                    background-color: #d8d8d8;
                    border-color: #d8d8d8;
                    pointer-events: none;
                }
                @include max_rwd(1023) {
                    font-size: 0;
                    padding: 0 40px;
                    background-image: url('i/send.png');
                    background-position: center;
                    background-size: 16px;
                }
            }
            &--save {
                background-color: #fff;
                background-image: url('i/back.svg');
                background-position: 19px 50%;
                border: 1px solid #d8d8d8;
                &.is-disabled {
                    opacity: 0.7;
                    pointer-events: none;
                }
                &:hover {
                    border-color: #2990fb;
                    background-color: rgba(41, 144, 251, 0.05);
                    background-image: url('i/back--blue.svg');
                }
                @media (max-width: 1319px) {
                    padding: 0 21px;
                    font-size: 0;
                    background-position: center;
                }
            }
        }
    }
}

.top-bar__hint-span-text {
    color: #2990fb;
}
