@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.customHeaderTitle {
    position: relative;
    height: 60px;
    background-color: $color_blue--1;
    display: flex;
    align-items: center;
    font-family: $Ubuntu;
    font-weight: 500;
    font-size: 24px;
    color: $color_white;
    justify-content: center;
    padding: 0 54px;
    @include max_xs {
        padding-left: 0;
    }
}

.backButton {
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
    @include max_xs {
        font-size: 0;
    }
    &:before {
        content: '';
        display: inline-block;
        height: 20px;
        width: 12px;
        margin-right: 8px;
        background-image: url('./i/back.png');
        background-repeat: no-repeat;
        background-position: center;
    }
}
