.wheelSections {
    border-radius: 50%;
    border: 0 solid #fff;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
    user-select: none;
    backface-visibility: hidden;
    transform: translateZ(0);
}
