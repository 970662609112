.dangerMessage {
    margin-top: 16px;
    font-size: 14px;
    line-height: 16px;
    color: #3c3c3c;
    a,
    span {
        cursor: pointer;
        color: #2990fb;
        text-decoration: none;
        transition: opacity 0.2s ease-in-out;
        &:hover {
            opacity: 0.8;
        }
    }
}
