.templates {
    z-index: 150;
    display: block;
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    width: calc(100vw - 320px);
    max-width: 720px;
    background-color: #fff;
    box-sizing: border-box;
    [data-simplebar] {
        max-height: 100%;
        > .simplebar-vertical {
            > .simplebar-scrollbar {
                &:before {
                    top: 24px;
                    bottom: 24px;
                }
            }
        }
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 1px;
        background-color: #e1e1e1;
    }
    &__content {
        padding: 24px;
        .visibility-checker {
            opacity: 0;
            width: 100%;
            height: 1px;
        }
        > div.loader {
            display: flex;
            justify-content: center;
            > img {
                max-width: 400px;
            }
        }
        > div.empty {
            text-align: center;
            padding-top: 15px;
            font-family: 'Roboto', sans-serif;
            font-style: italic;
            font-size: 14px;
            opacity: 0.5;
        }
        > p.title {
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            color: #3c3c3c;
            margin-bottom: 26px;
        }
        > ul.templates__list {
            display: flex;
            flex-wrap: wrap;
            margin: -20px;
            > li {
                max-width: 100%;
                width: 300px;
                cursor: pointer;
                margin: 20px;
                &:hover {
                    .preview {
                        box-shadow: 0 2px 9px rgba(0, 0, 0, 0.07);
                    }
                }
                .preview {
                    position: relative;
                    padding-bottom: 80.5%;
                    overflow: hidden;
                    width: 100%;
                    transition: box-shadow 0.2s ease-in-out;
                    > img {
                        max-width: 100%;
                        max-height: 100%;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
                .label {
                    margin-top: 10px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    > span {
                        font-family: 'Roboto', sans-serif;
                        font-size: 14px;
                        line-height: 16px;
                        color: #626262;
                        &.name {
                        }
                        &.description {
                            color: #979797;
                            &:before {
                                content: ' | ';
                            }
                        }
                    }
                }
            }
        }
    }
}
