@import '../../../../../../../../../../../../styles/mixins';

.presetItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9fafd;
    border-radius: 4px;
    height: 44px;
    margin-bottom: 4px;
    padding: 0 40px;
    box-sizing: border-box;
    &:last-child {
        margin-bottom: 0;
    }
    @include max_rwd(599) {
        padding: 0 20px;
    }
}

.label {
    font-size: 14px;
    line-height: 16px;
    color: #3c3c3c;
}

.settings {
    display: flex;
    align-items: center;
}

.units {
    margin-left: 12px;
    font-size: 14px;
    line-height: 16px;
    color: #3c3c3c;
}

.settingsInput {
    padding: 0 8px;
    box-sizing: border-box;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    margin: 0;
    width: 60px;
    height: 32px;
    outline: none;
    appearance: none;
    font-size: 14px;
    color: #3c3c3c;
    background: white;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &[type='number'] {
        -moz-appearance: textfield;
    }
}
