@import '../../../../styles/mixins';

.map-card {
    background: #fff;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    padding: 40px 50px;

    @include max_rwd(1205) {
        width: 650px !important;
        h1 {
            font-size: 29px !important;
        }

        .description {
            font-size: 13px !important;
        }
    }

    @include max_rwd(780) {
        width: 264px !important;
        padding: 12px !important;

        h1 {
            font-size: 20px !important;
            line-height: 23px !important;
            margin-bottom: 0px !important;
        }

        .description {
            margin-top: 16px !important;
            margin-bottom: 20px !important;
        }
    }

    h1 {
        font-weight: 300;
        font-size: 32px;
        line-height: 37px;
        margin-bottom: 24px;
        width: 100%;
    }

    .description {
        font-size: 15px;
        line-height: 18px;
        color: #626262;
        margin-bottom: 30px;
        width: 100%;
    }
}
