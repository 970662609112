$Roboto: 'Roboto', sans-serif;

.leader-board-panel {
    width: 100%;

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        > h2 {
            font-family: $Roboto;
            font-style: normal;
            font-weight: 300;
            font-size: 32px;
            line-height: 37px;
            color: #000000;
        }
        > div {
            display: flex;
            align-items: center;
            .export {
                font-family: $Roboto;
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-right: 20px;
                font-size: 16px;
                line-height: 19px;
                text-align: right;
                color: #2990fb;
                margin-left: auto;
                transition: opacity 0.2s ease-in-out;
                &:hover {
                    opacity: 0.8;
                }
                > img {
                    margin-left: 10px;
                }
            }
        }
    }

    &__add-rating-button {
        width: 165px;
        height: 36px;

        background: #2990fb;
        border-radius: 4px;

        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #ffffff;

        &:hover {
            opacity: 0.8;
        }
    }

    &__rating-list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 16px;
    }

    &__rating-button {
        width: 110px;
        height: 60px;
        margin-right: 8px;
        margin-bottom: 8px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;

        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        line-height: 28px;
        align-items: center;
        text-align: center;

        &:hover {
            opacity: 0.8;
        }

        &.is-active {
            background: no-repeat url('active-pointer.svg') right 8px top 8px;
        }

        &.selected {
            border-color: #2990fb;
            box-shadow: inset 0 0 0 1px #2990fb;
            box-sizing: border-box;
            pointer-events: none;
        }
    }

    &__rating-info {
        margin-bottom: 20px;
        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        color: #626262;

        > div {
            margin-bottom: 8px;
            > span {
                color: red;
                &.is-active {
                    color: green;
                }
            }
        }

        :last-child {
            margin-bottom: 0;
        }
    }

    &__empty-rating {
        height: 200px;
        display: grid;
        place-items: center;
        text-align: center;
        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #626262;
    }
}
