.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    > img {
        width: 100%;
        max-width: 140px;
    }

    &.isModal {
        background: white;
        height: 100%;
        padding: 0;
    }
}
