.noFollowers {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #8a8a8a;
}
