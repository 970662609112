.country-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    .flag {
        width: 28px;
        height: 20px;
        border-radius: 1px;
        margin-right: 16px;
    }

    .row {
        align-self: stretch;
        display: flex;
        height: 49px;
        border-bottom: 1px solid #d9d9d9;
        > li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #626262;
            width: 20%;
            &:last-child {
                justify-content: flex-end;
            }
        }
    }
}
