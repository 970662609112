@import '../../../../styles/mixins';

.card {
    width: 50%;
    box-sizing: border-box;
    padding-bottom: 28px;
    @include max_rwd(1199) {
        width: 100%;
        padding: 0 0 20px 0 !important;
        &:last-child {
            padding-bottom: 0 !important;
        }
    }
    &:nth-child(odd) {
        padding-right: 14px;
    }
    &:nth-child(even) {
        padding-left: 14px;
    }
}

.isWideCard {
    @include min_rwd(1200) {
        width: 100%;
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}

.box {
    box-sizing: border-box;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.07);
    background-color: #fff;
    height: 100%;
    padding-bottom: 40px;
    border-radius: 4px;
    overflow: hidden;
}

.isCoverBox {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.isWideBox {
    @include min_rwd(1200) {
        display: flex;
        justify-content: space-between;
        padding: 40px 0;
    }
}

.head {
    position: relative;
    height: 94px;
    margin-bottom: 12px;
    padding: 48px 60px 0;
    box-sizing: border-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include max_rwd(1199) {
        padding-left: 40px;
        padding-right: 40px;
    }
    @include max_rwd(480) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.isWideHead {
    @include min_rwd(1200) {
        margin-bottom: 0;
        padding-top: 0;
        height: 100%;
        align-items: center;
    }
}

.isCoverHead {
    @include max_rwd(700) {
        height: auto;
        flex-direction: column;
        padding: 60px 60px 30px 20px;
        .mark {
            position: absolute;
            top: 10px;
            right: 10px;
            margin: 0 !important;
        }
    }
}

.title {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #555;
    position: relative;
    > img {
        margin-right: 8px;
    }
}

.badgeIcon {
    position: absolute;
    top: 0;
    right: -24px;
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
        max-width: 16px;
        max-height: 16px;
    }
}

.isCoverTitle {
    color: #fff;
}

.mark {
    margin: -6px 0 0 10px;
    flex-shrink: 0;
}

.content {
    padding: 0 60px;
    @include max_rwd(1199) {
        padding-left: 40px;
        padding-right: 40px;
    }
    @include max_rwd(480) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.isDisabledContent {
    opacity: 0.4;
    pointer-events: none;
}

.description {
    font-size: 14px;
    line-height: 20px;
    color: #8a8a8a;
    > a {
        color: #2990fb;
        text-decoration: none;
        transition: opacity 0.2s ease-in-out;
        &:hover {
            opacity: 0.8;
        }
    }
}

.isCoverDescription {
    padding-top: 24px;
}
