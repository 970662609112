.start-screen-preview {
    position: relative;

    > img.image {
        width: 100%;
        object-fit: cover;
        object-position: center;
        height: 600px;
    }
    > div.box {
        margin: -122px 20px 20px;
        padding: 28px 60px 16px;
        background-color: #fff;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        position: inherit;

        &.no-image {
            margin-top: 20px;
        }
        > div.head {
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            font-size: 36px;
            line-height: 42px;
            text-align: center;
            color: #000000;
            margin-bottom: 16px;
            white-space: pre-line;
        }
        > div.description {
            font-family: 'Roboto', sans-serif;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            color: #000000;
            margin-bottom: 20px;
            white-space: pre-line;
        }
        > div.btn-wrap {
            display: flex;
            justify-content: center;
            > div.btn {
                box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
                border-radius: 4px;
                padding: 0 44px;
                display: flex;
                align-items: center;
                height: 52px;
                font-family: 'Roboto', sans-serif;
                font-size: 20px;
                color: #ffffff;
            }
        }
        > div.image-disclaimer {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: #b7b7b7;
            margin-top: 24px;
            white-space: pre-line;
        }
    }
}
