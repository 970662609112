.pinBlock {
    padding: 36px 20px 0;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    height: 220px;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
}

.pin {
    margin-top: 20px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    font-size: 80px;
    line-height: 80px;
    text-align: center;
    letter-spacing: 20px;
    color: #3c3c3c;
}
