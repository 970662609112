.invalidWordsModal {
    max-width: 100%;
    width: 520px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    background-color: #fff;
    padding: 36px 60px 40px;
    box-sizing: border-box;
    border-radius: 4px;
}

.title {
    font-family: 'Ubuntu', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #3c3c3c;
    max-width: 360px;
    margin: 0 auto 12px;
}

.subtitle {
    font-size: 16px;
    line-height: 20px;
    color: #3c3c3c;
    max-width: 280px;
    margin: 0 auto 16px;
}

.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
}

.item {
    padding: 4px 12px;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
    margin-bottom: 6px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 8px;
    color: #fd8d8d;
    text-transform: uppercase;
    &:last-child {
        margin-bottom: 0;
    }
}

.note {
    text-align: left;
    font-size: 16px;
    line-height: 20px;
}

.noteTitle {
    color: #3c3c3c;
}

.noteList {
    margin-bottom: 8px;
}

.noteItem {
    color: #979797;
}

.readMoreLink {
    text-decoration: none;
    color: #298ff8;
    font-size: 16px;
    line-height: 20px;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.7;
    }
}

.button {
    margin: 20px auto 0;
    min-width: 94px;
}
