.card-editor-list {
    list-style: none;
    padding: 36px 40px 20px;

    &__item {
        display: block;
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 40px;
        margin-bottom: 20px;
    }
    &__item-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__header-title {
        font-family: 'Ubuntu', sans-serif;
        font-weight: 500;
        font-size: 24px;
        float: left;
    }
    &__header-bar {
        float: right;
    }
}
