.link-bar {
    height: 100%;
    text-align: center;
    color: #3c3c3c;
    font-style: normal;
    font-weight: 500;
    padding: 24px;
    &__title {
        font-family: 'Ubuntu', sans-serif;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 22px;
    }

    &__block {
        box-sizing: border-box;
        position: relative;
        border-radius: 4px;
        margin-bottom: 20px;
        overflow: hidden;

        &.no-image {
            background: #f9fafd;
            height: 152px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .link-bar__block-title-wrapper {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border: 1px solid #d8d8d8;
                border-bottom: none;
            }

            .link-bar__block-title {
                border: none;
                height: auto;
            }
        }

        &-percentage {
            position: absolute;
            width: 43px;
            height: 16px;
            right: 2px;
            top: 2px;

            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;

            background: #fff;
            border-top-right-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &-img {
            height: 70px;
            object-fit: cover;
            object-position: center;
            width: 100%;
        }

        &-title-wrapper {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }

        &-title {
            box-sizing: border-box;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            line-height: 18px;
            height: 56px;
            padding: 8px 8px 12px;
            border-right: 1px solid #d8d8d8;
            border-left: 1px solid #d8d8d8;
        }

        &-content {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
        }

        &-buttons {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            line-height: 18px;
        }

        &-button {
            flex: auto;
            font-size: 12px;
            border: 1px solid #d8d8d8;
            background: #ffffff;
            line-height: 18px;
            padding: 4px;
            cursor: pointer;
            user-select: none;

            &:first-child {
                border-right: none;
                border-bottom-left-radius: 4px;
            }
            &:last-child {
                border-left: none;
                border-bottom-right-radius: 4px;
            }

            &.is-active {
                pointer-events: none;
                border: 1px solid #2990fb;

                &.Unrelated {
                    color: #2990fb;
                    background: #ffffff;
                }

                &.Neutral {
                    color: #fff;
                    background: #87c2ff;
                }

                &.Related {
                    color: #fff;
                    background: #2990fb;
                }
            }
        }
    }
}
