@mixin maxWidth($value) {
    @media (max-width: $value+'px') {
        @content;
    }
}

.trial-success-modal-education {
    max-width: 100%;
    width: 960px;
    height: 600px;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 90px 140px 140px;
    box-sizing: border-box;
    position: relative;
    @include maxWidth(1019) {
        flex-direction: column;
        padding: 60px 20px;
        min-height: auto;
    }
    @include maxWidth(599) {
        padding: 40px 20px;
        height: inherit;
    }
    .subscription-title {
        font-size: 28px;
        margin: 40px;
        color: #000000;
    }
    .subscription-text {
        font-size: 16px;
        line-height: 24px;
    }
    .ok-button {
        color: #ffffff;
        background-color: #2990fbff;
        width: 460px;
        height: 60px;
        padding: 10px 30px;
        border-radius: 4px;
        margin: 25px 0 0 0;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        cursor: pointer;

        @include maxWidth(599) {
            width: 100%;
        }
    }
}
