.project-analytics-band {
    background-color: #2990fb;
    display: flex;
    position: relative;
    z-index: 40;
    &__container {
        flex: 1;
        height: 60px;
        position: relative;
        padding: 0 40px;
        display: flex;
        align-items: center;
    }
    .back {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #fff;
        text-decoration: none;
        > img {
            margin-right: 10px;
        }
    }
    .title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 16px;
        color: #fff;
        > span {
            font-weight: bold;
        }
    }
}
