.item {
    width: 100%;
    margin-right: 10px;
    background-color: #fff;
    border: 1px solid #2990fb;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.7;
    }
    &:last-child {
        margin-right: 0;
    }
}

.link {
    display: flex;
    height: 42px;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.icon {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
}

.label {
    margin-left: 8px;
    font-size: 16px;
    color: #767676;
    @media (max-width: 575px) {
        display: none;
    }
}
