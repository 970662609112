@mixin maxWidth($value) {
    @media (max-width: $value+'px') {
        @content;
    }
}

.trial-activated-modal {
    max-width: 100%;
    width: 640px;
    height: 400px;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 60px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @include maxWidth(1019) {
        flex-direction: column;
        padding: 60px 20px;
        min-height: auto;
    }
    @include maxWidth(599) {
        padding: 40px 20px;
    }
    .title {
        font-family: 'Ubuntu', sans-serif;
        font-weight: bold;
        font-size: 28px;
        line-height: 28px;
        color: #000;
    }
    img {
        width: 147px;
        height: 147px;
    }
    > p {
        font-size: 14px;
    }
    .ok-button {
        cursor: pointer;
        color: #fff;
        width: 130px;
        height: 35px;
        background-color: #2990fbff;
        padding: 10px 30px;
        border-radius: 4px;
        margin: 25px 0 0 0;
        transition: opacity 0.2s ease-in-out;
        &:hover {
            opacity: 0.8;
        }
    }
}
