.cardsGroup {
    width: 100%;
    max-width: 1360px;
}
.title {
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.66px;
    color: #000;
    margin-bottom: 28px;
}

.list {
    width: 100%;
}
