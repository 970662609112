.indicator {
    display: flex;
}

.indicatorLight {
    width: 14px;
    height: 14px;

    background: #c5c5c5;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 100px;

    &.isConnected {
        background: #65bb5a;
        box-shadow: 0 1px 4px rgba(101, 187, 90, 0.2);
    }
}

.indicatorStatus {
    padding-left: 12px;
}

.statusText {
    padding-right: 12px;
    &.isConnected {
        color: #65bb5a;
    }
}
