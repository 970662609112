@import '../../styles/mixins';

$font-normal-robot: normal normal 14px Roboto;

.tips {
    z-index: 200;
    position: fixed;
    bottom: 24px;
    left: 170px;
    @include max_rwd(767) {
        left: 20px;
        bottom: 20px;
    }

    &__preview {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }

        &-icon {
            height: 44px;
            width: 44px;
            border-radius: 26px;
            margin-right: 6px;

            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
            background: #ffffff;

            &-img {
                height: 37px;
                width: 37px;
            }
        }

        &-text {
            height: 26px;
            padding: 0 10px;
            border-radius: 12px;

            cursor: pointer;
            background: #ffffff;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);

            text-align: center;
            font-family: $font-normal-robot;
            line-height: 26px;
        }
    }

    &__info {
        width: 240px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        overflow: hidden;
        background: #ffffff;

        &-header {
            padding: 8px 10px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            font: $font-normal-robot;
            line-height: 20px;
        }

        &-close-button {
            cursor: pointer;
            background-image: url('i/close.svg');
            width: 12px;
            height: 12px;
        }
    }

    &__navigation-panel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 23px;
        padding: 0 6px;

        &-button {
            cursor: pointer;
        }

        &-navigation {
            display: flex;
            &-dot {
                width: 6px;
                height: 6px;
                border-radius: 3px;
                background: #d8d8d8;
                margin: 0 2px;
                cursor: pointer;

                &.active {
                    background: #4891f3;
                }
            }
        }
    }

    &__tip {
        position: relative;
        background-image: url('i/stub-tip.svg');
        height: 135px;
        cursor: pointer;

        &:hover {
            .tips__tip-cover {
                opacity: 0;
            }
        }

        &-cover,
        &-video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &-play-button {
            z-index: 1000;
            position: absolute;
            top: 44px;
            left: 96px;
            transition: transform 0.3s ease-in-out;

            &:hover {
                transform: scale(1.2);
            }

            &:hover + .video-tips__tip-cover {
                opacity: 0;
            }
        }
    }

    &__help-center-link {
        margin-top: 4px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #4891f3;
        text-decoration-line: none;
        cursor: pointer;
    }
}
