.common-radio {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;

    &__label {
        color: #3c3c3c;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        line-height: 16px;

        > span.link {
            display: inline-block;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            color: #2990fb;
            margin-left: auto;
            cursor: pointer;
            vertical-align: text-top;
        }
    }
    &__content {
        display: flex;
        align-items: flex-start;
    }

    &__radio-option {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 12px;
        margin-bottom: 8px;
        font-family: 'Roboto', sans-serif;
        color: #3c3c3c;
        font-size: 14px;
        &:before {
            content: '';
            width: 14px;
            height: 14px;
            margin-right: 8px;
            background-image: url('./i/radio_button_normal.svg');
        }
        &.is-checked {
            &:before {
                background-image: url('./i/radio_button_selected.svg');
            }
        }
    }
}
