.treasure-hunt-preview {
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;

    &.is-modal {
        .treasure-hunt-preview__tile-wrapper {
            padding: 2px;
        }
        .treasure-hunt-preview__tile {
            padding: 8px 6px;

            svg {
                width: 20px;
                height: 20px;
                top: 6px;
                right: 6px;
            }
            &-text {
                margin-bottom: 8px;
                font-size: 9px;
            }
            &-btn {
                font-size: 9px;
                border-radius: 3px;
                padding: 4px 10px;
                height: auto;
                width: auto;
            }
            &.large {
                padding: 40px;
                svg {
                    width: 24px;
                    height: 24px;
                    top: 8px;
                    right: 8px;
                }
            }
            &.small[data-proportion='0.8'] {
                .treasure-hunt-preview__tile-text {
                    -webkit-line-clamp: 2;
                }
            }
        }
    }

    &__tiles {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex: 1;
    }

    &__tile-wrapper {
        padding: 4px;
        box-sizing: border-box;
        height: auto;
    }

    &__tile {
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        text-align: center;
        box-sizing: border-box;
        padding: 20px;

        &-text {
            margin-bottom: 28px;
            font-size: 24px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 3;
        }
        &-btn {
            border: 0;
            height: 50px;
            width: 150px;
            white-space: nowrap;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
            border-radius: 6px;
            background-color: #fff;
            font-size: 20px;
        }

        svg {
            position: absolute;
            opacity: 0.7;
            width: 40px;
            height: 40px;
            top: 10px;
            right: 10px;
        }
        &.medium {
            padding: 40px;
            svg {
                top: 20px;
                right: 20px;
            }
        }
        &.large {
            padding: 140px;
            svg {
                width: 60px;
                height: 60px;
                top: 28px;
                right: 28px;
            }
        }

        &.center {
            justify-content: center;
        }
        &.bottom {
            justify-content: flex-end;
        }
    }
}
