@import '../../../../../../../../styles/mixins';
@import '../../../../../../../../styles/variables';

.add-list-item-button {
    cursor: pointer;
    font-family: $Roboto;
    font-size: 14px;
    color: #2990fb;
    position: relative;
    padding: 4px 0 4px 0;
    @include transition(0.1s, ease-in-out, 0s, (opacity));
    text-align: left;
    &:hover {
        opacity: 0.7;
    }
    &.with-icon {
        padding-left: 20px;
        margin-top: 28px;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 12px;
            height: 12px;
            transform: translateY(-50%);
            background-repeat: no-repeat;
            background-position: center;
        }
        &[data-icon='add'] {
            &:before {
                background-image: url('../../Modals/i/add.svg');
            }
        }
        &.no-offset {
            margin-top: 0;
        }
    }
}
