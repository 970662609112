header.header {
    background-color: $color_white;
    color: $color_black;
    position: sticky;
    top: 0;
    z-index: 50;
    box-shadow: $box-shadow-blue-1;

    .hamburger {
        display: none;
    }
    .sign-up-mobile {
        display: none;
        margin-right: 11px;
        > button {
            @media (max-width: 434px) {
                font-size: 14px;
                padding: 0 10px;
            }
        }
    }

    .header-inner {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        @media (max-width: 1025px) {
            height: 48px;
        }
        .left {
            position: relative;
            height: 100%;
            width: 100%;
            z-index: 5;
            &-inner {
                height: 100%;
                overflow: hidden;
                &-2 {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    align-items: center;
                    position: absolute;
                    .logo {
                        > a {
                            display: inline-block;
                            font-size: 0;
                            > img {
                                width: 136px;
                                @media (max-width: 434px) {
                                    width: 112px;
                                }
                            }
                        }
                    }
                    .menu {
                        display: flex;
                        position: absolute;
                        width: calc(100% - 226px);
                        height: 100%;
                        left: 226px;
                        align-items: center;
                        @include max_xl {
                            left: 196px;
                            width: calc(100% - 196px);
                        }
                        > ul {
                            display: flex;
                            white-space: nowrap;
                            height: 100%;
                            align-items: center;
                            > li {
                                padding-right: 32px;
                                &:last-child {
                                    padding-right: 0 !important;
                                }
                                > a {
                                    cursor: pointer;
                                    font-size: 16px;
                                    color: $color_black;
                                    text-decoration: none;
                                    display: flex;
                                    align-items: center;
                                    @include transition(
                                        $transition_duration--1,
                                        $transition_timing_function--1,
                                        $transition_delay--1,
                                        (color)
                                    );
                                    &.is-active {
                                        color: $color_blue--1;
                                        cursor: default;
                                        pointer-events: none;
                                    }
                                    &:hover:not(.is-active) {
                                        color: $color_light_blue--3;
                                    }
                                }
                                &.with-dropdown {
                                    flex-shrink: 0;
                                    height: 100%;
                                    align-items: center;
                                    display: flex;
                                    position: relative;
                                    &.is-active {
                                        > div > .current {
                                            color: $color_light_blue--3;
                                            > svg {
                                                transform: rotate(180deg);
                                                path.color_fill--1 {
                                                    fill: $color_light_blue--3 !important;
                                                }
                                            }
                                            &:before {
                                                opacity: 0.1;
                                            }
                                        }
                                        > div > .list {
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                    }
                                    > div {
                                        display: flex;
                                        height: 100%;
                                        > .current {
                                            cursor: pointer;
                                            flex: 1;
                                            display: flex;
                                            justify-content: flex-start;
                                            align-items: center;
                                            position: relative;
                                            font-size: 16px;
                                            color: $color_black;
                                            text-decoration: none;
                                            @include transition(
                                                $transition_duration--1,
                                                $transition_timing_function--1,
                                                $transition_delay--1,
                                                (color)
                                            );
                                            &:hover {
                                                color: $color_light_blue--3;
                                                &:before {
                                                    opacity: 0.1;
                                                }
                                                > svg {
                                                    path.color_fill--1 {
                                                        fill: $color_light_blue--3 !important;
                                                    }
                                                }
                                            }
                                            &:before {
                                                opacity: 0;
                                                content: '';
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                right: 0;
                                                bottom: 0;
                                                width: 100%;
                                                height: 100%;
                                                z-index: 1;
                                                background-color: $color_white;
                                                mix-blend-mode: normal;
                                                @include transition(
                                                    $transition_duration--1,
                                                    $transition_timing_function--1,
                                                    $transition_delay--1,
                                                    (opacity)
                                                );
                                            }
                                            > svg {
                                                path {
                                                    @include transition(
                                                        $transition_duration--1,
                                                        $transition_timing_function--1,
                                                        $transition_delay--1,
                                                        (fill)
                                                    );
                                                }
                                                path.color_fill--1 {
                                                    fill: $color_black !important;
                                                }
                                            }
                                        }
                                        > .list {
                                            margin-top: 4px;
                                            padding: 20px 48px 24px 28px;
                                            border-radius: 8px;
                                            visibility: hidden;
                                            opacity: 0;
                                            position: absolute;
                                            top: 100%;
                                            left: -16px;
                                            right: 0;
                                            min-width: 178px;
                                            width: fit-content;
                                            box-sizing: border-box;
                                            background-color: $color_white;
                                            box-shadow: $box-shadow-blue-1;
                                            @include transition(
                                                $transition_duration--1,
                                                $transition_timing_function--1,
                                                $transition_delay--1,
                                                (opacity, visibility)
                                            );
                                            > li {
                                                display: flex;
                                                align-items: center;
                                                cursor: pointer;
                                                margin-bottom: 12px;
                                                &:last-child {
                                                    margin-bottom: 0;
                                                }
                                                &.is-active {
                                                    pointer-events: none;
                                                }
                                                > img {
                                                    width: 20px;
                                                    height: 20px;
                                                    margin-right: 12px;
                                                }
                                                > a {
                                                    text-decoration: none;
                                                    font-size: 16px;
                                                    color: $color_black;
                                                    line-height: 20px;
                                                    &:hover {
                                                        color: $color_light_blue--3;
                                                    }
                                                    &.is-active {
                                                        color: $color_light_blue--3;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .hide-menu {
                            margin-left: 16px;
                            flex-shrink: 0;
                            height: 100%;
                            display: flex;
                            position: relative;
                            &.is-active {
                                > div > .current {
                                    color: $color_light_blue--3;
                                    > svg {
                                        transform: rotate(180deg);
                                        path.color_fill--1 {
                                            fill: $color_light_blue--3 !important;
                                        }
                                    }
                                    &:before {
                                        opacity: 0.1;
                                    }
                                }
                                > div > .list {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                            > div {
                                display: flex;
                                flex: 1;
                                > .current {
                                    cursor: pointer;
                                    flex: 1;
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    position: relative;
                                    padding-left: 16px;
                                    padding-right: 16px;
                                    font-size: 16px;
                                    color: $color_white;
                                    text-decoration: none;
                                    @include transition(
                                        $transition_duration--1,
                                        $transition_timing_function--1,
                                        $transition_delay--1,
                                        (color)
                                    );
                                    &:hover {
                                        color: $color_light_blue--3;
                                        &:before {
                                            opacity: 0.1;
                                        }
                                        > svg {
                                            path.color_fill--1 {
                                                fill: $color_light_blue--3 !important;
                                            }
                                        }
                                    }
                                    &:before {
                                        opacity: 0;
                                        content: '';
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        width: 100%;
                                        height: 100%;
                                        z-index: 1;
                                        background-color: $color_white;
                                        mix-blend-mode: normal;
                                        @include transition(
                                            $transition_duration--1,
                                            $transition_timing_function--1,
                                            $transition_delay--1,
                                            (opacity)
                                        );
                                    }
                                    > svg {
                                        path {
                                            @include transition(
                                                $transition_duration--1,
                                                $transition_timing_function--1,
                                                $transition_delay--1,
                                                (fill)
                                            );
                                        }
                                        path.color_fill--1 {
                                            fill: $color_white !important;
                                        }
                                    }
                                }
                                > .list {
                                    padding: 16px;
                                    visibility: hidden;
                                    opacity: 0;
                                    position: absolute;
                                    top: 100%;
                                    left: 0;
                                    right: 0;
                                    min-width: 160px;
                                    width: fit-content;
                                    box-sizing: border-box;
                                    background-color: $color_dark_gray--4;
                                    @include transition(
                                        $transition_duration--1,
                                        $transition_timing_function--1,
                                        $transition_delay--1,
                                        (opacity, visibility)
                                    );
                                    > li {
                                        display: flex;
                                        align-items: center;
                                        cursor: pointer;
                                        margin-bottom: 12px;
                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                        &.is-active {
                                            pointer-events: none;
                                        }
                                        > a {
                                            text-decoration: none;
                                            font-size: 16px;
                                            color: $color_white;
                                            line-height: 20px;
                                            &:hover {
                                                color: $color_light_blue--3;
                                            }
                                            &.is-active {
                                                color: $color_light_blue--3;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .right {
            display: flex;
            height: 100%;
            margin-left: 16px;
            flex-shrink: 0;
            align-items: center;
            position: relative;
            z-index: 5;

            .lng-menu {
                margin-right: 8px;
                height: 100%;
                width: 60px;
                display: flex;
                &.is-active {
                    > div > .current:before {
                        opacity: 0.1;
                    }
                    > div > .list {
                        opacity: 1;
                        visibility: visible;
                    }
                }
                > div {
                    display: flex;
                    flex: 1;
                    > .current {
                        cursor: pointer;
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        position: relative;
                        &:hover {
                            &:before {
                                opacity: 0.1;
                            }
                        }
                        &:before {
                            opacity: 0;
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 1;
                            background: $color_white;
                            mix-blend-mode: normal;
                            @include transition(
                                $transition_duration--1,
                                $transition_timing_function--1,
                                $transition_delay--1,
                                (opacity)
                            );
                        }
                        > img {
                            z-index: 2;
                            width: 28px;
                        }
                    }
                    > .list {
                        padding: 16px;
                        visibility: hidden;
                        opacity: 0;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        right: 0;
                        width: 100%;
                        box-sizing: border-box;
                        background-color: $color_dark_gray--4;
                        @include transition(
                            $transition_duration--1,
                            $transition_timing_function--1,
                            $transition_delay--1,
                            (opacity, visibility)
                        );
                        > li {
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            margin-bottom: 20px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            &.is-active,
                            &.is-disabled {
                                pointer-events: none;
                            }
                            &.is-silent {
                                cursor: default;
                                &:not(.is-active) > span {
                                    color: $color_white !important;
                                }
                            }
                            &.is-disabled,
                            &.is-silent:not(.is-active) {
                                opacity: 0.5;
                            }
                            > img {
                                width: 28px;
                                margin-right: 12px;
                            }
                            > span {
                                font-size: 16px;
                                color: $color_white;
                                &:hover {
                                    color: $color_light_blue--3;
                                }
                                &.is-active {
                                    color: $color_blue--1;
                                }
                            }
                        }
                    }
                }
            }
            .guest {
                display: flex;
                align-items: center;
                > div.login-btn {
                    font-family: $Roboto;
                    font-size: 16px;
                    margin-right: 34px;
                    cursor: pointer;
                    @include transition(
                        $transition_duration--1,
                        $transition_timing_function--1,
                        $transition_delay--1,
                        (color)
                    );
                    &:hover {
                        color: $color_light_blue--3;
                    }
                }
                > button {
                    min-width: 120px;
                    justify-content: center;
                }
            }

            .hamburger {
                z-index: 5;
            }
        }

        .menu-mobile {
            display: none;
            overflow-y: auto;
            position: fixed;
            z-index: 3;
            width: 100%;
            left: 0;
            bottom: 0;
            margin: 0 !important;
            background-color: $color_light_gray--3;
            transform: translateY(-100%);
            padding: 0 40px 40px;
            text-align: center;
            box-sizing: border-box;
            @include transition(
                $transition_duration--3,
                $transition_timing_function--1,
                $transition_delay--1,
                (transform)
            );
            &.is-active {
                transform: translateY(0);
            }

            .divider {
                width: 100%;
                height: 1px;
                background-color: $color-white;
                opacity: 0.2;
                margin-top: 30px;
            }

            .account {
                text-align: center;
                .ava {
                    flex-shrink: 0;
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    font-size: 16px;
                    font-weight: 500;
                    color: $color-white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto;
                    overflow: hidden;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
                .info {
                    margin-top: 15px;
                    width: 100%;
                    overflow: hidden;
                    .full_name {
                        font-size: 16px;
                        color: $color-black;
                        margin-bottom: 4px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .email {
                        margin-right: 4px;
                        font-size: 14px;
                        color: $color-black;
                        opacity: 0.5;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        padding-bottom: 4px;
                    }
                }
            }

            > ul {
                margin-top: 30px;
                li {
                    padding-bottom: 30px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    &:last-child {
                        padding-bottom: 0;
                    }
                    &.with-dropdown {
                        &.is-active {
                            .dropdown-block > .value {
                                color: $color_light_blue--3;
                                > svg {
                                    transform: rotate(180deg);
                                    path.color_fill--1 {
                                        fill: $color_light_blue--3 !important;
                                    }
                                }
                            }
                            .dropdown-block {
                                ul {
                                    display: block;
                                }
                            }
                        }
                    }
                    a {
                        font-size: 16px;
                        text-align: center;
                        color: $color-black;
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        &.is-active {
                            color: $color_light_blue--3;
                            cursor: default;
                            pointer-events: none;
                        }
                    }
                    .dropdown-block {
                        display: flex;
                        flex-direction: column;
                        animation: c;
                        align-items: center;
                        .value {
                            cursor: pointer;
                            font-size: 16px;
                            text-align: center;
                            color: $color-black;
                            text-decoration: none;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            @include transition(
                                $transition_duration--1,
                                $transition_timing_function--1,
                                $transition_delay--1,
                                (color)
                            );
                            > svg {
                                path {
                                    @include transition(
                                        $transition_duration--1,
                                        $transition_timing_function--1,
                                        $transition_delay--1,
                                        (fill)
                                    );
                                }
                                path.color_fill--1 {
                                    fill: $color_black !important;
                                }
                            }
                        }
                        ul {
                            display: none;
                            padding-bottom: 31px;
                            position: relative;
                            margin-top: 30px;
                            &:after {
                                content: '';
                                position: absolute;
                                bottom: 0;
                                left: 50%;
                                width: 100%;
                                max-width: 40px;
                                height: 1px;
                                background-color: $color_white;
                                transform: translateX(-50%);
                            }
                            > li {
                                padding-bottom: 30px;
                                &:last-child {
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }

            button.logout {
                cursor: pointer;
                margin-top: 30px;
                font-size: 16px;
                color: $color-black;
                opacity: 0.5;
            }
            button.button {
                margin: 60px auto 0;
            }

            .current-lng-string {
                margin: 30px auto 0;
                display: inline-block;
                .box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    img {
                        width: 28px;
                        margin-right: 10px;
                    }
                    p {
                        font-size: 16px;
                        text-align: center;
                        color: $color-black;
                    }
                }
            }
        }
    }

    @media (max-width: 1025px) {
        position: fixed;
        width: 100%;
        .profile {
            display: none !important;
        }
        .header-inner {
            &:before {
                content: '';
                position: absolute;
                z-index: 4;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $color_white;
            }
            &.is-active {
                &:before {
                    background-color: #f9fafd;
                }
            }

            .left {
                .menu {
                    display: none !important;
                }
            }
            .right {
                .lng-menu,
                .guest {
                    display: none;
                }
                .hamburger {
                    display: block;
                }
                .sign-up-mobile {
                    display: block;
                }
            }
            .menu-mobile {
                display: block !important;
            }
        }
    }

    @include max_sm {
        .header-inner {
            padding: 0 17px !important;
        }
    }
}

// вынес, чтобы использовать и в мобильной версии меню
.profile {
    display: flex;
    height: 100%;
    position: relative;
    align-items: center;
    > div {
        height: 100%;
    }
    &-top {
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 100%;
        &__ava {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 12px;
            font-size: 16px;
            font-weight: 500;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        &__name {
            font-size: 16px;
            color: $color_black;
            margin-right: 8px;
        }
        &__icon {
            > svg path.color_stroke {
                stroke: $color_black;
            }
            &.is-opened {
                transform: rotate(180deg);
            }
        }
    }
}
