.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 11px;
    position: relative;
    &:last-child {
        margin-bottom: 0;
    }
}

.answerContainer {
    width: 240px;
    margin-right: 20px;
    flex-shrink: 0;
}

.answerLabel {
    font-weight: bold;
}

.questionContainer {
    width: 100%;
}

.removeContainer {
    position: absolute;
    left: 100%;
    bottom: 0;
    margin: 0 0 11px 16px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}
