.adminPanel {
    position: fixed;
    z-index: 99999;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: #ff5656;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
}
