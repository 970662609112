.winArrow {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    font-size: 0;
    text-align: center;
    > svg {
        height: 100%;
    }
}
