.question-personality {
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #d9d9d9;
    &__title {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-content {
            position: relative;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 500;
            font-size: 24px;
            color: #3c3c3c;
            display: flex;
            align-items: center;
        }
    }

    &__content {
        margin-bottom: 12px;

        &-block {
            display: flex;
            &-input {
                flex: auto;
                padding-left: 5px;
            }
        }
    }

    &__answer-type-select {
        display: flex;
        justify-content: center;
        margin-bottom: 12px;
        &-item {
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 120px;
            height: 32px;
            cursor: pointer;
            border: 1px solid #d8d8d8;

            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            line-height: 14px;
            color: #3c3c3c;

            &-img {
                margin-right: 10px;
                width: 18px;
                height: 18px;
                background-position: center;
                background-repeat: no-repeat;
                &[data-image='text'] {
                    background-image: url('../../../i/text.svg');
                }
                &[data-image='images'] {
                    background-image: url('../../../i/images.svg');
                }
            }

            &.is-active {
                border-color: #2990fb;
                pointer-events: none;

                .question__answer-type-select-item-img {
                    &[data-image='text'] {
                        background-image: url('../../../i/text--blue.svg');
                    }
                    &[data-image='images'] {
                        background-image: url('../../../i/images--blue.svg');
                    }
                }
            }

            &:first-child {
                border-radius: 4px 0 0 4px;
            }
            &:last-child {
                border-radius: 0 4px 4px 0;
            }
        }
    }
}
