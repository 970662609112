.customEventsModalWrapper {
    width: 960px;
    height: 428px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    background-color: #f9fafd;
    box-sizing: border-box;
}

.body {
    height: 100%;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 40px 0 0 32px;
}

.logo {
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 28px;
    > img {
        max-width: 100%;
        max-height: 100%;
    }
}

.content {
    width: 100%;
    position: relative;
}
.contentTitle {
    margin-bottom: 12px;
    font-size: 16px;
    color: #3c3c3c;
}

.scrollableContent {
    padding: 0 40px 20px 0;
}

.tableHeadCols {
    display: flex;
    padding: 14px 20px;
    background-color: #eaf4ff;
    border-radius: 4px 4px 0 0;
}

.tableHeadCol {
    color: #3c3c3c;
    &:nth-child(1) {
        width: 25%;
    }
    &:nth-child(2) {
        width: 35%;
    }
    &:nth-child(3) {
        width: 40%;
    }
}

.tableRows {
    padding: 16px 20px;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
}

.tableRow {
    display: flex;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
}

.tableCol {
    display: flex;
    align-items: center;
    color: #787878;
    box-sizing: border-box;
    font-size: 14px;
    &:nth-child(1) {
        width: 25%;
    }
    &:nth-child(2) {
        width: 35%;
        padding-right: 28px;
    }
    &:nth-child(3) {
        width: 40%;
        font-size: 12px;
        line-height: 14px;
    }
}
