.instruction {
    width: 400px;
    padding: 40px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.0668875);
    border-radius: 4px;
    flex-shrink: 0;
    margin-left: 40px;
    @media screen and (max-width: 1025px) {
        margin-left: 0;
        margin-top: 40px;
        width: 100%;
    }
    @media screen and (max-width: 680px) {
        padding: 20px;
    }
}

.article {
    margin-bottom: 28px;
    text-align: center;
    &:last-child {
        margin-bottom: 0;
    }
}

.image {
    width: 100%;
    @media screen and (max-width: 1025px) {
        max-width: 400px;
    }
}

.title {
    text-align: left;
    font-size: 18px;
    line-height: 20px;
    color: #3c3c3c;
    margin-bottom: 20px;
}

.text {
    text-align: left;
    font-size: 14px;
    line-height: 24px;
    color: #8a8a8a;
    > ul {
        > li {
            position: relative;
            padding-left: 14px;
            &:before {
                content: '';
                position: absolute;
                top: 10px;
                left: 0;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: #8a8a8a;
            }
        }
    }
}
