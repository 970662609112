@import '../../../../styles/mixins';

.activityItem {
    display: flex;
    align-items: center;
    margin-right: 40px;
    @include max_rwd(1340) {
        width: 50%;
        margin-right: 0;
        padding: 10px 0;
    }
    @include max_rwd(767) {
        width: 100%;
    }
    &:last-child {
        margin-right: 0;
    }
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
        max-width: 100%;
        max-height: 100%;
    }
}

.value {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #3c3c3c;
    margin-right: 8px;
}

.label {
    font-size: 16px;
    line-height: 24px;
    color: #787878;
}
