.card-proportions {
    margin-bottom: 12px;
    font-family: 'Roboto', sans-serif;

    .card-proportions__label {
        margin-bottom: 8px;
    }

    .card-proportions__content-wrapper {
        display: flex;

        .card-proportions__proportion {
            position: relative;
            background: #ffffff;
            border: 1px solid #d8d8d8;
            box-sizing: border-box;
            border-radius: 2px;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &.card-proportions__proportion-is-selected {
                border-color: #2990fb;
            }

            &.card-proportions__square {
                width: 60px;
                height: 60px;
            }

            &.card-proportions__horizontal-rectangle {
                width: 60px;
                height: 48px;
            }

            &.card-proportions__vertical-rectangle {
                width: 48px;
                height: 60px;
            }
        }
    }
}
