.time-is-up-screen {
    display: flex;
    height: 100%;
    max-width: 100%;
    width: 960px;
    text-align: left;

    &__workplace-wrapper {
        width: 100%;
        box-sizing: border-box;
        background-color: #f9fafd;
        overflow: hidden;
    }

    &__workplace {
        padding: 36px 40px 20px;

        &-block {
            display: flex;
            justify-content: flex-start;
            align-content: center;
        }
        &-text-field {
            margin-right: 25px;
        }

        &-title {
            margin-bottom: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 18px;
            color: #787878;

            &:before {
                content: '';
                width: 28px;
                height: 28px;
                border-radius: 50%;
                background-color: #ffc451;
                background-image: url('./i/white-tip.svg');
                background-repeat: no-repeat;
                background-position: center;
                margin-right: 12px;
            }
        }
    }

    &__preview-wrapper {
        flex-shrink: 0;
        width: 340px;
        box-sizing: border-box;
        background-color: #f9fafd;
        border-left: 1px solid #d8d8d8;
        overflow: hidden;
    }

    &__preview-title {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        color: #787878;
        margin: 10px 40px;
    }

    &__preview {
        margin: 10px 40px 20px;
        padding-bottom: 20px;
        background-color: #ffffff;

        &-empty {
            font-size: 12px;
            font-family: 'Ubuntu', sans-serif;
            text-align: center;
            color: #979797;
            margin: 50px 0 42px;
            padding: 0 8px;
        }

        &-content {
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            text-align: center;
            white-space: pre-line;
            overflow-wrap: break-word;
        }
        &-header {
            margin-top: 16px;
            font-size: 16px;
            line-height: 19px;
            color: #000;
            padding: 0 8px;
        }

        &-description {
            margin-top: 16px;
            font-size: 12px;
            line-height: 16px;
            color: #000;
            padding: 0 8px;
        }

        &-image {
            object-fit: cover;
            width: 100%;
            height: 192px;
            border-radius: 4px;
        }

        &-buttons {
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            align-items: center;
        }

        &-button {
            margin-top: 20px;
            text-align: center;
            background-color: #000;
            border-radius: 4px;
            min-width: 112px;
            height: 32px;
            padding: 0 10px;
            box-sizing: border-box;
        }
        &-button-restart {
            box-sizing: border-box;
            height: 32px;
            min-width: 112px;
            border: 1px solid #d8d8d8;
            border-radius: 4px;
            margin-top: 20px;
            padding: 0 20px 0 35px;
            outline: none;
            background-color: #fff;
            line-height: 24px;
            text-align: center;
            color: #787878;
            > span {
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    left: -20px;
                    width: 16px;
                    height: 20px;
                    background-repeat: no-repeat;
                    background-image: url("data:image/svg+xml,%3Csvg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 12C15 15.866 11.866 19 8 19C4.13401 19 1 15.866 1 12C1 8.13401 4.13401 5 8 5H10' stroke='%23787878' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M7 9L11 5L7 1' stroke='%23787878' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }
}
