.item {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin: 4px 0;
}

.itemIcon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    > svg * {
        transition: all 0.2s ease-in-out;
    }
}

.itemText {
    font-size: 14px;
    line-height: 18px;
    color: #8a8a8a;
    transition: color 0.2s ease-in-out;
}

.isHoveredItem {
    .itemText {
        color: #2990fb;
    }
}
