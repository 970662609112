@mixin maxWidth($value) {
    @media (max-width: $value+'px') {
        @content;
    }
}

.description {
    max-width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    flex-shrink: 0;
    padding-right: 40px;
    box-sizing: border-box;
    @include maxWidth(1019) {
        width: 100% !important;
        align-items: center;
        padding-right: 0;
        padding-bottom: 40px;
    }
}

.title {
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: -1px;
    color: #000000;
    text-align: left;
    @include maxWidth(1019) {
        text-align: center;
    }
}

.subTitle {
    margin-top: 24px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #3c3c3c;
    text-align: left;
    white-space: pre-line;
    @include maxWidth(1019) {
        text-align: center;
    }
}

.contactsList {
    text-align: left;
    margin-top: 20px;
}

.contact {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    &:last-child {
        margin-bottom: 0;
    }
}

.contactIconBox {
    display: flex;
    align-items: center;
}

.contactIcon {
    width: 28px;
    height: 28px;
    margin-right: 16px;
}

.contactLink {
    color: #2990fb;
    font-size: 16px;
    line-height: 28px;
    text-decoration-line: underline;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}
