@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';
@import '../../../../../../styles/animations';

.project-block__updated-view-info {
    > div {
        font-size: 14px;
        color: $color_light_gray--4;
        margin-bottom: 4px;
        position: relative;
        &:first-child {
            display: inline-block;
        }
        &:last-child {
            margin-bottom: 0;
        }
        > .triangle-icon {
            cursor: help;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 100%;
            svg {
                margin-left: 5px;
                width: 14px;
                padding-bottom: 2px;
                @include max_xl {
                    width: 12px;
                }
            }
        }
        @include max_xl {
            font-size: 13px;
            margin-bottom: 3px;
        }

        &.is-loading {
            color: transparent;
            @extend .animatedLoading;
        }
    }
}
