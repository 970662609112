.payoutsDisabled {
    margin-top: 30px;
    width: 100%;
}

.alert {
    display: flex;
    justify-content: center;
}

.imageBox {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffc451;
    border-radius: 50%;
}

.title {
    margin-top: 20px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #3c3c3c;
}

.description {
    margin: 8px auto 0;
    max-width: 400px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #626262;
}

.link {
    text-decoration: none;
    color: #2990fb;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}

.buttonBox {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.contact {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: #8a8a8a;
    margin-top: 20px;
}
