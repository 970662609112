@import '../../../../../../styles/mixins';

.sideNav {
    width: 120px;
    background-color: #2990fb;
    flex-shrink: 0;
    @include max_rwd(768) {
        width: 46px;
    }
}

.itemsList {
    position: relative;
}

.item {
    position: relative;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    padding: 10px;
    box-sizing: border-box;
    border-top: 1px solid #62a5ea;
    transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
    background-color: #2687ec;
    @include max_rwd(768) {
        height: 80px;
    }
    &:hover {
        background-color: #2075cf;
        &:before {
            background-color: #2075cf;
        }
        .itemIcon {
            opacity: 1;
        }
        .itemLabel {
            z-index: 3;
        }
        .itemLabel {
            opacity: 1;
        }
    }
    &:before {
        content: '';
        position: absolute;
        z-index: 2;
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: 12px;
        height: 12px;
        border: 1px solid #62a5ea;
        background-color: #2687ec;
        border-top: 0;
        border-left: 0;
        transition: background-color 0.2s ease-in-out;
    }
    &:last-child {
        &:before {
            display: none;
        }
    }
}
.isActiveItem {
    pointer-events: none;
    background-color: #2075cf;
    &:before {
        background-color: #2075cf;
    }
    .itemIcon {
        opacity: 1;
    }
    .itemLabel {
        z-index: 3;
    }
    .itemLabel {
        opacity: 1;
    }
}

.itemIcon {
    background-color: #fff;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    z-index: 5;
    position: relative;
    overflow: hidden;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.itemLabel {
    text-align: center;
    color: #fff;
    position: relative;
    opacity: 0.8;
    transition: opacity 0.2s ease-in-out;
    @include max_rwd(768) {
        display: none;
    }
}
