@import '../../../../../../../../styles/variables';
@import '../../../../../../../../styles/mixins';

.shareUrlBody {
    display: flex;
    justify-content: space-between;
    @include max_rwd(1024) {
        flex-direction: column;
        align-items: center;
    }
}

.inputBox {
    width: 100%;
    @include max_rwd(1024) {
        margin-bottom: 16px;
    }
}
.input {
    height: 44px;
    padding: 0;
}

.button {
    margin-left: 12px;
    @include max_rwd(1024) {
        margin-left: 0;
        margin-bottom: 8px;
        width: 100%;
    }
}

.buttonIcon {
    margin-right: 5px;
}
