.myPurchases {
    width: 100%;
    max-width: 1400px;
    margin: 48px auto 40px;
    padding: 0 20px;
    box-sizing: border-box;
}

.title {
    margin-bottom: 28px;
    font-size: 20px;
    line-height: 20px;
    color: #555;
}

.empty {
    opacity: 0.8;
}
