@import '../../../../styles/mixins';

.table {
    margin-top: 55px;
    width: 100%;
    @include max_rwd(540) {
        margin-top: 40px;
    }
}

.header {
    display: flex;
    padding-bottom: 16px;
    border-bottom: 1px solid #d9d9d9;
    @include max_rwd(540) {
        display: none;
    }
}
.headerColumn {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #3c3c3c;
    padding-right: 10px;
    box-sizing: border-box;
    &:last-child {
        text-align: right;
        padding-right: 0;
    }
}

.data {
    position: relative;
}
.dataRow {
    border-bottom: 1px solid #d9d9d9;
    padding: 16px 0;
    &:last-child {
        border-bottom: 0;
    }
}
.dataColumn {
    display: flex;
    @include max_rwd(540) {
        flex-direction: column;
    }
}
.dataColumnValue {
    font-size: 14px;
    line-height: 18px;
    color: #787878;
    padding-right: 10px;
    box-sizing: border-box;
    &:last-child {
        text-align: right;
        padding-right: 0;
    }
    @include max_rwd(540) {
        padding: 0;
        width: 100% !important;
        margin-bottom: 10px;
        text-align: left !important;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.mobileTitle {
    display: none;
    padding-bottom: 5px;
    opacity: 0.7;
    @include max_rwd(540) {
        display: block;
    }
}
