.common-checkbox {
    padding: 5px 0;
    margin-bottom: 5px;
    font-family: 'Roboto', sans-serif;

    &.common-checkbox__disabled {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    }

    .common-checkbox__content {
        display: flex;
        align-items: flex-start;

        .common-checkbox__checkbox {
            width: 14px;
            height: 14px;
            cursor: pointer;
            border-radius: 2px;
            margin: 0 8px 0 0;
            flex-shrink: 0;
            background-color: #d7d7d7;
            background-position: 50% 3px;
            background-repeat: no-repeat;

            &.common-checkbox__checkbox-checked {
                background-color: #2990fb;
                background-image: url('../../../pages/Editor/Tabs/Editor/i/checkmark.svg');
            }
        }

        .common-checkbox__label {
            font-size: 14px;
            color: #3c3c3c;
            cursor: pointer;
            display: flex;
            align-items: center;
        }

        .common-checkbox__required {
            &:after {
                content: ' *';
                color: #ff5656;
            }
        }
    }
}
