@import '../../styles/variables';

.MailchimpMappingView {
    padding-top: 35px;
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .aside {
        flex: 1 auto;
        padding-bottom: 35px;
        width: 130px;
    }

    .main {
        flex: 35 0;
        margin-right: 25px;
        > * {
            padding-bottom: 10px;
        }
    }

    .tableName {
        color: #3c3c3c;
        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        padding-bottom: 10px;
        text-align: left;
    }
}

.MuiDataGrid-root {
    & .MuiDataGrid-columnHeaderTitleContainer {
        padding: 0 !important;
    }

    .tableRow {
        background: white;
    }

    .columnHeader {
        background: #2990fb1a;
        color: #3c3c3c;
    }
}
