@import '../../../../styles/variables';

.unsplash-library {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    padding: 0 40px;

    &__header {
        height: 80px;
        text-align: left;

        &_title {
            margin: 16px 0 8px;
            display: flex;
            align-items: flex-end;
            &--title {
                font-family: $Ubuntu;
                font-weight: 500;
                font-size: 24px;
                line-height: 28px;
                color: $color_dark_gray--3;
            }
            &--note {
                padding: 0 0 3px 10px;
                font-size: 14px;
                color: #787878;
            }
        }

        &_description {
            font-family: $Roboto;
            font-size: 14px;
            line-height: 20px;
            color: $color_dark_gray--1;
        }
    }

    &__place_bar_description {
        margin-left: 40px;
        text-align: left;
        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: $color_dark_gray--1;
    }

    &__link {
        color: $color_blue--1;
        text-decoration: none;
    }
}
