.mainPreloader {
    position: fixed;
    z-index: 9999999999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image {
    width: 400px;
    max-width: 100%;
}
