.internal-rounds__round {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100px;
    height: 106px;
    margin-bottom: 34px;
    border-radius: 4px;
    cursor: pointer;
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: -12px;
        left: -12px;
        width: 124px;
        height: 152px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url('./i/shield.svg');
        transition: background-image 0.2s ease-in-out;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &--is-selected {
        cursor: default;

        &:before {
            background-image: url('./i/shieldSelected.svg');
        }

        .internal-rounds__round-number__text {
            color: #2990fb;
        }
        .actionsDropdown {
            visibility: visible;
        }
    }

    &:hover {
        .actionsDropdown {
            visibility: visible;
        }
    }

    &-input {
        position: relative;
        z-index: 3;
        padding: 0;
        height: 16px;
        .common-text-input__input-text {
            border: none;
            font-size: 14px;
            line-height: 16px;
            padding: 0;
            text-align: center;

            &:focus:not(:read-only),
            &.is-focused:not(:read-only) {
                border: none;
                border-radius: 0;
                box-shadow: none;
            }
        }
    }

    &-input-wrapper {
        position: fixed;
        z-index: 2;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &-label {
        box-sizing: border-box;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        line-height: 16px;
        color: #787878;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: default;
        padding: 20px 5px 6px;
        text-align: center;
        white-space: nowrap;
    }

    &-number {
        display: flex;
        justify-content: center;
        position: relative;
        &:before,
        &:after {
            content: '';
            position: absolute;
            width: 25px;
            height: 30px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: url('./i/roundNumberArea.svg');
            top: 50%;
        }
        &:before {
            transform: translateY(-50%);
            left: 6px;
        }
        &:after {
            transform: rotate(180deg) translateY(50%);
            right: 6px;
        }
        &__text {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            padding: 0 4px;
            position: relative;
            z-index: 3;
            font-size: 36px;
            line-height: 44px;
            text-align: center;
            color: #979797;
            transition: color 0.2s ease-in-out;
            background-color: #fff;
        }
    }
}
