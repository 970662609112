.playground {
    margin: 0 auto;
    padding: 20px;
    max-width: 1200px;
}

.tilesContainer {
    position: relative;
    margin: 0 auto;
}

.tilesList {
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(var(--columnsCount), 1fr);
}
