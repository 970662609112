.codeInput {
    position: relative;
}

.label {
    height: 16px;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    &:after {
        margin-left: 5px;
        content: attr(data-optional);
        color: #b7b7b7;
    }
}

.inputContainer {
    position: relative;
}

.inputBlock {
    display: flex !important;
    justify-content: space-between;
    > input {
        margin-right: 20px;
        background-color: #fff;
        border: 1px solid #979797;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: 'Ubuntu', sans-serif;
        font-size: 48px;
        text-align: center;
        box-shadow: none;
        outline: none;
        padding: 12px 0;
        &:focus {
            caret-color: #69b1fc;
            outline: 2px solid #69b1fc;
            outline-offset: -2px;
        }
        &:last-child {
            margin-right: 0;
        }
        @media (max-width: 991px) {
            font-size: 42px;
        }
        @media (max-width: 480px) {
            font-size: 34px;
            margin-right: 12px;
        }
    }
}
.inputBlockError {
    > input {
        border-color: #ff5656;
    }
}

.error {
    position: absolute;
    top: 100%;
    margin-top: 5px;
    right: 0;
    font-size: 12px;
    color: #ff5656;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
