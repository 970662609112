@import '../../../../../../../styles/mixins';

.followerItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    &:last-child {
        margin-bottom: 0;
    }
}

.user {
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}

.avatar {
    margin-right: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    color: #fff;
    overflow: hidden;
    flex-shrink: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include max_rwd(767) {
        width: 30px;
        height: 30px;
        margin-right: 6px;
    }
}

.name {
    font-size: 16px;
    line-height: 16px;
    color: #3c3c3c;
    @include max_rwd(767) {
        font-size: 14px;
        line-height: 14px;
    }
}

.button {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 44px;
    justify-content: center;
    min-width: 144px;
    padding: 0 15px;
    border-radius: 4px;
    font-size: 16px;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
    @include max_rwd(767) {
        min-width: auto;
        font-size: 14px;
        height: 32px;
    }
}

.isLoadingButton {
    opacity: 0.5;
    pointer-events: none;
}

.isFollowButton {
    background-color: #2990fb;
    border: 1px solid #2990fb;
    color: #fff;
}

.isUnfollowButton {
    background-color: #fff;
    border: 1px solid #979797;
    color: #979797;
}
