@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.organization-panel__space-header {
    display: flex;
    position: relative;
    align-items: center;
    margin: 20px 12px;
    cursor: pointer;

    &-icon {
        box-sizing: border-box;
        display: flex;
        flex: none;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        background-color: $color_white;
        border-radius: 4px;
        color: $color_blue--1;
        font-family: $Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 20px;
        text-align: center;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            width: 54px;
            height: 54px;
            left: -2px;
            top: -2px;
            border-radius: 4px;
            box-shadow: 0 0 0 1px $color_light_gray--2;
        }

        > img {
            width: 100%;
            object-position: center;
            object-fit: cover;
        }
    }

    &-type {
        margin-left: 10px;
        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: $color_middle_gray--2;
        margin-bottom: 4px;
    }

    &-name {
        width: 100%;
        margin-left: 10px;
    }

    &-title {
        min-height: 32px;
        margin-left: 10px;
        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        color: $color_dark_gray--3;
    }

    &-input {
        margin: 0;
        margin-left: 10px;
        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        color: $color_dark_gray--3;
    }
}
