.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.title {
    font-family: 'Ubuntu', sans-serif;
    font-size: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.buttons {
    display: flex;
    align-items: center;
    position: relative;
}