.pagination-wrapper {
    margin-top: 30px;
    display: none;
    &.is-visible {
        display: block;
    }
    ul.pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        > li {
            outline: none;
            border-radius: 50%;
            margin-right: 4px;
            @include transition(
                $transition_duration--1,
                $transition_timing_function--1,
                $transition_delay--1,
                (background-color, box-shadow)
            );
            &:hover:not(.is-active) {
                background-color: $color_white;
                box-shadow: $box-shadow--5;
            }
            &:last-child {
                margin-right: 0;
            }
            > a {
                display: flex;
                width: 40px;
                height: 40px;
                align-items: center;
                justify-content: center;
                color: $color_blue--1;
                font-size: 16px;
                cursor: pointer;
                outline: none;
                text-decoration: none;
            }
            &.is-active {
                background-color: $color_blue--1;
                > a {
                    color: #fff;
                    cursor: default;
                }
            }
            &.disabled {
                > a {
                    cursor: default;
                    color: #d8d8d8;
                }
            }
            &.previous,
            &.next {
                background-color: $color-white;
                background-repeat: no-repeat;
                background-position: center;
                border: 1px solid $color_light_gray--2;
                > a {
                    font-size: 0;
                }
            }
            &.previous {
                background-image: url('../../svg/pagination/arrow-left.svg');
                &.disabled {
                    visibility: hidden;
                }
            }
            &.next {
                background-image: url('../../svg/pagination/arrow-right.svg');
                &.disabled {
                    visibility: hidden;
                }
            }

            &.break {
            }
        }
    }
}

.pagination-wrapper2 {
    margin-top: 40px;
    display: none;
    &.is-visible {
        display: block;
    }
    ul.pagination {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        > li {
            outline: none;
            border-radius: 2px;
            margin-right: 0;
            background-color: #f9fafd;
            @include transition(
                $transition_duration--1,
                $transition_timing_function--1,
                $transition_delay--1,
                (background-color, box-shadow)
            );
            &:hover:not(.is-active) {
                background-color: darken(#f9fafd, 2);
            }
            &:last-child {
                margin-right: 0;
            }
            > a {
                display: flex;
                width: 40px;
                height: 40px;
                align-items: center;
                justify-content: center;
                color: #6c6c6c;
                font-size: 16px;
                cursor: pointer;
                outline: none;
                text-decoration: none;
            }
            &.is-active {
                background-color: $color_blue--1;
                > a {
                    color: #fff;
                    cursor: default;
                }
            }
            &.disabled {
                > a {
                    cursor: default;
                    color: #d8d8d8;
                }
            }
            &.previous,
            &.next {
                background-color: #f9fafd;
                background-repeat: no-repeat;
                background-position: center;
                > a {
                    font-size: 0;
                }
            }
            &.previous {
                background-image: url('../../svg/pagination/arrow-left.svg');
                margin-right: 16px;
                &.disabled {
                    visibility: hidden;
                }
            }
            &.next {
                margin-left: 16px;
                background-image: url('../../svg/pagination/arrow-right.svg');
                &.disabled {
                    visibility: hidden;
                }
            }

            &.break {
                background-color: $color-white;
            }
        }
    }
}
