.wrapper {
    main {
        min-height: 75vh;
    }
    > .main {
        background-color: #f9fafd;
        &.is-editor-main {
            padding-top: 0 !important;
        }
        @include max_rwd(1025) {
            padding-top: 48px;
        }
        .page {
            display: flex;
            flex: 1;
            &[data-structure='1'] {
                flex-direction: column;
            }
            &[data-structure='2'] {
                flex-direction: row;
            }
            &-bar {
                display: flex;
                margin-top: 45px;
                margin-bottom: 20px;
                align-items: center;
                @include max_md {
                    flex-direction: column;
                }
                > div {
                    width: 33.3%;
                    @include max_md {
                        width: 100%;
                    }
                    @include max_md {
                        width: 100%;
                    }
                    &.title {
                        text-align: left;
                        @include max_md {
                            margin-bottom: 25px;
                        }
                        > h1 {
                            font-size: 20px;
                        }
                    }
                    &.tab {
                        > ul {
                            display: flex;
                            justify-content: center;
                            > li {
                                cursor: pointer;
                                margin-right: 40px;
                                color: $color_dark_gray--2;
                                &.is-active {
                                    cursor: default;
                                    color: $color_blue--1;
                                    border-bottom: 2px solid $color_blue--1;
                                }
                                &:last-child {
                                    margin-right: 0;
                                }
                                > span {
                                    font-size: 16px;
                                    color: $color_light_gray--2;
                                    padding: 0 2px;
                                }
                            }
                        }
                    }
                    &.actions {
                        display: flex;
                        justify-content: flex-end;

                        @include max_md {
                            a.create-project-link {
                                display: none;
                            }
                        }
                    }
                }
            }

            section {
                flex: 1;
                padding-bottom: 110px;
                width: 100%;
                overflow: hidden;
            }
        }

        .container {
            margin: 0 auto;
            width: $container-xs;
            box-sizing: border-box;
            padding: 0 15px;
            @include min_sm {
                width: $container-sm;
            }
            @include min_md {
                width: $container-md;
            }
            @include min_lg {
                width: $container-lg;
            }
            @include min_xl {
                width: $container-xl;
            }
            @include min_xxl {
                width: $container-xxl;
            }
        }
        .container-fluid {
            width: 100%;
            padding: 0 40px;
            box-sizing: border-box;

            @include max_rwd(780) {
                .band-inner {
                    .back {
                        > a {
                            > span {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
