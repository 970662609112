.column {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    box-sizing: border-box;
    &:last-child {
        padding-right: 0;
    }
    @media (max-width: 1024px) {
        width: 100% !important;
        padding: 15px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
        }
    }
}

.label {
    display: none;
    font-size: 14px;
    padding-bottom: 10px;
    @media (max-width: 1024px) {
        display: block;
    }
}

.content {
    position: relative;
}
