.personality-start-screen {
    display: flex;
    height: 100%;
    width: 100%;

    &__workplace-wrapper {
        width: 100%;
        box-sizing: border-box;
        background-color: #f9fafd;
        overflow: hidden;
        height: 100%;
    }
    &__workplace {
        padding: 36px 40px 20px;

        &-title {
            margin-bottom: 20px;
            display: flex;
            justify-content: left;
            align-items: center;
            position: relative;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 500;
            font-size: 24px;
            color: #3c3c3c;

            &-toggle {
                margin-top: 5px;
                margin-left: 10px;
            }
        }
        &-block {
            display: flex;
            &-input {
                flex: auto;
            }
        }
    }

    &__preview-wrapper {
        flex-shrink: 0;
        width: 340px;
        box-sizing: border-box;
        background-color: #f9fafd;
        border-left: 1px solid #d8d8d8;
        overflow: hidden;
    }
    &__preview {
        margin: 10px 40px 20px;
        background-color: #ffffff;

        &-title {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            color: #787878;
            margin-bottom: 8px;
        }

        &-empty {
            font-size: 12px;
            font-family: 'Ubuntu', sans-serif;
            text-align: center;
            color: #979797;
            margin: 50px 0 42px;
            padding: 0 8px;
        }

        &-content {
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            text-align: center;
            white-space: pre-line;
            overflow-wrap: break-word;
        }
        &-header {
            margin-top: 16px;
            font-size: 16px;
            line-height: 19px;
            color: #000;
            padding: 0 8px;
        }

        &-description {
            margin-top: 16px;
            font-size: 12px;
            line-height: 16px;
            color: #000;
            padding: 0 8px;
        }

        &-image {
            object-fit: cover;
            width: 100%;
            height: 192px;
        }

        &-btn {
            overflow: hidden;
            outline: none;
            cursor: pointer;
            border: 0;
            padding: 0 44px;
            height: 30px;
            white-space: nowrap;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
            border-radius: 4px;
            color: #fff;
            margin: 16px 8px 0;
        }

        &-image-disclaimer {
            text-align: left;
            margin-top: 12px;
            font-family: 'Roboto', sans-serif;
            font-size: 8px;
            line-height: 9px;
            color: #b7b7b7;
            padding: 0 8px 8px;
        }
    }
}
