@mixin transition($transition_duration, $transition_timing_function, $transition_delay, $transition_property) {
    transition-duration: $transition_duration;
    transition-timing-function: $transition_timing_function;
    transition-delay: $transition-delay;
    transition-property: $transition-property;
}
$Roboto: 'Roboto', sans-serif;
$Ubuntu: 'Ubuntu', sans-serif;

.quiz-modal-wrapper {
    display: flex;
    max-height: 540px;
    height: 468px;
    width: 960px;
    max-width: 100%;
    text-align: left;
    ul.actions {
        display: flex;
        > li {
            cursor: pointer;
            font-size: 0;
            width: 24px;
            height: 24px;
            margin-right: 8px;
            background-position: center;
            background-repeat: no-repeat;
            @include transition(0.1s, ease-in-out, 0s, (opacity));
            &[data-icon='up'] {
                background-image: url('../i/up.svg');
            }
            &[data-icon='down'] {
                background-image: url('../i/down.svg');
            }
            &[data-icon='clone'] {
                background-image: url('../i/clone.svg');
            }
            &[data-icon='remove'] {
                background-image: url('../i/remove.svg');
            }
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                opacity: 0.7;
            }
            &.rounded {
                border-radius: 50%;
                background-color: #fff;
            }
        }
    }
    aside {
        height: 100%;
        flex-shrink: 0;
        width: 232px;
        background-color: #fff;
        box-sizing: border-box;
        overflow: hidden;
        .bank-switcher {
            margin: 16px 24px 0;
            height: 64px;
            padding: 0 16px;
            box-sizing: border-box;
            background: #fff;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
            border-radius: 8px;
            display: flex;
            align-items: center;
            > .common-toggle {
                flex-shrink: 0;
                .common-toggle__slider-wrapper {
                    margin: 0;
                }
                .common-toggle__label {
                    display: none;
                }
            }
            > p {
                padding-left: 16px;
                font-size: 12px;
                line-height: 16px;
                color: #3c3c3c;
            }
        }
    }
    .body {
        width: 100%;
        box-sizing: border-box;
        height: 100%;
        > .tab-wrapper {
            height: 100%;
            > .workplace {
                width: 100%;
                box-sizing: border-box;
                background-color: #f9fafd;
                overflow: hidden;
                height: 100%;
                .scrollbar-inner {
                    padding: 36px 40px 20px;
                    input {
                        height: 32px;
                        &:focus {
                            border-color: #69b1fc !important;
                        }
                    }
                    textarea {
                        border-width: 1px !important;
                        padding: 5px 12px 6px !important;
                        &:focus {
                            border-color: #69b1fc !important;
                            box-shadow: inset 0 0 0 1px #69b1fc;
                        }
                    }
                    h3.tab-title {
                        margin-bottom: 20px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        > span {
                            position: relative;
                            font-family: $Ubuntu;
                            font-weight: 500;
                            font-size: 24px;
                            color: #3c3c3c;
                            display: flex;
                            align-items: center;
                            > span.note {
                                position: absolute;
                                left: 100%;
                                bottom: 4px;
                                margin-left: 16px;
                                font-family: $Roboto;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                color: #979797;
                                white-space: nowrap;
                            }
                            > div.toggle-element {
                                margin: 0 0 3px 12px;
                            }
                            &.flex-end {
                                align-items: flex-end;
                            }
                        }
                    }
                    div.content {
                        &.is-hidden {
                            pointer-events: none;
                            opacity: 0.5;
                            user-select: none;
                        }
                    }
                    div.input-block,
                    div.image-block {
                        position: relative;
                        > div.label {
                            height: 16px;
                            margin-bottom: 8px;
                            display: flex;
                            justify-content: space-between;
                            align-items: baseline;
                            &--centered {
                                align-items: center;
                            }
                            > ul.group {
                                display: flex;
                                > li {
                                    margin-right: 10px;
                                    &:last-child {
                                        margin-right: 0;
                                    }
                                    > span {
                                        font-size: 10px;
                                        color: #979797;
                                    }
                                }
                            }
                            > span.text {
                                font-family: $Roboto;
                                font-size: 14px;
                                color: #3c3c3c;
                                position: relative;
                                white-space: nowrap;
                                &[data-required='true'] {
                                    &:after {
                                        content: ' *';
                                        color: #ff5656;
                                    }
                                }
                                &[data-optional] {
                                    &:after {
                                        content: attr(data-optional);
                                        color: #b7b7b7;
                                    }
                                }
                            }
                        }
                        .combined-box {
                            display: flex;
                        }
                        .combined-box,
                        .input-box,
                        .textarea-box {
                            &.w-all {
                                width: 100%;
                            }
                            &.is-error {
                                > input,
                                > textarea {
                                    border-color: #ff5656;
                                }
                            }
                        }
                        div.error-message {
                            position: absolute;
                            top: 100%;
                            right: 0;
                            font-family: $Roboto;
                            font-size: 12px;
                            padding-top: 4px;
                            color: #ff5656;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                    div.checkbox-block {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        &.is-disabled {
                            opacity: 0.7;
                            pointer-events: none;
                        }
                        > p {
                            padding-left: 8px;
                            font-family: $Roboto;
                            font-size: 14px;
                            color: #3c3c3c;
                        }
                    }
                    .block {
                        margin-bottom: 12px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        &--combined {
                            display: flex;
                            align-items: center;
                        }
                        &--checkbox {
                            display: flex;
                            justify-content: center;
                            margin-top: 15px;
                            > ul {
                                display: flex;
                                > li {
                                    font-family: $Roboto;
                                    font-size: 14px;
                                    line-height: 14px;
                                    color: #3c3c3c;
                                    border: 1px solid #d8d8d8;
                                    width: 120px;
                                    height: 32px;
                                    box-sizing: border-box;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    cursor: pointer;
                                    &:first-child {
                                        border-radius: 4px 0 0 4px;
                                    }
                                    &:last-child {
                                        border-radius: 0 4px 4px 0;
                                    }
                                    &.is-active {
                                        border-color: #2990fb;
                                        pointer-events: none;
                                        > div.img {
                                            &[data-image='text'] {
                                                background-image: url('../i/text--blue.svg');
                                            }
                                            &[data-image='images'] {
                                                background-image: url('../i/images--blue.svg');
                                            }
                                        }
                                    }
                                    > div.img {
                                        margin-right: 10px;
                                        width: 18px;
                                        height: 18px;
                                        background-position: center;
                                        background-repeat: no-repeat;
                                        &[data-image='text'] {
                                            background-image: url('../i/text.svg');
                                        }
                                        &[data-image='images'] {
                                            background-image: url('../i/images.svg');
                                        }
                                    }
                                }
                            }
                        }
                    }
                    div.image-block {
                        &.right-margin {
                            margin-right: 12px;
                        }
                        > div.inner {
                            display: flex;
                            div.img {
                                height: 32px;
                                width: 32px;
                                border-radius: 2px;
                                background-repeat: no-repeat;
                                background-size: cover;
                                background-position: center;
                                margin-right: 8px;
                            }
                            button.select {
                                cursor: pointer;
                                border-radius: 4px;
                                height: 32px;
                                width: 112px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #fff;
                                background-color: #2990fb;
                                &--wide {
                                    width: 100%;
                                }
                                @include transition(0.1s, ease-in-out, 0s, (box-shadow, background-color));
                                &:hover {
                                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
                                    background-color: #69b1fc;
                                }
                            }
                            button.remove {
                                margin-left: 15px;
                                cursor: pointer;
                                height: 32px;
                                width: 20px;
                                background-image: url('../i/remove.svg');
                                background-position: center;
                                background-repeat: no-repeat;
                                @include transition(0.1s, ease-in-out, 0s, (opacity));
                                &:hover {
                                    opacity: 0.8;
                                }
                            }
                        }
                    }
                }
            }
            &--1 {
                display: flex;
                > .workplace {
                    div.image-block {
                        flex-shrink: 0;
                        padding-right: 20px;
                        width: 187px;
                        box-sizing: content-box;
                    }
                    div.input-block {
                        width: 100%;
                    }
                }
                > .preview {
                    flex-shrink: 0;
                    width: 340px;
                    box-sizing: border-box;
                    background-color: #f9fafd;
                    border-left: 1px solid #d8d8d8;
                    overflow: hidden;
                    .scrollbar-inner {
                        padding: 10px 40px 20px;
                        > p.title {
                            font-family: $Roboto;
                            font-size: 14px;
                            color: #787878;
                            margin-bottom: 8px;
                        }
                        > div.content {
                            background-color: #fff;
                            padding-bottom: 8px;
                            overflow: hidden;
                            border-radius: 4px;
                            pointer-events: none;
                            user-select: none;
                            &.is-hidden {
                                opacity: 0.5;
                            }
                            > div.image {
                                > img {
                                    object-fit: cover;
                                    width: 100%;
                                    height: 192px;
                                }
                            }
                            > div.header {
                                margin-top: 16px;
                                font-family: $Roboto;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 19px;
                                text-align: center;
                                color: #000;
                                white-space: pre-line;
                                overflow-wrap: break-word;
                                padding: 0 8px;
                            }
                            > div.description {
                                margin-top: 16px;
                                font-family: $Roboto;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 16px;
                                text-align: center;
                                color: #000;
                                white-space: pre-line;
                                overflow-wrap: break-word;
                                padding: 0 8px;
                            }
                            > div.button {
                                margin: 16px 8px 0;
                                box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
                                height: 30px;
                                color: #fff;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 4px;
                            }
                            > div.image-disclaimer {
                                margin-top: 12px;
                                font-family: $Roboto;
                                font-size: 8px;
                                line-height: 9px;
                                color: #b7b7b7;
                                white-space: pre-line;
                                overflow-wrap: break-word;
                                padding: 0 8px;
                            }

                            > p.empty {
                                font-size: 12px;
                                font-family: $Ubuntu;
                                text-align: center;
                                color: #979797;
                                margin: 50px 0 42px;
                                padding: 0 8px;
                            }
                        }
                    }
                }
            }
            &--2 {
                > .workplace {
                    div.image-block {
                        width: 187px;
                        box-sizing: content-box;
                    }
                    div.input-block {
                        width: 100%;
                    }
                    ul.list {
                        overflow: hidden;
                        margin-bottom: 40px;
                        &.is-full {
                            margin-bottom: 0;
                            > li {
                                &:last-child {
                                    padding-bottom: 0;
                                    border: 0;
                                }
                            }
                        }
                        > li {
                            margin-bottom: 40px;
                            padding-bottom: 40px;
                            border-bottom: 1px solid #d9d9d9;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            div.content {
                                > ul.q-content {
                                    > li {
                                    }
                                }
                                > ul.a-content {
                                    margin-top: 12px;
                                    padding-right: 32px;
                                    > li {
                                        margin-bottom: 24px;
                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                        ul.actions {
                                            margin-left: 8px;
                                            position: absolute;
                                            left: 100%;
                                            height: 42px;
                                            bottom: 0;
                                            align-items: center;
                                        }
                                        > div.input-block {
                                            margin-bottom: 12px;
                                            &:last-child {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &--3 {
                > .workplace {
                    div.image-block {
                        width: 187px;
                        box-sizing: content-box;
                    }
                    ul.list {
                        overflow: hidden;
                        margin-bottom: 40px;
                        &.is-full {
                            margin-bottom: 0;
                        }
                        > li {
                            margin-bottom: 40px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
