@import '../../../../../../../styles/mixins';

.info {
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.0668875);
    border-radius: 4px;
    padding: 32px 60px;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 19px;
    color: #555;
    text-align: center;
    @include max_rwd(620) {
        padding: 30px;
    }
    > a {
        color: #2990fb;
        text-decoration: none;
        transition: opacity 0.2s ease-in-out;
        &:after {
            content: '';
            position: absolute;
            padding-left: 8px;
            width: 20px;
            height: 20px;
            background-image: url('../../i/newTab.svg');
            background-position: center;
            background-repeat: no-repeat;
        }
        &:hover {
            opacity: 0.8;
        }
    }
}
