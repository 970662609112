@mixin transition($transition_duration, $transition_timing_function, $transition_delay, $transition_property) {
    transition-duration: $transition_duration;
    transition-timing-function: $transition_timing_function;
    transition-delay: $transition-delay;
    transition-property: $transition-property;
}

/* Screens */
@mixin min_rwd($screen) {
    @media (min-width: $screen+'px') {
        @content;
    }
}
@mixin max_rwd($screen) {
    @media (max-width: $screen+'px') {
        @content;
    }
}

@mixin max_xs {
    @media (max-width: #{$screen-xs-max}) {
        @content;
    }
}
@mixin min_sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}
@mixin max_sm {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}
@mixin min_md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}
@mixin max_md {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
}
@mixin min_lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}
@mixin max_lg {
    @media (max-width: #{$screen-lg-max}) {
        @content;
    }
}
@mixin min_xl {
    @media (min-width: #{$screen-xl-min} ) {
        @content;
    }
}
@mixin max_xl {
    @media (max-width: #{$screen-xl-max} ) {
        @content;
    }
}
@mixin min_xxl {
    @media (min-width: #{$screen-xxl-min}) {
        @content;
    }
}

@mixin no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin placeholder {
    ::-webkit-input-placeholder {
        @content;
    }
    :-moz-placeholder {
        @content;
    }
    ::-moz-placeholder {
        @content;
    }
    :-ms-input-placeholder {
        @content;
    }
}

@mixin scroll-bar {
    &::-webkit-scrollbar {
        width: 8px;
        background: #eaeaea;
        border-radius: 0;
    }
    &::-webkit-scrollbar-thumb {
        background: #bdbdbd;
        border-radius: 8px;
    }
}
