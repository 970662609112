.rank-battle {
    &__main {
        width: 100%;
        flex-shrink: 0;
        user-select: none;
        &.is-mobile {
            flex-shrink: unset;
        }
        &__inner {
            position: relative;
        }
        &__image-wrapper {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }
        &__image {
            object-position: center top;
            object-fit: cover;
            width: 100%;
            height: 100%;
            pointer-events: none;
            transform: none;
        }
        &__text-wrapper {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            padding: 50px 40px 80px 40px;
            box-sizing: border-box;
            &.is-mobile {
                padding: 30px 30px 110px 30px;
            }
        }
        &__text-inner {
            height: 100%;
            width: 100%;
            display: flex;
            position: relative;
        }
        &__text-block {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
