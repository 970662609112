.pairs-feedback-modal {
    display: flex;

    &__toggle {
        display: flex;
        justify-content: center;
        background: #ffffff;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        height: 52px;
    }

    &__preview {
        display: flex;
        position: relative;
        width: 125px;
        height: 90px;
        margin: 0 10px;

        &-image {
            position: absolute;
            width: 70px;
            height: 70px;
            margin: 0.2rem;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            filter: drop-shadow(0 2px 10px rgba(0, 0, 0, 0.1));
            border-radius: 8px;

            &.back {
                bottom: 35px;
                left: 0;
                background-image: url('../../../i/aston-martin-logo.png');
            }

            &.front {
                top: 0;
                left: 45px;
                background-image: url('../../../i/aston-martin-v12-speedster-2-jpg.-scaled.png');
            }
        }
    }
}
