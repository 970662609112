@import '../../../../../../../../styles/mixins';

.qrBlock {
    padding: 16px 20px 0;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    height: 220px;
    text-align: center;
    box-sizing: border-box;
    width: 220px;
    flex-shrink: 0;
    margin-left: 20px;
    @include max_rwd(767) {
        margin-left: 0;
        margin-top: 12px;
        width: 100%;
    }
}

.qr {
    width: fit-content;
    margin: 18px auto 0;
    cursor: pointer;
}
