@import '../../../../styles/mixins';

.stripeStatusBadge {
    background-color: #f9fafd;
    border-radius: 20px;
    height: 24px;
    padding: 0 8px;
    margin-left: 16px;
    font-size: 12px;
    line-height: 16px;
    color: #626262;
    display: flex;
    align-items: center;
    white-space: nowrap;
    > img {
        margin-right: 6px;
    }
    @include max_rwd(767) {
        position: absolute;
        top: 5px;
        right: 5px;
        margin: 0;
    }
}
