@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';
.quizStatistics {
    font-family: $Roboto;
    font-style: normal;
    color: $color_black;
}
.quizStatisticsWrapper {
    margin-bottom: 60px;
}
.quizStatisticsHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}
.quizStatisticsTitle {
    font-weight: 300;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 40px;
}
.quizStatisticsQuizHeader {
    display: flex;
    flex-direction: column;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
}
.quizStatisticsQuizAnswered {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $color_middle_gray--8;
}
.quizStatisticsBlock {
    margin-bottom: 40px;
}
.quizStatisticsBlockItem {
    margin-bottom: 8px;
}
.quizStatisticsBlockWrapper {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $color_middle_gray--8;
    margin-bottom: 8px;
}
.quizStatisticsBlockHeader {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $color_black;
    margin-bottom: 24px;
}
.quizStatisticsBlockTitle {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50%;
}
.quizStatisticsBlockTitleCorrect {
    color: #86bd73;
    &:after {
        content: '';
        margin-left: 8px;
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #94c184;
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='13' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.81641 5.99995L7.83317 11.8333L16.6236 1.05249' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-size: 10px;
    }
}
.quizStatisticsBlockValues {
    font-weight: 500;
    color: #000000;
    text-align: right;
    > span {
        margin-left: 5px;
        color: $color_light_gray--4;
    }
}

.quizStatisticsTip {
    box-sizing: border-box;
    display: flex;
    width: 342px;
    height: fit-content;
    background: $color_white;
    border: 1px solid $color_blue--1;
    box-shadow: $box-shadow--1;
    border-radius: 4px;
    padding: 20px;

    font-family: $Roboto;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: $color_middle_gray--8;

    > svg {
        flex: none;
    }

    > p {
        margin-left: 12px;
    }
}

.quizStatisticsBlockList {
}
.quizStatisticsLinearProgress {
    height: 12px;
    border-radius: 20px;
    background-color: #f8f8f8;
}
.quizStatisticsLinearProgressBar {
    background-color: #69b1fc;
}
