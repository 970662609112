.custom-translations-modal {
    display: flex;
    flex-direction: column;
    height: 458px;
    max-width: 100%;
    width: 960px;
    text-align: left;
    box-sizing: border-box;
    background-color: #f9fafd;
    overflow: hidden;
    padding: 36px 40px;

    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #2990fb;
    line-height: 16px;

    &__upload {
        background-color: #2990fb;
        color: #fff;
        height: 44px;
        &:hover {
            box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
            background-color: #69b1fc;
            border-color: #69b1fc;
        }
    }

    &__header-block {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    &__info {
        flex-shrink: 0;
        display: flex;
        height: 44px;
        margin-left: 40px;
        align-items: center;
        &-image {
            width: 44px;
            height: 100%;
            border-radius: 50%;
            background-color: #ffffff;
            background-image: url('../../i/tip.svg');
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 6px;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
        }
        &-text {
            line-height: 18px;
            text-decoration: none;
            color: #3c3c3c;
            background: #ffffff;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
            border-radius: 34px;
            padding: 4px 10px;
        }
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -10px;
        .button {
            margin: 0 10px 10px 0;
        }
    }

    &__json-block {
        display: flex;
        overflow: auto;
        flex-direction: column;
        &-viewer {
            padding: 5px 0;
            width: 100%;
        }
    }
}
