.previewBlock {
    position: absolute;
    z-index: 9000;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    width: 260px;
    padding: 40px 34px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #d2d5de;
    background-color: rgba(249, 250, 253, 0.9);
    text-align: center;
    color: #3c3c3c;
    font-size: 14px;
    line-height: 20px;
}

.icon {
    display: inline-block;
    vertical-align: middle;
}

.link {
    padding-left: 2px;
    color: #2990fb;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    &:hover {
        opacity: 0.8;
    }
}
