@import '../../../../../styles/mixins';

.uploadImage {
    padding-bottom: 5px;
}

.label {
    font-size: 16px;
    color: #8a8a8a;
    margin-bottom: 8px;
    height: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.box {
    display: flex;
    align-items: flex-start;
    @include max_rwd(512) {
        flex-direction: column;
    }
}

.leftSide {
    display: flex;
    align-items: center;
}

.button {
    min-width: 112px;
}

.preview {
    width: 32px;
    height: 32px;
    border-radius: 2px;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 8px;
    flex-shrink: 0;
}

.delete {
    cursor: pointer;
    margin-left: 12px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}

.isDisabledDelete {
    pointer-events: none;
    opacity: 0.5;
}

.description {
    margin-left: 32px;
    font-size: 16px;
    line-height: 20px;
    color: #787878;
    white-space: pre-line;
    @include max_rwd(512) {
        margin-left: 0;
        margin-top: 5px;
    }
}

.input {
    display: none;
}
