.helpBox {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #555;
}

.link {
    margin-top: 8px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #2990fb;
    text-decoration: none;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}
