@mixin maxWidth($value) {
    @media (max-width: $value+'px') {
        @content;
    }
}

.subscription-expired-modal__content {
    width: 960px;
    max-width: 100%;
    padding: 40px 20px 60px;
    box-sizing: border-box;
    @include maxWidth(767) {
        padding: 40px 20px;
    }
    > img {
        width: 210px;
        margin-bottom: 22px;
    }
    > .title {
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 28px;
        text-align: center;
        letter-spacing: -1px;
        color: #000;
        max-width: 500px;
        margin: 0 auto 24px;
    }
    > .text {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #3c3c3c;
        max-width: 490px;
        margin: 0 auto 12px;
        b {
            font-weight: 500;
        }
    }
    > .button-container {
        max-width: 460px;
        margin: 28px auto 0;
        > .trial-extend-query {
            margin-top: 14px;
            text-align: center;
            > p {
                font-family: 'Roboto', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #555555;
                margin-bottom: 12px;
            }
            > a {
                font-family: 'Roboto', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                color: #2990fb;
                text-decoration: none;
            }
        }
        > .ok-button {
            color: #ffffff;
            width: 130px;
            height: 45px;
            background-color: #2990fbff;
            padding: 10px 30px;
            border-radius: 4px;
            cursor: pointer;
        }
    }
}
