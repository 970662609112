@import '../../../../styles/variables';

.WebhooksMappingView {
    padding-top: 35px;
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .aside {
        flex: 1 auto;
        padding-bottom: 35px;
        width: 130px;
    }

    .main {
        flex: 35 0;
        margin-right: 25px;
        > * {
            padding-bottom: 10px;
        }
    }

    .tableName {
        color: #3c3c3c;
        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        padding-bottom: 10px;
        text-align: left;
    }

    .list {
        margin-top: 25px;
    }

    .inputWrapper {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;

        .button {
            margin-left: 20px;
            margin-bottom: 5px;
        }
    }
}
