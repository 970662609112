.title {
    font-family: 'Ubuntu';
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #3c3c3c;
    margin-bottom: 16px;
    > span {
        color: #2990fb;
    }
}

.playground {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
}

.placeholder {
    margin-left: -40px;
}

.texts {
    margin-top: 20px;
}

.textTitle {
    font-size: 14px;
    line-height: 16px;
    color: #3c3c3c;
    max-width: 200px;
    margin-bottom: 5px;
}

.textColored {
    color: #2990fb;
    font-size: 12px;
}

.input {
    max-width: 180px;
}

.note {
    margin-top: 15px;
    font-size: 14px;
    line-height: 20px;
    color: #979797;
    max-width: 280px;
}

.noteLink {
    color: #2990fb;
    text-decoration: none;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}
