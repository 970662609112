$s_pos: -6px;

.z_cnt {
    display: inline-block;
    position: relative;
    min-height: 100px;
    background-position: center;
    background-size: cover;
    max-width: 100%;
    > img {
        user-select: none;
        max-width: 100%;
    }
}

.z_pin {
    position: absolute;
    width: 20px;
    height: 20px;
    transform: translate(-11px, -11px);
    border: 2px solid #fff;
    border-radius: 999px;
    background-color: #f06666;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;

    & > img {
        user-select: none;
    }

    &.__sel {
        z-index: 9;
    }

    & .s {
        position: absolute;
        width: 6px;
        height: 6px;
        background-color: #2990fb;
        border: 1px solid #fff;

        &._1 {
            top: $s_pos;
            left: $s_pos;
        }
        &._2 {
            top: $s_pos;
            right: $s_pos;
        }
        &._3 {
            bottom: $s_pos;
            right: $s_pos;
        }
        &._4 {
            bottom: $s_pos;
            left: $s_pos;
        }
    }
}
