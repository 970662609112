.upgrade {
    width: 100%;
    padding: 50px 0;
}

.note {
    font-size: 16px;
    line-height: 24px;
    color: #3c3c3c;
    margin-bottom: 28px;
    text-align: center;
}

.title {
    font-weight: 300;
    font-size: 32px;
    line-height: 37px;
    text-align: center;
    color: #3c3c3c;
    margin-bottom: 28px;
}

.text {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #787878;
    max-width: 580px;
    margin: 0 auto 28px;
}

.upgradeButton {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
}

.image {
    text-align: center;
    > img {
        max-width: 100%;
    }
}
