.common-text-area {
    padding: 5px 0;
    width: 100%;
    font-size: 0;
    position: relative;

    &.common-text-area__disabled {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    }

    .common-text-area__required {
        .common-text-area__label-text:after {
            content: ' *';
            color: #ff5656;
        }
    }

    .common-text-area__label {
        height: 16px;
        margin-bottom: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-size: 14px;
        &-text {
            font-size: inherit;
        }
    }

    .common-text-area__maxLength {
        font-size: 12px;
        color: #8a8a8a;
    }

    .common-text-area__text-area {
        -webkit-appearance: none;
        appearance: none;
        border: 1px solid #979797;
        border-radius: 4px;
        background-color: #ffffff;
        outline: none;
        font-size: 16px;
        box-sizing: border-box;
        width: 100%;
        resize: none;
        line-height: 19px;
        color: #3c3c3c;
        font-family: 'Roboto', sans-serif;
        padding: 5px 12px 6px;

        &::placeholder {
            color: #979797;
        }

        &.is-error {
            border-color: #ff5656;
        }

        &:focus {
            border-color: #69b1fc;
            box-shadow: inset 0 0 0 1px #69b1fc;
        }
    }

    .common-text-area__error {
        position: absolute;
        top: 100%;
        right: 0;
        font-size: 12px;
        color: #ff5656;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
