@mixin maxWidth($value) {
    @media (max-width: $value+'px') {
        @content;
    }
}

.user-role-modal__preloader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
        width: 100%;
        max-width: 150px;
    }
}
.user-role-modal__groups {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 100px 0;
    max-width: 100%;
    @include maxWidth(1019) {
        padding: 50px 0;
        justify-content: center;
        width: 480px;
        margin: auto;
    }
    @include maxWidth(599) {
        width: 400px;
        padding: 20px 0;
    }
    > li {
        width: 120px;
        position: relative;
        cursor: pointer;
        @include maxWidth(1019) {
            padding: 20px;
        }
        &:hover {
            > div.image {
                border-color: #2990fb;
            }
        }
        > div.image {
            width: 100%;
            height: 120px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: #fff;
            box-sizing: border-box;
            border-radius: 8px;
            border: 1px solid #d8d8d8;
            transition: border-color 0.2s ease-in-out;
        }
        > p {
            margin: 8px -30px 0;
            height: 20px;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: #3c3c3c;
        }
    }
}
