.aiGenerationModal {
  width: 540px;
  background-color: #f9fafd;
  overflow: hidden;
  border-radius: 4px;
  padding: 40px;
  box-sizing: border-box;
}

.title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #555;
}

.note {
  font-size: 12px;
  color: #8a8a8a;
}

.inputs {
  margin-bottom: 15px;
}

.cross {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  transition: opacity .2s ease-in-out;
  &:hover {
    opacity: .8;
  }
}

.buttons {
  margin-top: 20px;
}

.button {
  width: 100%;
}