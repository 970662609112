.padding-editor {
    display: flex;
    &__input-box {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        overflow: hidden;
        width: 49px;
        height: 32px;
        padding: 0 7px;
        margin-right: 12px;
        border: 1px solid #d8d8d8;
        border-radius: 4px;

        appearance: none;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        color: #3c3c3c;
        outline: none;

        &:last-child {
            margin-right: 0;
        }

        input {
            flex-grow: 1;
            font-size: 14px;
            border: none;
            outline: none;
            width: 100%;
            text-align: center;

            /* Chrome, Safari, Edge, Opera */
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            &[type='number'] {
                -moz-appearance: textfield;
            }
        }
    }
}
