@import '../../../../../../styles/mixins';

.internal-pages {
    width: 160px;
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    max-height: 100%;
    box-sizing: border-box;
    border-right: 1px solid #d8d8d8;
    text-align: left;
    z-index: 150;
    background-color: #f2f3f7;
    transition: transform 0.2s ease-in-out;
    @include max_rwd(1023) {
        top: 80px;
        max-height: calc(100vh - 80px);
    }
    @include max_rwd(768) {
        transform: translateX(-100%);
        &--opened {
            z-index: 1000;
            transform: translateX(0);
        }
    }

    &__title {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 14px;
        color: #3c3c3c;
        padding: 25px 20px 20px;

        &.is-sticky {
            box-shadow: 0 2px 9px rgba(0, 0, 0, 0.07);
        }
    }
    &__page-list {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
    }
    &__add-button {
        display: flex;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #979797;
        cursor: pointer;
        margin: 20px;

        &:before {
            content: '';
            box-sizing: border-box;
            position: relative;
            margin-right: 11px;
            background-image: url(../../i/add_page.svg);
            width: 14px;
            height: 14px;
        }
        &:hover {
            color: #4891f3;
            &:before {
                background-image: url(../../i/add_page_hover.svg);
            }
        }
    }
}
