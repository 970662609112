.block {
    display: flex;
    justify-content: space-between;
}

.title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #3c3c3c;
    margin-bottom: 8px;
}

.count {
    color: #979797;
    &.error {
        color: #ff5656;
    }
}
