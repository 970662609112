@import '../../../../styles/mixins';
@import '../../../../styles/variables';
.media-grid {
    position: relative;
    margin-top: 24px;
    &__list {
        display: grid;
        grid-template-columns: 120px 120px 120px 120px 120px;
        grid-column-gap: 12px;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 450px;
        margin-right: -32px;
    }

    &__list::-webkit-scrollbar {
        width: 8px;
    }

    &__list::-webkit-scrollbar-thumb {
        background-color: $color_light_gray--2;
        width: 8px;
        border-radius: 9px;
    }
    &__loader {
        height: 40px;
        grid-column: 3/3;
        position: relative;
        > div {
            background-color: transparent;
        }
    }
}
