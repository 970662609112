@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.manage-team-modal {
    width: 640px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    padding: 28px 40px 40px;
    position: relative;
    box-sizing: border-box;
    box-shadow: $box-shadow--4;
    text-align: left;
    @include max_sm {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
    }

    &__title {
        font-family: $Ubuntu;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 24px;
    }

    &__invite-block {
        display: flex;
        margin-bottom: 35px;
        @include max_sm {
            flex-direction: column;
        }
        &-input {
            width: 100%;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            color: #555555;
            outline: none;
            border: 1px solid #979797;
            box-shadow: none;
            margin: 0;
            caret-color: #2990fb;
            height: 44px;
            border-radius: 4px;
            padding: 0 20px;
            box-sizing: border-box;
            &:focus {
                border-color: #2990fb;
            }
        }
        &-button {
            margin-left: 12px;
            flex-shrink: 0;
            min-width: 120px;
            justify-content: center;
            @include max_sm {
                margin: 12px 0 0;
            }
        }
    }

    &__member-list {
        display: flex;
        flex-direction: column;
    }

    &__member {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;

        font-family: $Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: $color_dark_gray--3;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }

        &-info {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            overflow: hidden;
            @include max_sm {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &-name {
            display: flex;
            align-items: center;
            overflow: hidden;
            width: 100%;
        }

        &-title {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        &-icon {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            color: $color_white;
            margin-right: 6px;
            overflow: hidden;
            flex-shrink: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        &-status {
            color: $color_light_gray--2;
            flex-shrink: 0;
            margin-left: 20px;
            @include max_sm {
                margin-top: 5px;
                margin-left: 0;
            }
        }

        &-actions {
            margin-left: 40px;
            flex-shrink: 0;
            @include max_sm {
                margin-left: 20px;
            }
        }
        &-action {
            &.disabled {
                color: $color_light_gray--2;
                pointer-events: none;
                cursor: default;
            }

            &.active {
                color: $color_red--1;
                cursor: pointer;
            }
        }
    }
}
