.button {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    @media (max-width: 567px) {
        flex-direction: column-reverse;
    }
}

.textBox {
    width: 100%;
    padding-right: 40px;
    box-sizing: border-box;
    text-align: left;
    @media (max-width: 567px) {
        margin-top: 15px;
        padding-right: 0;
        text-align: center;
    }
}

.text {
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    color: #2990fb;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.7;
    }
}
