.avatar {
    position: relative;
    flex-shrink: 0;
    width: 140px;
    height: 140px;
    font-weight: 500;
    font-size: 19px;
    color: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid transparent;
    box-sizing: border-box;
}
