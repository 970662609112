@import '../../../../../../../../../../styles/mixins';

.item {
    margin-bottom: 8px;
    width: 100%;
    min-height: 60px;
    box-sizing: border-box;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.2s ease-in-out;
    padding: 12px 12px 12px 20px;
    cursor: pointer;
    &:last-child {
        margin-bottom: 0;
    }
    @include max_rwd(767) {
        flex-direction: column;
    }
}

.isActiveItem {
    cursor: default;
    border-color: #2990fb;
}

.main {
    display: flex;
    align-items: center;
    @include max_rwd(767) {
        width: 100%;
    }
}

.select {
    transition: opacity 0.2s ease-in-out;
    opacity: 0.5;
    @include max_rwd(767) {
        width: 100%;
        margin-top: 10px;
    }
}
.isActiveSelect {
    opacity: 1;
}

.checkbox {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    border: 1px solid #d8d8d8;
    transition: background-color 0.2s ease-in-out;
    &:before {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        background-color: transparent;
        transform: translate(-50%, -50%);
        transition: background-color 0.2s ease-in-out;
    }
}

.isActiveCheckbox {
    border-color: #2990fb;
    &:before {
        background-color: #2990fb;
    }
}

.label {
    margin-left: 12px;
    font-size: 16px;
    line-height: 24px;
    color: #787878;
    transition: color 0.2s ease-in-out;
}

.isActiveLabel {
    color: #2990fb;
}
