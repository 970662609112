@import '../../styles/mixins';

.publicPageStatistic {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 50px 40px;
    box-sizing: border-box;
    @include max_rwd(767) {
        padding: 40px 20px;
    }
}

.title {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 20px;
    color: #555;
    display: flex;
    align-items: center;
}

.cards {
    display: flex;
    margin: 28px -14px 0;
    @include max_rwd(1280) {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
    }
}

.noDateSales {
    margin-top: 100px;
}
.noDatePayouts {
    margin-top: 50px;
}

.tableIconLink {
    padding-left: 8px;
}
.tableIcon {
    cursor: pointer;
    margin-bottom: -4px;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}
.tablePurchasePrice {
    font-size: 16px;
    line-height: 19px;
    color: #3c3c3c;
}
.tablePayoutAmount {
    font-size: 16px;
    line-height: 20px;
    color: #3c3c3c;
}
.tableStatusText {
    font-size: 14px;
    line-height: 20px;
}

.payoutDateSelectBox {
    width: 100%;
    margin-top: 15px;
}
