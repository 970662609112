@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.editor-new {
    .integrations {
        margin: 40px 20px 0;
        padding-bottom: 40px;
        h1 {
            font-family: $Roboto;
            font-size: 24px;
            color: #3c3c3c;
            text-align: center;
            margin-bottom: 40px;
            > a {
                color: #56a9ff;
                text-decoration: underline;
                cursor: pointer;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .integrations-wrap {
            overflow: hidden;
            width: 100%;
            max-width: 1300px;
            margin: 0 auto;
            .cards {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin: -12px;
                &-item {
                    user-select: none;
                    margin: 12px;
                    height: 240px;
                    width: 414px;
                    background-color: #fff;
                    border-radius: 4px;
                    position: relative;
                    padding: 50px 36px 20px;
                    box-sizing: border-box;
                    text-align: center;
                    cursor: pointer;
                    &.is-checked {
                        .switcher {
                            background-color: #2990fb;
                            &:before {
                                border-color: #2990fb;
                                left: calc(100% - 21px);
                            }
                        }
                    }
                    &.is-disabled {
                        cursor: not-allowed;
                    }
                    .switcher {
                        position: absolute;
                        top: 20px;
                        right: 20px;
                        width: 38px;
                        height: 19px;
                        background-color: #979797;
                        border-radius: 9.5px;
                        @include transition(0.1s, ease-in-out, 0s, (background-color));
                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: -1px;
                            width: 21px;
                            height: 21px;
                            background-color: #fff;
                            border-color: #d2d2d2;
                            border-width: 1px;
                            border-style: solid;
                            border-radius: 50%;
                            box-sizing: border-box;
                            @include transition(0.1s, ease-in-out, 0s, (border-color, left));
                        }
                    }
                    .image {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 80px;
                        background-position: center;
                        background-repeat: no-repeat;
                        > img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    > p {
                        margin-top: 20px;
                        text-align: center;
                        font-size: 14px;
                        line-height: 16px;
                        color: #8d8d8d;
                    }
                    > .coming-soon {
                        background-color: #f9fafd;
                        border: 1px solid #f9fafd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        display: inline-block;
                        color: #787878;
                        font-size: 16px;
                        padding: 10px 40px;
                        margin-top: 36px;
                    }
                }
            }
        }
        .settings-window {
            visibility: hidden;
            opacity: 0;
            position: fixed;
            z-index: 10;
            top: 60px;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            @include transition(0.4s, ease-in-out, 0s, (visibility, opacity));
            &.is-active {
                visibility: visible;
                opacity: 1;
                .wrapper {
                    transform: translateX(0);
                }
            }
            .wrapper {
                transform: translateX(100%);
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 600px;
                max-width: calc(100% - 54px);
                box-sizing: border-box;
                @include transition(0.4s, ease-in-out, 0s, (transform));

                .close-btn {
                    position: absolute;
                    right: 100%;
                    top: 0;
                    padding: 15px;
                    cursor: pointer;
                    @include transition(0.1s, ease-in-out, 0s, (opacity));
                    &:hover {
                        opacity: 0.7;
                    }
                }

                .content {
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    background-color: #fff;
                    padding: 40px 80px 80px;
                    overflow-y: auto;
                    @include max_rwd(767) {
                        padding: 40px 20px;
                    }

                    &::-webkit-scrollbar {
                        width: 12px;
                        background: #eaeaea;
                        border-radius: 0;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #bdbdbd;
                        border-radius: 12px;
                    }

                    &__title {
                        font-family: $Roboto;
                        font-size: 20px;
                        color: #3c3c3c;
                        font-weight: normal;
                        margin-bottom: 40px;
                    }

                    &__prompt {
                        background: #ffffff;
                        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
                        border-radius: 8px;
                        padding: 28px 20px 0;
                        margin-bottom: 28px;

                        img {
                            max-width: 100%;
                        }
                    }

                    &__sub-title {
                        font-family: $Roboto;
                        font-size: 16px;
                        line-height: 24px;
                        color: #3c3c3c;
                        font-weight: normal;
                        margin: 20px 0;
                    }

                    &__image {
                        box-shadow: 0 2px 16px rgba(0, 0, 0, 0.12);
                        border-radius: 4px;
                        max-width: 100%;
                    }

                    .input-box {
                        margin-bottom: 36px;
                        .title {
                            font-size: 14px;
                        }
                    }

                    ul {
                        li {
                            font-family: $Roboto;
                            font-size: 14px;
                            line-height: 16px;
                            font-weight: normal;
                            color: #787878;
                            margin-bottom: 16px;
                            a {
                                text-decoration: none;
                                color: #2990fb;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .btns {
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 36px;
                        > a,
                        > button {
                            margin-right: 12px;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }

                    .webhook-btn {
                        margin-top: 16px;
                    }
                }
            }
        }
    }
}
