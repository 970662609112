@import '../../../../../../../../../styles/variables';

.block {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
    @media (max-width: 767px) {
        flex-direction: column;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.image {
    border-radius: 8px;
    overflow: hidden;
    margin-right: 28px;
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    @media (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 15px;
    }
}

.texts {
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: left;
    @media (max-width: 767px) {
        text-align: center;
    }
}

.title {
    font-size: 16px;
    line-height: 24px;
    color: #3c3c3c;
    margin-bottom: 8px;
    @media (max-width: 767px) {
        text-align: center;
    }
}

.article {
    font-size: 14px;
    line-height: 22px;
    color: #3c3c3c;
    a {
        text-decoration: underline;
        color: #2990fb;
        &:hover {
            text-decoration: none;
        }
    }
}
