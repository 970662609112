@import '../../../../../../styles/mixins';
@import '../../../../../../styles/variables';

.chart-card {
    background: #ffffff;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    color: #000000;
    width: 100%;
    margin-bottom: 50px;
    padding: 40px 50px;
    @include max_rwd(1205) {
        .tooltip {
            padding: 5px 10px !important;
        }

        h1 {
            font-size: 29px !important;
        }

        .description {
            font-size: 14px !important;
            line-height: 16px !important;
        }

        .tabs {
            .tab {
                .name {
                    font-size: 14px !important;
                }

                .count {
                    font-size: 25px !important;

                    span {
                        font-size: 17px !important;
                    }
                }
            }
        }
    }
    @include max_rwd(780) {
        width: 264px !important;
        padding: 12px !important;
        overflow: hidden;

        .tooltip {
            padding: 0 7px !important;
            font-size: 10px !important;
        }

        h1 {
            font-size: 20px !important;
            line-height: 23px !important;
            margin-bottom: 17px !important;
        }

        .description {
            margin-top: 16px !important;
        }

        .tabs {
            .tab {
                padding: 8px !important;
                flex-direction: column-reverse;
                height: 70px !important;
                justify-content: flex-end !important;
                margin-right: 0 !important;
                border-width: 1px !important;
                text-align: left !important;
                align-items: flex-start !important;

                &:nth-child(1) {
                    border-radius: 4px 0 0 4px;
                }
                &:nth-child(2) {
                    border-radius: 0;
                    border-left: none;
                }
                &:nth-child(3) {
                    border-radius: 0 4px 4px 0;
                    border-left: none;
                }
                .name {
                    font-size: 11px !important;
                    line-height: 14px !important;
                }

                .count {
                    font-size: 20px !important;
                    line-height: 24px !important;
                }
            }
        }
    }

    h1 {
        font-weight: 300;
        font-size: 32px;
        line-height: 37px;
        margin-bottom: 24px;
        width: 100%;
    }

    .tabs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 -5px;
        .tab {
            height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            box-sizing: border-box;
            border: 1px solid #2990fb;
            border-radius: 4px;
            margin: 0 5px;
            cursor: pointer;
            background: #ffffff;
            width: 100%;
            @include no-select;
            @include transition(
                $transition_duration--1,
                $transition_timing_function--1,
                $transition_delay--1,
                background
            );
            @include max_rwd(1280) {
                flex-direction: column;
                justify-content: center;
                padding: 10px;
            }

            &.is-active {
                background: rgba(41, 144, 251, 0.101016);
                outline: 1px solid #2990fb;
                outline-offset: -2px;
                .count {
                    color: #2990fb !important;
                }
            }

            .label {
                font-weight: normal;
                font-size: 16px;
                line-height: 18px;
                @include max_rwd(1280) {
                    text-align: center;
                }
            }

            .count {
                font-weight: bold;
                font-size: 28px;
                white-space: nowrap;
                padding-left: 20px;
                @include transition(
                    $transition_duration--1,
                    $transition_timing_function--1,
                    $transition_delay--1,
                    color
                );
                @include max_rwd(1280) {
                    padding: 5px 0 0;
                }

                span {
                    font-weight: normal;
                    font-size: 20px;
                    line-height: 20px;
                }
            }
        }
    }

    .description {
        font-size: 15px;
        line-height: 18px;
        color: #626262;
        margin-top: 20px;
        margin-bottom: 30px;
        width: 100%;
    }
}
