.colorPicker {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.chromePicker {
    max-width: 100%;
}

.buttonBox {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
