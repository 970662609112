.modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    height: 72px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    text-align: left;
    position: relative;
    &__note {
        display: flex;
        align-items: center;
        p {
            margin-left: 12px;
            width: 290px;
            font-size: 12px;
            line-height: 16px;
            color: #787878;
        }
    }
    &__actions {
        display: flex;
        button {
            margin-right: 12px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
