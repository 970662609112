.memory-modal-final-screen {
    display: flex;
    height: 100%;
    .memory-modal-final-screen__workplace-wrapper {
        width: 100%;
        box-sizing: border-box;
        background-color: #f9fafd;
        overflow: hidden;
        height: 100%;
        .memory-modal-final-screen__workplace {
            padding: 36px 40px 20px;

            .memory-modal-final-screen__workplace-title {
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                font-family: 'Ubuntu', sans-serif;
                font-weight: 500;
                font-size: 24px;
                color: #3c3c3c;
            }
        }
    }

    .memory-modal-final-screen__preview-wrapper {
        flex-shrink: 0;
        width: 340px;
        box-sizing: border-box;
        background-color: #f9fafd;
        border-left: 1px solid #d8d8d8;
        overflow: hidden;

        .memory-modal-final-screen__preview {
            margin: 10px 40px 20px;
            background-color: #ffffff;

            .memory-modal-final-screen__preview-title {
                font-family: 'Roboto', sans-serif;
                font-size: 14px;
                color: #787878;
                margin-bottom: 8px;
            }

            .memory-modal-final-screen__preview-empty {
                font-size: 12px;
                font-family: 'Ubuntu', sans-serif;
                text-align: center;
                color: #979797;
                margin: 50px 0 42px;
                padding: 0 8px;
            }

            .memory-modal-final-screen__preview-content {
                font-family: 'Roboto', sans-serif;
                font-weight: 500;
                text-align: center;
                white-space: pre-line;
                overflow-wrap: break-word;

                .memory-modal-final-screen__preview-header {
                    margin-top: 16px;
                    font-size: 16px;
                    line-height: 19px;
                    color: #000;
                    padding: 0 8px;
                }

                .memory-modal-final-screen__preview-description {
                    margin-top: 16px;
                    font-size: 12px;
                    line-height: 16px;
                    color: #000;
                    padding: 0 8px;
                }

                .memory-modal-final-screen__preview-image {
                    object-fit: cover;
                    width: 100%;
                    height: 192px;
                }

                .memory-modal-final-screen__preview-image-disclaimer {
                    text-align: left;
                    margin-top: 12px;
                    font-family: 'Roboto', sans-serif;
                    font-size: 8px;
                    line-height: 9px;
                    color: #b7b7b7;
                    padding: 0 8px 8px;
                }
            }
        }
    }
}
