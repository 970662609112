@import '../../../../styles/mixins';

.info {
    width: 100%;
    max-width: 280px;
    //@include max_rwd(1080) {
    //    max-width: 100%;
    //    text-align: center;
    //}
}

.title {
    font-size: 14px;
    line-height: 20px;
    color: #8a8a8a;
}

.link {
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    color: #2990fb;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}
