@import '../../../../../../styles/mixins';

.list {
    max-height: 100%;
    overflow: auto;
    padding: 28px 170px 28px 60px;
    @include max_rwd(767) {
        padding-left: 20px;
        padding-right: 20px;
    }
}
