@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/mixins';

.leadInfoModal {
    display: flex;
    flex-direction: column;
    width: 640px;
    height: 910px;
    background: $color_white;
    box-shadow: $box-shadow--4;
    border-radius: 4px;

    font-family: $Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color_dark_gray--3;
    text-align: left;
}

.leadInfoModalHeader {
    padding: 28px 40px 16px;
    border-bottom: 1px solid $color_light_gray--2;

    font-family: $Ubuntu;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
}

.leadInfoModalBody {
    display: flex;
    flex-direction: column;
    margin: 20px 8px 40px 40px;
    padding-right: 32px;
    overflow-y: auto;
    @include scroll-bar();
}

.leadInfoModalTimeStamp {
    margin-bottom: 20px;

    color: $color_middle_gray--2;
}

.leadInfoModalFields {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
}
.leadInfoModalField {
    margin-bottom: 8px;
}

.leadInfoModalFieldDescription {
    color: $color_middle_gray--2;
}

.leadInfoModalBlockTitle {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 12px;
}

.leadInfoModalButton {
    display: flex;
    background: $color_light_gray--3;
    border-radius: 4px;
    padding: 6px;
    cursor: pointer;
}
