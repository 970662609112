.tile {
    position: absolute;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    background-repeat: no-repeat;
    font-size: 0;
    border-radius: 4px;
}

.isHiddenTile {
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
}

.inner {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.number {
    position: relative;
}
