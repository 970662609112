.modal {
    &-overlay {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 20px;
        display: flex;
        align-items: flex-start;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 1111;
        &.preview-style {
            padding-top: 80px;
            background-color: rgba(0, 0, 0, 0.8);
            &:before {
                content: '';
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 320px;
                z-index: 999;
                background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
            }
            @include max_sm {
                padding-top: 120px;
            }
        }
    }
    &-content {
        background-color: #fff;
        border-radius: 4px;
        overflow: hidden;
        padding: 48px 40px 28px;
        position: relative;
        box-sizing: border-box;
        max-width: 100%;
        width: 100%;
        box-shadow: $box-shadow--4;
        z-index: 1001;
    }
    &-close-icon {
        display: none;
    }
    &-close {
        display: none;
        &--custom {
            cursor: pointer;
            position: absolute;
            z-index: 20;
            width: 15px;
            height: auto;
            top: 20px;
            right: 20px;
            outline: none;
            > svg {
                width: 100%;
                height: auto;
                path.color_stroke {
                    @include transition(
                        $transition_duration--1,
                        $transition_timing_function--1,
                        $transition_delay--1,
                        (stroke)
                    );
                }
                path.color_stroke--1 {
                    stroke: $color_dark_gray--1;
                }
            }

            &.sidebar-style {
                top: 14px;
                right: 14px;
                > svg path.color_stroke--1 {
                    stroke: $color_middle_gray--2 !important;
                }
            }

            &:hover {
                > svg {
                    path.color_stroke--1 {
                        stroke: $color_blue--1 !important;
                    }
                }
            }
        }
    }

    &-content {
        &.share-style {
            padding: 0;
            .modal-close--custom {
                top: 24px;
                @include transition(
                    $transition_duration--1,
                    $transition_timing_function--1,
                    $transition_delay--1,
                    (opacity)
                );
                &:hover {
                    opacity: 0.7;
                }
                path.color_stroke--1 {
                    stroke: $color_white !important;
                }
            }
            .head {
                position: relative;
                &-v1,
                &-v3 {
                    height: 60px;
                    background-color: $color_blue--1;
                    padding: 0 32px;
                    display: flex;
                    align-items: center;
                    font-family: $Ubuntu;
                    font-weight: 500;
                    font-size: 24px;
                    color: $color_white;
                }
                &-v3 {
                    justify-content: center;
                    padding: 0;
                    .back-btn {
                        cursor: pointer;
                        font-size: 16px;
                        font-weight: normal;
                        display: flex;
                        align-items: center;
                        position: absolute;
                        left: 32px;
                        top: 50%;
                        transform: translateY(-50%);
                        &:before {
                            content: '';
                            display: inline-block;
                            height: 20px;
                            width: 12px;
                            margin-right: 8px;
                            background-image: url('../../images/png/share/back.png');
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                    }
                }
                &-v2 {
                    height: 160px;
                    background-color: $color_blue--1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: $Ubuntu;
                    font-weight: 500;
                    font-size: 32px;
                    color: $color_white;
                    &:before {
                        content: '';
                        position: absolute;
                        height: 100%;
                        background-image: url('../../images/png/share/seeds.png');
                        opacity: 0.3;
                        left: 50px;
                        right: 50px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }
            }
            .wrap {
                min-height: 200px;
                display: flex;
                background-color: $color_light_gray--3;
                aside {
                    min-height: 100%;
                    flex-shrink: 0;
                    width: 232px;
                    background-color: $color_white;
                    padding: 16px 12px 20px 32px;
                    margin-bottom: 72px;
                    box-sizing: border-box;
                    ul {
                        padding-right: 20px;
                        overflow-y: auto;
                        height: 100%;
                        &::-webkit-scrollbar {
                            width: 8px;
                        }
                        &::-webkit-scrollbar-thumb {
                            background-color: $color_light_gray--2;
                            width: 8px;
                            border-radius: 9px;
                        }
                        li {
                            font-size: 16px;
                            color: $color_dark_gray--1;
                            cursor: pointer;
                            padding: 20px 0 19px;
                            box-sizing: border-box;
                            border-bottom: 1px solid $color_light_gray--6;
                            @include transition(
                                $transition_duration--1,
                                $transition_timing_function--1,
                                $transition_delay--1,
                                (color)
                            );
                            &:last-child {
                                border: 0;
                                padding-bottom: 20px;
                            }
                            &:hover,
                            &.is-active {
                                color: $color_blue--1;
                            }
                        }
                    }
                }
                .body {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 36px 156px 126px 124px;
                    min-height: 532px;
                    &.with-menu {
                        padding: 0 0 40px 40px;
                        margin-right: 8px;
                        margin-bottom: 72px;
                        margin-top: 36px;
                    }
                    &-page {
                        padding-top: 40px;
                        padding-bottom: 80px;
                        .title {
                            font-size: 20px;
                            line-height: 23px;
                            color: $color_middle_gray--1;
                            text-align: center;
                            width: 300px;
                            margin: 0 auto 24px;
                        }
                        .contact {
                            margin-top: 50px;
                            text-align: center;
                            font-size: 16px;
                            color: $color_dark_gray--3;
                            a {
                                text-decoration: none;
                                color: $color_blue--1;
                            }
                        }
                        .blocks .block {
                            margin-bottom: 20px !important;
                        }
                    }
                    .blocks {
                        .block {
                            margin-bottom: 32px;
                            font-size: 0;
                            &:last-child {
                                margin-bottom: 0 !important;
                            }
                            &-title {
                                margin-bottom: 12px;
                                font-size: 16px;
                                color: $color_dark_gray--1;
                            }
                            &-content {
                                .cards {
                                    display: flex;
                                    justify-content: space-between;
                                    &-item {
                                        position: relative;
                                        width: 220px;
                                        height: 220px;
                                        padding: 16px 23px 30px;
                                        box-sizing: border-box;
                                        border: 1px solid $color_light_gray--2;
                                        border-radius: 4px;
                                        background-color: $color_white;
                                        box-shadow: $box-shadow--2;
                                        @include transition(
                                            $transition_duration--1,
                                            $transition_timing_function--1,
                                            $transition_delay--1,
                                            (border-color, box-shadow)
                                        );
                                        &:last-child {
                                            margin-right: 0;
                                        }
                                        &.disabled {
                                            background-color: transparent;
                                            box-shadow: none;
                                            cursor: not-allowed;
                                        }
                                        &.active {
                                        }
                                        &:hover:not(.disabled) {
                                            cursor: pointer;
                                            border-color: $color_middle_gray--2;
                                        }
                                        .title {
                                            text-align: center;
                                            font-size: 16px;
                                            line-height: 19px;
                                            color: $color_dark_gray--1;
                                            margin-bottom: 16px;
                                        }
                                        .box {
                                            text-align: center;
                                            height: 120px;
                                            img {
                                                max-width: 100%;
                                                max-height: 100%;
                                            }
                                            &.box-many {
                                                padding-top: 14px;
                                                img {
                                                    margin: 0 6px;
                                                    &:first-child {
                                                        margin-left: 0;
                                                    }
                                                    &:last-child {
                                                        margin-right: 0;
                                                    }
                                                }
                                            }
                                        }
                                        .fake-btn {
                                            position: absolute;
                                            bottom: 12px;
                                            left: 12px;
                                            width: calc(100% - 24px);
                                            height: 44px;
                                            font-size: 16px;
                                            color: $color_dark_gray--1;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            border: 1px solid $color_light_gray--2;
                                            box-sizing: border-box;
                                            border-radius: 4px;
                                            background-color: $color_light_gray--3;
                                        }
                                    }
                                }
                                .url-zone {
                                    display: flex;
                                    justify-content: space-between;
                                    .input-box {
                                        width: 100%;
                                        input[type='text'] {
                                            height: 44px;
                                        }
                                    }
                                    .btns {
                                        display: flex;
                                        flex-shrink: 0;
                                        > button {
                                            margin-left: 12px;
                                        }
                                    }
                                }
                                .code {
                                    display: flex;
                                    justify-content: space-between;
                                    .textarea-box {
                                        width: 100%;
                                    }
                                    button {
                                        flex-shrink: 0;
                                        margin-left: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
                .bottom-bar {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 40px;
                    height: 72px;
                    box-sizing: border-box;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    background-color: $color_white;
                    border-radius: 4px;
                    box-shadow: $box-shadow--4;
                    .left {
                        display: flex;
                        align-items: center;
                        .icon {
                            > svg {
                                path.color_stroke--1,
                                line.color_stroke--1 {
                                    stroke: $color_blue--1 !important;
                                }
                                circle.color_fill--1 {
                                    fill: $color_blue--1 !important;
                                }
                            }
                        }
                        p {
                            margin-left: 12px;
                            width: 290px;
                            font-size: 12px;
                            line-height: 16px;
                            color: $color_dark_gray--1;
                        }
                    }
                    .right {
                        display: flex;
                        button {
                            margin-right: 12px;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .wrap-custom-project-id {
                width: 760px;
                margin: 0 auto;
                padding: 90px 0 40px;
                > h2 {
                    font-family: $Roboto;
                    font-weight: normal;
                    color: $color_middle_gray--1;
                    font-size: 20px;
                    margin-bottom: 8px;
                    text-align: center;
                }
                > p {
                    font-family: $Roboto;
                    font-weight: normal;
                    text-align: center;
                    font-size: 16px;
                    color: $color_dark_gray--1;
                    margin-bottom: 33px;
                }
                > .input-place {
                    margin-bottom: 26px;
                    width: 100%;
                    height: 76px;
                    padding: 0 16px;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: 1px solid $color_middle_gray--2;
                    border-radius: 4px;
                    &.focus {
                        border: 1px solid $color_light_blue--1;
                    }
                    &.free {
                        border: 1px solid $color_green--1;
                    }
                    &.already_taken {
                        border: 1px solid $color_light_red--2;
                    }
                    &.invalid {
                        border: 1px solid $color_light_red--2;
                    }
                    > .side {
                        &--left {
                            width: 100%;
                            display: flex;
                            padding-right: 24px;
                            align-items: center;
                            .placeholder {
                                font-family: $Roboto;
                                font-weight: 300;
                                font-size: 18px;
                                color: $color_dark_gray--3;
                                letter-spacing: -0.24px;
                                line-height: 20px;
                                height: auto;
                                background-color: transparent;
                            }
                            > input {
                                font-family: $Roboto;
                                font-weight: 300;
                                border: 0;
                                font-size: 18px;
                                color: $color_light_blue--1;
                                padding: 0 0 0 4px;
                                line-height: 20px;
                                height: auto;
                                width: 100%;
                                &::placeholder {
                                    color: $color_light_gray--2;
                                }
                                &:focus {
                                    caret-color: $color_light_blue--1;
                                    outline: none;
                                }
                            }
                        }
                        &--right {
                            flex-shrink: 0;
                            > div {
                                font-family: $Roboto;
                                font-weight: normal;
                                font-size: 16px;
                                background-repeat: no-repeat;
                                background-position: 0 50%;
                                padding: 5px 0;
                                &.available {
                                    padding-left: 28px;
                                    background-image: url('../../svg/smile_ok.svg');
                                    color: $color_green--1;
                                }
                                &.unavailable {
                                    padding-left: 28px;
                                    background-image: url('../../svg/smile_bad.svg');
                                    color: $color_light_red--2;
                                }
                                &.invalid {
                                    color: $color_light_red--2;
                                }
                            }
                        }
                    }
                }
                > .note {
                    font-family: $Roboto;
                    font-weight: normal;
                    text-align: center;
                    font-size: 16px;
                    line-height: 24px;
                    color: $color_dark_gray--1;
                    margin-bottom: 12px;
                }
                > ul.allowed {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 78px;
                    > li {
                        font-family: $Roboto;
                        font-weight: normal;
                        border: 1px solid $color_light_gray--2;
                        padding: 6px 10px 7px;
                        border-radius: 4px;
                        color: $color_dark_gray--1;
                        font-size: 20px;
                        margin-right: 8px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                .buttons {
                    display: flex;
                    justify-content: center;
                    > button {
                        width: 122px;
                        justify-content: center;
                        margin-right: 12px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            &.share-options-style {
                .wrap {
                    max-height: 540px;
                    .body {
                        min-height: 100%;
                        &.with-menu {
                            padding-bottom: 0;
                            .blocks-options {
                                li {
                                    &:last-child {
                                        margin-bottom: 40px;
                                    }
                                }
                            }
                        }
                        .blocks-options {
                            overflow-y: auto;
                            height: 100%;
                            padding-right: 32px;
                            &::-webkit-scrollbar {
                                width: 8px;
                            }
                            &::-webkit-scrollbar-thumb {
                                background-color: $color_light_gray--2;
                                width: 8px;
                                border-radius: 9px;
                            }
                            li {
                                margin-bottom: 40px;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                                > .title {
                                    font-family: $Ubuntu;
                                    font-weight: 500;
                                    font-size: 24px;
                                    margin-bottom: 24px;
                                    color: $color_dark_gray--3;
                                }
                                .box {
                                    display: flex;
                                    .preview-block {
                                        width: 296px;
                                        flex-shrink: 0;
                                        .window {
                                            width: 100%;
                                            border-radius: 4px;
                                            background-color: $color_white;
                                            border: 1px solid $color_light_gray--2;
                                            .image {
                                                height: 155px;
                                                background-color: $color_light_gray--2;
                                                background-position: center;
                                                background-repeat: no-repeat;
                                                background-size: cover;
                                                &.is-loading {
                                                    background-size: 200px;
                                                    background-color: $color_white;
                                                    background-image: url('../../images/preloader.gif') !important;
                                                }
                                            }
                                            .text {
                                                height: 45px;
                                                display: flex;
                                                justify-content: center;
                                                padding: 0 12px;
                                                flex-direction: column;
                                                .title {
                                                    font-size: 14px;
                                                    color: $color_dark_gray--3;
                                                    font-weight: bold;
                                                    padding-bottom: 4px;
                                                    white-space: nowrap;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    &-empty {
                                                        width: 64px;
                                                        height: 7px;
                                                        background: $color_middle_gray--5;
                                                        border-radius: 16px;
                                                    }
                                                }
                                                .description {
                                                    font-size: 12px;
                                                    color: $color_middle_gray--4;
                                                    white-space: nowrap;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    &-empty {
                                                        width: 100%;
                                                        height: 7px;
                                                        background: $color_middle_gray--5;
                                                        border-radius: 16px;
                                                    }
                                                }
                                            }
                                        }
                                        .upload {
                                            display: flex;
                                            align-items: center;
                                            margin-top: 16px;
                                            > p {
                                                cursor: pointer;
                                                margin-left: 14px;
                                                display: inline-block;
                                                font-size: 14px;
                                                color: $color_blue--1;
                                                @include transition(
                                                    $transition_duration--1,
                                                    $transition_timing_function--1,
                                                    $transition_delay--1,
                                                    (opacity)
                                                );
                                                &:hover {
                                                    opacity: 0.7;
                                                }
                                            }
                                        }
                                        .note {
                                            margin-top: 10px;
                                            color: $color_dark_gray--1;
                                            font-size: 12px;
                                            line-height: 14px;
                                        }
                                    }
                                    .info-block {
                                        width: 100%;
                                        margin-left: 32px;
                                        .inputs-block {
                                            > li {
                                                margin-bottom: 16px;
                                                &:last-child {
                                                    margin-bottom: 0;
                                                }
                                                .title {
                                                    font-family: $Roboto;
                                                    width: 100%;
                                                    position: relative;
                                                    font-size: 14px;
                                                    color: $color_dark_gray--1;
                                                    margin-bottom: 6px;
                                                    font-weight: normal;
                                                    // padding-right: 70px;
                                                    box-sizing: border-box;
                                                    > span {
                                                        position: absolute;
                                                        bottom: 0;
                                                        right: 0;
                                                        font-size: 12px;
                                                        color: $color_dark_gray--1;
                                                    }
                                                }
                                                textarea {
                                                    padding: 12px;
                                                    &:focus {
                                                        padding: 11px;
                                                    }
                                                    &::-webkit-scrollbar {
                                                        width: 8px;
                                                    }
                                                    &::-webkit-scrollbar-thumb {
                                                        background-color: $color_light_gray--2;
                                                        width: 8px;
                                                        border-radius: 9px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.langs-style {
            padding: 30px 24px;
            background-color: $color_black;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 0;
            .modal-close--custom {
                display: none;
            }
        }
        &.sidebar-style {
            padding: 40px 0 28px;
            border: 1px solid $color_white;
            border-radius: 4px;
        }
        &.preview-style {
            background-color: transparent;
            box-shadow: none;
            padding: 0;
            border-radius: 0;
            width: auto;
            .modal-close--custom {
                display: none;
            }
            .top-panel {
                padding: 0 40px;
                box-sizing: border-box;
                z-index: 999;
                height: 60px;
                background-color: $color_black;
                position: fixed;
                display: flex;
                align-items: center;
                justify-content: space-between;
                top: 0;
                left: 0;
                width: 100%;
                @include max_rwd(1299) {
                    padding: 0 20px;
                }
                > div,
                > ul {
                    width: 40%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    &.name {
                        font-size: 16px;
                        color: $color_white;
                    }
                    &.device {
                        width: 20%;
                        justify-content: center;
                        @include max_sm {
                            position: absolute;
                            left: 50%;
                            top: 100%;
                            transform: translateX(-50%);
                            height: 40px;
                            width: 100%;
                            background-color: $color_black;
                            border-radius: 0 0 8px 8px;
                            padding-bottom: 5px;
                        }
                        > li {
                            margin-right: 30px;
                            cursor: pointer;
                            &:last-child {
                                margin-right: 0;
                            }
                            @include max_sm {
                                margin-right: 20px;
                            }
                            &.desktop {
                                > svg path.color_stroke--1,
                                line.color_stroke--1 {
                                    stroke: $color_white;
                                }
                            }
                            &.mobile {
                                > svg rect.color_stroke--1,
                                line.color_stroke--1 {
                                    stroke: $color_white;
                                }
                            }
                            &.is-active {
                                cursor: default;
                                pointer-events: none;
                                &.desktop {
                                    > svg path.color_stroke--1,
                                    line.color_stroke--1 {
                                        stroke: $color_light_blue--1;
                                    }
                                }
                                &.mobile {
                                    > svg rect.color_stroke--1,
                                    line.color_stroke--1 {
                                        stroke: $color_light_blue--1;
                                    }
                                }
                            }
                        }
                    }
                    &.actions {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        > p {
                            font-size: 16px;
                            color: $color_white;
                            margin-right: 20px;
                            @include max_rwd(1299) {
                                display: none;
                            }
                        }
                        > img {
                            width: 30px;
                            height: auto;
                            margin-right: 20px;
                            @include max_rwd(1299) {
                                display: none;
                            }
                        }
                        > button {
                            margin-right: 55px;
                            min-width: 120px;
                            justify-content: center;
                            @include max_rwd(1299) {
                                margin-right: 30px;
                            }
                        }
                        > .close {
                            cursor: pointer;
                            width: 25px;
                            height: 25px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            > svg {
                                path.color_stroke--1 {
                                    @include transition(
                                        $transition_duration--1,
                                        $transition_timing_function--1,
                                        $transition_delay--1,
                                        (stroke)
                                    );
                                }
                                path.color_stroke--1 {
                                    stroke: $color_white !important;
                                }
                            }
                            &:hover {
                                > svg {
                                    path.color_stroke--1 {
                                        stroke: $color_blue--1 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .iframe_wrapper {
                background-color: $color_white;
                position: relative;
                max-width: 100%;
                &.desktop {
                    width: 800px;
                    min-height: 600px;
                    > .iframe_inner {
                        position: relative;
                    }
                }
                &.mobile {
                    border: 44px solid #ffffff;
                    border-left-width: 8px;
                    border-right-width: 8px;
                    border-radius: 20px;
                    width: 320px;
                    height: 560px;
                    &:before {
                        content: '';
                        position: absolute;
                        top: -29px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 14px;
                        height: 14px;
                        background-color: $color_light_gray--2;
                        border-radius: 50%;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: -24px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 60px;
                        height: 8px;
                        background-color: $color_light_gray--2;
                        border-radius: 12px;
                    }
                }

                > .iframe_inner {
                    overflow-x: hidden;
                    overflow-y: auto;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    -ms-overflow-style: none; /* Internet Explorer 10+ */
                    scrollbar-width: none; /* Firefox */
                    &::-webkit-scrollbar {
                        display: none; /* Safari and Chrome */
                    }
                    > .remix_cnt > a {
                        display: none;
                    }
                }
            }
        }
        &.personality-style {
            padding: 0;
            .modal-close--custom {
                top: 24px;
                @include transition(
                    $transition_duration--1,
                    $transition_timing_function--1,
                    $transition_delay--1,
                    (opacity)
                );
                &:hover {
                    opacity: 0.7;
                }
                path.color_stroke--1 {
                    stroke: $color_white !important;
                }
            }
            .head {
                height: 60px;
                background-color: $color_blue--1;
                padding: 0 32px;
                display: flex;
                align-items: center;
                font-family: $Ubuntu;
                font-weight: 500;
                font-size: 24px;
                color: $color_white;
            }
            > .local-loading {
                height: 612px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .lds-ellipsis {
                    display: inline-block;
                    position: relative;
                    width: 80px;
                    height: 13px;
                    div {
                        position: absolute;
                        top: 0;
                        width: 13px;
                        height: 13px;
                        border-radius: 50%;
                        background: $color_blue--1;
                        animation-timing-function: cubic-bezier(0, 1, 1, 0);
                        &:nth-child(1) {
                            left: 8px;
                            animation: lds-ellipsis1 0.6s infinite;
                        }
                        &:nth-child(2) {
                            left: 8px;
                            animation: lds-ellipsis2 0.6s infinite;
                        }
                        &:nth-child(3) {
                            left: 32px;
                            animation: lds-ellipsis2 0.6s infinite;
                        }
                        &:nth-child(4) {
                            left: 56px;
                            animation: lds-ellipsis3 0.6s infinite;
                        }
                    }
                }
            }
            .wrap {
                display: flex;
                height: 540px;
                padding: 14px 0;
                box-sizing: border-box;
                .title {
                    font-family: $Ubuntu;
                    font-size: 20px;
                    font-weight: 500;
                    color: $color_dark_gray--3;
                    margin-bottom: 8px;
                }
                .description {
                    font-family: $Roboto;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 18px;
                    color: $color_dark_gray--1;
                    margin-bottom: 20px;
                }
                .slide-preview {
                    width: 100%;
                    margin-bottom: 20px;
                    > p {
                        width: 100%;
                        text-align: center;
                        box-sizing: border-box;
                        color: #000;
                        font-size: 16px;
                        padding-bottom: 10px;
                    }
                    > div.img {
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        height: 256px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 20px;
                        border-radius: 4px;
                        border: 1px solid #000;
                        box-sizing: border-box;
                        > span {
                            background-color: #000;
                            color: #fff;
                            text-align: center;
                            padding: 5px;
                            font-family: $Ubuntu;
                            font-size: 14px;
                            line-height: 18px;
                        }
                    }
                    > div.text {
                        font-size: 14px;
                        line-height: 18px;
                        text-align: center;
                        color: #000;
                    }
                }

                > div {
                    position: relative;
                    &.left-divider {
                        &:before {
                            content: '';
                            position: absolute;
                            top: -14px;
                            left: 0;
                            bottom: -14px;
                            width: 1px;
                            background-color: $color_light_gray--2;
                        }
                    }
                    > .loading {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                    .local-loading {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .lds-ellipsis {
                            display: inline-block;
                            position: relative;
                            width: 80px;
                            height: 13px;
                            div {
                                position: absolute;
                                top: 0;
                                width: 13px;
                                height: 13px;
                                border-radius: 50%;
                                background: $color_blue--1;
                                animation-timing-function: cubic-bezier(0, 1, 1, 0);
                                &:nth-child(1) {
                                    left: 8px;
                                    animation: lds-ellipsis1 0.6s infinite;
                                }
                                &:nth-child(2) {
                                    left: 8px;
                                    animation: lds-ellipsis2 0.6s infinite;
                                }
                                &:nth-child(3) {
                                    left: 32px;
                                    animation: lds-ellipsis2 0.6s infinite;
                                }
                                &:nth-child(4) {
                                    left: 56px;
                                    animation: lds-ellipsis3 0.6s infinite;
                                }
                            }
                        }
                    }
                    > .body {
                        height: 100%;
                        overflow-y: auto;
                        box-sizing: border-box;
                        &::-webkit-scrollbar {
                            width: 8px;
                            border-right: 7px solid $color_white;
                        }
                        &::-webkit-scrollbar-thumb {
                            background-color: $color_light_gray--16;
                            width: 8px;
                            border-radius: 8px;
                        }
                    }
                    &.slides {
                        flex-shrink: 0;
                        width: 104px;
                        > .body {
                            padding: 10px 0;
                            > ul {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                > li {
                                    cursor: pointer;
                                    margin: 0;
                                    width: 48px;
                                    height: 38px;
                                    border: 1px solid transparent;
                                    border-radius: 2px;
                                    padding: 4px;
                                    box-sizing: border-box;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    &.is-active {
                                        cursor: default;
                                        border-color: $color_blue--1;
                                    }
                                    > .box {
                                        width: 100%;
                                        height: 100%;
                                        background-position: center;
                                        background-repeat: no-repeat;
                                        background-size: cover;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border-radius: 2px;
                                        border: 1px solid #000;
                                        box-sizing: border-box;
                                        > span {
                                            font-family: $Ubuntu;
                                            font-size: 12px;
                                            color: #fff;
                                            background-color: #000;
                                            padding: 2px;
                                            border-radius: 2px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.answers {
                        width: 430px;
                        flex-shrink: 0;
                        > .body {
                            width: 100%;
                            padding: 10px 40px;
                            > ul {
                                box-sizing: border-box;
                                display: flex;
                                flex-direction: column;
                                > li {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 10px;
                                    background-color: $color_white;
                                    box-shadow: $box-shadow--2;
                                    border-radius: 4px;
                                    padding: 8px 60px 8px 18px;
                                    box-sizing: border-box;
                                    min-height: 48px;
                                    position: relative;
                                    border: 2px solid transparent;
                                    cursor: pointer;
                                    overflow: hidden;
                                    position: relative;
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                    &:after {
                                        content: '';
                                        position: absolute;
                                        z-index: 1;
                                        right: 0;
                                        top: 0;
                                        width: 60px;
                                        height: 100%;
                                        background-color: $color_white;
                                    }
                                    &.is-active {
                                        cursor: default;
                                        border-color: $color_blue--1;
                                    }
                                    &.text {
                                        font-size: 16px;
                                        line-height: 16px;
                                        color: $color_dark_gray--1;
                                    }
                                    &.img {
                                        img {
                                            max-height: 60px;
                                            max-width: 100%;
                                            border-radius: 4px;
                                        }
                                    }

                                    .linked {
                                        position: absolute;
                                        z-index: 3;
                                        top: 4px;
                                        right: 4px;
                                        width: 36px;
                                        height: 36px;
                                        > svg {
                                            rect.color_stroke--1,
                                            path.color_stroke--1 {
                                                stroke: $color_middle_gray--2 !important;
                                            }
                                        }
                                    }
                                    &.is-active .linked {
                                        > svg {
                                            rect.color_stroke--1,
                                            path.color_stroke--1 {
                                                stroke: $color_blue--1 !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.results {
                        width: 426px;
                        flex-shrink: 0;
                        > .body {
                            width: 100%;
                            padding: 10px 80px;
                            > ul {
                                display: flex;
                                flex-direction: column;
                                > li {
                                    position: relative;
                                    margin-bottom: 16px;
                                    &.with-probability {
                                        margin-bottom: 32px;
                                    }
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                    &:after {
                                        content: '';
                                        position: absolute;
                                        z-index: 1;
                                        bottom: 1px;
                                        left: 1px;
                                        right: 1px;
                                        height: 45px;
                                        border-radius: 0 0 4px 4px;
                                        background: linear-gradient(360deg, #f9fafd 0%, rgba(249, 250, 253, 0) 100%);
                                    }
                                    > .percentage {
                                        position: absolute;
                                        top: -15px;
                                        left: 0;
                                        color: #3c3c3c;
                                        display: flex;
                                        align-items: center;
                                        font-size: 10px;
                                        justify-content: center;
                                        width: 100%;
                                        > img {
                                            flex-shrink: 0;
                                            padding-right: 8px;
                                            width: 14px;
                                        }
                                    }
                                    > .box {
                                        border-radius: 4px;
                                        height: 140px;
                                        width: 100%;
                                        overflow: hidden;
                                        background-position: center;
                                        background-repeat: no-repeat;
                                        background-size: cover;
                                        position: relative;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        padding: 0 20px;
                                        box-sizing: border-box;
                                        border: 1px solid #000;
                                        &.with-image {
                                            border: 0;
                                        }
                                        > div {
                                            text-align: center;
                                            > span {
                                                display: inline-block;
                                                font-family: $Ubuntu;
                                                font-size: 12px;
                                                color: #fff;
                                                background-color: #000;
                                                padding: 4px;
                                                border-radius: 4px;
                                                margin-bottom: 5px;
                                            }
                                            > p {
                                                font-size: 12px;
                                                color: #fff;
                                                background-color: #000;
                                                padding: 4px;
                                                border-radius: 4px;
                                            }
                                        }
                                        //&:before {
                                        //    content: '';
                                        //    position: absolute;
                                        //    z-index: 2;
                                        //    bottom: 0;
                                        //    left: 0;
                                        //    width: 100%;
                                        //    height: 30px;
                                        //    background: linear-gradient(
                                        //        360deg,
                                        //        #f9fafd 0%,
                                        //        rgba(249, 250, 253, 0) 100%
                                        //    );
                                        //}
                                    }
                                    .linked {
                                        position: absolute;
                                        top: 8px;
                                        right: 8px;
                                        width: 36px;
                                        height: 36px;
                                        background-color: $color_white;
                                        border-radius: 50%;
                                        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
                                        > svg {
                                            rect.color_stroke--1,
                                            path.color_stroke--1 {
                                                stroke: $color_blue--1 !important;
                                            }
                                        }
                                    }
                                    .actions {
                                        opacity: 0;
                                        position: absolute;
                                        z-index: 5;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        background-color: rgba(0, 0, 0, 0.5);
                                        border-radius: 4px;
                                        @include transition(
                                            $transition_duration--1,
                                            $transition_timing_function--1,
                                            $transition_delay--1,
                                            (opacity)
                                        );
                                        > li {
                                            cursor: pointer;
                                            margin-right: 12px;
                                            &:last-child {
                                                margin-right: 0;
                                            }
                                            &:hover:not(.clear) {
                                                > .icon {
                                                    background-color: $color_blue--1;
                                                    > svg {
                                                        rect.color_stroke--1,
                                                        path.color_stroke--1 {
                                                            stroke: $color_white !important;
                                                        }
                                                    }
                                                }
                                            }
                                            > .icon {
                                                width: 36px;
                                                height: 36px;
                                                background-color: $color_white;
                                                border-radius: 50%;
                                                margin: 0 auto 4px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                @include transition(
                                                    $transition_duration--1,
                                                    $transition_timing_function--1,
                                                    $transition_delay--1,
                                                    (background-color)
                                                );
                                                > svg {
                                                    rect.color_stroke--1,
                                                    path.color_stroke--1 {
                                                        stroke: $color_blue--1 !important;
                                                        @include transition(
                                                            $transition_duration--1,
                                                            $transition_timing_function--1,
                                                            $transition_delay--1,
                                                            (stroke)
                                                        );
                                                    }
                                                }
                                            }
                                            > p {
                                                text-align: center;
                                                font-family: $Roboto;
                                                font-size: 10px;
                                                font-weight: normal;
                                                color: $color_white;
                                            }
                                        }
                                    }
                                    &:hover {
                                        .actions {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .buttons {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 40px;
                height: 72px;
                box-sizing: border-box;
                width: 100%;
                background-color: $color_white;
                border-radius: 4px;
                box-shadow: $box-shadow--4;
                > div {
                    display: flex;
                }
                span.reset {
                    cursor: pointer;
                    color: $color_blue--1;
                    @include transition(
                        $transition_duration--1,
                        $transition_timing_function--1,
                        $transition_delay--1,
                        (opacity)
                    );
                    &:hover {
                        opacity: 0.7;
                    }
                }
                button {
                    margin-right: 12px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        &.rank-battle-style {
            padding: 0;
            .modal-close--custom {
                top: 24px;
                @include transition(
                    $transition_duration--1,
                    $transition_timing_function--1,
                    $transition_delay--1,
                    (opacity)
                );
                &:hover {
                    opacity: 0.7;
                }
                path.color_stroke--1 {
                    stroke: $color_white !important;
                }
            }
            .body {
                padding: 103px 192px 225px;

                .title {
                    font-family: $Ubuntu;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 23px;
                    color: #3c3c3c;
                }
                .description {
                    padding-top: 12px;
                    font-family: $Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    color: #3c3c3c;
                }
                .items {
                    margin-top: 35px;
                    display: flex;

                    .item {
                        cursor: pointer;
                        width: 126px;
                        height: 126px;
                        border-radius: 4px;
                        border: 1px solid #d8d8d8;
                        box-sizing: border-box;
                        position: relative;

                        .img {
                            width: 100%;
                            height: 100%;
                            background-position: center;
                            background-size: cover;
                            background-repeat: no-repeat;
                        }

                        .photo_icon {
                            position: absolute;
                            bottom: 14px;
                            right: 14px;
                            width: 12px;
                            height: 12px;
                        }
                    }

                    .item:not(:first-child) {
                        margin-left: 24px;
                    }
                }
            }
            .head {
                position: relative;
                &-v1,
                &-v3 {
                    height: 60px;
                    background-color: $color_blue--1;
                    padding: 0 32px;
                    display: flex;
                    align-items: center;
                    font-family: $Ubuntu;
                    font-weight: 500;
                    font-size: 24px;
                    color: $color_white;
                }
                &-v3 {
                    justify-content: center;
                    padding: 0;
                    .back-btn {
                        cursor: pointer;
                        font-size: 16px;
                        font-weight: normal;
                        display: flex;
                        align-items: center;
                        position: absolute;
                        left: 32px;
                        top: 50%;
                        transform: translateY(-50%);
                        &:before {
                            content: '';
                            display: inline-block;
                            height: 20px;
                            width: 12px;
                            margin-right: 8px;
                            background-image: url('../../images/png/share/back.png');
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                    }
                }
                &-v2 {
                    height: 160px;
                    background-color: $color_blue--1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: $Ubuntu;
                    font-weight: 500;
                    font-size: 32px;
                    color: $color_white;
                    &:before {
                        content: '';
                        position: absolute;
                        height: 100%;
                        background-image: url('../../images/png/share/seeds.png');
                        opacity: 0.3;
                        left: 50px;
                        right: 50px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }
            }
            .bottom-bar {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 40px;
                height: 72px;
                box-sizing: border-box;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                background-color: $color_white;
                border-radius: 4px;
                box-shadow: $box-shadow--4;
                .left {
                    display: flex;
                    align-items: center;
                    .icon {
                        > svg {
                            path.color_stroke--1,
                            line.color_stroke--1 {
                                stroke: $color_blue--1 !important;
                            }
                            circle.color_fill--1 {
                                fill: $color_blue--1 !important;
                            }
                        }
                    }
                    p {
                        margin-left: 12px;
                        width: 290px;
                        font-size: 12px;
                        line-height: 16px;
                        color: $color_dark_gray--1;
                    }
                }
                .right {
                    display: flex;
                    button {
                        margin-right: 12px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    &-content.add-new-slide {
        width: 960px;
        height: 600px;
        padding: 0;
        .modal-close--custom {
            top: 24px;
            @include transition(
                $transition_duration--1,
                $transition_timing_function--1,
                $transition_delay--1,
                (opacity)
            );
            &:hover {
                opacity: 0.7;
            }
            path.color_stroke--1 {
                stroke: $color_white !important;
            }
        }
    }

    &-content.asset-manager {
        width: 960px;
        height: 600px;
        padding: 0;
        .modal-close--custom {
            top: 24px;
            @include transition(
                $transition_duration--1,
                $transition_timing_function--1,
                $transition_delay--1,
                (opacity)
            );
            &:hover {
                opacity: 0.7;
            }
            path.color_stroke--1 {
                stroke: $color_white !important;
            }
        }
    }

    &-content.downgrade-modal {
        width: 640px;
        min-height: 394px;

        .downgrade-modal {
            &__main {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                color: $color_middle_gray--1;
                text-align: center;

                h1 {
                    font-size: 24px;
                    line-height: 28px;
                }
            }

            &__icon {
                margin-top: 19px;
                margin-bottom: 12px;
            }

            &__msg {
                font-size: 16px;
                line-height: 24px;
            }

            &__ps {
                font-size: 14px;
                line-height: 16px;
                color: $color_dark_gray--1;
                margin-top: 40px;
            }

            &__buttons {
                margin-top: 20px;
                margin-bottom: 15px;
                display: flex;

                button:first-child {
                    margin-right: 12px;
                }
            }
        }
    }

    &-content {
        .langs-modal-menu {
            li {
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }

                &.is-active,
                &.is-disabled {
                    pointer-events: none;
                }
                &.is-silent {
                    cursor: default;
                    &:not(.is-active) > span {
                        color: $color_white !important;
                    }
                }
                &.is-disabled,
                &.is-silent:not(.is-active) {
                    opacity: 0.5;
                }

                > img {
                    width: 28px;
                    margin-right: 12px;
                }
                > span {
                    font-size: 16px;
                    color: $color_white;
                    &:hover {
                        color: $color_light_blue--3;
                    }
                    &.is-active {
                        color: $color_blue--1;
                    }
                }
            }
        }
        .sidebar-modal-menu {
            li {
                padding: 0 28px;
                height: 72px;
                display: flex;
                align-items: center;
                cursor: pointer;
                &.is-active:first-child {
                    > p {
                        color: $color_light_blue--5;
                    }
                }
                &.is-disabled {
                    pointer-events: none;
                    cursor: not-allowed;
                }
                > .icon,
                > .icon--all {
                    width: 48px;
                    height: 48px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    margin-right: 20px;
                    @include transition(
                        $transition_duration--1,
                        $transition_timing_function--1,
                        $transition_delay--1,
                        (background-image)
                    );
                }
                .icon--all {
                    background-image: url('../../images/png/allTemplates.png');
                }
                > .text {
                    width: 100%;
                    > .coming-soon,
                    > a,
                    > p {
                        display: block;
                        padding: 20px 0;
                        text-decoration: none;
                        font-size: 16px;
                        color: $color_middle_gray--1;
                        @include transition(
                            $transition_duration--1,
                            $transition_timing_function--1,
                            $transition_delay--1,
                            (color)
                        );
                    }
                    > .coming-soon {
                        cursor: not-allowed;
                        flex-basis: 100%;
                        padding: 12px 0;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        color: #979797;

                        .label {
                            color: #8a8a8a;
                            font-size: 13px;
                            line-height: 13px;
                            opacity: 0.5;
                            margin-top: 2px;
                            text-transform: lowercase;
                        }
                    }
                }
            }
        }
    }
}
