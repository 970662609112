.ageSelector {
    margin-top: 120px;
    display: flex;
    @media (max-width: 1019px) {
        flex-direction: column;
        margin-top: 40px;
    }
}

.list {
    width: 100%;
}
