@import '../../../../../../../../styles/mixins';

.rangeSlider {
    margin-top: 90px;
    padding: 0 88px;
    box-sizing: border-box;
    @include max_rwd(1023) {
        padding: 0;
    }
}

.isAutoModeRangeSlider {
    margin-top: 30px;
}

.title {
    font-size: 16px;
    line-height: 20px;
    color: #3c3c3c;
    margin-bottom: 14px;
    text-align: center;
}

.note {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #787878;
    width: 260px;
    margin: 0 auto;
    @include max_rwd(767) {
        margin-top: 10px;
    }
}

.sliderBox {
    display: flex;
    align-items: center;
    width: 100%;
}

.countBox {
    flex-shrink: 0;
    margin-left: 16px;
    height: 44px;
    background-color: #f9fafd;
    box-shadow: inset 0 2px 4px rgba(41, 144, 251, 0.12);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px 0 12px;
    box-sizing: border-box;
}

.countBoxTotal {
    font-size: 16px;
    line-height: 20px;
    color: #787878;
}

.countBoxInput {
    padding: 0;
    border: 0;
    margin: 0;
    outline: none;
    appearance: none;
    font-size: 24px;
    color: #3c3c3c;
    width: fit-content;
    background: transparent;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &[type='number'] {
        -moz-appearance: textfield;
    }
}
