.followerItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    &:last-child {
        margin-bottom: 0;
    }
}

.user {
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}

.avatar {
    margin-right: 6px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    color: #fff;
    overflow: hidden;
    flex-shrink: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.name {
    font-size: 14px;
    line-height: 16px;
    color: #3c3c3c;
}

.button {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 28px;
    padding: 0 14px;
    border-radius: 32px;
    font-size: 14px;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}

.isLoadingButton {
    opacity: 0.5;
    pointer-events: none;
}

.isFollowButton {
    background-color: #2990fb;
    border: 1px solid #2990fb;
    color: #fff;
}

.isUnfollowButton {
    background-color: #fff;
    border: 1px solid #979797;
    color: #979797;
}
