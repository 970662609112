@import '../../../../../../styles/mixins';

.addInternalPageModal {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 100%;
    width: 960px;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: auto;
    padding: 60px 0 42px;
    text-align: center;
}

.title {
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: -1px;
    color: #000;
    margin-bottom: 40px;
    padding: 0 20px;
}

.sides {
    width: 100%;
    display: flex;
    justify-content: center;
    @include max_rwd(991) {
        flex-direction: column;
        align-items: center;
    }
}

.side {
    max-width: 320px;
    margin: 0 40px;
    @include max_rwd(991) {
        max-width: 460px;
        margin: 0 20px 40px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.image {
    max-width: 100%;
}

.sideText {
    text-align: left;
    padding-left: 20px;
    @include max_rwd(991) {
        text-align: center;
    }
}

.subTitle {
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -1px;
    color: #000;
    margin-top: 20px;
}

.description {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #3c3c3c;
    margin-top: 8px;
}

.link {
    text-decoration: none;
    color: #2990fb;
}

.buttons {
    display: flex;
    margin-top: 72px;
    @include max_rwd(991) {
        margin-top: 60px;
    }
    @include max_rwd(575) {
        flex-direction: column;
    }
}

.button {
    margin-right: 12px;
    justify-content: center;
    min-width: 120px;
    &:last-child {
        margin-right: 0;
    }
    @include max_rwd(575) {
        margin: 0 0 14px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.showAgain {
    margin-top: 24px;
}
