@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.baseModal {
    max-width: 100%;
    width: 960px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    padding: 40px 60px 60px;
    box-sizing: border-box;
    position: relative;
    @include max_rwd(540) {
        padding-bottom: 40px;
    }
    @include max_rwd(420) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.close {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.7;
    }
}

.imageBox {
    margin: 0 auto;
    width: 200px;
    height: 200px;
    > img {
        max-width: 100%;
        max-height: 100%;
    }
}

.title {
    max-width: 480px;
    margin: 8px auto 0;
    font-family: $Ubuntu;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -1px;
    color: #000;
}

.description {
    max-width: 480px;
    margin: 12px auto 0;
    font-family: $Ubuntu;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #3c3c3c;
}

.buttonBox {
    margin-top: 28px;
    display: flex;
    justify-content: center;
}

.link {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    background-color: #2990fb;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    text-decoration: none;
    &:hover {
        opacity: 0.8;
    }
}
