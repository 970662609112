.roundsTime {
    margin-top: 40px;
    font-size: 16px;
    line-height: 20px;
    color: #3c3c3c;
    text-align: center;
}

.lnkText {
    text-decoration: underline;
}

.lnk {
    cursor: pointer;
    color: #2990fb;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}

.icon {
    margin-bottom: -3px;
}
