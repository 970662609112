@import './../../../../../../../../../../../../styles/mixins';

.rank-battle {
    &__card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
        overflow: hidden;
        border-radius: 16px;
        position: relative;
        cursor: default;
        @include transition(0.2s, ease-in-out, 0s, background-color);
        &.is-mobile {
            margin: 0 0 12px 0 !important;
            flex-direction: row;
            &:last-child {
                margin-bottom: 0 !important;
            }
        }
        &:before {
            content: '';
            position: absolute;
            z-index: 15;
            border-radius: 16px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 4px solid var(--borderColor);
            pointer-events: none;
            opacity: 0;
        }
    }
}
