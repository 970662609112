.custom-project-link-modal {
    max-width: 100%;
    width: 960px;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    overflow: hidden;
    box-sizing: border-box;
    text-align: left;
    padding: 90px 100px 40px;
    > h2 {
        font-family: 'Roboto', sans-serif;
        font-weight: normal;
        color: #555;
        font-size: 20px;
        margin-bottom: 8px;
        text-align: center;
    }
    > p {
        font-family: 'Roboto', sans-serif;
        font-weight: normal;
        text-align: center;
        font-size: 16px;
        color: #787878;
        margin-bottom: 33px;
    }
    > .input-place {
        margin-bottom: 26px;
        width: 100%;
        height: 76px;
        padding: 0 16px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #979797;
        border-radius: 4px;
        &.focused {
            border-color: #69b1fc;
        }
        &.free {
            border-color: #65bb5a;
        }
        &.already_taken {
            border-color: #fd8d8d;
        }
        &.invalid {
            border-color: #fd8d8d;
        }
        > .side {
            &--left {
                width: 100%;
                display: flex;
                padding-right: 24px;
                align-items: center;
                .placeholder {
                    font-family: 'Roboto', sans-serif;
                    font-weight: 300;
                    font-size: 18px;
                    color: #3c3c3c;
                    letter-spacing: -0.24px;
                    line-height: 20px;
                    height: auto;
                    background-color: transparent;
                }
                > input {
                    font-family: 'Roboto', sans-serif;
                    font-weight: 300;
                    border: 0;
                    font-size: 18px;
                    color: #69b1fc;
                    padding: 0 0 0 4px;
                    line-height: 20px;
                    height: auto;
                    width: 100%;
                    &::placeholder {
                        color: #d8d8d8;
                    }
                    &:focus {
                        caret-color: #69b1fc;
                        outline: none;
                    }
                }
            }
            &--right {
                flex-shrink: 0;
                > div {
                    font-family: 'Roboto', sans-serif;
                    font-weight: normal;
                    font-size: 16px;
                    background-repeat: no-repeat;
                    background-position: 0 50%;
                    padding: 5px 0;
                    &.available {
                        padding-left: 28px;
                        background-image: url('./i/smile--ok.svg');
                        color: #65bb5a;
                    }
                    &.unavailable {
                        padding-left: 28px;
                        background-image: url('./i/smile--bad.svg');
                        color: #fd8d8d;
                    }
                    &.invalid {
                        color: #fd8d8d;
                    }
                }
            }
        }
    }
    > .note {
        font-family: 'Roboto', sans-serif;
        font-weight: normal;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        color: #787878;
        margin-bottom: 12px;
    }
    > ul.allowed {
        display: flex;
        justify-content: center;
        margin-bottom: 78px;
        > li {
            font-family: 'Roboto', sans-serif;
            font-weight: normal;
            border: 1px solid #d8d8d8;
            padding: 6px 10px 7px;
            border-radius: 4px;
            color: #787878;
            font-size: 20px;
            margin-right: 8px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .buttons {
        display: flex;
        justify-content: center;
        > button {
            width: 122px;
            justify-content: center;
            margin-right: 12px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
