@import '../../../../../../../../../../../../styles/variables';
@import '../../../../../../../../../../../../styles/mixins';

.reason {
    box-sizing: border-box;
    width: 960px;
    height: auto;
    max-width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 120px 100px 55px;
    @include max_rwd(700) {
        padding: 60px 40px 40px;
    }
    @include max_rwd(480) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.box {
    display: flex;
    align-items: center;
    @include max_rwd(1025) {
        flex-direction: column;
    }
}

.text {
    width: 50%;
    text-align: left;
    padding-right: 60px;
    box-sizing: border-box;
    @include max_rwd(1025) {
        width: 100%;
        padding-right: 0;
        text-align: center;
        margin-bottom: 30px;
    }
}

.title {
    font-family: $Ubuntu;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: -1px;
    color: #000;
}

.description {
    margin-top: 24px;
    font-size: 14px;
    line-height: 24px;
    color: #3c3c3c;
}

.list {
    width: 50%;
    @include max_rwd(1025) {
        width: 100%;
    }
}

.item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    padding: 12px;
    text-align: left;
    margin-bottom: 6px;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    @include transition(0.1s, ease-in-out, 0s, (opacity, border-color));
    &:last-child {
        margin-bottom: 0;
    }
    &:not(.isActiveItem):hover {
        opacity: 0.8;
    }
}

.isActiveItem {
    cursor: default;
    border-color: #2990fb;
    .itemRound:before {
        background-color: #2990fb;
    }
}

.option {
    display: flex;
    align-items: center;
}

.itemRound {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border: 1px solid #d8d8d8;
    background-color: #fff;
    box-sizing: border-box;
    margin-right: 12px;
    border-radius: 50%;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        width: 14px;
        height: 14px;
        background-color: transparent;
        transform: translate(-50%, -50%);
        transition: background-color 0.2s ease-in-out;
    }
}

.itemLabel {
    font-size: 16px;
    line-height: 20px;
    color: #3c3c3c;
}

.buttons {
    margin-top: 70px;
    display: flex;
    justify-content: flex-end;
    @include max_rwd(700) {
        margin-top: 40px;
        flex-direction: column;
    }
}

.button {
    margin-right: 16px;
    @include max_rwd(700) {
        margin-right: 0;
        margin-bottom: 16px;
    }
    &:last-child {
        margin-right: 0;
        @include max_rwd(700) {
            margin-bottom: 0;
        }
    }
}

.commentBox {
    margin-top: 20px;
    width: 100%;
}

.textarea {
    box-sizing: border-box;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    outline: none;
    width: 100% !important;
    max-width: 100% !important;
    min-height: 99px !important;
    font-size: 16px;
    line-height: 19px;
    padding: 15px 20px 25px;
    color: #3c3c3c;
    font-family: $Roboto;
    resize: none;
    overflow: auto;
    &:focus {
        border-color: #69b1fc;
        caret-color: #69b1fc;
        border-width: 2px;
        padding: 14px 19px 24px;
    }
    &::placeholder {
        color: #979797;
    }
}
