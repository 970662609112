.devtools {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    transition: transform 0.2s ease-in-out;
}

.isOpenedDevtools {
    transform: translate(-50%, 0);
    .tongueCircle {
        background-color: #198f51;
        left: 21px;
    }
}

.topBar {
    position: absolute;
    z-index: 1500;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 12px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(46, 35, 128, 0.15), 0 0 8px -2px rgba(46, 35, 128, 0.15);
    border-radius: 4px 4px 0 0;
    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        height: 4px;
        width: 100%;
        background-color: #fff;
    }
}

.content {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(46, 35, 128, 0.15), 0 0 8px -2px rgba(46, 35, 128, 0.15);
}

.list {
    padding: 10px 20px;
    display: flex;
}

.tongue {
    width: 42px;
    height: 24px;
    background-color: #555;
    border-radius: 16px;
    position: relative;
    cursor: pointer;
    &:hover {
        .tongueCircle {
            opacity: 0.8;
        }
    }
}

.tongueCircle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3px;
    width: 18px;
    height: 18px;
    padding: 4px;
    box-sizing: border-box;
    background-color: #2c2c2c;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
        max-width: 100%;
        max-height: 100%;
    }
}

.item {
    background-color: #2990fb;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    padding: 10px 14px;
    margin-right: 10px;
    outline: none;
    transition: opacity 0.2s ease-in-out;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    &:hover {
        opacity: 0.8;
    }
    &:last-child {
        margin-right: 0;
    }
}

.isDisabledItem {
    opacity: 0.5;
    pointer-events: none;
}
