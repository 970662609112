$audio_theme-color: #3c3c3c !default;
$audio_bar-color: #f5f5f5 !default;

.audio_container {
    box-sizing: border-box;
    display: flex;
    padding: 18px 24px;
    background-color: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    pointer-events: none;

    font-family: Roboto, sans-serif;
    color: $audio_theme-color;
}

.audio_controls-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.audio_main {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: 20px;

    &:last-child {
        margin-top: 15px;
        &:empty {
            margin: 0;
        }
    }
}

.audio_time {
    color: $audio_theme-color;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    user-select: none;
}

.audio_button {
    display: flex;
    align-items: center;
    position: relative;
    background: transparent;
    border: none;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
    flex: none;

    width: 24px;
    height: 24px;

    &:first-child {
        margin-left: 0;
    }
}

.audio_main-controls {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.audio_progress-container {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    cursor: pointer;
    border: 5px solid transparent;
    user-select: none;

    &:hover {
        .audio_progress-indicator {
            opacity: 1;
        }
    }
}

.audio_progress-bar {
    box-sizing: border-box;
    position: relative;
    z-index: 0;
    width: 100%;
    height: 6px;
    background-color: $audio_bar-color;
    border-radius: 2px;
}

.audio_progress-filled {
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: $audio_theme-color;
    border-radius: 34px;
}

.audio_progress-bar-show-download {
    background-color: rgba($audio_bar-color, 0.5);
}

.audio_download-progress {
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: $audio_bar-color;
    border-radius: 34px;
}

.audio_progress-indicator {
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: 3;
    margin-left: -5px;
    top: -2px;
    background: $audio_theme-color;
    border-radius: 50%;
    transition: opacity 0.3s ease;

    opacity: 0;
}

.audio_volume-container {
    display: flex;
    align-items: center;
    flex: 0 1 100px;
    user-select: none;
}

.audio_volume-bar-area {
    display: flex;
    align-items: center;
    width: 100%;
    height: 14px;
    cursor: pointer;
    margin: 0 3px;
    border: 5px solid transparent;

    &:hover {
        .audio_volume-indicator {
            opacity: 1;
        }
    }
}

.audio_volume-bar {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 6px;
    background: $audio_bar-color;
    border-radius: 34px;
}

.audio_volume-indicator {
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: 3;
    margin-left: -5px;
    top: -2px;
    background: $audio_theme-color;
    border-radius: 50%;
    transition: opacity 0.3s ease;

    opacity: 0;
}

.audio_volume-filled {
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: $audio_theme-color;
    border-radius: 34px;
}

.audio_menu,
.audio_speed_menu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
}

.audio_menu {
    width: 20px;
}
