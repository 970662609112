.card-dialog {
    max-width: 100%;
    width: 960px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    text-align: left;
    max-height: 540px;
    height: 468px;
}
