.priceSelector {
    padding-bottom: 5px;
}

.label {
    font-size: 16px;
    color: #8a8a8a;
    margin-bottom: 8px;
    height: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list {
    opacity: 1;
}

.item {
    margin-bottom: 12px;
    background: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    border: 1px solid transparent;
    padding: 24px 56px 20px 28px;
    box-sizing: border-box;
    width: 100%;
    &:last-child {
        margin-bottom: 0;
    }
}

.isSelectedItem {
    border-color: #2990fb;
}
