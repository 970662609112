.textInputWithPageSelection {
    position: relative;
}

.openText {
    position: absolute;
    top: 5px;
    right: 0;
    font-size: 14px;
    color: #2990fb;
    cursor: pointer;
    z-index: 5;
}
