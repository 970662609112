.list {
    display: flex;
}
.item {
    box-sizing: border-box;
    position: relative;
    width: 28px;
    height: 28px;
    border: 1px solid #d8d8d8;
    border-radius: 50%;
    margin-right: 8px;
    cursor: pointer;
}

.isSelectedItem {
    &:before {
        content: '';
        position: absolute;
        width: 8px;
        height: 12px;
        left: 7px;
        top: 3px;
        border-bottom: 3px solid;
        border-right: 3px solid;
        transform: rotate(35deg);
        border-radius: 1px;
    }
}
