.upgrade-dialog {
    max-width: 100%;
    width: 640px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    padding: 48px 60px 40px;
    box-sizing: border-box;
    position: relative;
    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 22px;
        height: 22px;
        background-image: url('i/cross.svg');
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        transition: opacity 0.2s ease-in-out;
        &:hover {
            opacity: 0.7;
        }
    }
    &__head {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-size: 24px;
        text-align: center;
        color: #3c3c3c;
    }
    &__description {
        margin-top: 30px;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: 24px;
        color: #3c3c3c;
        text-align: center;
        white-space: pre-line;
        > span {
            font-weight: bold;
        }
    }
    &__image {
        text-align: center;
        margin-top: 15px;
        > img {
            max-width: 100%;
        }
    }
    &__buttons {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }
    &__button {
        margin-right: 12px;
        &:last-child {
            margin-right: 0;
        }
    }
}
