.answer {
    &__wrapper {
        margin-bottom: 24px;
        padding-right: 32px;
    }

    &__body {
        position: relative;

        &-title {
            display: flex;
            justify-content: space-between;
            &-required {
                &:after {
                    content: ' *';
                    color: #ff5656;
                }
            }
            &-link {
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: #2990fb;
                cursor: pointer;
                position: relative;

                &-txt {
                    padding-bottom: 2px;
                    &.is-have-links {
                        &:before {
                            content: '';
                            position: absolute;
                            background: url('../../../i/links.svg');
                            width: 20px;
                            height: 20px;
                            left: -25px;
                            bottom: 0;
                        }
                    }
                }
            }
        }

        &-block {
            display: flex;
            &-input {
                flex: auto;
            }
        }

        &-remove {
            position: absolute;
            cursor: pointer;
            width: 16px;
            height: 16px;
            background: url('../../../../../../i/delete.svg') center no-repeat;
            top: 27px;
            right: -32px;
        }
    }
}
