@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.chooseImageModal {
    width: 800px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 40px 60px 60px;
    @include max_rwd(580) {
        padding: 40px 20px 30px;
    }
}

.title {
    font-family: $Ubuntu;
    font-size: 24px;
    line-height: 24px;
    color: #000;
    margin-bottom: 40px;
    text-align: left;
}

.list {
    display: flex;
    flex-direction: column;
}

.item {
    position: relative;
    height: 94px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    margin-bottom: 8px;
    border-radius: 8px;
    &:last-child {
        margin-bottom: 0;
    }
    &:hover {
        opacity: 0.8;
    }
}

.isSelectedItem {
    pointer-events: none;
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translateY(-50%);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #fff;
        background-image: url('./i/check.svg');
        background-position: center;
        background-repeat: no-repeat;
    }
}
