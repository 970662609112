.imageCropper {
    width: 640px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    text-align: center;
    filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.25));
    padding: 20px;
}

.cropper {
    position: relative;
}

.isHiddenModal {
    pointer-events: none;
    opacity: 0;
}

.bottomBar {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.actions {
    display: flex;
    > button {
        margin-right: 16px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.shapeSelector {
    display: flex;
    align-items: center;
}

.shapeText {
    font-size: 16px;
    color: #787878;
}

.shapeList {
    display: flex;
    align-items: center;
    margin-left: 16px;
}

.shape {
    cursor: pointer;
    margin-right: 11px;
    &:last-child {
        margin-right: 0;
    }
}

.isActiveShape {
    pointer-events: none;
}
