/* Fonts */
$Roboto: 'Roboto', sans-serif;
$Ubuntu: 'Ubuntu', sans-serif;
$Caveat: 'Caveat', sans-serif;
$Montserrat: 'Montserrat', sans-serif;

/* Screens */
$screen-xs-min: 0;
$screen-xs-max: 575px;
$screen-sm-min: 576px;
$screen-sm-max: 767px;
$screen-md-min: 768px;
$screen-md-max: 991px;
$screen-lg-min: 992px;
$screen-lg-max: 1199px;
$screen-xl-min: 1200px;
$screen-xl-max: 1599px;
$screen-xxl-min: 1600px;

$container-xs: 100%;
$container-sm: 570px;
$container-md: 750px;
$container-lg: 990px;
$container-xl: 1170px;
$container-xxl: 1390px;

/* Colors */
$color_white: #ffffff;
$color_black: #000000;
// Grayscale
$color_light_gray--1: #efefef;
$color_light_gray--2: #d8d8d8;
$color_light_gray--3: #f9fafd;
$color_light_gray--4: #8d8d8d;
$color_light_gray--5: #8a8a8a;
$color_light_gray--6: #d9d9d9;
$color_light_gray--7: #efeeee;
$color_light_gray--8: #d7d7d7;
$color_light_gray--9: #b2b6ba;
$color_light_gray--10: #e5e5e5;
$color_light_gray--11: #ebebeb;
$color_light_gray--12: #d2d2d2;
$color_light_gray--13: #eeeeee;
$color_light_gray--14: #cacaca;
$color_light_gray--15: #dbdbdb;
$color_light_gray--16: #cecece;
$color_middle_gray--1: #555555;
$color_middle_gray--2: #979797;
$color_middle_gray--3: #9b9b9b;
$color_middle_gray--4: #606060;
$color_middle_gray--5: #c4c4c4;
$color_middle_gray--6: #666666;
$color_middle_gray--7: #4f4f4f;
$color_middle_gray--8: #626262;
$color_dark_gray--1: #787878;
$color_dark_gray--2: #797979;
$color_dark_gray--3: #3c3c3c;
$color_dark_gray--4: #2b2b2b;
$color_dark_gray--5: #5c5c5c;
// Main
$color_blue--1: #2990fb;
$color_light_blue--1: #69b1fc;
$color_light_blue--2: #499cf3;
$color_light_blue--3: #56a9ff;
$color_light_blue--4: #e0f0ff;
$color_light_blue--5: #148fff;
$color_light_blue--6: #e6f2ff;
$color_green--1: #65bb5a;
$color_red--1: #ff5656;
$color_light_red--1: #fc9a96;
$color_light_red--2: #fd8d8d;
// Bright
$color_green--1: #65bb5a;
$color_green--2: #41ad6a;
$color_red--1: #ff5656;
$color_purple--1: #9560e6;
$color_turquoise--1: #2aa9c6;
$color_orange--1: #e67a32;
$color_orange--2: #ffca63;

$color_gold--1: #bfa677;

// Transition
$transition_duration--1: 0.1s;
$transition_duration--2: 0.2s;
$transition_duration--3: 0.4s;

$transition_timing_function--1: ease-in-out;
$transition_delay--1: 0s;

// Box-shadow
$box-shadow--1: 0 2px 9px rgba(0, 0, 0, 0.07);
$box-shadow--2: 0 2px 8px rgba(0, 0, 0, 0.1);
$box-shadow--3: 0 2px 8px rgba(0, 0, 0, 0.2);
$box-shadow--4: 0 4px 20px rgba(0, 0, 0, 0.1);
$box-shadow--5: 0 4px 20px rgba(0, 0, 0, 0.25);
$box-shadow--6: 0 4px 8px rgba(0, 0, 0, 0.1);
$box-shadow-blue-1: 0 4px 20px rgba(41, 144, 251, 0.12);

//
// $hamburger-layer-width: 20px;
