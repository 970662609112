.slideshow-modal-wrapper {
    max-width: 100%;
    width: 960px;
    height: 428px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    background-color: #f9fafd;
    & > &__body {
        height: 100%;
        width: 100%;
        overflow: hidden;

        .scrollbar-inner {
            padding: 17px 40px 40px;
        }

        .slide-card {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            padding-top: 14px;

            &__image {
                width: 72px;
                padding: 9px 20px 0 0;

                .image-picker {
                    padding-bottom: 0;
                    width: 72px;

                    &__label {
                        text-align: center;
                        font-weight: bold;
                        font-size: 14px;
                    }

                    &__area {
                        width: 72px;
                        height: 72px;
                    }

                    &__error {
                        overflow: unset;
                        text-overflow: unset;
                        text-align: left;
                    }
                }
            }

            &__settings {
                display: flex;
                flex-direction: column;
                width: 788px;

                &-header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;

                    &-desc-label,
                    &-action {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }

                    &-action-button {
                        padding-right: 16px;
                        font-family: 'Roboto', sans-serif;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 16px;
                        color: #2990fb;
                        cursor: pointer;
                    }
                }

                &-desc-input {
                    .common-text-area {
                        padding: 0;

                        &__text-area {
                            border-color: #d8d8d8;
                            font-size: 14px;
                            line-height: 20px;

                            &:focus {
                                border-color: #69b1fc;
                            }
                        }
                    }
                }

                &-text-options {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding-top: 12px;

                    &-header,
                    &-image-caption {
                        width: calc(50% - 10px);

                        .common-text-input {
                            padding: 0;

                            &__input-text {
                                height: 38px;
                                font-size: 14px;
                                border-color: #d8d8d8;

                                &:focus {
                                    border-color: #69b1fc;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
