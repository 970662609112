.earnText {
    margin-top: 40px;
    font-size: 20px;
    line-height: 23px;
    color: #3c3c3c;
    text-align: right;
    width: 100%;
}

.sum {
    padding-left: 24px;
    font-weight: 500;
}
