@import '../../styles/variables';
@import '../../styles/mixins';

.page-projects {
    background-color: #f9fafd;
    display: flex;
    justify-content: center;
    position: relative;
    @include max_rwd(1024) {
        flex-direction: column;
        padding: 0;
    }

    &__panels {
        margin: 25px 0 0 20px;
        width: 280px;
        box-sizing: border-box;
        flex-shrink: 0;
        padding-bottom: 20px;
        @include max_rwd(1024) {
            width: 100%;
            box-sizing: border-box;
            padding: 40px 20px 0;
            margin: 0;
        }
        @include max_sm {
            padding: 30px 20px 0;
        }
        &__content {
            position: sticky;
            top: 80px;
        }
    }

    &__projects-block {
        width: 100%;
        min-height: 95vh;
        padding: 0 20px 110px;
        overflow: hidden;
        box-sizing: border-box;
        margin-top: 25px;
        @include max_sm {
            padding: 0 20px 60px;
        }
    }
}
