.board {
    border-radius: 4px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(var(--tilesCount), var(--tileSize));
    grid-template-rows: repeat(var(--tilesCount), var(--tileSize));
    position: relative;
    overflow: hidden;
    border-style: solid;
    border-color: #fff;
    background-color: #fff;
    outline: none;
    box-sizing: border-box;
}
