.gamification-promo-modal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 600px;
    max-width: 960px;
    width: 100%;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;

    &__img {
        position: absolute;
        width: 283px;
        height: 533px;
        filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.12));
        border-radius: 8px;
        transform: rotate(-13.96deg);
        object-fit: cover;
    }

    &__images {
        position: relative;
        flex: none;
        width: 510px;
        height: 100%;

        :nth-child(1) {
            right: 240px;
            top: 10px;
        }

        :nth-child(2) {
            right: 95px;
            top: 55px;
        }
    }

    &__announce {
        margin-bottom: 40px;
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -1px;
        text-align: left;
        color: #000000;
    }

    &__title {
        margin-bottom: 20px;
        margin-right: 64px;
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -1px;
        color: #000000;
        text-align: left;
    }

    &__description {
        margin-bottom: 40px;
        margin-right: 64px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #3c3c3c;
        text-align: left;
    }

    &__link {
        text-decoration: none;
        color: #2990fb;
    }

    &__buttons {
        display: flex;
        margin-bottom: 40px;
        button {
            margin-right: 12px;
            justify-content: center;
            min-width: 120px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
