.block {
    display: flex;
    padding: 8px 12px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 6px;
    border-radius: 4px;
    background-color: #f8fbff;
    border: 1px solid #f8fbff;
    box-sizing: border-box;
    transition: border-color 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
        border-color: #2990fb;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.checkbox {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #d8d8d8;
    background-color: #fff;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.isActiveCheckbox {
    background-color: #2990fb;
    border-color: #2990fb;
    .icon {
        opacity: 1;
    }
}

.icon {
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
}

.text {
    margin-left: 14px;
    width: 100%;
    color: #3c3c3c;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
}
