.modalEditedBlock {
    position: relative;
    min-height: 50px;
}

.preview {
    position: relative;
    z-index: 5;
    overflow: hidden;
}

.overlay {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(242, 243, 247, 0.5);
    backdrop-filter: blur(1.5px);
}
