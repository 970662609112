@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.qr-code-tab {
    background-color: #f9fafd;
    width: 100%;
    box-sizing: border-box;
    padding: 40px 120px 80px 120px;
    min-height: 550px;

    @include max_xs {
        padding: 40px;
    }

    &__download-button {
        margin: 24px auto;
    }
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__contact {
        margin-top: 50px;
        text-align: center;
        font-size: 16px;
        color: $color_dark_gray--3;
        a {
            text-decoration: none;
            color: $color_blue--1;
        }
    }
}
