@import '../../../../styles/variables';

.audioLibrary {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    padding: 0 40px;
}

.header {
    height: 60px;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.title {
    font-family: $Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: $color_dark_gray--3;
}

.clickableTitle {
    color: #979797;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}

.titleArrow {
    padding: 4px 10px 0;
    height: 20px;
}

.titleFolderName {
    font-family: $Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: $color_dark_gray--3;
}

.foldersList {
    display: flex;
    flex-wrap: wrap;
    margin: 24px -30px 34px -6px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color_light_gray--2;
        width: 8px;
        border-radius: 9px;
    }
}

.folder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 6px;
    cursor: pointer;
    &:hover {
        .folderImage {
            box-shadow: inset 0 0 0 1px #979797, 0 2px 8px rgb(0 0 0 / 10%);
        }
    }
}

.folderImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    box-shadow: inset 0 0 0 1px #d8d8d8;
    > img {
        width: 60px;
    }
}

.folderName {
    max-width: 100px;
    font-size: 12px;
    line-height: 12px;
    margin-top: 6px;
    margin-bottom: 10px;
    color: #787878;
    text-align: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.folderTitle {
    margin-bottom: 10px;
    color: #3c3c3c;
    font-size: 18px;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    > img {
        padding: 0 10px;
    }
}

.audioWrapper {
    height: 100%;
}
