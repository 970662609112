@import '../../styles/variables';
@import '../../styles/mixins';

.page--billing_and_payments {
    background-color: #f9fafd;
    &.dim {
        opacity: 0.7;
    }

    div.table {
        border-radius: 4px;
        box-shadow: $box-shadow--1;
        overflow: hidden;

        .table-header {
            padding: 24px 60px;
            max-height: 64px;
            display: flex;
            align-items: center;
            mix-blend-mode: normal;
            @include max_xl {
                padding: 20px 40px;
                max-height: 56px;
            }
            > li {
                font-size: 16px;
                color: $color_white;
            }
        }

        .table-list {
            &__item {
                padding: 0 60px;
                display: flex;
                align-items: center;
                height: 114px;
                border-bottom: 1px solid $color_light_gray--2;
                .invoice-link {
                    cursor: pointer;
                }
                &:last-child {
                    border-bottom: 0;
                }
                @include max_xl {
                    padding: 0 40px;
                }
                > ul {
                    display: flex;
                    align-items: center;
                    > li {
                        font-size: 16px;
                        color: $color_light_gray--5;
                        > .sm {
                            font-size: 14px;
                            margin-top: 5px;
                        }
                        > .dark {
                            color: $color_middle_gray--1;
                        }
                        > .big {
                            font-size: 20px;
                        }

                        > a {
                            color: $color_blue--1;
                        }
                    }
                }
            }
        }
    }

    .table-1 {
        .col {
            padding-right: 30px;
            box-sizing: border-box;
            &-1 {
                width: 170px;
            }
            &-2 {
                width: 140px;
            }
            &-3 {
                width: 170px;
            }
            &-4 {
                width: 170px;
            }
            &-5 {
                width: 170px;
            }
            &-6 {
                width: 250px;
            }
            &-7 {
                width: 170px;
                padding-right: 0;
            }
        }
    }

    .table-2 {
        overflow: auto !important;
        background: linear-gradient(180deg, rgba(41, 144, 251, 0.7) 64px, $color_white 0);
        @include scroll-bar();

        @include max_xl {
            background: linear-gradient(180deg, rgba(41, 144, 251, 0.7) 56px, $color_white 0);
        }

        .col {
            margin-right: 30px;
            box-sizing: border-box;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            flex: none;
        }
    }

    .billing-history {
        &__no-subscription {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 15%;

            p {
                margin-top: 50px;
                font-family: $Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 23px;
                color: $color_middle_gray--1;
            }

            button {
                margin-top: 36px;
            }

            a {
                text-decoration: none;
            }
        }
    }

    .subscriptions {
        display: flex;
        flex-direction: column;
        align-items: center;
        &.dim {
            opacity: 0.5;
        }

        h1 {
            font-size: 20px;
            line-height: 23px;
        }

        &-list {
            width: 100%;
            margin-bottom: 40px;
        }

        &__banner {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            border: 1px solid $color_blue--1;
            border-radius: 4px;
            margin-bottom: 40px;
            height: auto;
            min-height: 100px;
            padding: 20px;
            box-sizing: border-box;
            @include max_rwd(700) {
                flex-direction: column;
                align-items: center;
            }
            &__text {
                color: #f1ae2b;
                margin-left: 12px;
                margin-right: 16px;
                @include max_rwd(700) {
                    margin: 10px 0 20px;
                }
            }
            &__description {
                font-size: 14px;
                @include max_rwd(700) {
                    text-align: center;
                }
            }
        }

        &__state {
            display: flex;
            flex-direction: column;
            width: 100%;
            border: 1px solid $color_blue--1;
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12);
            border-radius: 12px;
            font-size: 16px;
            line-height: 19px;
            background: $color_white;
            &--waiting {
                margin-top: 10px;
            }
            &--projects {
                margin-top: 30px;
            }

            &__row {
                min-height: 60px;
                height: auto;
                padding: 20px 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @include max_lg {
                    padding: 20px;
                }
                &:nth-last-child(n + 2) {
                    border-bottom: 1px solid $color_blue--1;
                }
                &__main {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    @include max_lg {
                        flex-direction: column;
                    }
                }
                &__info {
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                    align-items: center;
                    margin-top: 10px;
                    &--renewal {
                        @include max_rwd(576) {
                            flex-direction: column;
                            margin-top: 20px;
                            > p {
                                margin-right: 0 !important;
                                margin-bottom: 10px;
                            }
                        }
                    }
                    > p {
                        font-size: 12px;
                        line-height: 14px;
                        color: #979797;
                        margin-right: 16px;
                    }
                    &__cancel-btn {
                        height: 30px;
                        border: 1px solid #d8d8d8;
                        border-radius: 4px;
                        font-size: 12px;
                        color: #787878;
                        padding: 0 12px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        transition: opacity 0.2s ease-in-out;
                        flex-shrink: 0;
                        &:hover {
                            opacity: 0.7;
                        }
                        &--loading {
                            pointer-events: none;
                            opacity: 0.3;
                        }
                    }
                }
                &__text {
                    font-size: 12px;
                    line-height: 14px;
                    color: #979797;
                    margin-top: 14px;
                    > a {
                        text-decoration: none;
                        color: #2990fb;
                        transition: opacity 0.2s ease-in-out;
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }

            @include min_xl {
                &__item {
                    height: 46px;
                    width: 10%;
                    flex-shrink: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    &:nth-child(1) {
                        width: 13%;
                    }
                    &:nth-child(2) {
                        width: 10%;
                    }
                    &:nth-child(3) {
                        width: 10%;
                    }
                    &:nth-child(4) {
                        width: 15%;
                    }
                    &:nth-child(5) {
                        width: 15%;
                    }
                    &:nth-child(6) {
                        width: 22%;
                    }
                    &:nth-child(7) {
                        width: 15%;
                    }
                }
                &__item-appsumo-marks {
                    width: 100% !important;
                    flex-shrink: unset;
                    align-items: flex-end;
                }
            }

            @include max_lg {
                &__item {
                    display: flex;
                    align-self: stretch;
                    justify-content: space-between;
                    align-items: center;

                    &:nth-child(n + 2) {
                        margin-top: 20px;
                    }
                }

                &__item-value {
                    text-align: right;
                }
            }

            .payment-method {
                display: flex;
                align-items: flex-start;
                > .payment {
                    margin-right: 8px;
                }
            }

            &__item-title {
                color: $color_middle_gray--2;
            }

            &__item-value {
                color: $color_dark_gray--1;
            }

            &__item-icon-value {
                display: flex;
                align-items: center;
                svg {
                    margin-right: 12px;
                }
            }

            &__item-active-value {
                color: $color_blue--1;
                text-transform: capitalize;
            }

            &__status {
                width: 15%;
                &-info {
                    margin-left: 12px;
                }
            }

            .subscriptions__status {
                font-size: 14px;
                line-height: 16px;
            }

            .subscriptions__info {
                font-size: 12px;
                line-height: 14px;
                color: $color_middle_gray--2;
                margin-top: 4px;
                @include max_lg {
                    font-size: 14px;
                    line-height: 16px;
                }
                &.is-renewal {
                    color: $color_green--1;
                }
                &.is-until {
                    color: $color_dark_gray--3;
                }
            }

            .subscriptions__mobile-status-group {
                margin-left: 10px;
            }

            .subscriptions__cancel-recurring {
                cursor: pointer;
                color: $color_blue--1;
                font-size: 12px;
                line-height: 14px;
                @include max_lg {
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }

        &__switcher {
            width: 480px;
            height: 40px;
            font-size: 16px;
            line-height: 19px;
            border: 1px solid $color_light_gray--2;
            box-sizing: border-box;
            margin-bottom: 29px;
            margin-top: 20px;
            @include max_md {
                width: 288px;
                height: 60px;
                font-size: 16px;
                margin-bottom: 28px;
            }

            > label {
                width: 236px;
                height: 32px;
                color: $color_dark_gray--2;

                @include max_md {
                    width: 140px;
                    height: 52px;
                }
            }
        }

        &__table {
            display: flex;
            align-items: flex-end;
            color: $color_dark_gray--3;

            > div {
                box-shadow: 0 2px 8px rgba(41, 144, 251, 0.12);
                margin-left: 12px;

                &:first-child {
                    margin-left: 0;
                }
            }

            @include min_lg {
                .subscriptions__column-table:nth-child(2) {
                    border-bottom-left-radius: 0 !important;
                }
            }
        }

        &__column-header {
            position: relative;
            display: flex;
            margin-top: 3px;
            height: 50px;
            width: calc(100% - 24px);
            align-items: center;
            justify-content: center;

            svg {
                position: absolute;
                left: 0;
                width: 32px;
                height: 32px;
            }

            span {
                font-family: $Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                color: $color_blue--1;
            }
        }

        &__column-table {
            width: 210px;
            @include max_rwd(1480) {
                width: 164px;
            }
            @include max_md {
                width: 100%;
            }
        }

        &__column-table_selected {
            box-shadow: 0 0 0 1px $color_blue--1, 0 2px 8px rgba(41, 144, 251, 0.12) !important;
        }

        &__price-description {
            height: 16px;
            margin-top: 6px;
            font-size: 14px;
            line-height: 16px;
            color: $color_orange--1;
            font-family: $Ubuntu;
        }

        &__price-total {
            margin-top: 6px;
            font-size: 14px;
            line-height: 16px;
            color: $color_black;
            font-family: $Ubuntu;
            padding: 0 15px;
            text-align: center;
            height: 32px;
        }

        &__action {
            margin-top: 12px;
            margin-bottom: 19px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            @include no-select;

            &_current {
                color: $color_blue--1;
            }

            &_downgrade {
                cursor: pointer;
                font-size: 12px;
                line-height: 14px;
                color: $color_middle_gray--2;

                &:hover {
                    color: $color_blue--1;
                }
            }

            &_upgrade {
                cursor: pointer;
                background-color: $color_blue--1;
                border-radius: 4px;
                color: $color_white;
                width: calc(100% - 30px);

                @include transition(
                    $transition_duration--1,
                    $transition_timing_function--1,
                    $transition_delay--1,
                    (box-shadow, border-color, background-color)
                );

                &:hover {
                    box-shadow: $box-shadow--2;
                    background-color: $color_light_blue--1;
                    border-color: $color_light_blue--1;
                }
            }
        }
    }
}
