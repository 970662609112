@import '../../../../../../styles/mixins';

.lead-form-analytics {
    width: 100%;

    &__header {
        display: flex;
        margin-bottom: 24px;
        align-items: center;

        h2 {
            font-weight: 300;
            font-size: 32px;
            line-height: 37px;
            color: #000000;
        }
    }

    &__export {
        font-size: 16px;
        line-height: 19px;
        text-align: right;
        color: #2990fb;
        margin-left: auto;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        align-items: center;

        > img {
            margin-left: 12px;
        }
    }
    @include max_rwd(960) {
        position: relative;

        &__export {
            margin-top: -45px;
        }
    }
}
