@import './../../../../../../../../../../../../../../styles/mixins';

.rank-battle {
    &__votes {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        min-height: 100px;
        text-align: center;
        box-sizing: border-box;
        user-select: none;
        position: relative;
        padding-bottom: 28px;
        @include transition(0.2s, ease-in-out, 0s, color);
        &:before {
            content: '';
            position: absolute;
            z-index: 5;
            bottom: 100%;
            left: 0;
            width: 100%;
            height: 100px;
            background: linear-gradient(180deg, transparent 0%, var(--gradientColor) 100%);
        }
        &.is-mobile {
            width: 100px;
            flex-shrink: 0;
            position: static;
            padding-bottom: 20px;
            &:before {
                left: 0;
                bottom: 0;
                right: 100px;
                width: unset;
            }
        }
        &--is-text-card {
            &.is-mobile {
                border-left: 1px solid var(--mobileBorderColor);
            }
            &:before {
                display: none;
            }
        }
        &__button-box {
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            z-index: 10;
            margin-bottom: -8px;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1));
            &--is-no-description {
                margin: 0;
                bottom: 60px;
            }
            &.is-mobile {
                right: 100px;
                left: unset;
                bottom: 0;
                margin: 0;
                transform: none;
            }
        }
        &__inner {
            position: relative;
            text-transform: uppercase;
            text-align: center;
            display: flex;
            justify-content: center;
            &.is-mobile {
                flex-direction: column;
                align-items: flex-start;
                margin: 0 10px 0 12px;
            }
        }
        &-label {
            padding-right: 5px;
            &.is-mobile {
                padding-right: 0;
            }
        }
        &__count-wrapper {
            display: inline-block;
            &.is-mobile {
                margin-top: 4px;
                display: block;
            }
        }
        &__count {
            display: inline-block;
            animation: animateText 0.4s ease-in-out;
            font-weight: 700;
            font-size: 24px;
            &.is-mobile {
                font-size: 28px;
            }
        }
    }
}

@keyframes animateText {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
