.dropdown {
    background: #ffffff;
    border: 1px solid #acacac;
    border-radius: 2px;
    width: 160px;
    height: 36px;
    padding: 0 16px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;

    &.view--2 {
        padding-left: 12px;
        padding-right: 12px;
        width: 100%;
        border-radius: 4px;
        border-color: $color_middle_gray--2;
        &:hover {
            border-color: $color_dark_gray--1;
        }
        &.is-error {
            border-color: $color_red--1;
        }
        &.is-open {
            border-color: $color_light_blue--1 !important;
            border-width: 2px !important;
            padding-left: 11px;
            padding-right: 11px;
        }
    }

    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        > span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(100% - 15px);
            &.is-placeholder {
                color: $color_dark_gray--2;
            }
        }

        > svg {
            fill: #686868;
            @include transition(
                $transition_duration--1,
                $transition_timing_function--1,
                $transition_delay--1,
                'transform'
            );
        }
    }

    font-size: 16px;
    line-height: 18px;
    color: #555555;

    @include no-select;

    &.is-open {
        border: 1px solid #2990fb !important;
        .dropdown-list {
            display: block !important;
        }

        > div {
            > svg {
                transform: rotate(180deg);
            }
        }
    }

    &-list {
        box-sizing: border-box;
        display: none;
        position: absolute;
        left: 0;
        top: calc(100% + 3px);
        z-index: 1;
        background: #fff;
        width: 100%;
        border: 1px solid #d9d9d9;
        padding: 8px 0;

        > li {
            padding: 8px 16px 8px 16px;

            &:hover {
                background: #d9d9d9;
            }
        }
    }
}
