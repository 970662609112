@import './../../../../../../../../../../styles/mixins';

.rank-battle {
    &__playground {
        width: 100%;
        box-sizing: border-box;
        position: relative;
        z-index: 10;
    }

    &__cards {
        display: grid;
        grid-template-columns: repeat(var(--cardsInRowCount), 1fr);
        position: relative;
        &.is-mobile {
            width: 100%;
            margin: 0 !important;
        }
    }
}
