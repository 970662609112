@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/mixins';

.card {
    width: calc(50% - 10px);
    background-color: #fff;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.0668875);
    border-radius: 4px;
    margin-right: 20px;
    padding: 36px 60px;
    display: flex;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: cover;
    &:last-child {
        margin-right: 0;
    }
    @include max_rwd(1280) {
        width: 100%;
        margin: 0 0 20px;
        padding: 30px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include max_rwd(620) {
        flex-direction: column;
    }
}

.isAloneCard {
    width: 100%;
    margin-right: 0;
}

.image {
    flex-shrink: 0;
    margin-right: 50px;
    @include max_rwd(620) {
        margin-right: 0;
        text-align: center;
        margin-bottom: 20px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include max_rwd(620) {
        align-items: center;
    }
}

.title {
    font-family: $Ubuntu;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #000;
    margin-bottom: 20px;
    max-width: 320px;
    @include max_rwd(620) {
        max-width: 100%;
        text-align: center;
    }
}

.children {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include max_rwd(620) {
        align-items: center;
    }
}
