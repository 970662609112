@import '../../../../styles/mixins';

.stripeConnect {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 100%;
    @include max_rwd(767) {
        flex-direction: column;
    }
}

.buttonBlock {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    @include max_rwd(767) {
        width: 100%;
    }
    > button {
        margin-left: 16px;
        &:first-child {
            margin-left: 0;
        }
        @include max_rwd(767) {
            width: 100%;
            margin: 0 0 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
