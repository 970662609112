@import '../../../styles/variables';
@import '../utils/variables';

.asset-manager-place-bar {
    position: absolute;
    z-index: 20;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;

    &__content {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        flex-basis: 100%;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        background-color: #f9fafd;
    }

    button {
        margin: 12px 40px 16px;
        justify-self: flex-end;
    }
}
