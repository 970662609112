.controls {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.control {
    position: relative;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    border-radius: 50%;
    &:hover {
        opacity: 0.8;
    }
}

.controlStop {
    background-color: #ff5656;
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 22px;
        height: 22px;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 4px;
    }
}
.controlStart {
    border: 2px solid #ff5656;
    background-color: #fff;
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 28px;
        height: 28px;
        transform: translate(-50%, -50%);
        background-color: #ff5656;
        border-radius: 50%;
    }
}

.controlNote {
    margin-top: 20px;
    font-size: 16px;
    color: #3c3c3c;
}
