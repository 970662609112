@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.topBanner {
    position: relative;
    height: 120px;
    margin-bottom: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $Ubuntu;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    text-align: center;
    color: #fff;
    padding: 0 130px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include max_rwd(900) {
        padding: 0 60px;
        font-size: 26px;
        line-height: 32px;
        flex-direction: column;
    }
}

.counter {
    position: absolute;
    font-family: $Ubuntu;
    font-size: 24px;
    line-height: 24px;
    color: #fff;
    top: 50%;
    left: 130px;
    transform: translateY(-50%);
    @include max_rwd(900) {
        transform: none;
        position: static;
        font-size: 20px;
        line-height: 20px;
    }
}
