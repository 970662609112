.filterRoot {
    display: flex;
    align-items: center;
}

.label {
    text-align: left;
    color: #787878;
    margin-right: 8px;
}

.dropdown {
    padding: 0;

    &:focus {
        background-color: transparent;
    }
}
