@import '../../../styles/variables';
@import '../../../styles/mixins';

.fillYourProfile {
    width: 640px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    padding: 50px 100px 60px;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include max_rwd(680) {
        padding: 40px 20px;
    }
}

.title {
    font-family: $Ubuntu;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -1px;
    color: #000;
    margin-bottom: 24px;
}

.subtitle {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #3c3c3c;
    margin-bottom: 36px;
}

.list {
    margin-bottom: 48px;
}

.item {
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    color: #3c3c3c;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    &:last-child {
        margin-bottom: 0;
    }
}

.itemIcon {
    margin-right: 7px;
}
