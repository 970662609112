.result {
    margin-bottom: 40px;
    &__title {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-content {
            position: relative;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 500;
            font-size: 24px;
            color: #3c3c3c;
            display: flex;
            align-items: center;

            &-note {
                position: absolute;
                left: 100%;
                bottom: 4px;
                margin-left: 16px;
                font-family: 'Roboto', sans-serif;
                font-size: 14px;
                color: #979797;
                white-space: nowrap;
            }
        }
    }

    &__body {
    }
}
