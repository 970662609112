@import '../../styles/variables';

.error-boundary {
    width: 95%;
    box-sizing: border-box;
    padding: 40px 60px;
    background: #ffffff;
    margin: 0 auto;
    text-align: center;
    font-family: $Roboto;
    font-style: normal;
    font-weight: 400;
    white-space: pre-line;
    color: #3c3c3c;

    &__error-icon {
        background-image: url('../../svg/wrong-outline.svg');
        background-position: center;
        width: 32px;
        height: 32px;
        margin: 0 auto 12px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__error-title {
        font-family: $Ubuntu;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 30px;
    }
    &__error-message {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 25px;
    }
    &__reload-btn {
        background-repeat: no-repeat;
        background-position: 40px 50%;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 12C15 15.866 11.866 19 8 19C4.13401 19 1 15.866 1 12C1 8.13401 4.13401 5 8 5H10' stroke='%23787878' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M7 9L11 5L7 1' stroke='%23787878' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        border: 1px solid #d8d8d8;
        color: #787878;
        border-radius: 4px;
        padding: 5px 40px 5px 66px;
        cursor: pointer;
        height: 44px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
        font-size: 20px;
        margin-bottom: 25px;
    }
    &__details {
        max-width: 700px;
        margin: 0 auto;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #d8d8d8;
        border-radius: 4px;
        padding: 12px 20px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);

        &-btn {
            cursor: pointer;
            color: #787878;
            white-space: nowrap;
            font-size: 16px;
            line-height: 19px;
        }
        &-stack {
            overflow: hidden;
            text-align: left;
            white-space: pre-line;
            font-size: 16px;
            line-height: 18px;
            background: #fff;
            color: #ff4d4f;
            transition: max-height 0.25s ease-out;
            max-height: 0;

            &.is-show {
                max-height: 2000px;
            }

            &-title {
                padding: 10px 15px 0;
            }

            &-content {
                border-top: dashed 1px #d8d8d8;
                margin-top: 12px;
            }
        }
    }
}
