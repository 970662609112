.banner {
    box-shadow: $box-shadow--1;
    box-sizing: border-box;
    margin-bottom: 16px;
    @include max_sm {
        display: none;
    }

    &[data-variant='danger'] {
        background-color: $color_light_red--2;
    }

    &[data-padding='normal'] {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    &[data-padding='small'] {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    &-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > div {
            display: flex;
            align-items: center;
            padding-right: 50px;
            > span.icon {
                margin-right: 20px;
                &[data-icon='attention'] {
                    > svg path.color_fill {
                        fill: $color_white;
                    }
                }
            }
            > .text {
                color: $color_white;
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}
