@import '../../../styles/variables';
@import '../../../styles/mixins';

.form-upload {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.form-upload-label {
    font-family: $Roboto;
    font-size: 16px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding: 0 14px;
    box-sizing: border-box;
    border: 1px solid #2990fb;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    height: 36px;
    color: #2990fb;
    background-color: white;

    @include transition(
        $transition_duration--1,
        $transition_timing_function--1,
        $transition_delay--1,
        (box-shadow, border-color, background-color)
    );

    &:hover {
        box-shadow: $box-shadow--2;
    }

    &:disabled {
        pointer-events: none !important;
    }
}
