@import '../../../../../styles/mixins';

.formCombinedBlock {
    width: 50%;
    box-sizing: border-box;
    &:nth-child(odd) {
        padding-right: 14px;
    }
    &:nth-child(even) {
        padding-left: 14px;
    }
    @include max_rwd(700) {
        width: 100%;
        padding: 0 !important;
        margin-bottom: 24px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
