@import '../../../../../../../../styles/variables';
@import '../../../../../../../../styles/mixins';

.banner {
    margin-top: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 28px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    @include max_rwd(767) {
        flex-direction: column;
    }
}

.texts {
    width: 100%;
}

.title {
    font-size: 20px;
    line-height: 23px;
    color: #555;
}

.description {
    margin-top: 9px;
    font-size: 14px;
    line-height: 20px;
    color: #3c3c3c;
}

.buttonContainer {
    margin-left: 40px;
    flex-shrink: 0;
    @include max_rwd(767) {
        margin-left: 0;
        width: 100%;
    }
}

.button {
    font-family: $Ubuntu;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    @include max_rwd(767) {
        width: 100%;
        margin-top: 10px;
        white-space: normal;
        font-size: 14px !important;
    }
}
