.preview {
    position: relative;
}

.title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #3c3c3c;
    margin-bottom: 8px;
}

.block {
    box-sizing: border-box;
    width: 128px;
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    padding: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.blockMatching {
    display: flex;
    padding: 8px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.matchingPreview {
    background-color: #f2f3f7;
    border: 1px dashed #d8d8d8;
    border-radius: 4px;
    background-image: url('./i/undo.svg');
    background-position: center;
    background-repeat: no-repeat;
    height: 90px;
    width: 90px;
    box-sizing: border-box;
}

.matchingTextWrapper {
    height: 90px;
    width: 90px;
    box-sizing: border-box;
    margin-left: 8px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.matchingText {
    color: #000;
    text-align: left !important;
}

.text {
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    word-wrap: break-word;
    overflow: hidden;
    text-align: center;

    &.big {
        font-size: 28px;
        line-height: 32px;
    }

    &.medium {
        font-size: 20px;
        line-height: 24px;
    }

    &.small {
        font-size: 15px;
        line-height: 20px;
    }
}
