@import '../../../../../../styles/mixins';

.headMark {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 28px;
    height: 32px;
    padding: 0 20px 0 40px;
    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    position: relative;
    @include transition(0.2s, ease-in-out, 0s, (background-color, color));
    &:before {
        content: '';
        position: absolute;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
        width: 8px;
        height: 8px;
        border: 1px solid #fff;
        background-color: transparent;
        @include transition(0.2s, ease-in-out, 0s, (border-color, background-color));
    }
}

.isActiveHeadMark {
    background-color: #fff;
    color: #555;
    &:before {
        border-color: #65bb5a;
        background-color: #65bb5a;
    }
}
