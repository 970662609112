.topBar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1019px) {
        padding-top: 30px;
    }
}

.stepNumber {
    font-size: 16px;
    line-height: 20px;
    color: #979797;
    &:first-letter {
        color: #787878;
    }
}

.stepLines {
    display: flex;
    width: 362px;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: calc(50% - 10px);
    transform: translate(-50%, -50%);
    @media (max-width: 1019px) {
        left: 50%;
        top: 0;
        transform: translateX(-50%);
    }
}

.stepLine {
    width: 100%;
    background-color: #f2f3f7;
    border-radius: 20px;
    height: 8px;
    margin: 0 4px;
}

.isActiveStepLine {
    background-color: #2990fb;
}

.buttonArrow {
    margin-left: 4px;
}
