@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.my-project-top-bar {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 16px;
    position: relative;
    overflow: hidden;
    @include max_rwd(1200) {
        padding-bottom: 30px;
    }

    > div {
        &.actions {
            display: flex;
            justify-content: flex-end;
            margin-left: 15px;
            .create-project-link {
                &:hover {
                    background-color: $color_blue--1;
                    color: $color_white;
                }
                @include max_sm {
                    padding: 0;
                    width: 36px;
                    justify-content: center;
                    > span {
                        display: none;
                    }
                }
            }
        }
    }

    .title {
        display: flex;
        position: relative;
        text-align: left;
        font-size: 20px;

        .path {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;
            cursor: pointer;
            color: #979797;

            &.is-last {
                color: #3c3c3c;
                cursor: default;
            }
        }

        .member-prefix {
            color: #979797;
            margin-right: 5px;
        }

        .member-postfix {
            color: #979797;
            margin-left: 5px;
        }
    }
}
