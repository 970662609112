@import '../../../../../../styles/mixins';

.formButtons {
    justify-content: space-between;
    align-items: center;
    @include max_rwd(480) {
        flex-direction: column;
    }
}

.checkboxClassName {
    margin-top: 0 !important;
}

.formButton {
    margin-left: 40px;
    @include max_rwd(480) {
        margin-left: 0;
        margin-top: 10px;
    }
}
