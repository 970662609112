.wrapper {
    > .error {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        min-width: 100vw;
        background-color: #f9fafd;
        .error-box {
            display: flex;
            &_code {
                font-size: 40px;
                margin-right: 10px;
            }
            &_content {
                &--text {
                    font-size: 18px;
                    margin-bottom: 5px;
                }
                &--link a {
                    text-decoration: underline;
                    color: #2990fb;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
