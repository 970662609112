.data-grid {
    width: 100%;
    &__header {
        display: flex;
        border-bottom: 1px solid #d9d9d9;
        padding: 15px 0;
        &__column {
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            font-size: 15px;
        }
    }
    &__content {
        display: flex;
        &__column {
            display: flex;
            align-items: center;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            color: #626262;
            padding: 15px 0;
            border-bottom: 1px solid #d9d9d9;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    &__empty {
        padding: 100px 0;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        color: #626262;
        text-align: center;
    }

    //&__columns {
    //    display: inline-flex;
    //    align-content: stretch;
    //}
    //
    //&__column {
    //    padding-right: 10px;
    //}
    //
    //&__rows {
    //    display: block;
    //}
    //
    //&__row {
    //    display: flex;
    //    padding: 10px;
    //}
    //
    //&__cell {
    //    padding-right: 10px;
    //}
}
