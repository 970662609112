@import '../../../../styles/mixins';

.multiplayerWelcome {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 960px;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: auto;
    padding: 64px 178px 68px;
    text-align: center;
    @include max_rwd(1023) {
        padding: 40px 60px;
    }
    @include max_rwd(559) {
        padding: 40px 20px;
    }
}

.imageBox {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 224px;
    > img {
        max-width: 100%;
    }
}

.title {
    margin-top: 30px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -1px;
    color: #000;
}

.description {
    margin: 20px auto 0;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #3c3c3c;
    max-width: 604px;
}

.note {
    margin: 12px auto 0;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #3c3c3c;
    max-width: 604px;
}

.noteLink {
    color: #2990fb;
    transition: opacity 0.2s ease-in-out;
    text-decoration: none;
    &:hover {
        opacity: 0.8;
    }
}

.buttonBox {
    margin-top: 32px;
    display: flex;
    justify-content: center;
}

.button {
    min-width: 180px;
    max-width: 100%;
}
