.textAndImage {
    display: flex;
    align-items: center;
    padding: 16px 0;
}

.isLeftText {
    flex-direction: row-reverse;
    .image {
        margin: 0 16px 0 25px;
    }
}

.image {
    flex-shrink: 0;
    width: calc(50% - 36px);
    margin: 0 25px 0 16px;
    border-radius: 8px;
}

.content {
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
}

.contentText {
    border: 1px dashed #979797;
}

.contentButton {
    margin-top: 28px;
    text-align: left;
}
