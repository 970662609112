@import '../../styles/mixins';

.questionBank {
    padding: 48px 40px 60px;
    background-color: #fff;
    @include max_rwd(1025) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    @include max_rwd(680) {
        flex-direction: column;
    }
}

.title {
    font-size: 20px;
    color: #555;
}
.buttons {
    flex-shrink: 0;
    display: flex;
    margin-left: 20px;
    @include max_rwd(680) {
        margin-left: 0;
        margin-top: 20px;
        width: 100%;
        flex-direction: column;
    }
}
.uploadButton {
    @include max_rwd(680) {
        width: 100%;
    }
}
.uploadButtonLabel {
    @include max_rwd(680) {
        justify-content: center;
    }
}

.uploadButtonIcon {
    margin-right: 8px;
}

.createButton {
    height: 36px;
    margin-right: 16px;
    @include max_rwd(680) {
        margin-right: 0;
        margin-bottom: 10px;
    }
}

.workplace {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include max_rwd(1025) {
        flex-direction: column;
    }
}

.banks {
    width: 100%;
}

.banksGroup {
    margin-bottom: 40px;
    &:last-child {
        margin-bottom: 0;
    }
}

.banksGroupTitle {
    margin-bottom: 15px;
    font-size: 16px;
    color: #8a8a8a;
}
