@import '../../../../../../styles/mixins';

.label {
    font-size: 16px !important;
    color: #8a8a8a !important;
    margin-bottom: 8px !important;
    height: auto !important;
}

.avatarBlock {
    margin-right: 40px;
    flex-shrink: 0;
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;
    @include max_rwd(700) {
        margin: 0 0 20px;
        display: flex;
        justify-content: center;
    }
}

.inputsBlock {
    width: 100%;
}
