.playground {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    z-index: 10;
}

.workplace {
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    flex-direction: column;
    position: relative;
}
