@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.code-box {
    margin-top: 18px;
    &__block {
        margin-bottom: 32px;
        font-size: 0;
        &:last-child {
            margin-bottom: 0 !important;
        }
        &-title {
            margin-bottom: 12px;
            font-size: 16px;
            color: $color_dark_gray--1;
        }
    }
    &__code {
        display: flex;
        justify-content: space-between;
        @include max_md() {
            flex-direction: column;
            align-items: center;
        }
        &-textarea {
            width: 100%;
        }
        &-button {
            flex-shrink: 0;
            margin: 5px 0 5px 20px;
            @include max_md() {
                margin: 10px;
                justify-content: center;
            }
        }
    }
}
