@import '../../../../../../styles/mixins';

.inspection {
    padding-top: 15px;
    width: 100%;
}

.box {
    display: flex;
    @include max_rwd(767) {
        flex-direction: column;
    }
}

.buttons {
    margin-top: 36px;
    display: flex;
    justify-content: center;
}

.newGameButton {
    > img {
        margin-right: 8px;
    }
}
