@import '../../../../../../../../../../styles/mixins';

.priceItem {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}

.top {
    display: flex;
    margin-bottom: 12px;
}

.topBox {
    display: flex;
}
.isDisabledTopBox {
    pointer-events: none;
}

.box {
    width: 14px;
    height: 14px;
    background-color: #d7d7d7;
    border-radius: 2px;
    margin: 2px 8px 0 0;
    cursor: pointer;
}

.isActiveBox {
    background-color: #2990fb;
    background-image: url('./i/check.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.title {
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    color: #3c3c3c;
}

.price {
    display: flex;
    align-items: center;
    @include max_rwd(512) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.select {
    min-width: 80px;
    margin-right: 14px;
    flex-shrink: 0;
    @include max_rwd(512) {
        margin-right: 0;
    }
}

.description {
    font-size: 14px;
    line-height: 18px;
    color: #787878;
    max-width: 420px;
    @include max_rwd(512) {
        margin-top: 5px;
    }
}
