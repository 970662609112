.newUserModal {
    display: flex;
    max-width: 100%;
    width: 960px;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    min-height: 600px;
    box-sizing: border-box;
}
