.slideshow {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 0;

        &-thumbnails {
            display: flex;
            flex-direction: row;
            margin-bottom: 12px;
            width: 100%;
            height: 70px;
            position: relative;
            overflow: hidden;

            &-items {
                display: flex;
                align-items: center;
                height: 62px;
                position: absolute;
                left: 46%;

                &-img {
                    display: flex;
                    border: 1px solid #d8d8d8;
                    box-sizing: border-box;
                    position: relative;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 60px;
                    height: 60px;
                    margin: 0 4px;
                    opacity: 0.75;

                    &.selected {
                        box-shadow: inset 0 0 0 2px #69b1fc;
                        border: none;
                        opacity: 1;
                    }
                }
            }
        }

        &-carousel {
            display: flex;
            flex-direction: row;
            position: relative;
            width: 100%;
            overflow: hidden;
            height: 480px;

            &-items {
                display: flex;
                margin: 0 40px;
                width: 100%;
                height: 100%;

                &-img {
                    display: flex;
                    border: 0;
                    position: relative;
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                    height: 100%;
                    margin: 0 10px;
                }
            }
        }

        &-descriptions {
            position: relative;
            width: 100%;
            overflow: hidden;
            text-align: left;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;

            &-header {
                font-size: 20px;
                line-height: 28px;
                padding: 0 20px 0;
            }

            &-text {
                font-size: 16px;
                line-height: 24px;
                padding: 12px 20px 0;
                white-space: pre-line;
            }

            &-image-caption {
                font-size: 14px;
                line-height: 16px;
                padding: 28px 20px 0;
                opacity: 0.6;
            }

            div:first-child {
                padding-top: 20px;
            }
        }
    }
}
