.modal-wrapper {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(9, 25, 48, 0.5);
    &__container {
        position: relative;
        height: 100%;
        outline: 0;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: center;
        &:after {
            content: '';
            width: 0;
            height: 100%;
            display: inline-block;
            vertical-align: middle;
        }
    }
    &__content {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        overflow-y: auto;
        border: 20px solid transparent;
        max-width: 100%;
        box-sizing: border-box;
    }
    &__close-icon {
        position: absolute;
        z-index: 2;
        top: 24px;
        right: 24px;
        width: 22px;
        height: 22px;
        background-image: url('i/cross.svg');
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        transition: opacity 0.2s ease-in-out;
        &--white {
            background-image: url('i/cross--white.svg');
        }
        &:hover {
            opacity: 0.7;
        }
    }
}
