@import '../../../../../../../../styles/variables';
@import '../../../../../../../../styles/mixins';

.statistics {
    position: relative;
}

.statisticsHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.statisticsHeaderTitle {
    font-weight: 300;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 40px;
}

.statisticsHeaderTip {
    box-sizing: border-box;
    display: flex;
    width: 342px;
    height: fit-content;
    background-color: #fff;
    border: 1px solid #2990fb;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    padding: 20px;
    > svg {
        flex: none;
    }
}

.statisticsHeaderTipNote {
    font-size: 15px;
    line-height: 18px;
    color: #626262;
    margin-left: 12px;
}

.statisticsMain {
    position: relative;
}

.statisticsMainBlockWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #626262;
    margin-bottom: 8px;
}
.statisticsMainBlockHeader {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000;
    margin-bottom: 24px;
}
.statisticsMainBlockTitle {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;
}
.statisticsMainBlockValues {
    font-weight: 500;
    color: #000;
    text-align: right;
    > span {
        margin-left: 5px;
        color: #8d8d8d;
    }
}

.statisticsBlockList {
    position: relative;
}

.statisticsBlockListItem {
    margin-bottom: 8px;
    border-bottom: 1px solid #dfdfdf;
    &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
    }
}

.statisticsMainLinearProgress {
    height: 12px;
    border-radius: 20px;
    background-color: #f8f8f8;
}

.statisticsMainLinearProgressBar {
    background-color: #69b1fc;
}

.statisticsMainBlockImage {
    width: 40px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
