@import '../../../../styles/mixins';

.editTemplateModal {
    width: 960px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    padding: 0 0 36px;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.content {
    padding: 0 130px;
    @include max_rwd(767) {
        padding: 0 40px;
    }
    @include max_rwd(512) {
        padding: 0 20px;
    }
}
