@import '../../../../../../../../styles/mixins';

.listActionBar {
    display: flex;
    padding: 4px 0 4px 0;
    @include transition(0.1s, ease-in-out, 0s, (opacity));
}

.action {
    margin-right: 8px;
    display: inline;
    cursor: pointer;
    font-size: 0;
    border-radius: 50%;
    background: #ffffff no-repeat center;
    @include transition(0.1s, ease-in-out, 0s, (opacity));

    &:before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background-position: center;
        background-size: initial;
        background-repeat: no-repeat;
    }
    &[data-icon='up'] {
        &:before {
            background-image: url('../../Modals/i/up.svg');
        }
    }
    &[data-icon='down'] {
        &:before {
            background-image: url('../../Modals/i/down.svg');
        }
    }
    &[data-icon='clone'] {
        &:before {
            background-image: url('../../Modals/i/clone.svg');
        }
    }
    &[data-icon='remove'] {
        &:before {
            background-image: url('../../Modals/i/remove.svg');
        }
    }
    &:last-child {
        margin-right: 0;
    }
    &:hover {
        opacity: 0.7;
    }
}
