@import '../../../../../../../../styles/variables';
@import '../../../../../../../../styles/mixins';

.wideBox {
    padding: 20px 40px 20px 20px;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    @include max_rwd(767) {
        flex-direction: column;
        padding: 20px;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.imageBox {
    flex-shrink: 0;
    width: 120px;
    height: 102px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include max_rwd(767) {
        justify-content: center;
    }
}

.image {
    max-width: 100%;
    max-height: 100%;
}

.content {
    margin-left: 30px;
    width: 100%;
    @include max_rwd(767) {
        margin-left: 0;
        margin-top: 16px;
    }
}

.title {
    font-size: 16px;
    line-height: 20px;
    color: #3c3c3c;
    padding-bottom: 8px;
}

.newLabel {
    margin-left: 8px;
    display: inline-block;
    height: 20px;
    padding: 0 6px;
    vertical-align: middle;
    background-color: #6b00be;
    border-radius: 28px;
    width: 22px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #fff;
}

.body {
    position: relative;
}
