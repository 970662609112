@import '../../../../styles/mixins';

.card {
    padding: 40px 60px;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.0668875);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @include max_rwd(1280) {
        padding: 30px 40px;
        flex-direction: column;
    }
    @include max_rwd(767) {
        padding: 30px 20px;
    }
}

.isHalfCard {
    width: 50%;
    margin: 0 14px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @include max_rwd(1280) {
        width: 100%;
        margin: 0 0 20px 0;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .head {
        width: 100%;
    }
}

.head {
    display: flex;
    align-items: flex-start;
    @include max_rwd(767) {
        flex-direction: column;
    }
}

.leftHead {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.rightHead {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    @include max_rwd(767) {
        width: 100%;
        align-items: flex-end;
    }
}

.info {
    padding-top: 15px;
}

.control {
    margin: -8px 0 0 20px;
    @include max_rwd(767) {
        margin: 15px 0 0;
    }
}

.title {
    font-size: 20px;
    line-height: 20px;
    color: #555;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.loader {
    width: 100%;
    display: flex;
    justify-content: center;
}
