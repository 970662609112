.list {
    width: 100%;
}

.item {
    margin-bottom: 24px;
    &:last-child {
        margin-bottom: 0;
    }
}
