@import '../../../../../../../../styles/variables';
@import '../../../../../../../../styles/mixins';

.printProcessingBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include max_rwd(1024) {
        flex-direction: column;
    }
}

.left {
    position: relative;
    @include max_rwd(1024) {
        text-align: center;
        margin-bottom: 16px;
    }
}

.title {
    font-size: 16px;
    line-height: 20px;
    color: #3c3c3c;
    margin-bottom: 8px;
}

.time {
    font-size: 14px;
    line-height: 20px;
    color: #298ff8;
}
.timeIcon {
    margin-right: 6px;
    display: inline-block;
    vertical-align: middle;
}

.right {
    display: flex;
    align-items: center;
    @include max_rwd(767) {
        flex-direction: column-reverse;
    }
}

.note {
    font-size: 14px;
    line-height: 20px;
    color: #979797;
    white-space: pre-wrap;
    text-align: right;
    @include max_rwd(767) {
        text-align: center;
    }
}

.loader {
    margin-left: 20px;
    height: 48px;
    width: 48px;
    flex-shrink: 0;
    @include max_rwd(767) {
        margin-left: 0;
        margin-bottom: 16px;
    }
}
