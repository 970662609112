.memory-playground-preview {
    position: relative;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #787878;
    margin: 10px 0;
    pointer-events: none;

    .memory-playground-preview__statistic-wrapper {
        display: flex;
        padding: 18px 28px 10px;
        margin: 8px;
        justify-content: space-between;
        background-color: #fff;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
        border-radius: 8px;

        color: #3c3c3c;
        line-height: 24px;
        font-size: 24px;

        .memory-playground-preview__statistic-moves-wrapper {
            .memory-playground-preview__statistic-moves-title {
                font-size: 20px;
                color: #787878;
                margin-right: 8px;
            }
        }

        .memory-playground-preview__statistic-timer {
            display: flex;
            position: relative;

            &:before {
                content: '';
                position: relative;
                display: inline-block;
                width: 32px;
                height: 32px;
                margin-right: 12px;
                bottom: 4px;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIwIDNIMTJWMUgyMFYzWk0yNiAxN0MyNiAyMi41MjI4IDIxLjUyMjggMjcgMTYgMjdDMTAuNDc3MiAyNyA2IDIyLjUyMjggNiAxN0M2IDExLjQ3NzIgMTAuNDc3MiA3IDE2IDdDMjEuNTIyOCA3IDI2IDExLjQ3NzIgMjYgMTdaTTE2IDI5QzIyLjYyNzQgMjkgMjggMjMuNjI3NCAyOCAxN0MyOCAxNC40MDgzIDI3LjE3ODQgMTIuMDA4NSAyNS43ODE0IDEwLjA0NjhMMjcuNDE0MSA4LjQxNDE4TDI1Ljk5OTggNi45OTk5N0wyNC40ODUyIDguNTE0NjNDMjIuMzEzNiA2LjM0MzExIDE5LjMxMzcgNSAxNiA1QzkuMzcyNTggNSA0IDEwLjM3MjYgNCAxN0M0IDIzLjYyNzQgOS4zNzI1OCAyOSAxNiAyOVpNMTUgMTBWMThIMTdWMTBIMTVaIiBmaWxsPSIjNzg3ODc4Ii8+Cjwvc3ZnPgo=');
            }
        }
    }

    .memory-playground-preview__rows-wrapper {
        height: 100%;
        margin: 0 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: stretch;
        align-items: stretch;
        counter-reset: section;

        .memory-card-preview__row {
            display: flex;
            justify-content: center;
            align-content: stretch;
            align-items: stretch;

            .memory-card-preview__cell {
                margin: 3px;
                border-radius: 8px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                background-color: #d7d7d7;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                &.number-option-1 {
                    font-size: 6em;
                    color: #ffffff;
                    text-shadow: 0 0 16px #787878;
                    &:before {
                        counter-increment: section;
                        content: counter(section);
                    }
                }
                &.number-option-2 {
                    &:before {
                        counter-increment: section;
                        content: counter(section);
                        font-family: 'Ubuntu', sans-serif;
                        font-size: 36px;
                        color: #3c3c3c;
                        position: absolute;
                        top: 8px;
                        right: 8px;
                        width: 60px;
                        height: 60px;
                        background-color: #fff;
                        border: 1px solid rgba(60, 60, 60, 0.24);
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }

    .memory-playground-preview__cover-wrapper {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        .memory-playground-preview__cover {
            width: 60%;
            padding: 60px 40px;
            background: #fff;
            border-radius: 8px;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
            text-align: center;

            .memory-playground-preview__cover-header {
                font-weight: 500;
                font-size: 36px;
                line-height: 42px;
                color: #000;
                margin-bottom: 32px;
                white-space: pre-line;
            }

            .memory-playground-preview__cover-btn {
                overflow: hidden;
                outline: none;
                cursor: pointer;
                border: 0;
                padding: 0 44px;
                height: 50px;
                white-space: nowrap;
                box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
                border-radius: 4px;
                color: #fff;
                font-size: 20px;
            }

            &.is-modal {
                padding: 20px 25px;
                .memory-playground-preview__cover-header {
                    font-size: 16px;
                    line-height: 18px;
                    margin-bottom: 15px;
                }

                .memory-playground-preview__cover-btn {
                    font-size: 12px;
                    padding: 0 42px;
                    height: 35px;
                }
            }
        }
    }
}
