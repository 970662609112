@import '../../../../../../styles/mixins';

.content {
    width: 560px;
    max-width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 48px 40px 40px;
    box-sizing: border-box;
    @include max_rwd(767) {
        padding: 34px 20px 30px;
    }
}

.title {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -1px;
    color: #000;
    @include max_rwd(767) {
        font-size: 24px;
        line-height: 24px;
    }
}

.text {
    margin-top: 32px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #3c3c3c;
    padding: 0 20px;
    @include max_rwd(767) {
        margin-top: 24px;
        padding: 0;
        font-size: 14px;
        line-height: 20px;
    }
}

.chooseBlocks {
    display: flex;
    margin: 36px -3px 0;
    @include max_rwd(767) {
        margin: 24px 0 0;
        flex-direction: column;
    }
}

.buttonBox {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.button {
    font-size: 16px !important;
}
.buttonRussian {
    font-size: 16px !important;
    height: auto !important;
    padding: 6px 16px !important;
    white-space: normal !important;
    max-width: 100%;
}

.buttonIconBox {
    margin-left: 10px;
    padding-top: 2px;
}

.closeIcon {
    @include max_rwd(767) {
        top: 9px;
        right: 9px;
    }
}

.paymentDetails {
    margin-top: 28px;
}
.paymentDetailsTitle {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #3c3c3c;
}

.paymentDetailsInputs {
    margin-top: 16px;
}
.paymentDetailsInput {
    height: 44px !important;
    box-sizing: border-box;
}
.paymentDetailsInputBox {
    margin-bottom: 5px;
    &:last-child {
        margin-bottom: 0;
    }
}
