.modalContent {
    max-width: 100%;
    width: 620px;
    text-align: left;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
}

.title {
    background-color: #2990fb;
    padding: 20px 20px 20px 32px;
    border-bottom: 1px solid #d8d8d8;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #fff;
}

.content {
    position: relative;
}

.buttons {
    border-top: 1px solid #d8d8d8;
    padding: 10px 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > button {
        margin-right: 12px;
        &:last-child {
            margin-right: 0;
        }
    }
}
