@import '../../../../styles/mixins';

.public {
    margin-top: 15px;
}

.combinedBlocks {
    display: flex;
    padding-bottom: 5px;
    @include max_rwd(700) {
        flex-direction: column;
    }
}

.combinedBlock {
    width: 50%;
    box-sizing: border-box;
    &:nth-child(odd) {
        padding-right: 14px;
    }
    &:nth-child(even) {
        padding-left: 14px;
    }
    @include max_rwd(700) {
        width: 100%;
        padding: 0 !important;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.label {
    font-size: 16px !important;
    color: #8a8a8a !important;
    margin-bottom: 8px !important;
    height: auto !important;
}

.formButtons {
    justify-content: space-between !important;
    @include max_rwd(700) {
        flex-direction: column-reverse;
        align-items: flex-end;
    }
}

.isPublishFormButtons {
    justify-content: flex-end;
    @include max_rwd(700) {
        justify-content: center;
    }
}

.blueButton {
    margin-left: 16px;
    > img {
        margin-left: 10px;
    }
    @include max_rwd(700) {
        margin-left: 0;
        margin-top: 10px;
    }
}

.successPublicModalButton {
    > img {
        margin-left: 10px;
    }
}

.unpublishButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    line-height: 19px;
    transition: opacity 0.2s ease-in-out;
    color: #fd8d8d;
    text-align: left;
    margin-right: 10px;
    @include max_rwd(700) {
        margin-top: 30px;
        justify-content: center;
        width: 100%;
        text-align: center;
    }
    > img {
        margin-right: 12px;
    }
    &:hover {
        opacity: 0.8;
    }
}

.isDisabledUnpublishButton {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
}

.buttons {
    display: flex;
    @include max_rwd(700) {
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
}
