@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.main {
    background-color: #f9fafd;
    width: 100%;
    box-sizing: border-box;
    padding: 28px 40px 110px;
    min-height: 550px;
    @include max_rwd(767) {
        padding: 20px;
    }
}

.wideCards {
    position: relative;
    margin-bottom: 16px;
}

.compactCards {
    display: flex;
    margin: 0 -8px;
    @include max_rwd(1024) {
        flex-direction: column;
        margin: 0;
    }
}
