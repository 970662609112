.item {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}

.info {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
}

.leftSide {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: #979797;
    margin-right: 10px;
}

.icon {
    margin-right: 8px;
    display: flex;
}

.limit {
    font-size: 14px;
    line-height: 20px;
    color: #979797;
    > span {
        color: #3c3c3c;
    }
}

.limitIcon {
    height: 20px;
    > img {
        max-height: 100%;
    }
}

.value {
    display: flex;
    align-items: center;
}

.line {
    width: 100%;
    height: 4px;
    background-color: #d9d9d9;
    border-radius: 18px;
    overflow: hidden;
    position: relative;
}

.filledLine {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    border-radius: 0 18px 18px 0;
}
