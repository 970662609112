.paymentMethod {
    position: relative;
}

.method {
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 4px;
    max-height: 26px;
}
