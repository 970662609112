@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.modal-header {
    height: 60px;
    background-color: $color_blue--1;
    padding: 0 86px 0 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    text-align: left;
    border-radius: 4px 4px 0 0;

    @include max_xs {
        padding: 0 32px;
    }
    &__title {
        font-family: 'Ubuntu', sans-serif;
        font-weight: 500;
        font-size: 24px;
        color: #fff;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: help;
        white-space: nowrap;
        padding: 4px 0;
    }
    &__nav-wrapper {
        position: absolute;
        box-sizing: border-box;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__nav {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
            content: '';
            position: absolute;
            right: calc(100% - 1px); // 1px - css hack
            top: 0;
            height: 100%;
            width: 50px;
            background: linear-gradient(
                to right,
                rgba(41, 144, 251, 0),
                $color_blue--1
            ); // 41, 144, 251 - RBG from $color_blue--1
        }
    }
    &__nav-item {
        box-sizing: border-box;
        position: relative;
        height: 32px;
        margin-right: 4px;
        cursor: pointer;
        background-color: #69b1fc;
        padding: 8px 8px 8px 22px;

        &:hover > p {
            opacity: 1;
        }
        > p {
            position: relative;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            color: #fff;
            opacity: 0.8;
            transition: opacity 0.2s ease-in-out;
        }
        &:after,
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: -11px;
            width: 0;
            height: 0;
            border-top: 16px solid transparent;
            border-bottom: 16px solid transparent;
            border-left: 11px solid #69b1fc;
            z-index: 2;
        }
        &:before {
            right: auto;
            left: 0;
            border-left: 11px solid #2990fb;
            z-index: 0;
        }
        &:last-child {
            padding-right: 23px;
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
            &:after {
                border: none;
            }
        }

        &--is-active {
            cursor: default;
            pointer-events: none;
            background-color: #fff;
            > p {
                opacity: 1 !important;
                color: #2990fb;
            }

            &:after {
                border-top: 16px solid transparent;
                border-bottom: 16px solid transparent;
                border-left: 11px solid #fff;
            }
        }
    }
    &__close {
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        right: 32px;
        width: 22px;
        height: 22px;
        background-image: url('i/cross.svg');
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        transition: opacity 0.2s ease-in-out;
        &:hover {
            opacity: 0.7;
        }
    }
}
