.button {
    position: relative;
    height: 44px;
    background-color: #2990fb;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
    padding: 0 16px;
    border: 1px solid transparent;
    &:hover {
        opacity: 0.8;
    }
}

.isDisabledButton {
    pointer-events: none;
    opacity: 0.4;
}

.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
        max-height: 50%;
        max-width: 50%;
        width: 40px;
    }
}