.aiButton {
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  margin-left: 20px;
  color: #2990fb;
  transition: opacity .2s ease-in-out;
  overflow: hidden;
  &:hover {
    opacity: .8;
  }
}

.aiButtonMain {
  height: 28px;
  display: flex;
  align-items: center;
  border: 1px dashed #2990fb;
  border-right: 0;
  box-sizing: border-box;
  border-radius: 4px 0 0 4px;
  padding: 0 2px 0 6px;
  > img {
    width: 20px;
    margin-left: 6px;
    animation: rotate 2s linear infinite;
  }
}

.aiButtonBeta {
  height: 28px;
  display: flex;
  align-items: center;
  background-color: #2990fb;
  color: #fff;
  padding: 0 6px;
  font-family: 'Ubuntu', sans-serif;
  font-size: 12px;
  border-radius: 0 4px 4px 0;
}

@keyframes rotate {
  0%{
    transform: rotate(0deg);
  }
  50%{
    transform: rotate(-5deg);
  }
  100%{
    transform: rotate(0);
  }
}