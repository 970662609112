@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.emptyProjectList {
    margin-top: 20px;
    border-radius: 4px;
}

.button {
    display: flex;
    align-items: center;
    padding: 10px 24px;
    box-shadow: 0 4px 20px rgba(41, 144, 251, 0.2);
    border-radius: 75px;
    font-family: $Roboto;
    color: #555;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 16px;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        box-shadow: 0 4px 20px rgba(41, 144, 251, 0.3);
        opacity: 0.8;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.blueButton {
    background-color: #2990fb;
    color: #fff;
}

.greenButton {
    background-color: #65bb5a;
    color: #fff;
}

.buttonIcon {
    width: 20px;
    height: auto;
    margin-right: 16px;
}
.rightButtonIcon {
    margin-right: 0;
    margin-left: 16px;
}
