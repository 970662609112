@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.delete-project-modal {
    width: 640px;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    padding: 48px 40px 28px;
    position: relative;
    box-sizing: border-box;
    box-shadow: $box-shadow--4;

    .main {
        .title {
            font-size: 24px;
            color: $color_middle_gray--1;
            text-align: center;
        }
        .icon {
            margin-top: 15px;
            text-align: center;
            > svg {
                margin-right: 10px;
                width: 55px;
                height: auto;
                path.color_fill--1 {
                    fill: $color_light_red--2 !important;
                }
            }
        }
        .text {
            margin-top: 12px;
            font-size: 16px;
            text-align: center;
            color: $color_middle_gray--1;
            line-height: 24px;
            > p {
                span {
                    color: $color_light_red--2;
                }
            }
        }
        .check-agree {
            margin-top: 40px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            > p {
                margin-left: 10px;
                font-size: 14px;
                line-height: 16px;
                color: $color_dark_gray--1;
            }
        }
    }
    .buttons {
        margin-top: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        > button {
            margin-right: 12px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .result {
        text-align: center;
        .icon {
            text-align: center;
            > svg {
                display: inline-block;
                width: 32px;
                height: auto;
            }
        }
        .title {
            margin-top: 12px;
            font-family: $Ubuntu;
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            color: $color_dark_gray--3;
            text-align: center;
        }
        .text {
            margin-top: 30px;
            font-size: 16px;
            line-height: 24px;
            color: $color_dark_gray--3;
            text-align: center;
        }

        &-success {
            .icon {
                > svg {
                    path.color_stroke--1 {
                        stroke: $color_green--1 !important;
                    }
                }
            }
        }
        &-error {
            .icon {
                > svg {
                    path.color_stroke--1 {
                        stroke: $color_red--1 !important;
                    }
                }
            }
        }
    }

    &.p1 {
        padding: 12px 0 32px;
    }
}
