@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.invitation-sent-by-email-modal {
    width: 520px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    padding: 30px 60px 40px;
    position: relative;
    box-sizing: border-box;
    text-align: center;
    filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.25));
    @include max_sm {
        padding-left: 30px;
        padding-right: 30px;
    }
    &__image {
        text-align: center;
        margin-bottom: 20px;
    }
    &__title {
        font-family: $Ubuntu;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #3c3c3c;
        margin-bottom: 16px;
    }
    &__description {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #979797;
        margin-bottom: 24px;
    }
    &__button {
        display: flex;
        justify-content: center;
    }
}
