.preview {
    position: relative;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #787878;
    margin: 10px 0;
    pointer-events: none;
}

.statisticWrapper {
    display: flex;
    padding: 18px 28px 10px;
    margin: 8px;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    color: #3c3c3c;
    line-height: 24px;
    font-size: 24px;
}
.statisticMovesTitle {
    font-size: 20px;
    color: #787878;
    margin-right: 8px;
}
.statisticTimer {
    display: flex;
    position: relative;
    &:before {
        content: '';
        position: relative;
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 12px;
        bottom: 4px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIwIDNIMTJWMUgyMFYzWk0yNiAxN0MyNiAyMi41MjI4IDIxLjUyMjggMjcgMTYgMjdDMTAuNDc3MiAyNyA2IDIyLjUyMjggNiAxN0M2IDExLjQ3NzIgMTAuNDc3MiA3IDE2IDdDMjEuNTIyOCA3IDI2IDExLjQ3NzIgMjYgMTdaTTE2IDI5QzIyLjYyNzQgMjkgMjggMjMuNjI3NCAyOCAxN0MyOCAxNC40MDgzIDI3LjE3ODQgMTIuMDA4NSAyNS43ODE0IDEwLjA0NjhMMjcuNDE0MSA4LjQxNDE4TDI1Ljk5OTggNi45OTk5N0wyNC40ODUyIDguNTE0NjNDMjIuMzEzNiA2LjM0MzExIDE5LjMxMzcgNSAxNiA1QzkuMzcyNTggNSA0IDEwLjM3MjYgNCAxN0M0IDIzLjYyNzQgOS4zNzI1OCAyOSAxNiAyOVpNMTUgMTBWMThIMTdWMTBIMTVaIiBmaWxsPSIjNzg3ODc4Ii8+Cjwvc3ZnPgo=');
    }
}

.rowsWrapper {
    height: 100%;
    margin: 0 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: stretch;
    align-items: stretch;
    counter-reset: section;
}
.row {
    display: flex;
    justify-content: center;
    align-content: stretch;
    align-items: stretch;
}
.ceil {
    margin: 3px;
    border-radius: 8px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #d7d7d7;
    display: flex;
    align-items: center;
    justify-content: center;
}

.isModal {
    padding: 20px 25px;
}

.playground {
    overflow: hidden;
    padding-bottom: 32px;
}
.playgroundMobile {
    display: flex;
    justify-content: center;
}

.playgroundGroup {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
}

.playgroundActionGroupMobile {
    flex-direction: column;
    align-items: center;
    margin: 0 10px 0 0 !important;
    padding: 8px 0 !important;
}

.playgroundActionGroupItemMobile {
    height: 66px;
    display: flex;
    align-items: center;
    margin: 0 0 12px !important;
    &:last-child {
        margin-bottom: 0 !important;
    }
}

.playgroundStaticGroupMobile {
    flex-direction: column;
    align-items: center;
    margin: 0 !important;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}
