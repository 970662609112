button,
a {
    &.button,
    &.button-style {
        font-family: $Roboto;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        padding: 0 20px;
        box-sizing: border-box;
        border: 1px solid;
        border-radius: 4px;
        cursor: pointer;
        position: relative;
        outline: none;
        white-space: nowrap;
        text-decoration: none;
        &:disabled {
            cursor: not-allowed;
            pointer-events: none !important;
        }
        &[data-size='s'] {
            height: 32px;
        }
        &[data-size='sm'] {
            height: 34px;
        }
        &[data-size='md'] {
            height: 36px;
        }
        &[data-size='lg'] {
            height: 44px;
        }
        // Primary
        &[data-variant='primary'] {
            color: $color_white;
            background-color: $color_blue--1;
            border-color: $color_blue--1;

            @include transition(
                $transition_duration--1,
                $transition_timing_function--1,
                $transition_delay--1,
                (box-shadow, border-color, background-color, color)
            );

            &:hover {
                box-shadow: $box-shadow--2;
                background-color: $color_light_blue--1;
                border-color: $color_light_blue--1;
            }
            &:disabled {
                background-color: $color_light_gray--2;
                border-color: $color_light_gray--2;
            }
        }
        // Primary-light
        &[data-variant='primary-light'] {
            color: $color_blue--1;
            border-color: $color_blue--1;

            @include transition(
                $transition_duration--1,
                $transition_timing_function--1,
                $transition_delay--1,
                (box-shadow, background-color, border-color)
            );

            &:hover {
                box-shadow: $box-shadow--2;
            }
            &:disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }
        // Primary-danger
        &[data-variant='primary-danger'] {
            color: $color_white;
            background-color: $color_light_red--2;
            border-color: $color_light_red--2;

            @include transition(
                $transition_duration--1,
                $transition_timing_function--1,
                $transition_delay--1,
                (border-color, background-color)
            );

            &:disabled {
                background-color: $color_light_gray--2;
                border-color: $color_light_gray--2;
            }
        }
        // Primary-danger 2
        &[data-variant='primary-danger--2'] {
            color: $color_white;
            background-color: $color_red--1;
            border-color: $color_red--1;
            @include transition(
                $transition_duration--1,
                $transition_timing_function--1,
                $transition_delay--1,
                (opacity)
            );
            &:hover {
                opacity: 0.7;
            }
        }
        // Primary (inverse)
        &[data-variant='primary--inverse'] {
            color: $color_blue--1;
            background-color: $color_white;
            border-color: $color_white;

            @include transition(
                $transition_duration--1,
                $transition_timing_function--1,
                $transition_delay--1,
                (box-shadow)
            );

            &:hover {
                box-shadow: $box-shadow--3;
            }
        }
        // Secondary
        &[data-variant='secondary'] {
            color: $color_light_gray--5;
            background-color: $color_white;
            border-color: $color_light_gray--2;
            @include transition(
                $transition_duration--1,
                $transition_timing_function--1,
                $transition_delay--1,
                (border-color, color)
            );
            &:hover {
                border-color: $color_light_gray--5;
            }
            &:disabled {
                color: $color_middle_gray--2;
            }
        }
        &[data-variant='secondary--2'] {
            color: $color_dark_gray--1;
            background-color: $color_white;
            border-color: $color_middle_gray--2;
            @include transition(
                $transition_duration--1,
                $transition_timing_function--1,
                $transition_delay--1,
                (border-color, color, box-shadow)
            );
            &:hover {
                color: $color_dark_gray--3;
                border-color: $color_dark_gray--1;
            }
            &:disabled {
                color: $color_light_gray--2;
                border-color: $color_light_gray--2;
                box-shadow: $box-shadow--2;
            }
        }
        &[data-variant='secondary-white'] {
            color: $color_white;
            background-color: transparent;
            border-color: $color_white;
        }
        // Black-white
        &[data-variant='black-white'] {
            color: $color_white;
            background-color: $color_black;
            border-color: $color_middle_gray--6;
            &[data-icon='back'] {
                padding: 0 12px;
                > svg {
                    margin-right: 10px;
                    path.color_stroke--1 {
                        stroke: $color_middle_gray--6 !important;
                    }
                }
            }
        }
        &[data-variant='black-white--2'] {
            font-family: $Ubuntu;
            color: $color_black;
            background-color: $color_white;
            border-color: $color_white;
        }
        // Transparent
        &[data-variant='transparent'] {
            font-family: $Ubuntu;
            color: $color_white;
            background-color: transparent;
            border-color: $color_white;
            &:disabled {
                opacity: 0.5;
            }
        }
    }
}
