@import '../../../../../../../../styles/variables';
@import '../../../../../../../../styles/mixins';

.compactBox {
    padding: 16px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
    margin: 0 8px;
    @include max_rwd(1024) {
        width: 100% !important;
        margin: 0 0 8px 0;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.clickableCompactBox {
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}

.iconBox {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 12px;
}
.icon {
    position: relative;
}

.textBox {
    display: flex;
    width: 100%;
}

.textBoxLeft {
    width: 100%;
}

.title {
    font-size: 16px;
    line-height: 20px;
    color: #3c3c3c;
    margin-bottom: 8px;
}

.text {
    font-size: 14px;
    line-height: 16px;
    color: #787878;
}

.readMoreLink {
    font-size: 14px;
    line-height: 16px;
    color: #2990fb;
    text-decoration: none;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}

.textBoxRight {
    flex-shrink: 0;
    margin-left: 20px;
}
