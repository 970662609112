.page--editor {
    .editor-tab {
        //overflow: hidden;
        &--preview,
        .workspace_cnt {
            display: flex;
            align-items: center;
            flex-direction: column;
            .device-changer {
                display: flex;
                justify-content: center;
                padding: 0 44px 12px;
                background-color: $color_black;
                border-radius: 0 0 8px 8px;
                > li {
                    margin-right: 30px;
                    cursor: pointer;
                    &:last-child {
                        margin-right: 0;
                    }
                    &.desktop {
                        > svg path.color_stroke--1,
                        line.color_stroke--1 {
                            stroke: $color_white;
                        }
                    }
                    &.mobile {
                        > svg rect.color_stroke--1,
                        line.color_stroke--1 {
                            stroke: $color_white;
                        }
                    }
                    &.is-active {
                        cursor: default;
                        pointer-events: none;
                        &.desktop {
                            > svg path.color_stroke--1,
                            line.color_stroke--1 {
                                stroke: $color_light_blue--1;
                            }
                        }
                        &.mobile {
                            > svg rect.color_stroke--1,
                            line.color_stroke--1 {
                                stroke: $color_light_blue--1;
                            }
                        }
                    }
                }
            }
            .iframe_wrapper {
                background-color: $color_light_gray--13;
                position: relative;
                max-width: 100%;
                &.desktop {
                    margin-top: 30px;
                    width: 800px;
                    height: 600px;
                    &.editor {
                        width: 1200px;
                        background-color: transparent;
                        height: auto;
                        margin-bottom: 20px;
                    }
                }
                &.mobile {
                    margin-top: 16px;
                    border: 44px solid #ffffff;
                    border-left-width: 8px;
                    border-right-width: 8px;
                    border-radius: 20px;
                    width: 320px;
                    height: 560px;
                    &:before {
                        content: '';
                        position: absolute;
                        top: -29px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 14px;
                        height: 14px;
                        background-color: $color_light_gray--2;
                        border-radius: 50%;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: -24px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 60px;
                        height: 8px;
                        background-color: $color_light_gray--2;
                        border-radius: 12px;
                    }
                }

                > .iframe_inner {
                    overflow-x: hidden;
                    overflow-y: auto;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    -ms-overflow-style: none; /* Internet Explorer 10+ */
                    scrollbar-width: none; /* Firefox */
                    &::-webkit-scrollbar {
                        display: none; /* Safari and Chrome */
                    }
                    &.editor {
                        position: relative;
                    }
                }
            }
        }
        &--integrations {
            width: 1390px;
            margin: 40px auto;
            h1 {
                font-family: $Roboto;
                font-size: 24px;
                color: $color_white;
                text-align: center;
                margin-bottom: 40px;
                > span {
                    color: $color_light_blue--3;
                    text-decoration: underline;
                    cursor: pointer;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            .integrations-wrap {
                overflow: hidden;
                .cards {
                    display: flex;
                    flex-wrap: wrap;
                    margin: -12px;
                    &-item {
                        user-select: none;
                        width: calc(33.333% - 24px);
                        margin: 12px;
                        height: 240px;
                        background-color: $color_white;
                        border-radius: 4px;
                        position: relative;
                        padding: 50px 36px 20px;
                        box-sizing: border-box;
                        text-align: center;
                        cursor: pointer;
                        &.is-checked {
                            .switcher {
                                background-color: $color_blue--1;
                                &:before {
                                    border-color: $color_blue--1;
                                    left: calc(100% - 21px);
                                }
                            }
                        }
                        &.is-disabled {
                            cursor: not-allowed;
                        }
                        .switcher {
                            position: absolute;
                            top: 20px;
                            right: 20px;
                            width: 38px;
                            height: 19px;
                            background-color: $color_middle_gray--2;
                            border-radius: 9.5px;
                            @include transition(
                                $transition_duration--1,
                                $transition_timing_function--1,
                                $transition_delay--1,
                                (background-color)
                            );
                            &:before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: -1px;
                                width: 21px;
                                height: 21px;
                                background-color: $color_white;
                                border-color: $color_light_gray--12;
                                border-width: 1px;
                                border-style: solid;
                                border-radius: 50%;
                                box-sizing: border-box;
                                @include transition(
                                    $transition_duration--1,
                                    $transition_timing_function--1,
                                    $transition_delay--1,
                                    (border-color, left)
                                );
                            }
                        }
                        .image {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 80px;
                            background-position: center;
                            background-repeat: no-repeat;
                            > img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        > p {
                            margin-top: 20px;
                            text-align: center;
                            font-size: 14px;
                            line-height: 16px;
                            color: $color_light_gray--4;
                        }
                        > .coming-soon {
                            background-color: $color_light_gray--3;
                            border: 1px solid $color_light_gray--2;
                            box-sizing: border-box;
                            border-radius: 4px;
                            display: inline-block;
                            color: $color_dark_gray--1;
                            font-size: 16px;
                            padding: 10px 40px;
                            margin-top: 36px;
                        }
                    }
                }
            }
            .settings-window {
                visibility: hidden;
                opacity: 0;
                position: fixed;
                z-index: 10;
                top: 60px;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.5);
                @include transition(
                    $transition_duration--3,
                    $transition_timing_function--1,
                    $transition_delay--1,
                    (visibility, opacity)
                );
                &.is-active {
                    visibility: visible;
                    opacity: 1;
                    .content {
                        transform: translateX(0);
                    }
                }
                .content {
                    transform: translateX(100%);
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 600px;
                    background-color: $color_white;
                    padding: 40px 80px;
                    box-sizing: border-box;
                    @include transition(
                        $transition_duration--3,
                        $transition_timing_function--1,
                        $transition_delay--1,
                        (transform)
                    );

                    .close-btn {
                        position: absolute;
                        right: 100%;
                        top: 0;
                        padding: 15px;
                        cursor: pointer;
                        @include transition(
                            $transition_duration--1,
                            $transition_timing_function--1,
                            $transition_delay--1,
                            (opacity)
                        );
                        &:hover {
                            opacity: 0.7;
                        }
                    }

                    &__title {
                        font-family: $Roboto;
                        font-size: 20px;
                        color: $color_dark_gray--3;
                        font-weight: normal;
                        margin-bottom: 40px;
                    }
                    &__sub-title {
                        font-family: $Roboto;
                        font-size: 16px;
                        color: $color_dark_gray--3;
                        font-weight: normal;
                        margin-bottom: 24px;
                    }

                    .input-box {
                        margin-bottom: 36px;
                        .title {
                            font-size: 14px;
                        }
                    }

                    ul {
                        li {
                            font-family: $Roboto;
                            font-size: 14px;
                            line-height: 16px;
                            font-weight: normal;
                            color: $color_dark_gray--1;
                            margin-bottom: 16px;
                            a {
                                text-decoration: none;
                                color: $color_blue--1;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .btns {
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 36px;
                        > a,
                        > button {
                            margin-right: 12px;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
        &--publish {
            text-align: center;
            > h1 {
                font-family: $Roboto;
                font-size: 24px;
                color: $color_white;
                font-weight: normal;
                margin: 40px 0 20px;
            }
            > button {
                margin: 0 auto;
            }
            > p {
                font-family: $Roboto;
                font-size: 16px;
                color: $color_white;
                font-weight: normal;
                margin-bottom: 20px;
            }
            > div.loading-title {
                padding-top: 40px;
            }
            .wrap-window {
                display: inline-block;
                .boxes {
                    display: flex;
                    .box {
                        position: relative;
                        width: 496px;
                        box-sizing: border-box;
                        background-color: $color_middle_gray--7;
                        border-radius: 4px;
                        margin: 28px 0 0;
                        padding: 25px 100px 35px;
                        &:nth-child(2) {
                            margin-left: 24px;
                        }
                        &--personality {
                            padding-left: 40px;
                            padding-right: 40px;
                            > div.workplace {
                                display: flex;
                                > div.graph {
                                    width: 201px;
                                    height: 201px;
                                    flex-shrink: 0;
                                    text {
                                        font-family: $Roboto !important;
                                        font-size: 14px !important;
                                        font-weight: normal;
                                    }
                                }
                                > div.list {
                                    width: 100%;
                                    overflow: hidden;
                                    text-align: left;
                                    padding-left: 25px;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    > ul {
                                        > li {
                                            cursor: help;
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 8px;
                                            overflow: hidden;
                                            > p {
                                                font-size: 12px;
                                                line-height: 12px;
                                                color: #fff;
                                                white-space: nowrap;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                            }
                                            > span {
                                                width: 16px;
                                                height: 16px;
                                                border-radius: 50%;
                                                background-color: #f47560;
                                                margin-right: 12px;
                                                flex-shrink: 0;
                                            }
                                            > img {
                                                width: 16px;
                                                flex-shrink: 0;
                                                margin-right: 12px;
                                            }
                                        }
                                    }
                                    > p {
                                        font-size: 12px;
                                        line-height: 12px;
                                        color: rgba(255, 255, 255, 0.8);
                                        padding: 8px 0 10px;
                                    }
                                }
                            }
                        }
                        &--trivia {
                            padding-left: 40px;
                            padding-right: 40px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            > div.workplace {
                                > p {
                                    font-size: 16px;
                                    color: #ffffff;
                                    margin-bottom: 20px;
                                }
                                > ul.graph {
                                    width: 100%;
                                    white-space: nowrap;
                                    display: flex;
                                    > li {
                                        height: 44px;
                                        border: 1px solid #fff;
                                        box-sizing: border-box;
                                        display: flex;
                                        flex-shrink: 0;
                                        align-items: center;
                                        justify-content: center;
                                        font-size: 16px;
                                        line-height: 12px;
                                        color: #000000;
                                        &:first-child {
                                            border-radius: 4px 0 0 4px;
                                        }
                                        &:last-child {
                                            border-radius: 0 4px 4px 0;
                                        }
                                    }
                                }
                                > ul.map {
                                    display: flex;
                                    flex-wrap: wrap;
                                    width: 100%;
                                    margin-top: 20px;
                                    justify-content: center;
                                    > li {
                                        display: flex;
                                        align-items: center;
                                        margin: 0 16px 12px 0;
                                        > span {
                                            border-radius: 50%;
                                            width: 16px;
                                            height: 16px;
                                            flex-shrink: 0;
                                            margin-right: 8px;
                                        }
                                    }
                                }
                            }
                        }
                        > h3 {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-family: $Roboto;
                            font-size: 24px;
                            color: $color_white;
                            font-weight: normal;
                            margin-bottom: 20px;
                            min-height: 48px;
                            > img {
                                flex-shrink: 0;
                                margin-right: 10px;
                            }
                        }

                        > .inner-box {
                            border-radius: 4px;
                            background-color: $color_white;
                            overflow: hidden;
                            text-align: left;
                            .preview-img {
                                height: 155px;
                                width: 100%;
                                background-position: center;
                                background-size: cover;
                                background-repeat: no-repeat;
                                &.is-loading {
                                    background-size: 200px;
                                }
                            }
                            .text {
                                padding: 8px 12px;
                                .title {
                                    font-family: $Roboto;
                                    font-size: 14px;
                                    font-weight: bold;
                                    color: $color_dark_gray--3;
                                    margin-bottom: 4px;
                                }
                                .description {
                                    font-family: $Roboto;
                                    font-size: 12px;
                                    font-weight: normal;
                                    color: $color_middle_gray--4;
                                }
                            }
                        }
                        > .btns {
                            &--bottom {
                                position: absolute;
                                bottom: 35px;
                                left: 0;
                                width: 100%;
                            }
                            > button {
                                min-width: 144px;
                                justify-content: center;
                                margin: 35px auto 0;
                            }
                        }

                        > .loading {
                            padding-top: 80px;
                            > img {
                            }
                        }
                    }
                }
                .custom-url-box {
                    width: 100%;
                    min-width: 496px;
                    margin: 24px auto 0;
                    display: flex;
                    align-items: center;
                    height: 128px;
                    background-color: $color_middle_gray--7;
                    border-radius: 4px;
                    box-sizing: border-box;
                    &.compact {
                        display: block;
                        height: auto;
                        padding: 24px 48px 19px;
                        width: 496px;
                        > .side {
                            width: 100%;
                            margin: 0 0 12px 0;
                            padding: 0;
                            text-align: center;
                            &--left {
                                padding-right: 15px;
                                .edit-btn {
                                    display: block;
                                }
                            }
                            &--right {
                                > .edit-btn {
                                    display: none;
                                }
                            }
                            &:last-child {
                                margin: 0;
                            }
                        }
                    }
                    > .side {
                        width: 50%;
                        box-sizing: border-box;
                        text-align: left;
                        position: relative;
                        &--left {
                            display: flex;
                            align-items: center;
                            padding-left: 100px;
                            .switcher {
                                cursor: pointer;
                                position: relative;
                                width: 38px;
                                height: 19px;
                                background-color: $color_middle_gray--2;
                                border-radius: 9.5px;
                                @include transition(
                                    $transition_duration--1,
                                    $transition_timing_function--1,
                                    $transition_delay--1,
                                    (background-color)
                                );
                                &:before {
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    top: -1px;
                                    width: 21px;
                                    height: 21px;
                                    background-color: $color_white;
                                    border-color: $color_light_gray--12;
                                    border-width: 1px;
                                    border-style: solid;
                                    border-radius: 50%;
                                    box-sizing: border-box;
                                    @include transition(
                                        $transition_duration--1,
                                        $transition_timing_function--1,
                                        $transition_delay--1,
                                        (border-color, left)
                                    );
                                }
                                &.is-checked {
                                    background-color: $color_blue--1;
                                    &:before {
                                        border-color: $color_blue--1;
                                        left: calc(100% - 21px);
                                    }
                                }
                            }
                            > p {
                                font-family: $Roboto;
                                font-weight: normal;
                                font-size: 24px;
                                margin-left: 24px;
                                color: $color_white;
                                > span {
                                    cursor: pointer;
                                    color: $color_blue--1;
                                }
                            }

                            .edit-btn {
                                position: absolute;
                                top: 50%;
                                right: -19px;
                                transform: translateY(-50%);
                            }
                        }
                        &--right {
                            padding-right: 100px;
                            font-family: $Roboto;
                            font-weight: normal;
                            font-size: 16px;
                            letter-spacing: -0.24px;
                            color: $color_light_gray--2;
                            text-align: right;
                            .project_id {
                                display: block;
                                margin-top: 3px;
                                > span {
                                    font-family: $Roboto;
                                    font-weight: 300;
                                    font-size: 24px;
                                    color: $color_light_blue--1;
                                    &.ph {
                                        color: $color_middle_gray--2;
                                    }
                                }
                            }

                            .edit-btn {
                                position: absolute;
                                top: 50%;
                                right: 51px;
                                transform: translateY(-50%);
                            }
                        }

                        .edit-btn {
                            cursor: pointer;
                            > svg {
                                path.color_fill--1 {
                                    fill: $color_blue--1 !important;
                                    @include transition(
                                        $transition_duration--1,
                                        $transition_timing_function--1,
                                        $transition_delay--1,
                                        (fill)
                                    );
                                }
                            }
                            &:hover {
                                > svg {
                                    path.color_fill--1 {
                                        fill: $color_light_blue--1 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
